import { Grid } from 'cv-library-react-web';
import { themes } from 'cv-react-core';

import GridActivityIndicator from './GridActivityIndicator';
import GridCell from './GridCell';
import GridEditor from './GridEditor';
import GridHeaderCellGroup from './GridHeaderCellGroup';
import GridSelectionTray from './GridSelectionTray';
import SpacerCell from './SpacerCell';

// Get theme method
import getGridTheme from './Grid.theme';

// Get theme component method
const { themeComponent } = themes;

// Generate and export a themed component
export default themeComponent(Grid, getGridTheme);
export {
    GridActivityIndicator,
    GridCell,
    GridEditor,
    GridHeaderCellGroup as GridHeaderCell,
    GridSelectionTray,
    SpacerCell,
};
