import React from 'react';
import * as PropTypes from 'prop-types';
import { engineConstants, utilities } from 'cv-react-core';
import { BaseTooltip } from 'cv-library-react-web';
import Button from '../components/base/Button';
import RWSaltComponent from './RWSaltComponent';
import RWText from './RWText';
import RWBox from './RWBox';

const {
    styleHelper,
} = utilities;

export default class RWAction extends RWSaltComponent {
    static propTypes = {
        id: PropTypes.string.isRequired,
        type: PropTypes.oneOf([
            engineConstants.action.type.button,
            engineConstants.action.type.text,
            engineConstants.action.type.custom,
        ]),
        style: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.arrayOf(PropTypes.object),
        ]),
        xStyle: PropTypes.oneOfType([
            // PropTypes.arrayOf(PropTypes.object),
            PropTypes.object,
        ]),
        children: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.arrayOf(PropTypes.element),
            PropTypes.string,
        ]),
        onFireAction: PropTypes.func,
        menuItem: PropTypes.shape({
            actionId: PropTypes.string,
            directive: PropTypes.string,
            iconUrl: PropTypes.string,
            id: PropTypes.string,
            label: PropTypes.string,
            visible: PropTypes.bool,
            modes: PropTypes.arrayOf(PropTypes.string),
        }),
        params: PropTypes.shape({
            onPlatformNodeEvent: PropTypes.func,
        }),
    };

    render() {
        const {
            style,
            type,
            children,
            hotkey,
        } = this.props;

        // Combine style arrays
        let combinedStyles = {};
        if (Array.isArray(style)) {
            style.forEach((styleObj) => {
                combinedStyles = {
                    ...combinedStyles,
                    ...styleObj,
                };
            });
        }
        else {
            combinedStyles = style;
        }

        this.styles = styleHelper.categorizeWebStyles(combinedStyles);
        if (hotkey) this.registerHotkeyHandler(hotkey);

        if (type === engineConstants.action.type.button) {
            return this.renderButton();
        }
        if (type === engineConstants.action.type.text) {
            return this.renderText();
        }
        if (type === engineConstants.action.type.custom) {
            return this.renderCustom();
        }

        if (children) {
            return this.renderCustom();
        }

        return this.renderText();
    }

    componentWillUnmount() {
        const { hotkey } = this.props;
        if (!hotkey) return;

        const { eventType } = hotkey;
        if (eventType) {
            document.removeEventListener(eventType, this.handleOnKeyDown, false);
        }
    }

    renderButton() {
        const {
            menuItem,
            xStyle,
            children,
            params,
            tooltip,
        } = this.props;

        const buttonText = menuItem ? menuItem.label : null;
        const buttonProps = {
            text: buttonText,
            children,
            onClick: this.handleOnPress,
            contextStyles: {
                container: this.styles.container,
                text: this.styles.text,
            },
        };
        const containerProps = { params };

        if (xStyle) {
            if (xStyle.container) {
                buttonProps.contextStyles.container = {
                    ...buttonProps.contextStyles.container,
                    ...xStyle.container,
                };
            }
            if (xStyle.text) {
                buttonProps.contextStyles.text = {
                    ...buttonProps.contextStyles.text,
                    ...xStyle.text,
                };
            }
            if (xStyle.buttonType === 'secondary') {
                buttonProps.variant = 'outlined';
            }
        }

        // Wrapping container for autosize
        return (
            <RWBox { ...containerProps }>
                <BaseTooltip tooltip={ tooltip }>
                    <Button { ...buttonProps } />
                </BaseTooltip>
            </RWBox>
        );
    }

    renderText() {
        const {
            menuItem,
            style,
            children,
            params,
        } = this.props;
        const menuText = menuItem ? menuItem.label : null;
        const newChildren = children || menuText;

        // Pass params for autosize
        const textProps = {
            style,
            params,
        };

        return (
            <div
                className="abs-action__text-container"
                onClick={ this.handleOnPress }>
                <RWText { ...textProps }>
                    { newChildren }
                </RWText>
            </div>
        );
    }

    renderCustom() {
        const {
            children,
            menuItem,
        } = this.props;
        const tooltip = menuItem ? menuItem.label : '';
        const customProps = {
            className: 'abs-action__custom-container',
            style: {
                ...this.styles.container,
                ...this.styles.text,
            },
            onClick: this.handleOnPress,
        };

        // Pass params for autosize
        return (
            <BaseTooltip tooltip={ tooltip }>
                <div { ...customProps }>
                    { children }
                </div>
            </BaseTooltip>
        );
    }

    registerHotkeyHandler = (hotkeyProps) => {
        const { eventType } = hotkeyProps;
        if (eventType) {
            document.addEventListener(eventType, this.handleOnKeyDown, false);
        }
    }

    handleOnKeyDown = (event) => {
        const { hotkey } = this.props;
        if (!hotkey) return;

        const { eventCodes } = hotkey;
        if (!eventCodes) return;

        let foundKeysCounter = 0;
        eventCodes.forEach((keyCode) => {
            const eventKey = event[keyCode.key];
            if (eventKey && eventKey === keyCode.value) {
                foundKeysCounter += 1;
            }
        });

        if (foundKeysCounter === eventCodes.length) {
            this.handleOnPress(event);
        }
    }

    handleOnPress = (event) => {
        const { onFireAction } = this.props;
        if (onFireAction) {
            event.preventDefault();
            event.stopPropagation();
            onFireAction();
        }
    }
}
