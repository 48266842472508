import React from 'react';
import * as PropTypes from 'prop-types';

const RWMenu = (props) => {
    const {
        menu,
        style,
    } = props;

    // Log.debug('PLATFORM COMPONENT: MENU: RENDER: ', props);

    // Combine style arrays
    let combinedStyles = {};
    if (Array.isArray(style)) {
        style.forEach((styleObj) => {
            combinedStyles = {
                ...combinedStyles,
                ...styleObj,
            };
        });
    }
    else {
        combinedStyles = style;
    }

    // Log.debug('PLATFORM COMPONENT: MENU: PROPS: ', { style: combinedStyles }, menu);

    // This should perhaps use Box to add some magic sizing
    return (
        <div
            className="abs-menu__container"
            style={ combinedStyles }>
            { menu }
        </div>
    );
};

RWMenu.propTypes = {
    menu: PropTypes.object.isRequired,
    style: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.arrayOf(PropTypes.object),
    ]),
};

export default RWMenu;
