import { rootStore } from 'cv-react-core';

const coreStyles = {
    container: {
    },

    dividerTop: {
        borderTopStyle: 'solid',
        borderTopWidth: '1px',
    },

    header: {
    },

    headerClose: {
        padding: '10px',
    },

    headerCloseIcon: {
        fontSize: '24px',
    },

    drawerFooter: {
        cursor: 'pointer',
    },

    menuButton: {
        marginLeft: '-12px',
    },

    menuIcon: {
        color: '#fff',
        fontSize: '24px',
    },

    menuIconDisabled: {
        color: '#77777',
        fontSize: '24px',
    },

    headerNavigation: {
    },

    content: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },

    fixedHeaderContainer: {
        display: 'flex',
        flexDirection: 'column',
        position: 'sticky',
        top: 0,
        zIndex: 1,
    },

    footer: {
    },
};

const getStyles = (contextStyles = {}) => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();
    return {
        content: {
            backgroundColor: theme.pageBackgroundColor,
            ...coreStyles.content,
            ...contextStyles.content,
        },

        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },

        dividerTop: {
            ...coreStyles.dividerTop,
            ...contextStyles.dividerTop,
        },

        drawerFooter: {
            ...coreStyles.drawerFooter,
            ...contextStyles.drawerFooter,
        },

        fixedHeaderContainer: {
            ...coreStyles.fixedHeaderContainer,
            ...contextStyles.fixedHeaderContainer,
        },

        footer: {
            ...coreStyles.footer,
            ...contextStyles.footer,
        },

        header: {
            ...coreStyles.header,
            ...contextStyles.header,
        },

        headerClose: {
            ...coreStyles.headerClose,
            ...contextStyles.headerClose,
            backgroundColor: theme.drawerMenuItemBackgroundColor,
            color: theme.drawerMenuItemTextColor,
        },

        headerCloseIcon: {
            ...coreStyles.headerCloseIcon,
            ...contextStyles.headerCloseIcon,
            color: theme.drawerMenuItemTextColor,
        },

        headerLink: {
            ...coreStyles.headerLink,
            ...contextStyles.headerLink,
        },

        headerLinkSelected: {
            ...coreStyles.headerLink,
            ...contextStyles.headerLink,
            ...coreStyles.headerLinkSelected,
            ...contextStyles.headerLinkSelected,
        },

        headerNavigation: {
            ...coreStyles.headerNavigation,
            ...contextStyles.headerNavigation,
        },

        menuButton: {
            ...coreStyles.menuButton,
            ...contextStyles.menuButton,
        },

        menuIcon: {
            ...coreStyles.menuIcon,
            ...contextStyles.menuIcon,
        },

        menuIconDisabled: {
            ...coreStyles.menuIconDisabled,
            ...contextStyles.menuIconDisabled,
        },

        logout: {
            title: {
                ...theme.fonts.logoutPrompt,
                fontSize: theme.fonts.fontSizeMidLarge,
            },
            text: theme.fonts.logoutPrompt,
        },

        drawerText: {
            container: {
                display: 'flex',
                flexGrow: 1,
            },
            text: {
                paddingLeft: 10,
                ...theme.fonts.default,
                color: theme.drawerMenuItemActiveTextColor,
                fontWeight: 'normal',
            },
        },
        topBannerInfo: {
            color: '#fff',
            fontFamily: 'Open Sans',
            fontSize: '16px',
        },
        centerImage: {
            maxHeight: '35px',
            maxWidth: '100px',
            objectFit: 'contain',
            paddingRight: '5px',
        },
        centerBox: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
    };
};

export default getStyles;
