import { rootStore } from 'cv-react-core';

const getTheme = () => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    // Populate the component parts with theme styles
    return {
        button: {
            backgroundColor: theme.buttonPrimaryBackgroundColor,
            borderColor: theme.buttonPrimaryBorderColor,
        },

        buttonHover: {
            backgroundColor: theme.buttonUnderlayColor,
        },

        buttonText: {
            ...theme.fonts.buttonPrimary,
        },

        container: {
        },

        error: {
        },

        heading: {
            backgroundColor: theme.panelBackgroundColor,
            ...theme.fonts.panelTitle,
        },
    };
};

export default getTheme;
