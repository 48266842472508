import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
// import { trace } from 'mobx';
import { observer } from 'mobx-react';
import { CvScrollView } from 'cv-library-react-web';
// import { Log } from 'cv-dialog-sdk';

const LIST_SCROLL_POSITION = 'LIST_SCROLL_POSITION';

@observer
class TableScrollContainer extends PureComponent {
    static propTypes = {
        children: PropTypes.object,
        dialogStore: PropTypes.object,
        uiStore: PropTypes.object,
        onRequestMore: PropTypes.func,
        onScrollPositionSet: PropTypes.func,
        loadingIndicatorComponent: PropTypes.object,
    };

    static defaultProps = {
        onRequestMore: () => Promise.resolve(),
        onScrollPositionSet: () => Promise.resolve(),
    }

    constructor(props) {
        super(props);
        this.scrollRef = React.createRef();
        this.handleOnRequestMore = this.handleOnRequestMore.bind(this);
        this.handleOnScrollPositionSet = this.handleOnScrollPositionSet.bind(this);
        const { dialogStore } = this.props;
        const { dialog } = dialogStore;
        this.dialogId = dialog.id;
        const { uiStore } = this.props;
        this.savedScrollPosition = uiStore.getValueForUIObject(this.dialogId, LIST_SCROLL_POSITION);
    }

    render() {
        // trace(true);
        const {
            dialogStore,
            loadingIndicatorComponent: IsLoadingComponent,
            children} = this.props;
        const { isListInitialized, queryInProgress } = dialogStore;

        return (
            <CvScrollView
                ref={ this.scrollRef }
                isLoading={ queryInProgress }
                loadingDataIndicator={ IsLoadingComponent }
                scrollToPosition={ isListInitialized }
                scrollPosition={ this.savedScrollPosition }
                onRequestMore={ this.handleOnRequestMore }
                onScrollPositionSet={ this.handleOnScrollPositionSet }>
                { children }
            </CvScrollView>
        );
    }

    componentWillUnmount() {
        if (!this.scrollRef || !this.scrollRef.current) return;
        const { uiStore } = this.props;
        uiStore.setValueForUIObject(this.dialogId, LIST_SCROLL_POSITION, this.scrollPosition );
    }

    handleOnScrollPositionSet() {
        const { onScrollPositionSet } = this.props;
        this.savedScrollPosition = null;
        onScrollPositionSet();
    }

    handleOnRequestMore() {
        const { onRequestMore } = this.props;
        onRequestMore();
    }

    get scrollPosition() {
        if (!this.scrollRef || !this.scrollRef.current) return { top: 0, left: 0 };
        return { top: this.scrollRef.current.scrollTop, left: this.scrollRef.current.scrollLeft };
    }
}

export default TableScrollContainer;
