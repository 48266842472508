import React, {Fragment} from 'react';
import * as PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { rootStore, constants, workbenchController } from 'cv-react-core';

import Route from './Route';
import WorkbenchPage from '../components/pages/WorkbenchPage/WorkbenchPage';
import LoadingIndicator from '../components/modal/LoadingIndicator';
import ErrorRouteModal from '../components/modal/ErrorRouteModal';
import lang from '../nls/i18n';

const {
    ui,
} = constants;
const {
    WORKBENCH_UI_OBJECT_ID,
} = ui;

@observer
class WorkbenchRoute extends Route {
    static propTypes = {
        onOpenLauncher: PropTypes.func,
    };

    render() {
        const {
            sessionStore,
            uiStore,
        } = rootStore;
        const {
            selectedWorkbenchStore,
        } = sessionStore;

        // Set browser tab name to the workbench name.
        // Doing this on each route for consistency for now.
        const { workbench } = selectedWorkbenchStore;
        document.title = workbench.name;
        const loadingProps = {
            objectId: WORKBENCH_UI_OBJECT_ID,
            uiStore,
        };
        return (
            <Fragment>
                { React.createElement(LoadingIndicator, { ...loadingProps }) }
                { React.createElement(ErrorRouteModal, { ...loadingProps }) }
                <WorkbenchPage
                    workbenchStore={ selectedWorkbenchStore }
                    onOpenLauncher={ this.handleOnOpenLauncher }
                    onTransition={ this.handleTransition }
                    onError={ this.handleError } />
            </Fragment>
        );
    }

    componentDidMount() {
        const {
            uiStore,
            settingsStore,
            sessionStore } = rootStore;
        const dl = settingsStore.getValue('DEEP_LINKING_ID');
        if (dl) {
            this.handleDeeplink(dl);
        }
        workbenchController.clearGloballyBusy(WORKBENCH_UI_OBJECT_ID, uiStore);
        const oAuthToken = sessionStore.getOAuthToken();
        if (oAuthToken) {
            const { id: sessionId, tenantId } = sessionStore.session;
            workbenchController.performWorkbenchOAuthCallBackCall(tenantId, sessionId, uiStore, oAuthToken, this.handleTransition, null, constants.clientType.DESKTOP);
        }
    }

    handleOnOpenLauncher = (workbenchStore, launcher, isInitialLaunchAction, modifiers) => {
        const { uiStore, settingsStore } = rootStore;
        if (settingsStore.getValue('DEEP_LINKING_ID') && isInitialLaunchAction) return;
        workbenchController.performWorkbenchAction(uiStore, workbenchStore, this.handleTransition, this.handleError, launcher, isInitialLaunchAction, modifiers);
        const { onOpenLauncher } = this.props;
        if (onOpenLauncher) onOpenLauncher();
    }

    handleDeeplink = (dl) => {
        const { settingsStore, sessionStore } = rootStore;
        sessionStore.performDeepLink(dl)
        .then((dialogOrRedirection) => {
            settingsStore.setValue('DEEP_LINKING_ID', null);
            this.handleTransition(dialogOrRedirection);
        })
        .catch((err) => {
            const error = {
                title: lang.dialog.errors.errorOpenDialogTitle,
                err,
                type: constants.error.errorTypes.soft,
            };
            settingsStore.setValue('DEEP_LINKING_ID', null);
            this.handleError(error);
        });
    }
}

export default WorkbenchRoute;
