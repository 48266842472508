const fileManager = {
    /**
     * For future reference. Only resolves promise now
     * @param {string} fileName
     * @returns {void}
     */
    getStreamConsumer(fileName) {
        Promise.resolve(fileName);
    },
    /**
     * convert base64 string back to original binary data, remove spaces for cross-browser compatibility
     * @param {string} fileType
     * @param {string} encodedData
     * @returns {Promise<string>}
     */
    getFileURL(fileType, encodedData) {
        const binary = atob(encodedData.replace(/\s/g, ''));
        const len = binary.length;
        const buffer = new ArrayBuffer(len);
        const view = new Uint8Array(buffer);
        for (let i = 0; i < len; i += 1) {
            view[i] = binary.charCodeAt(i);
        }

        // create the blob object with content-type
        const blob = new Blob([ view ], { type: fileType });
        const url = URL.createObjectURL(blob);
        return Promise.resolve(url);
    },
    /**
     * Remove extra params and decode URI
     * @param {string} fileName
     * @returns {string}
     */
    getDownloadFileName(fileName) {
        let downloadFileName = fileName;
        const index = fileName.indexOf('?');
        if (index > 0) {
            downloadFileName = fileName.substring(0, index);
        }
        return decodeURI(downloadFileName);
    },
};

export default fileManager;