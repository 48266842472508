import _defineProperty from"/home/vsts/work/1/s/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";/*       */import{MISC_A,QUALIFIERS,THIS}from'../gmlConstants';import GmlUtil from'../GmlUtil';import Context from'../Context';import Scroll from'../layoutAttribute/general/Scroll';import generalLayoutFactory from'../layoutAttribute/factory/GeneralLayoutFactory';import Height from'../layoutAttribute/general/Height';import Width from'../layoutAttribute/general/Width';import AlignItems from'../layoutAttribute/general/AlignItems';import Expand from'../layoutAttribute/general/Expand';import gmlAttributeFactory from'../layoutAttribute/factory/GmlAttributeFactory';import SimpleStringAttribute from'../layoutAttribute/SimpleStringAttribute';import Attribute from'../Attribute';import MutedLayoutAttribute from'../layoutAttribute/MutedLayoutAttribute';import StyleSet from'../StyleSet';import Base from'./Base';import FillerSearch from'../FillerSearch';import SaltBoxModel from'../SaltBoxModel';import FlexDirection from'../layoutAttribute/general/FlexDirection';export default class AbstractGml{constructor(jsonObject){_defineProperty(this,"json",void 0);this.json=jsonObject;}addBackgroundImage(context,children){if(context.parentContext){const bi=context.gml.getBackgroundImageAttribute(context);if(!(bi&&bi.value)){return;}const sourceParts=bi.value.split(',').map(m=>m.trim());const source=sourceParts[0];const resizeMode=sourceParts.length>=2?sourceParts[1]:'stretch';// cover, contain, stretch, repeat, center
const nineSliceInsets={};const insets=context.gml.getBackgroundNineSliceInsetsAttribute(context);if(insets&&insets.value){const insetParts=insets.value.split(',').map(m=>m.trim());if(insetParts.length===4){nineSliceInsets.capInsets={top:parseFloat(insetParts[0]),right:parseFloat(insetParts[1]),bottom:parseFloat(insetParts[2]),left:parseFloat(insetParts[3])};}}children.unshift({box:{style:{position:'absolute',top:0,right:0,bottom:0,left:0},children:[{image:{resizeMode,source,style:{position:'absolute',top:0,right:0,bottom:0,left:0},...nineSliceInsets}}]}});}}// For ad-hoc created styleSet.  Normal export handles debugColor for div/alist/plist.
applyDebugColor(myContext,styleSet){const dc=myContext.getDebugColorAttribute();if(dc&&dc.value==='true'){dc.exportTo(myContext,styleSet);}}applyDefaultFlex(myContext,styleSet){if(myContext.flexDirection.isRow()){styleSet.flexPolicy.setAlignItemsCenterSafe();}else{// eslint-disable-next-line no-lonely-if
if(GmlUtil.doesCrossAxisFlexOfAnyChild(myContext,myContext.flexDirection)){styleSet.flexPolicy.setAlignItemsStretchSafe();}else{styleSet.flexPolicy.setAlignItemsStartSafe();// Unless parent has an alignItems...
// NOTE: alignSelf must be used because the parent has to consider the needs of all of it's children.
// If any one needs a stretch, they all get a stretch without unless restated with the alignSelf.
const parentAI=myContext.parentContext&&myContext.parentContext.gml.getAlignItemsAttribute(myContext);const isEquallySized=myContext.parentContext&&myContext.parentContext.isEquallySized();const doIStretch=GmlUtil.doesCrossAxisFlex(myContext,myContext.flexDirection);if(!parentAI&&!doIStretch&&!isEquallySized){styleSet.flexPolicy.setAlignSelfStart();}}}const{parentContext}=myContext;if(parentContext&&parentContext.flexDirection.isRow()){if(!parentContext.isHorizontalScroll()){// Children of a row need to shrink, unless they are being scrolled.
styleSet.flexPolicy.setStandardShrinkSafe();}}}applyEquallySized(myContext,styleSet){// If the parent is equally sized, then each child needs these flex values.
const{parentContext}=myContext;if(parentContext&&parentContext.isEquallySized()){// Issue a warning if V1 is using equallySized on a column.
if(myContext.version.isV1()&&parentContext.flexDirection.isColumn()){myContext.warnings.addV1EquallySizedColWarning();return;}styleSet.flexPolicy.setStandardGrow();styleSet.flexPolicy.setStandardBasis();}}applyFlexForFiller(myContext,styleSet,parentFlexDirection){const{parentContext}=myContext;if(parentContext){const parentDirection=parentFlexDirection||parentContext.flexDirection;const fillerSearch=this.findFillers(parentContext);const{flexDirection}=myContext;if(flexDirection.isRow()&&this.getWidthAttribute(myContext)||flexDirection.isColumn()&&this.getHeightAttribute(myContext)){return;// Do not override the explicit size set
}if(fillerSearch.horizontalCount){if(parentDirection.isRow()){styleSet.flexPolicy.setStandardGrow();}else{styleSet.flexPolicy.setAlignSelfStretch();}}if(fillerSearch.verticalCount){if(parentDirection.isColumn()){styleSet.flexPolicy.setStandardGrow();}else{styleSet.flexPolicy.setAlignSelfStretch();}}}}asAssertAttribute(assertExpr){// Used to spread the assert expression on a box.
return assertExpr?{assert:{expr:assertExpr}}:{};}asQualifierExprString(){let inExpr=arguments.length>0&&arguments[0]!==undefined?arguments[0]:'';// Add qualifier conditions to the input expression.
const orientationGroup=[QUALIFIERS.landscape,QUALIFIERS.portrait];const sizeGroup=[QUALIFIERS.small,QUALIFIERS.smallLandscape,QUALIFIERS.smallPortrait,QUALIFIERS.medium,QUALIFIERS.mediumLandscape,QUALIFIERS.mediumPortrait,QUALIFIERS.large,QUALIFIERS.largeLandscape,QUALIFIERS.largePortrait];const modeGroup=[QUALIFIERS.readOnly,QUALIFIERS.maintenance];let myExpr='';const allQuals=(this.getAttributeValue('qualifiers')||'').split(',').map(m=>m.trim());const onlyMyGroup=group=>{return allQuals.filter(f1=>group.find(f2=>f2===f1));};const addToExpr=(i1,i2,operator)=>{return i1?"".concat(i1," ").concat(operator," (").concat(i2,")"):"(".concat(i2,")");};// Each group is a set of conditions to be ORed together. Those groups are then ANDed together.
let groupsFound=0;let currentGroup=onlyMyGroup(orientationGroup);if(currentGroup.length){let currentGroupExpr='';currentGroup.forEach(e=>{// landscape | portrait
const part="$lowercase($device.orientation) = '".concat(e.toLowerCase(),"'");currentGroupExpr=addToExpr(currentGroupExpr,part,'or');});myExpr=addToExpr(myExpr,currentGroupExpr,'and');groupsFound+=1;}currentGroup=onlyMyGroup(sizeGroup);if(currentGroup.length){let currentGroupExpr='';currentGroup.forEach(e=>{// small | small-landscape | small-portrait | medium | medium-landscape...
const partA="$lowercase($device.type) = '".concat(e.toLowerCase(),"'");const partB="$lowercase($device.type) = $substringBefore('".concat(e.toLowerCase(),"', '-')");const partC="$lowercase($device.orientation) = $substringAfter('".concat(e.toLowerCase(),"', '-')");const partD=addToExpr(partB,partC,'and');const part=addToExpr(partA,partD,'or');// where: partA: $device.type = 'SMALL'
// partB: $device.type = SMALL (parsed from small-landscape)
// partC: $device.orientation = LANDSCAPE (parsed from small-landscape)
// partD: ($device.type = SMALL) AND ($device.orientation = LANDSCAPE)
// part: ($device.type = 'SMALL') OR (($device.type = SMALL) AND ($device.orientation = LANDSCAPE))
currentGroupExpr=addToExpr(currentGroupExpr,part,'or');});myExpr=addToExpr(myExpr,currentGroupExpr,'and');groupsFound+=1;}currentGroup=onlyMyGroup(modeGroup);if(currentGroup.length){let currentGroupExpr='';currentGroup.forEach(e=>{// readOnly | maintenance
let part;if(e==='readOnly'){part='($dialog.isReadMode or ($dialog.view.editable = false))';}else{part='($dialog.isWriteMode and $dialog.view.editable = true)';}currentGroupExpr=addToExpr(currentGroupExpr,part,'or');});myExpr=addToExpr(myExpr,currentGroupExpr,'and');groupsFound+=1;}if(myExpr===''){return inExpr;// Just return what was passed in
}if(inExpr){return"((".concat(myExpr,") and ").concat(inExpr,")");// AND the content inside of parens.
}return groupsFound>1?"(".concat(myExpr,")"):myExpr;// Either add parens or not
}exportStyleTo(styleSet,context,including,excluding){// Apply cascading attributes.
if(context.parentContext){const parentCascading=context.parentContext.cascading;Object.keys(parentCascading).forEach(k=>{if(this.shouldProcessAttribute(parentCascading[k],including,excluding)){parentCascading[k].exportTo(context,styleSet);}});}// Apply named style attributes, which may overlay a parent style.
const namedStyles=this.getNamedStyleAttributes(context);Object.keys(namedStyles).forEach(k=>{const attribute=namedStyles[k];if(this.shouldProcessAttribute(attribute,including,excluding)){attribute.exportTo(context,styleSet);}});// Apply this level attributes, which may overlay a parent style.
Object.keys(GmlUtil.getAttributesAsObject(this.json)).forEach(k=>{const attribute=gmlAttributeFactory.attributeFor(this.json,k,context);if(attribute&&this.shouldProcessAttribute(attribute,including,excluding)){attribute.exportTo(context,styleSet);}if(!attribute){context.warnings.addUnknownAttribute(k);}});}getAlignItemsAttribute(context){const attribute=AbstractGml.getUntypedAttribute(this.json,MISC_A.alignItems,context);return attribute;}getAttributes(context){const result=[];Object.keys(GmlUtil.getAttributesAsObject(this.json)).forEach(k=>{const a=gmlAttributeFactory.attributeFor(this.json,k,context);if(a){result.push(a);}else{context.warnings.addUnknownAttribute(k);}});return result;}getAttributesAsObject(mergeInto){return GmlUtil.getAttributesAsObject(this.json,mergeInto);}getAttributeValue(name,cascading){const v=GmlUtil.getValueForExpr(this.json,[THIS,name]);if(v===undefined&&cascading){const a=cascading[name];if(a){return a.value;}}return v;}getCascadingAttributes(context){let base=arguments.length>1&&arguments[1]!==undefined?arguments[1]:{};const result=Object.assign({},base);// As a named style in this GML document.
if(context){const namedStyles=this.getNamedStyleAttributes(context);Object.keys(namedStyles).forEach(k=>{const attribute=namedStyles[k];if(attribute.isCascading){result[attribute.name]=attribute;}});// On this level of GML.
this.getAttributes(context).filter(f=>f.isCascading).forEach(e=>{result[e.name]=e;});}return result;}getActionIdAttribute(context){const attribute=AbstractGml.getUntypedAttribute(this.json,MISC_A.actionId,context);return attribute;}getBackgroundImageAttribute(context){const attribute=AbstractGml.getUntypedAttribute(this.json,MISC_A.backgroundImage,context);return attribute;}getBackgroundNineSliceInsetsAttribute(context){const attribute=AbstractGml.getUntypedAttribute(this.json,MISC_A.backgroundNineSliceInsets,context);return attribute;}getCvId(){return this.json.$.cvId;}getEquallySizedAttribute(context){const attribute=AbstractGml.getUntypedAttribute(this.json,MISC_A.equallySized,context);return attribute;}static getEquallySizedAttributeJson(json,context){const attribute=AbstractGml.getUntypedAttribute(json,MISC_A.equallySized,context);return attribute;}getExpandAttribute(context){return AbstractGml.getExpandAttributeJson(this.json,context);}static getExpandAttributeJson(json,context){const attribute=AbstractGml.getUntypedAttribute(json,MISC_A.expand,context);return attribute;}getHeightAttribute(context){return AbstractGml.getHeightAttributeJson(this.json,context);}static getHeightAttributeJson(json,context){const attribute=AbstractGml.getUntypedAttribute(json,MISC_A.height,context);return attribute;}getNamedStyleAttributes(context){const result={};(this.getAttributeValue('styleNames')||'').split(',').map(m=>m.trim()).forEach(e=>{const namedStyle=context.namedStyles[e];if(namedStyle){this.extractStyleAttributes(context,result,namedStyle);}else{// eslint-disable-next-line no-lonely-if
if(e){context.warnings.addUndefinedStyleNameRef(e);}}});return result;}extractStyleAttributes(context,result,namedStyle){// Pull the attributes off the named style and put them onto the result object.  Handle the case where
// a named style contains a reference to another named style.
if(namedStyle){const gmlLike={};gmlLike.$=namedStyle;Object.keys(namedStyle).forEach(k=>{// Pass 1
// Seek out the named styles first so they do not override values on this named style.
if(k==='styleNames'){const innerStyleNames=namedStyle[k]||'';innerStyleNames.split(',').map(m=>m.trim()).forEach(e=>{const namedStyleInner=context.namedStyles[e];this.extractStyleAttributes(context,result,namedStyleInner);});}});Object.keys(namedStyle).forEach(k=>{// Pass 2
// Properties called out on this named style.
if(k!=='styleNames'){const attribute=gmlAttributeFactory.attributeFor(gmlLike,k,context);if(attribute){result[attribute.name]=attribute;// eslint-disable-line no-param-reassign
}else{context.warnings.addUnknownAttribute(k);}}});}return result;}static getUntypedAttribute(gml,attribute,context){if(GmlUtil.getValueForExpr(gml,[THIS,attribute])){const resultByName=gmlAttributeFactory.attributeFor(gml,attribute.name(),context);if(resultByName){return resultByName;}if(attribute.hasAlias()){const alias=attribute.alias()||'';// alias() is known to exist.
return gmlAttributeFactory.attributeFor(gml,alias,context);}}return null;}getScrollAttribute(context){return AbstractGml.getScrollAttributeJson(this.json,context);}static getScrollAttributeJson(json,context){if(GmlUtil.getValueForExpr(json,[THIS,MISC_A.scroll])){const scrollA=generalLayoutFactory.attributeFor(json,MISC_A.scroll.name(),context);return scrollA;}return null;}getWidthAttribute(context){return AbstractGml.getWidthAttributeJson(this.json,context);}static getWidthAttributeJson(json,context){if(GmlUtil.getValueForExpr(json,[THIS,MISC_A.width])){const attribute=generalLayoutFactory.attributeFor(json,MISC_A.width.name(),context);return attribute;}return null;}findFillers(parentContext){let fillerSearch=arguments.length>1&&arguments[1]!==undefined?arguments[1]:new FillerSearch();const cached=parentContext.document.fillerSearchAt[this.getCvId()];if(cached)return fillerSearch.merge(cached);const myContext=this.updateContext(parentContext);let myFillerSearch=new FillerSearch();Base.children(myContext).forEach(c=>{myFillerSearch=c.findFillers(myContext,myFillerSearch);});Base.elseChildren(myContext).forEach(c=>{myFillerSearch=c.findFillers(myContext,myFillerSearch);});// eslint-disable-next-line no-param-reassign
parentContext.document.fillerSearchAt[this.getCvId()]=myFillerSearch;return fillerSearch.merge(myFillerSearch);}shouldProcessAttribute(attribute){let including=arguments.length>1&&arguments[1]!==undefined?arguments[1]:[];let excluding=arguments.length>2&&arguments[2]!==undefined?arguments[2]:[];let shouldProcess=false;if(including.length){// If any export class in attribute exists in the including array, mark for include.
shouldProcess=!!attribute.exportClass.find(f=>including.includes(f));}else{shouldProcess=true;}if(shouldProcess&&excluding.length){// If any export class in attribute exists in the excluding array, mark for exclude.
if(attribute.exportClass.find(f=>excluding.includes(f))){shouldProcess=false;}}return shouldProcess;}updateContext(context){return context.newContext(this);}wrapForSpecialCases(myContext,parentContext,boxModel){// If a column has a child that is percent based (100% with margin will cause a problem) it must be
// wrapped in a row to allow flexShrink to work on the main axis.
const namedStyles=this.getNamedStyleAttributes(parentContext);const widthA=this.getWidthAttribute(myContext)||namedStyles.width;if(parentContext.flexDirection.isColumn()&&widthA&&widthA.value==='100%'){// Cheat and make this alignSelf:stretch. 100% and margin runs off the screen.  Yes 98% and margin will to.
const{style}=boxModel.styleSet;delete style.width;boxModel.styleSet.flexPolicy.setAlignSelfStretch();}// If there is an actionId, set the actionID on the boxModel.
const actionIdA=this.getActionIdAttribute(myContext);if(actionIdA){const resolvedId=SimpleStringAttribute.hasConstantRef(actionIdA.value)?"$ref('".concat(SimpleStringAttribute.asRefName(actionIdA.value),"')"):actionIdA.value;boxModel.setActionId(resolvedId);}// If there is a scroll, wrap the box in a div so that the scroll bar is on the container and not just
// the content.
const scrollA=this.getScrollAttribute(myContext);if(scrollA){return GmlUtil.wrapForScroller(myContext,boxModel,scrollA).asSalt();}return boxModel.asSalt();}}