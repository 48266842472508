import React from 'react';
import PropTypes from 'prop-types';
import Prompt from '../base/Prompt';
import TextLabel from '../base/TextLabel';
import Button, { BUTTON_VARIANT } from '../base/Button';
import lang from '../../nls/i18n';


const SimpleModal = (({ show, onCancel, onConfirm, title, message, yesText, noText, contextStyles }) => {
    const actionsList = [];
    if (onCancel) {
        actionsList.push(
            <Button
                onClick={ onCancel }
                key="btnCancel"
                text={ noText || lang.generic.no } />
        );
    }
    if (onConfirm) {
        actionsList.push(
            <Button
                onClick={ onConfirm }
                variant={ BUTTON_VARIANT.OUTLINED }
                key="btnLogout"
                text={ yesText || lang.generic.yes } />
        );
    }

    return (
        <Prompt
            contextStyles={ contextStyles }
            isModalOpen={ show }
            isTransparentOverlay
            onClose={ onCancel }
            onSubmit={ onConfirm }
            title={ title }
            actions={ actionsList }>
            <TextLabel
                contextStyles={ contextStyles }>
                { message || null }
            </TextLabel>
        </Prompt>
    );
});

SimpleModal.propTypes = {
    show: PropTypes.bool,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    title: PropTypes.string,
    message: PropTypes.string,
    yesText: PropTypes.string,
    noText: PropTypes.string,
    contextStyles: PropTypes.object,
};

export default SimpleModal;
