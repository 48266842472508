import React from 'react';
import PropTypes from 'prop-types';
import SaltModal from '../components/salt/SaltModal';

const RWSaltEditor = ((
    { show, onRestore, onTest, onSave, onClose, onSelectDocument, onDocumentChange, onDocumentNameChange, onDialogIdChange, title, salt, saltDocumentName, dialogNames, documentNames, currentDialogId, saveText, testText, closeText, restoreText, errorMessage }) => (
        <SaltModal
            show={ show }
            title={ title }
            salt={ salt }
            saltDocumentName={ saltDocumentName }
            documentNames={ documentNames }
            dialogNames={ dialogNames }
            currentDialogId={ currentDialogId }
            onDialogIdChange={ onDialogIdChange }
            onRestore={ onRestore }
            onClose={ onClose }
            onSave={ onSave }
            onTest={ onTest }
            onSelectDocument={ onSelectDocument }
            onDocumentChange={ onDocumentChange }
            onDocumentNameChange={ onDocumentNameChange }
            saveText={ saveText }
            closeText={ closeText }
            testText={ testText }
            restoreText={ restoreText }
            errorMessage={ errorMessage } />
));

RWSaltEditor.propTypes = {
    show: PropTypes.bool,
    onRestore: PropTypes.func,
    onSave: PropTypes.func,
    onTest: PropTypes.func,
    onClose: PropTypes.func,
    onSelectDocument: PropTypes.func,
    onDocumentChange: PropTypes.func,
    onDocumentNameChange: PropTypes.func,
    onDialogIdChange: PropTypes.func,
    title: PropTypes.string,
    salt: PropTypes.string,
    saltDocumentName: PropTypes.string,
    currentDialogId: PropTypes.string,
    documentNames: PropTypes.array,
    dialogNames: PropTypes.array,
    saveText: PropTypes.string,
    closeText: PropTypes.string,
    testText: PropTypes.string,
    restoreText: PropTypes.string,
    errorMessage: PropTypes.string,
};

export default RWSaltEditor;