import { rootStore } from 'cv-react-core';

const coreStyles = {
    container: {
        flex: 1,
        backgroundColor: 'transparent',
    },
};

const getStyles = (contextStyles) => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    return {
        container: {
            ...coreStyles.container,
            backgroundColor: theme.pageBackgroundColor,
            ...contextStyles.container,
        },
    };
};

export default getStyles;
