import { rootStore } from 'cv-react-core';

const getTheme = () => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    // Populate the component parts with theme styles
    return {
        container: {
            backgroundColor: theme.detailSectionBackgroundColor,
        },

        row: {
            verticalAlign: theme.detailRowVerticalAlignment,
        },

        column: {
            verticalAlign: theme.detailColumnVerticalAlignment,
        },
    };
};

export default getTheme;
