import _defineProperty from"/home/vsts/work/1/s/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";var _class,_class2;import{observer}from'mobx-react';import PropTypes from'prop-types';import engineConstants from'./engineConstants';import RefUtil from'./ref/RefUtil';import SaltComponent from'./SaltComponent';import SaltContext from'./SaltContext';/*
    Ref param configurations:
    1) A name only - this is a read operation (no write)
    2) A value or expression only - this is a read operation
*/let ReadRef=observer(_class=(_class2=class ReadRef extends SaltComponent{render(){const resolvedValue=this.resolveValue();if(!resolvedValue){return null;}return resolvedValue;}// the observables that are dereferenced here are saltStore and scopeManager
// 1) A name only - this is a read operation
// 2) A value or expression only - this is a read operation
resolveValue(){const{name,expr,value}=this.props;const viewId=this.getViewId(this.props.viewId);const{saltStore,scopeManager}=this.context;const dialogStore=saltStore.getDialogStoreForViewId(viewId);let resolvedValue=null;if(value){resolvedValue=value;}else if(expr){if(dialogStore&&dialogStore.dataChange){resolvedValue=RefUtil.evaluateExpression(expr,dialogStore,scopeManager,saltStore);}}else if(name){resolvedValue=RefUtil.getRefByName(name,scopeManager);}return resolvedValue;}},_defineProperty(_class2,"propTypes",{// unique name to identify the reference
// if omitted, the resolved value is not stored, but may be returned (if no children)
name:PropTypes.string,// with the jsonata expression to be applied to the dialog associated with the specified viewId
// if expr is omitted , the assumption is that we are referencing a name already in scope
expr:PropTypes.string,// Sets a value directly
/* eslint-disable react/forbid-prop-types */value:PropTypes.any,viewId:PropTypes.string}),_defineProperty(_class2,"typeName",engineConstants.component.name.readRef),_class2))||_class;export{ReadRef as default};ReadRef.contextType=SaltContext;