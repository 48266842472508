/* eslint-disable arrow-body-style */
import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { constants, loginController, serviceFactory, pageController } from 'cv-react-core';
import { Catavolt, storage } from 'cv-dialog-sdk';

import Layout from '../../layouts/BasePage/BasePage';
import PageActivityIndicator from '../../base/PageActivityIndicator';

import OAuthForm from '../../base/OAuthForm';
import oAuth, { OAuth } from '../../../services/oAuth';
import lang from '../../../nls/i18n';

import getStyles from './OAuthPage.styles';
import PasswordExpiryPrompt from '../LoginPage/PasswordExpiryPrompt';
import ChangePassword from '../LoginPage/ChangePassword';

const {
    ui,
    clientType,
} = constants;

const {
    APPLICATION_UI_ID,
    LOGIN_IN_PROGRESS,
} = ui;

@observer
class OAuthPage extends Component {
    static defaultProps = {
        contextStyles: {},
        sessionStore: null,
    };

    static propTypes = {
        contextStyles: PropTypes.shape({
            container: PropTypes.object,
        }),
        sessionStore: PropTypes.object,
        settingsStore: PropTypes.object,
        uiStore: PropTypes.object,
    };

    render() {
        const {
            contextStyles,
            uiStore,
            sessionStore,
            settingsStore,
        } = this.props;
        const { getErrorsForUIObject, getValueForUIObject } = uiStore;

        const styles = getStyles(contextStyles);
        const isLoggingIn = getValueForUIObject(APPLICATION_UI_ID, LOGIN_IN_PROGRESS);
        const {
            passwordExpiryInXDays,
        } = sessionStore;

        // Generate container props
        const containerProps = {
            className: 'p-login-page__layout',
            contextStyles: {
                container: styles.container,
            },
        };

        // Generate error object
        let err = null;
        if (getErrorsForUIObject(APPLICATION_UI_ID).length) {
            err = getErrorsForUIObject(APPLICATION_UI_ID)[0].msg;
        }

        return (
            <Layout { ...containerProps }>
                { isLoggingIn &&
                    <PageActivityIndicator isOpen />
                }
                <div
                    style={ {
                        display: 'flex',
                        justifyContent: 'center',
                        padding: '4rem',
                    } }>
                    <OAuthForm
                        contextStyles={ {} }
                        errors={ { main: err } }
                        isLoggingIn={ isLoggingIn }
                        oAuthBtnText={ lang.login.oAuthBtnText }
                        onOAuthPress={ this.handleOnOAuthLogin } />
                </div>
                <PasswordExpiryPrompt
                    sessionStore={ sessionStore }
                    passwordExpiryInXDays={ passwordExpiryInXDays }
                    uiStore={ uiStore }
                    settingsStore={ settingsStore } />
                <ChangePassword
                    sessionStore={ sessionStore }
                    passwordExpiryInXDays={ passwordExpiryInXDays }
                    uiStore={ uiStore }
                    settingsStore={ settingsStore } />
            </Layout>
        );
    }

    componentDidMount() {
        this.registerOAuthLogin();
    }

    /**
     * This method will handle the redirection to the oAuth site for login.
     * After oAuth login, it will redirect here with the oAuthToken and mount to run
     * the registerOAuthLogin which will log you into the system.
     */
    handleOnOAuthLogin = async() => {
        const {
            sessionStore,
            settingsStore,
            uiStore,
        } = this.props;
        await loginController.handleOAuthPress(sessionStore, settingsStore, uiStore, this.getDeviceProps(), clientType.DESKTOP);
    };

    /**
     * This method will run on mount to see if we have an oAuth token to log the user in. If we
     * do not have a token we return null. Since we are not using routing for login and using state
     * there is no need to navigate to the login page.
     * @returns session
     */
    registerOAuthLogin = async() => {
        const { uiStore } = this.props;

        // Get the stored oAuth token if it exists
        return storage.getItem(OAuth.CREATE_SESSION_TOKEN_PARAM_NAME)
            .then((storedOauthToken) => {
                if (storedOauthToken) {
                    // Use the previously saved 'oauthToken' to login.
                    return this.loginWithToken(storedOauthToken, uiStore);
                }
                // Look for 'oauthToken' in the URL and save it in the localStorage so that client can use it in the next redirection.
                const oauthToken = oAuth.extractPermissionToken();
                if (oauthToken) {
                    return storage.setItem(OAuth.CREATE_SESSION_TOKEN_PARAM_NAME, oauthToken).then(() => this.loginWithToken(oauthToken, uiStore));
                }
                // If we do not have a token, simply return null.
                return null;
            })
            .catch((err) => loginController.handleError(err, uiStore));
    }

    /**
     * Attempt to login using the provided token using the login controller.
     * @param {string} permissionToken
     * @param {object} uiStore
     * @returns session
     */
    loginWithToken = async(permissionToken, uiStore) => {
        const {
            settingsStore,
            sessionStore,
        } = this.props;

        // Get the previously stored proofKey
        return storage.getItem(OAuth.PROOF_KEY)
            .then((proofKey) => {
                // Login with the stored keys
                return loginController.handleLoginWithSessionToken(sessionStore, settingsStore, uiStore, this.getDeviceProps(), permissionToken, proofKey, clientType.DESKTOP)
                    .then((session) => {
                        Catavolt.onSessionExpiration = pageController.logout;
                        // Delete the oauthToken and proofKey from localStorage
                        return this.removeStorageItems().then(() => session);
            })
            .catch((err) => this.removeStorageItems()
                .then(() => loginController.handleError(err, uiStore)));
        });
    };

    getDeviceProps = () => {
        const { uiStore } = this.props;
        return serviceFactory.device.getDeviceProps(uiStore, serviceFactory.device.deviceSize);
    };

    removeStorageItems = async() => storage.removeItem(OAuth.CREATE_SESSION_TOKEN_PARAM_NAME)
            .then(() => storage.removeItem(OAuth.PROOF_KEY)
                .then(() => true
            ));
}

export default OAuthPage;
