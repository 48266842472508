import { rootStore } from 'cv-react-core';

const getTheme = () => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    // Populate the component parts with theme styles
    return {
        container: {
        },

        thumb: {
            color: theme.checkBoxButtonColor,
        },

        thumbContainer: {
        },

        thumbContainerSelected: {
        },

        thumbSelected: {
        },

        track: {
            backgroundColor: theme.checkBoxUnCheckedBackgroundColor,
        },

        trackContainer: {
        },

        trackSelected: {
            backgroundColor: theme.checkBoxCheckedBackgroundColor,
        },
    };
};

export default getTheme;
