import { utilities, uiHelper } from 'cv-react-core';
import copy from 'copy-to-clipboard';
import lang from '../nls/i18n';

const {
    annotationHelper,
} = utilities;

class Clipboard {
    /**
     * Copies content to clipboard
     * @param {string} content
     */
    cpoyContentToClipboard = (content) => {
        copy(content, {
            format: 'text/plain',
        });
    }

    /**
     * Copies selected records to clipboard
     * @param {Object} dialogStore
     * @param {Array} selectedArray
     */
    performClipboardCopy = (dialogStore, selectedArray, cols, includeHeader = true) => {
        if (!selectedArray.length) {
            return Promise.resolve();
        }
        const { records, dialog } = dialogStore;
        const { view: { columns }, recordDef } = dialog;

        const columnData = (cols || columns).map((c) => {
            return {
                name: c.propertyName,
                title: c.heading,
            };
        });

        let clipboardContent = '';
        if (includeHeader) {
            clipboardContent += columnData.map((column) => column.title).join('\t');
        }

        selectedArray.forEach((row) => {
            const record = records.find((item) => (item.id === row));

            if (record) {
                if (clipboardContent) clipboardContent += '\n';
                const columnsCotent = columnData.map((column) => {
                    const propertyName = column.name;
                    const property = record.propAtName(propertyName) || {};
                    const propDef = recordDef.propDefAtName(propertyName) || {};
                    let textString = uiHelper.formatPropertyForRead(property, propDef) || '';

                    // Convert boolean display values to Yes/No instead of true/false
                    if (propDef.isBooleanType) {
                        textString = uiHelper.parse(property, propDef) ? lang.generic.yes : lang.generic.no;
                    }

                    // Apply text annotation styles
                    if (textString !== '') {
                        textString = annotationHelper.getOverrideText(record, property, textString);
                    }
                    return textString;
                }).join('\t');
                clipboardContent += columnsCotent;
            }
        });

        this.cpoyContentToClipboard(clipboardContent);

        return Promise.resolve();
    }
};

const clipboard = new Clipboard();
export default clipboard;