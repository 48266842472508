import { ImagePickerMenu, IMAGE_ACTIONS } from 'cv-library-react-web';
import { themes } from 'cv-react-core';

// Get theme method
import getTheme from './ImagePickerMenu.theme';

// Get theme component method
const { themeComponent } = themes;

// Generate and export a themed component
export default themeComponent(ImagePickerMenu, getTheme);
export {
    IMAGE_ACTIONS,
};
