import React, { PureComponent } from 'react';
// import * as PropTypes from 'prop-types';

import { DeTableCell } from 'cv-library-react-web';
import getStyles from './TableHeaderCellStub.styles';


class TableHeaderCellStub extends PureComponent {
    static propTypes = {
        // contextStyles: PropTypes.object,
    }

    static defaultProps = {
        // contextStyles: {},
    }

    render() {
        // trace(true);
        const style = getStyles();
        const stubProps = {
            ...this.props,
            style,
            disabled: true,
        };
        return React.createElement(DeTableCell, { ...stubProps });
    };
};

export default TableHeaderCellStub;
