import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Log } from 'cv-dialog-sdk';
import { GVCOKTAAuth, GVCSAMAuth } from '@ppm/gvc-react';

import PPMViewControl from '../divisional_components/ppm/graphicViewControl/ppmViewControl';
import CvContextMenu from '../components/menu/CvContextMenu';
import MenuItem from '../components/base/MenuItem';
import TextLabel from '../components/base/TextLabel';

// TODO: create an HOC for this functionality and extend to destroy listner
const loadLibrary = (lib) => new Promise((resolve, reject) => {
    // const mcdPath = typeof SharedArrayBuffer !== 'undefined' ? `/wasm-t/${lib}` : `/wasm'}/${lib}`;
    // const foundScript = document.querySelector(`script[src="${mcdPath}"]`);
    const foundScript = document.querySelector(`script[src="/${lib}"]`);
    if (foundScript) {
        resolve();
    }
    else {
        const script = document.createElement('script');
        // script.src = mcdPath;
        script.src = `/${lib}`;
        script.type = 'application/javascript';
        script.async = false;
        script.addEventListener('load', () => {
            resolve();
        });
        script.addEventListener('error', (e) => {
            reject(e);
        });
        document.head.append(script);
    }
});

/**
 * PPM View Control Native Impl
 * This component is used to display graphical data generated by Xalt Visualization and PPM View Control.
 * PPM View Control is located at: https://dev.azure.com/hexagonPPMInnerSource/Visualization/_packaging?_a=feed&feed=PPM%40Release
 */
class RWViz extends PureComponent {
    static propTypes = {
        style: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        xStyle: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        isLibraryLoaded: PropTypes.bool,
        isViewerLoaded: PropTypes.bool,
        height: PropTypes.number,
        width: PropTypes.number,
        models: PropTypes.object,
        componentProperties: PropTypes.object,
        modelProperties: PropTypes.object,
        graphicProperties: PropTypes.object,
        availableMenuItems: PropTypes.array,
        xMenu: PropTypes.array,
        onSelectionChanged: PropTypes.func,
        onModelLoaded: PropTypes.func,
        onViewerLoaded: PropTypes.func,
        onWebGVCLibLoaded: PropTypes.func,
        onWindowResize: PropTypes.func,
        onMenuAction: PropTypes.func,
        onLoadGraphicProperties: PropTypes.func,
        adsToken: PropTypes.string,
        onADSToken: PropTypes.func,
        onADSTokenError: PropTypes.func,
    };

    static defaultProps = {
        onWebGVCLibLoaded: () => {},
        onWindowResize: () => {},
        onMenuAction: () => {},
        onLoadGraphicProperties: () => {},
        availableMenuItems: [],
    };

    constructor(props) {
        super(props);
        this.contextMenuRef = React.createRef();
        this.viewRef = React.createRef();
        this.handleResize = this.handleResize.bind(this);
        this.handleOnContextMenu = this.handleOnContextMenu.bind(this);
        // TODO: This section needs refactoring...
        // Build menu items.
        const { availableMenuItems, onMenuAction } = this.props;
        this.menuItems = availableMenuItems.map((item) => (
            <MenuItem
                key={ `${item.id}${item.menuText}` }
                id={ item.id }
                onClick={ () => {
                    if (onMenuAction) {
                        onMenuAction(item);
                    }
                } }
                text={ item.menuText } />
        ));
    }

    render() {
        const {
            style,
            xStyle,
            isLibraryLoaded,
            isViewerLoaded,
            onSelectionChanged,
            onViewerLoaded,
            height,
            width,
            models,
            graphicProperties,
            componentProperties,
            modelProperties,
            onLoadGraphicProperties,
            adsToken,
        } = this.props;
        const { adsFileAuthenticationBaseUrl, adsFileClientId } = models;
        const showViewer = adsFileAuthenticationBaseUrl && adsFileClientId ? !!adsToken : true;
        const viewProps = {
            adsToken,
            style,
            xStyle,
            height,
            width,
            models,
            modelProperties,
            componentProperties,
            isViewerLoaded,
            graphicProperties,
            onSelectionChanged,
            onViewerLoaded,
            onLoadGraphicProperties,
        };

        return (
            <div
                ref={ this.viewRef }
                style={ {
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    height: '100%',
                    minHeight: '50vh',
                } }
                onContextMenu={ this.handleOnContextMenu }>
                { (!isLibraryLoaded || !isViewerLoaded) && (
                    <TextLabel
                        contextStyles={ {
                            container: {
                                marginBottom: 10,
                            },
                        } }>
                        Loading...
                    </TextLabel>
                ) }
                { isLibraryLoaded && showViewer &&
                    <div style={ { visibility: isViewerLoaded ? 'visible' : 'hidden' } }>
                        <PPMViewControl
                            { ...viewProps } />
                    </div>
                }
                <CvContextMenu
                    ref={ this.contextMenuRef }>
                    { this.menuItems }
                </CvContextMenu>
            </div>
        );
    }

    componentDidMount() {
        // We have to know the component is mounted and re-render since the GVC has a requirement
        // to do the initial render of the graphics after it is mounted. Sine we have to pass in a special
        // object type to the GVC we control that in the converter to set a state value so it isn't propogated into
        // our framework.
        loadLibrary('libMCD.js')
            .then(() => {
                const { onWebGVCLibLoaded } = this.props;
                const viewSizing = this.viewRef.current;
                onWebGVCLibLoaded(viewSizing.clientHeight, viewSizing.clientWidth);
            })
            .catch((error) => {
                Log.error(error);
            });
        // Handle file server authentication
        const { models } = this.props;
        const { adsFileAuthenticationBaseUrl, adsFileClientId } = models;
        if (adsFileAuthenticationBaseUrl && adsFileClientId) {
            this.getAccessToken();
        }
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    getAccessToken = async() => {
        const {
            models,
            onADSToken,
            onADSTokenError,
        } = this.props;
        const {
            adsFileClientId,
            adsFileClientSecret,
            adsFileAuthenticationBaseUrl,
        } = models;
        const redirectUrl = `${window.location.origin}/gvcAuthRedirect.html`;
        let authClient;

        if (adsFileClientSecret) {
            authClient = new GVCSAMAuth(adsFileClientId, adsFileClientSecret, redirectUrl, true);
        }
        else {
            authClient = new GVCOKTAAuth(adsFileClientId, redirectUrl, true);
        }
        try {
            const tokenData = await authClient.getAccessToken(adsFileAuthenticationBaseUrl);
            onADSToken(tokenData, authClient, adsFileAuthenticationBaseUrl);
        }
        catch (error) {
            onADSTokenError(error);
        }
    }

    handleResize() {
        const { onWindowResize } = this.props;
        const viewSizing = this.viewRef.current;
        const parentViewSizing = viewSizing.parentElement;
        onWindowResize(parentViewSizing.clientHeight, parentViewSizing.clientWidth);
    }

    handleOnContextMenu(event) {
        const { clientX, clientY } = event;
        this.contextMenuRef.current.show({ top: clientY, left: clientX });
    }
}

export default RWViz;
