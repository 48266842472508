/* eslint-disable putout/multiple-properties-destructuring */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { BUTTON_VARIANT } from '../base/Button';
import IconButton from '../base/IconButton';
import DropDown from '../base/DropDown';
import getStyles from './styles/Component.styles';

export default class SortComponent extends PureComponent {
    static defaultProps = {
        // styles: {},
    }

    static propTypes = {
        index: PropTypes.number,
        propertyName: PropTypes.string,
        isAscending: PropTypes.bool,
        remainingSortableValues: PropTypes.array,
        sortableValues: PropTypes.array,
        onSortTermChange: PropTypes.func,
        onRemove: PropTypes.func,
        onChangeSortDirection: PropTypes.func,
        // styles: PropTypes.object,
    }

    constructor(props) {
        super(props);
        this.handleOnSortTermChange = this.handleOnSortTermChange.bind(this);
        this.handleOnChangeSortDirection = this.handleOnChangeSortDirection.bind(this);
        this.handleOnRemove = this.handleOnRemove.bind(this);
    }

    render() {
        const {isAscending, sortableValues, propertyName, index, remainingSortableValues } = this.props;
        const iconDirection = isAscending ? 'arrow_upward' : 'arrow_downward';
        const styles = getStyles();
        return (
            <div
                key={ `sort_component__key${index}` }
                style={ styles.container }>
                <div
                    key={ `sort-selector-drop_down_${index}` }
                    style={ styles.anchor }>
                    <DropDown
                        items={ remainingSortableValues }
                        onChange={ this.handleOnSortTermChange }
                        renderItem={ (MenuItem, item) => (
                            <MenuItem
                                item_key={ item.name }
                                key={ item.name }
                                text={ item.label }
                                value={ item.label } />
                        ) }
                        renderValue={ (v) => ( this.findSortValue(sortableValues, v) ) }
                        value={ propertyName } />
                </div>
                <IconButton
                    contextStyles={ styles.buttons }
                    variant={ BUTTON_VARIANT.CONTAINED }
                    iconName={ iconDirection }
                    onClick={ this.handleOnChangeSortDirection } />
                <IconButton
                    contextStyles={ styles.buttons }
                    variant={ BUTTON_VARIANT.CONTAINED }
                    iconName="delete"
                    onClick={ this.handleOnRemove } />
            </div>
        );
    }

    findSortValue = (availableValues, valueToFind) => {
        if (!availableValues || valueToFind === '') return valueToFind;
        let propVal = valueToFind;
        if (typeof valueToFind === 'object') {
            propVal = valueToFind.name;
        }
        const foundValue = availableValues.find((value) => value.name === propVal );
        return foundValue && foundValue.label ? foundValue.label : '';
    }

    handleOnChangeSortDirection() {
        const { onChangeSortDirection, index, isAscending } = this.props;
        onChangeSortDirection(index, isAscending);
    }

    handleOnRemove() {
        const { onRemove, index } = this.props;
        onRemove(index);
    }

    handleOnSortTermChange(event, propData) {
        // eslint-disable-next-line camelcase
        const { item } = propData;
        const { onSortTermChange, index, isAscending } = this.props;
        onSortTermChange(index, item, isAscending);
    }
}
