/* eslint-disable putout/multiple-properties-destructuring */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TextLabel from '../base/TextLabel';
import { BUTTON_VARIANT } from '../base/Button';
import IconButton from '../base/IconButton';
import FilterValueComponent from './FilterValueComponent';
import FilterOperationComponent from './FilterOperationComponent';
import getStyles from './styles/Component.styles';

export default class FilterComponent extends Component {
    static defaultProps = {
        // styles: {},
    }

    static propTypes = {
        propertyName: PropTypes.string,
        propertyLabel: PropTypes.object,
        onSetFilterValue: PropTypes.func,
        onSetFilterOperation: PropTypes.func,
        propertyValue: PropTypes.object,
        operation: PropTypes.object,
        searchDialogStore: PropTypes.object,
    }

    constructor(props) {
        super(props);
        this.handleClearFilterValue = this.handleClearFilterValue.bind(this);
    }

    render() {
        const { propertyName, propertyLabel, propertyValue, onSetFilterValue, operation, onSetFilterOperation, searchDialogStore } = this.props;
        const styles = getStyles();
        const { heading } = propertyLabel;
        const filterValueProps = {
            ...propertyValue,
            propertyName,
             onSetFilterValue,
             searchDialogStore,
        };

        const filterOperationProps = {
            ...operation,
            propertyName,
            onSetFilterOperation,
            searchDialogStore,
        };
        return (
            <div
                key={ `filter_component_key${propertyName}` }
                style={ styles.container }>
                <div
                    style={ { ...styles.anchor, flexBasis: '33%' } }>
                    <TextLabel
                        key={ `filter-label-column-${propertyName}` }
                        title={ heading }>
                        { heading }
                    </TextLabel>
                </div>
                <div
                    style={ { ...styles.anchor, paddingLeft: '10px', flexBasis: '33%' } }>
                    {
                        React.createElement(FilterOperationComponent, { ...filterOperationProps })
                    }
                </div>
                <div
                    style={ { ...styles.anchor, paddingLeft: '10px', flexBasis: '33%', minWidth: 0 } }>
                    {
                        React.createElement(FilterValueComponent, { ...filterValueProps } )
                    }
                </div>
                <div
                    style={ { paddingLeft: '10px' } }>
                    <IconButton
                        contextStyles={ styles.buttons }
                        variant={ BUTTON_VARIANT.CONTAINED }
                        iconName="clear"
                        onClick={ this.handleClearFilterValue } />
                </div>
            </div>
        );
    }

    handleClearFilterValue() {
        const { onSetFilterValue, onSetFilterOperation, propertyName } = this.props;
        if (onSetFilterValue) onSetFilterValue(propertyName, null);
        if (onSetFilterOperation) onSetFilterOperation(propertyName, null);
    }
}
