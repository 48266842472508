import React from 'react';
import * as PropTypes from 'prop-types';
import { engineConstants } from 'cv-react-core';
import ActionButton, { defaultProps as baseDefaultProps, LABEL_PLACEMENT_PRE, propTypes as basePropTypes } from '../ActionButton/ActionButton';
import Menu from '../Menu';
import MenuItem from '../MenuItem';
import MenuSeparator from '../MenuSeparator';

// Constants

/**
 * A TapActionToolSalt allows the user to change the default tap action on a list.  Tapping the tool opens a popup menu
 * of available tap actions.  Selecting a menu option simply sets that action as the action to be executed when the
 * user taps the list.
 * TapActionToolSalt originated from TapActionTool which was created prior to form level SALT.
 */
class TapActionToolSalt extends ActionButton {
    static instructSelectActionOnce = false;

    static defaultProps = {
        ...baseDefaultProps,
        labelPlacement: LABEL_PLACEMENT_PRE,
    };

    static propTypes = {
        ...basePropTypes,
        defaultActionId: PropTypes.string,
        defaultActionLabel: PropTypes.string,
        currentTapActionId: PropTypes.string,
        currentTapActionLabel: PropTypes.string,
        menu: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            icon: PropTypes.string,
            menuText: PropTypes.string,
        })),
    };

    constructor(props) {
        super(props);

        // Log.debug('TAP ACTION TOOL: CONSTRUCTOR: STATE: ', this.state); // eslint-disable-line

        this.state = {
            ...this.state,
            isMenuOpen: false,
        };
    }

    renderModal = (button) =>
        // Log.debug('TAP ACTION TOOL: RENDER MODAL'); // eslint-disable-line
         (
             <Menu
                 button={ button }
                 open={ this.state.isMenuOpen }>
                 { this.generateTapActionMenuItems() }
             </Menu>
        );

    handlePress = () => {
        // Log.debug('TAP ACTION TOOL: PRESS: ', event); // eslint-disable-line
        if (TapActionToolSalt.instructSelectActionOnce) {
            this.setState({ isMenuOpen: true });
        }
        else {
            TapActionToolSalt.instructSelectActionOnce = true;
            const msg = 'Instructions\n' +
                'Use this tool to select what happens when you tap a row.\n\n' +
                'NOTE: You are not executing the option now.  You are assigning a default tap action.';
            // eslint-disable-next-line no-alert
            if (window.alert(msg)) {
                this.setState({ isMenuOpen: true });
            }
        }
    };

    handleSetDefaultTapAction = (event, action) => {
        const {
            id,
            onAction,
        } = this.props;
        const localEvent = {
            id,
            defaultActionId: action.id,
        };
        if (onAction) {
            onAction(localEvent);
        }
    };

    generateTapActionMenuItems = () => {
        const {
            menu,
            defaultTapActionId,
            defaultTapActionLabel,
            currentTapActionId,
            currentTapActionLabel,
        } = this.props;
        const SELECT_ID = engineConstants.action.clientActions.select;

        // Add a select option as the first choice.  It will set selection mode on.
        const items = [];
        items.push(
            <MenuItem
                key="key-00"
                id={ SELECT_ID }
                text="(select)"
                onClick={ (event) => { this.handleSetDefaultTapAction(event, { id: '#select' }); } } />
        );

        // If there is a default action, add that to the top of the list.
        if (defaultTapActionId) {
            items.push(
                <MenuItem
                    key="key-01"
                    id={ defaultTapActionId }
                    text={ `(default) ${defaultTapActionLabel}` }
                    onClick={ (event) => { this.handleSetDefaultTapAction(event, { id: defaultTapActionId }); } } />
            );
        }

        // If there is an override default action, add that to the top of the list.
        if (currentTapActionId
        && (currentTapActionId !== SELECT_ID)
        && (currentTapActionId !== defaultTapActionId)) {
            items.push(
                <MenuItem
                    key="key-02"
                    id={ currentTapActionId }
                    text={ currentTapActionLabel }
                    onClick={ (event) => { this.handleSetDefaultTapAction(event, { id: currentTapActionId }); } } />
            );
        }

        // Add a separator between the two special menu options and the others.
        items.push(<MenuSeparator key="key-03" />);

        menu.forEach((m, i) => {
            const key = `key-${i}`;
            items.push(
                <MenuItem
                    key={ key }
                    id={ m.id }
                    text={ m.menuText }
                    onClick={ (event) => { this.handleSetDefaultTapAction(event, { id: m.id }); } } />
            );
        });
        return items;
    };
}

export default TapActionToolSalt;
