import React from 'react';
import { BaseComponentBuilder } from 'cv-react-core';
import { Log, TypeNames } from 'cv-dialog-sdk';
import ParseHTML, { convertNodeToElement } from 'react-html-parser';
import lang from '../../nls/i18n';

import TextLabel from '../../components/base/TextLabel';
import TextField from '../../components/base/TextField';

const SUPPORTED_TAGS = [
    'a',
    'b',
    'br',
    'font',
    'img',
    'i',
    'li',
    'p',
    'span',
    'u',
];

/**
 * Adding support for legacy HTML tags
 * @see https://help.adobe.com/en_US/FlashPlatform/reference/actionscript/3/flash/text/TextField.html#htmlText
 *
 * @typedef {Object} HtmlParserNode
 * @property {String} name
 * @property {String} type
 *
 * @param {HtmlParserNode} node
 * @param {Number} index
 * @return {undefined|React.Element}
 */
const parseHtmlNode = (node, index) => {
    const {
        name,
        type,
    } = node;

    // Unsupported tag, get and display any text nodes
    if (
        !SUPPORTED_TAGS.includes(name) &&
        (type === 'tag' || type === 'script' || type === 'style')
    ) {
        // Convert all unsupported tags to spans
        return convertNodeToElement({
            ...node,
            name: 'span',
            type: 'tag',
        }, index, parseHtmlNode);
    }

    // TODO: Legacy tag support???
    // else if (name === 'textformat') {
    //
    // }

    // No changes, continue processing
    return undefined;
};

/**
 * A builder for creating text components
 */
class TextLabelComponentBuilder extends BaseComponentBuilder {
    /**
     * COMPONENT BASED PROPERTIES
     */

    /** Begin Context Styles */
    setTextStyles(textStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            text: textStyles,
        });
    }
    getTextStyles() {
        const { text } = this.getContextStyles();
        return text || {};
    }
    /** End Context Styles */


    /** Begin Props */
    setMultiLine(multiline = true) {
        this.props.multiline = multiline;
        return this;
    }
    getMultiLine() {
        return this.props.multiline;
    }

    setNumberOfLines(numberOfLines) {
        if (typeof numberOfLines === 'number' && numberOfLines >= 0) {
            this.props.numberOfLines = numberOfLines;
        }
        return this;
    }
    getNumberOfLines() {
        return this.props.numberOfLines;
    }

    setOnClick(onClick) {
        this.props.onClick = onClick;
        return this;
    }
    getOnClick() {
        return this.props.onClick;
    }

    setText(text) {
        this.props.children = text;
        return this;
    }
    getText() {
        return this.props.children;
    }
    /** End Props */


    /**
     * PROCESS AND UPDATE COMPONENT PROPERTIES
     */

    processDisplay() {
        const viewDef = this.getViewDef();
        const property = this.getProperty();
        const propDef = this.getPropDef();

        // Get display value
        let textString = viewDef.value || BaseComponentBuilder.uiHelper.formatPropertyForRead(property, propDef) || '';

        const value = viewDef.value || property.value;
        textString = Array.isArray(value) ? value : textString;

        if (typeof textString === 'string' && propDef.isHTMLType) {
            try {
                textString = ParseHTML(textString, {
                    decodeEntities: true,
                    transform: parseHtmlNode,
                });
            }
            catch (e) {
                Log.warn('Error parsing HTML: ', e, textString);
            }
        }

        // Handle multiple CodeRef / ObjectRef values
        else if (Array.isArray(textString)) {
            // value is an array for multi-select dropdown
            // Sets dropdown value based on the number of selected items
            if (textString.length === 1) {
                textString = textString[0].description;
            }
            else if (textString.length === 0) {
                textString = lang.details.dropdown.noSelection;
            }
            else {
                textString = lang.details.dropdown.multipleSelections;
            }
        }

        // Handle CodeRef / ObjectRef display types
        else if (typeof textString === 'object') {
            textString = BaseComponentBuilder.uiHelper.formatPropertyForRead(property, propDef) || '';
        }

        // Convert boolean display values to Yes/No instead of true/false
        else if (propDef.isBooleanType) {
            textString = BaseComponentBuilder.uiHelper.parse(property, propDef) ? lang.generic.yes : lang.generic.no;
        }

        // Update text value
        this.setText(textString);
    }

    processStyle() {
        const viewDef = this.getViewDef();
        const propDef = this.getPropDef();
        const isHeadingStyle = viewDef.isHeading1Style || viewDef.isHeading2Style || viewDef.isHeading3Style || viewDef.isHeading4Style || viewDef.isSectionHeadingStyle;
        let newTextStyles = {};

        // Get defined styles
        if (typeof viewDef.style === 'object') {
            newTextStyles = { ...viewDef.style };
        }

        // This is a temporary change here to fit dropdowns into Maintainable lists.
        // We have permanent solution for this in new UX.
        const isDropdownType = viewDef.isDropDownEntryMethod || viewDef.isComboBoxEntryMethod || viewDef.isIconEntryMethod || (viewDef.isTextFieldEntryMethod && viewDef.autoFillCapable);
        if (isDropdownType) {
            newTextStyles.minWidth = '120px';
        }

        // Determine heading font weight and size
        if (isHeadingStyle) { newTextStyles.fontWeight = 'bold'; }
        if (viewDef.isHeading1Style) { newTextStyles.fontSize = 32; }
        if (viewDef.isHeading2Style) { newTextStyles.fontSize = 24; }
        if (viewDef.isHeading3Style) { newTextStyles.fontSize = 20; }
        if (viewDef.isHeading4Style) { newTextStyles.fontSize = 16; }
        if (viewDef.isSectionHeadingStyle) { newTextStyles.fontSize = 16; }
        if (propDef.isTextBlock) {
            newTextStyles.whiteSpace = 'pre-wrap';
        }
        // Providing the default min-width to Date / Time / DateTime data content
        else if (propDef.isDateTimeType || propDef.isDateType || propDef.isTimeType) {
            newTextStyles.minWidth = '100px';
        }

        const categorizedStyles = BaseComponentBuilder.styleHelper.categorizeWebStyles(this.getStyle());

        this.setContainerStyles({
            ...categorizedStyles.container,
            ...this.getContainerStyles(),
        });
        this.setTextStyles({
            ...categorizedStyles.text,
            ...newTextStyles,
            ...this.getTextStyles(),
        });
        if (propDef.isTextBlock) {
            this.setMultiLine();
        }
    }

    processAnnotations() {
        const text = this.getText();
        const property = this.getProperty();
        const propDef = this.getPropDef();
        const record = this.getRecord();
        const isReadMode = this.getReadMode();
        const enabledAnnotations = this.getEnabledAnnotations();
        const isCompactView = this.getCompactState();
        const overRideText = BaseComponentBuilder.annotationHelper.getOverrideText(record, property, text);
        let textStyles = this.getTextStyles();

        // Apply text annotation styles
        if (!BaseComponentBuilder.annotationHelper.isNone(enabledAnnotations) && isReadMode && ( text !== '' || overRideText !== '' )) {
            this.setText(overRideText);

            // Only apply annotations if a text value exists
            if (this.getText()) {
                if (BaseComponentBuilder.annotationHelper.isAll(enabledAnnotations)) {
                    textStyles = BaseComponentBuilder.annotationHelper.getBackgroundAsStyle(record, property, textStyles);
                }
                else if (BaseComponentBuilder.annotationHelper.isAllList(enabledAnnotations)) {
                    // Don't apply the background from the record because the list handles that
                    // and applying it to the property interferes with the selection highlighting.
                    textStyles = BaseComponentBuilder.annotationHelper.getBackgroundAsStyle(null, property, textStyles);
                }
                textStyles = BaseComponentBuilder.annotationHelper.getAsStyle(record, property, textStyles);
            }
        }

        // Right align numeric data for aesthetics
        if (!isCompactView && this.getViewType() === BaseComponentBuilder.typeNames.ListTypeName && (propDef.isNumericType || propDef.isDecimalType)) {
            textStyles.textAlign = 'right';
        }

        this.setTextStyles(textStyles);
    }

    processTextProps() {
        // Keep wrapping text to a max of 3 lines for list views
        if (this.getViewType() === BaseComponentBuilder.typeNames.ListTypeName) {
            this.setNumberOfLines(3);
        }
    }

    buildProps() {
        // Update the display text
        this.processDisplay();

        // Update the display text style
        this.processStyle();

        // Override the display text style with annotations
        this.processAnnotations();

        // Set text properties based on dialog data
        this.processTextProps();
    }


    /**
     * BUILD AND RETURN COMPONENT AS REACT ELEMENT
     */

    build() {
        // Process and construct final props
        this.buildProps();

        // Build and return component as React element
        return this.getMultiLine() ? this.renderTextArea() : this.renderTextLabel();
    }

    renderTextArea() {
        // Get children prop for text
        const { children, ...restProps } = this.getProps();
        const propDef = this.getPropDef();
        const viewType = this.getViewType();

        // This is a temporary solution for textblock styles in master branch. This will not be of any use in the latest UX.
        const multilineStyles = (propDef.isTextBlock || propDef.isMultiLineText) && viewType === TypeNames.ListTypeName ? {
            minWidth: '400px',
        } : {};

        // Transform TextLabel props to TextField props
        const tfProps = { ...restProps };
        tfProps.value = children;
        const {
            text,
            input,
        } = tfProps.contextStyles;
        tfProps.contextStyles.input = text;
        delete tfProps.contextStyles.text;

        // Build and return component as React element
        tfProps.readOnly = true;
        tfProps.disabled = true;
        tfProps.variant = 'standard';
        // This code was initially setup for paper forms until we
        // can refactor these builders to manage this in core with props.
        if (input){
            const {
                variant,
                padding,
            } = input;
            tfProps.variant = variant;
            tfProps.contextStyles.input = {
                ...multilineStyles,
                ...tfProps.contextStyles.input,
                padding,
                height: 'fit-content',
            };
        }

        tfProps.multiline = true;

        return (
            <TextField { ...tfProps } />
        );
    }

    renderTextLabel() {
        // Get children prop for text
        const { children, ...restProps } = this.getProps();

        // Build and return component as React element
        return (
            <TextLabel { ...restProps }>
                { children }
            </TextLabel>
        );
    }
}

export default TextLabelComponentBuilder;
