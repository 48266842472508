import React, { Component } from 'react';
import { observer } from 'mobx-react';
import * as PropTypes from 'prop-types';
import { constants, serviceFactory, loginController, languages } from 'cv-react-core';
import TimerMixin from 'react-timer-mixin';

import Layout from '../../layouts/BasePage/BasePage';
import SettingsForm from '../../base/SettingsForm';
import getStyles from './SettingsPage.styles';

const {
    settings,
    ui,
    clientType,
} = constants;
const {
    SERVER_URL,
    TENANT_ID,
    USE_DEVELOPMENT_SERVER,
    USE_OAUTH,
} = settings;

const {
    APPLICATION_UI_ID,
    LOGIN_IN_PROGRESS,
} = ui;

const DEBUG_TIMEOUT_INTERVAL = 4000; // milliseconds
const DEBUG_OPTION_ENABLE_CLICKS = 5; // How many times you need to touch screen before tools are enabled
const TOGGLE_TO_GRID_TABLE = 'TOGGLE_TO_GRID_TABLE';

//
@observer
class SettingsPage extends Component {
    static defaultProps = {
        contextStyles: {},
        onLogin: () => {},
    };

    static propTypes = {
        contextStyles: PropTypes.shape({
            container: PropTypes.object,
        }),
        getSettings: PropTypes.func,
        setSettings: PropTypes.func,
        sessionStore: PropTypes.object,
        uiStore: PropTypes.object,
        settingsStore: PropTypes.object,
        onLogin: PropTypes.func,
    };

    constructor(props) {
        super(props);
        const {
            getSettings,
        } = this.props;

        const settingsData = getSettings();
        this.handleOnClearCache = this.handleOnClearCache.bind(this);
        this.handleOnUseGridTable = this.handleOnUseGridTable.bind(this);
        this.state = {
            // loggingDropDownOpen: false,
            settings: settingsData,
            errorMessage: null,
            enableDebugTools: false,
        };

        this.enableDebugToolsCounter = 0;
    }

    render() {
        const {
            contextStyles,
            uiStore,
        } = this.props;
        const {
            enableDebugTools,
            errorMessage,
            settings, // eslint-disable-line no-shadow,
        } = this.state;
        const {
            tenantId,
            useOAuth,
            serverURL,
            useDevelopmentServer,
        } = settings;

        const {
            getErrorsForUIObject,
        } = uiStore;

        const styles = getStyles(contextStyles);

        // Log.debug('SETTINGS: ', settings);

        // TODO: This needs to be fixed. We are hard coding this now so that
        // we can show this edit to change dialog servers to the new test server.
        // const isDevEnv = process.env.NODE_ENV === 'development';
        // const enableTools = enableDebugTools || isDevEnv;

        // Generate container props
        const containerProps = {
            className: 'p-settings-page__layout',
            contextStyles: {
                container: styles.container,
            },
        };

        // Generate error object
        let err = null;
        if (getErrorsForUIObject(APPLICATION_UI_ID).length) {
            err = getErrorsForUIObject(APPLICATION_UI_ID)[0].msg;
            serviceFactory.notify.showError(err);
        }

        const useGridTable = settings[TOGGLE_TO_GRID_TABLE];

        return (
            <Layout { ...containerProps }>

                { /* Content Container */ }
                <div
                    style={ {
                        display: 'flex',
                        justifyContent: 'center',
                        padding: '4rem',
                    } }>

                    <SettingsForm
                        contextStyles={ { ...styles.settingsFormComponent } }
                        errors={ { tenant: errorMessage } }
                        isSaving={ false }
                        onCancelPress={ this.handleCancelPress }
                        onSavePress={ this.handleSavePress }
                        onServerChange={ this.handleServerChange }
                        onTenantChange={ this.handleTenantChange }
                        onUseOAuthChange={ this.handleUseOAuthChange }
                        onUseServerChange={ this.handleUseServerChange }
                        onClickDebugMode={ this.handleClickDebugMode }
                        server={ serverURL || '' }
                        tenant={ tenantId || '' }
                        useOAuth={ useOAuth || false }
                        showToggleServer={ enableDebugTools }
                        useServer={ useDevelopmentServer || false }
                        onClearCache={ this.handleOnClearCache }
                        onUseGridTable={ this.handleOnUseGridTable }
                        useGridTable={ useGridTable }
                        // TODO: Hard coded becaues it needs to be removed.
                        useGridTableToggleText="Use Virtualized Grid" />
                </div>
            </Layout>
        );
    }

    componentWillUnmount() {
        // Destroy the debug timer.
        this.timerDebugModeDestroy();
    }

    handleSavePress = () => {
        const {
            onLogin,
            uiStore,
            sessionStore,
            settingsStore,
        } = this.props;
        const { lang } = serviceFactory;
        const params = lang.loadingStatus.saveTenantData;
        loginController.setGloballyBusy(uiStore, params);
        const {
            settings, // eslint-disable-line no-shadow,
        } = this.state;

        serviceFactory.notify.dismissAllToasts(); // On save press, dismissing toasts on the screen.
        uiStore.asTransaction(() => {
            uiStore.setValueForUIObject(APPLICATION_UI_ID, LOGIN_IN_PROGRESS, true, false);
            uiStore.clearErrorsForUIObject(APPLICATION_UI_ID);
        });

        // Ensure we have a Tenant ID
        if (settings[TENANT_ID] && settings[TENANT_ID].length > 0) {
            const settingsObj = {
                ...settings,
                tenantId: settings[constants.settings.TENANT_ID].trim(),
            };
            const serverURL = settings[constants.settings.SERVER_URL].trim();

            if (serverURL) {
                settingsObj.serverURL = serverURL;
            }
            settingsStore.fromSanitizedSettings(settingsObj);
            loginController.isValidTenantId(settings[TENANT_ID], sessionStore, clientType.DESKTOP).then((result) => {
                if (result) {
                    loginController.applyTenantCapabilities(settings[TENANT_ID], sessionStore, constants.clientType.DESKTOP).then(() => {
                        uiStore.setValueForUIObject(APPLICATION_UI_ID, LOGIN_IN_PROGRESS, false, false);
                        onLogin();
                    });
                }
                else {
                    this.handleError(languages.english.en.errors.invalidTenantMessage, uiStore);
                }
            });
        }
        else {
            this.handleError(lang.login.errors.requiredField, uiStore);
        }
    };

    handleCancelPress = () => {
        const {
            onLogin,
            uiStore,
        } = this.props;
        uiStore.clearErrorsForUIObject(APPLICATION_UI_ID);
        onLogin();
    };

    handleError = (errorMessage, uiStore) => {
        loginController.clearGloballyBusy(uiStore);
        uiStore.setValueForUIObject(APPLICATION_UI_ID, LOGIN_IN_PROGRESS, false, false);
        if (errorMessage) {
            const newError = {
                type: 'generic',
                msg: errorMessage,
            };
            uiStore.addErrorForUIObject(APPLICATION_UI_ID, newError);
        }
    };

    handleServerChange = (event) => {
        const { currentTarget } = event;
        const { value } = currentTarget;
        this.handleValueChange(SERVER_URL, value.trim());
    };

    handleTenantChange = (event) => {
        const { currentTarget } = event;
        const { value } = currentTarget;
        this.handleValueChange(TENANT_ID, value);
    };

    handleUseOAuthChange = (event) => {
        const { target } = event;
        const { checked } = target;
        this.handleValueChange(USE_OAUTH, checked);
    };

    handleUseServerChange = (event) => {
        const { target } = event;
        const { checked } = target;
        this.handleValueChange(USE_DEVELOPMENT_SERVER, checked);
    };

    handleValueChange(key, value) {
        const { uiStore } = this.props;
        uiStore.clearErrorsForUIObject(APPLICATION_UI_ID);
        const { settings } = this.state; // eslint-disable-line no-shadow,
        settings[key] = value;
        this.setState({ settings });
    }

    /**
     * This hanlder was put in place to provide teams with the option to enable features for debugging or
     * switching application configurations that are typically delivered by the app. This will also aide in
     * providing an option for enabling more and more tools. Basic concept is, if a user clicks hidden icon-button at the bottom of
     * the settings-panel 5 times within the provide time range, the toggle option will appear
     */
    handleClickDebugMode = () => {
        this.enableDebugToolsCounter += 1;
        if (this.enableDebugToolsCounter >= DEBUG_OPTION_ENABLE_CLICKS) {
            this.setState({
                enableDebugTools: true,
            }, () => {
                // Reset the counter
                this.enableDebugToolsCounter = 0;
            });
        }
        this.timerDebugModeCreate(DEBUG_TIMEOUT_INTERVAL);
    }

    handleOnClearCache() {
        const { sessionStore, getSettings } = this.props;
        sessionStore.clearAll();
        this.setState({
            settings: getSettings(),
        });
    }

    handleOnUseGridTable(event) {
        const { target } = event;
        const { checked } = target;
        this.handleValueChange(TOGGLE_TO_GRID_TABLE, checked);
    }

    /**
     * Create a timer for going into development mode. If user doesn't tap the toggle
     * circle within 4 seconds we start the counter over. After 4 seconds we reset the counter.
     * This is to help prevent someone from exposing these debug option that might be confusing.
     * @param { number } interval - How long to wait before clearing debug counter.
     */
    timerDebugModeCreate = (interval) => {
        // If the refresh timer is set to zero it means it is disabled.
        if (!this.timer) {
            this.timer = TimerMixin.setTimeout(
                () => {
                    this.enableDebugToolsCounter = 0;
                    this.timerDebugModeDestroy();
                },
                interval,
            );
        }
    }

    /**
     * Destroy the timer when done.
     */
    timerDebugModeDestroy = () => {
        // If we have a timer clear it
        if (this.timer) {
            TimerMixin.clearInterval(this.timer);
            this.timer = undefined;
        }
    }
}

export default SettingsPage;
