import _initializerDefineProperty from"/home/vsts/work/1/s/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty";import _defineProperty from"/home/vsts/work/1/s/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";import _applyDecoratedDescriptor from"/home/vsts/work/1/s/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor";import _initializerWarningHelper from"/home/vsts/work/1/s/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper";var _dec,_class,_descriptor,_descriptor2,_descriptor3,_descriptor4,_descriptor5;import{Catavolt}from'cv-dialog-sdk';import{action,computed,observable,toJS}from'mobx';let WorkbenchStore=(_dec=observable.ref,(_class=class WorkbenchStore{constructor(sessionStore){/**
     * @type {Workbench}
     */_initializerDefineProperty(this,"workbench",_descriptor,this);/**
     * Used in conjunction with filtered actions to fiter down actions based on this string.
     * @type {String}
     */_initializerDefineProperty(this,"filterBy",_descriptor2,this);_initializerDefineProperty(this,"setWorkbench",_descriptor3,this);/**
     * @param workbenchId
     * @param actionId
     * @returns {Promise<Redirection>}
     */_initializerDefineProperty(this,"performWorkbenchAction",_descriptor4,this);/**
     * Set the filterBy value update the filteredActions array.
     * @param value
     */_initializerDefineProperty(this,"setFilter",_descriptor5,this);_defineProperty(this,"clearAll",()=>{// Clean up observables
this.setWorkbench(undefined,false);// Clean up local storage;
this.sessionStore.cacheStore.clearCacheType(this.sessionStore.cacheStore.cacheStores.WORKBENCH);});_defineProperty(this,"getSanitizedWorkbench",()=>{return toJS(this.workbench);});this.sessionStore=sessionStore;}/**
     * @returns {ReadonlyArray<WorkbenchAction>}
     */get actions(){return this.workbench.actions;}/**
    * Provides a read-only array of filtered down workbenches.
    * observable filterBy is the string used for the filter.
    * @returns {ReadonlyArray<WorkbenchAction>}
    */get filteredActions(){// If no filter just return the actions.
if(this.filterBy.length===0)return this.actions;// Return array of actions filtered by observable filterBy
return this.workbench.actions.filter(workbench=>{return workbench.name.toLowerCase().indexOf(this.filterBy)>-1;});}},(_descriptor=_applyDecoratedDescriptor(_class.prototype,"workbench",[_dec],{configurable:true,enumerable:true,writable:true,initializer:function(){return null;}}),_descriptor2=_applyDecoratedDescriptor(_class.prototype,"filterBy",[observable],{configurable:true,enumerable:true,writable:true,initializer:function(){return'';}}),_descriptor3=_applyDecoratedDescriptor(_class.prototype,"setWorkbench",[action],{configurable:true,enumerable:true,writable:true,initializer:function(){var _this=this;return function(workbench){let cacheData=arguments.length>1&&arguments[1]!==undefined?arguments[1]:true;_this.workbench=workbench;if(cacheData){_this.sessionStore.cacheStore.setValueForCacheType(_this.sessionStore.cacheStore.cacheStores.WORKBENCH,workbench);}};}}),_applyDecoratedDescriptor(_class.prototype,"actions",[computed],Object.getOwnPropertyDescriptor(_class.prototype,"actions"),_class.prototype),_descriptor4=_applyDecoratedDescriptor(_class.prototype,"performWorkbenchAction",[action],{configurable:true,enumerable:true,writable:true,initializer:function(){return(workbenchId,actionId)=>{return Catavolt.performWorkbenchActionForId(workbenchId,actionId);};}}),_applyDecoratedDescriptor(_class.prototype,"filteredActions",[computed],Object.getOwnPropertyDescriptor(_class.prototype,"filteredActions"),_class.prototype),_descriptor5=_applyDecoratedDescriptor(_class.prototype,"setFilter",[action],{configurable:true,enumerable:true,writable:true,initializer:function(){var _this2=this;return function(){let value=arguments.length>0&&arguments[0]!==undefined?arguments[0]:'';_this2.filterBy=value.toLowerCase();};}})),_class));export{WorkbenchStore as default};