import BasePropsFactory from'./BasePropsFactory';import{uiHelper}from'../../utilities';/**
* A property factory for creating swimlane graph component props
*/export default class SwimlaneChartPropFactory extends BasePropsFactory{static create(view,records,propDefs){const{groupingDataPoint,identityDataPoint,startDateTimeDataPoint,endDateTimeDataPoint,startDateDataPoint,endDateDataPoint,startTimeDataPoint,endTimeDataPoint,hoverDetails,xAxisLabel,xAxisRangeFromDateTime:xAxisRangeFrom,xAxisRangeToDateTime:xAxisRangeTo,yAxisLabel,yAxisRangeFrom,yAxisRangeTo}=view;let stacked=true;const categoryIndexProp='categoryIndex';const barColorProp='barColor';const xAxisLabelProp=groupingDataPoint&&groupingDataPoint[0].propertyName;const barValueProp=identityDataPoint&&identityDataPoint[0].propertyName;const{propertyName:startDateTimeValueProp,maintainable:startDateTimeMaintainableProp}=startDateTimeDataPoint||{};const{propertyName:endDateTimeValueProp,maintainable:endDateTimeMaintainableProp}=endDateTimeDataPoint||{};const{propertyName:startDateValueProp,maintainable:startDateMaintainableProp}=startDateDataPoint||{};const{propertyName:endDateValueProp,maintainable:endDateMaintainableProp}=endDateDataPoint||{};const{propertyName:startTimeValueProp}=startTimeDataPoint||{};const{propertyName:endTimeValueProp}=endTimeDataPoint||{};const formatProp=value=>{// We assume 23: 49 for dates without enddate. Not showing this time for consistency
// Update the condition to show the DateTime value when we have Hrs and minutes ignoring the above commented text prupose.
// We will monitor why was specificallu added.
const isDateTimeType=value instanceof Date&&(value.getHours()||value.getMinutes())&&value.getHours()<=23;return uiHelper.formatPropertyForRead({value},{isDateType:value instanceof Date,isTimeType:Object.prototype.hasOwnProperty.call(value,'hours'),isDateTimeType,isCodeRefType:Object.prototype.hasOwnProperty.call(value,'description'),isObjRefType:Object.prototype.hasOwnProperty.call(value,'description')});};const getDateWithTime=(timeValueProp,propItem,properties,defaultTime)=>{const time=properties.find(prop=>{return prop.name===timeValueProp;});if(time&&time.value){const dateWithTime=new Date(propItem.value);const{hours,minutes}=time.value;return new Date(dateWithTime.setHours(hours,minutes));}if(defaultTime.hours&&defaultTime.minutes){const defaultDate=new Date(propItem.value);defaultDate.setHours(defaultTime.hours,defaultTime.minutes);return new Date(defaultDate);}return new Date(propItem.value);};let startValueProp;let endValueProp;let isMaintainable=false;if(startDateTimeValueProp&&endDateTimeValueProp){startValueProp=startDateTimeValueProp;endValueProp=endDateTimeValueProp;isMaintainable=startDateTimeMaintainableProp&&endDateTimeMaintainableProp;}else if(startDateValueProp&&endDateValueProp){startValueProp=startDateValueProp;endValueProp=endDateValueProp;isMaintainable=startDateMaintainableProp&&endDateMaintainableProp;}let seriesData=[];if(startValueProp&&endValueProp){// Loop through dataPoints
seriesData=groupingDataPoint.map(dataPoint=>{const{// Get the legend key for the series legend item
legendKey,// Get plot type (stacked)
plotType,// Get the property name for finding the record property value
propertyName,// Get the color for the data set
seriesColor}=dataPoint;// Handle stacked charts
// If any data point is not stacked then the whole chart is not stacked
if(plotType!=='STACKED_BAR'){stacked=false;}// Generate chart series data
const swimlaneSeries={swimlaneColor:BasePropsFactory.formatColor(seriesColor),legendText:legendKey};const swimlaneData=records.map((record,index)=>{const{annotations:recordAnnotations,properties}=record;// Process annotations
const recAnnotations=BasePropsFactory.processAnnotations(recordAnnotations,'barColor');const{barColor}=recAnnotations;const propData=[index/100,...properties.map(propItem=>{if(propItem.name===startValueProp){return getDateWithTime(startTimeValueProp,propItem,properties,{});}if(propItem.name===endValueProp){let defaultTime={};if(endDateValueProp){defaultTime={hours:23,minutes:59};}return getDateWithTime(endTimeValueProp,propItem,properties,defaultTime);}return propItem.value;}),barColor,record.id];return propData;});const dimensions=['categoryIndex',...propDefs.map(propDef=>propDef.propertyName),'barColor','id'];swimlaneSeries.dimensions=dimensions;swimlaneSeries.swimlaneData=swimlaneData;swimlaneSeries.groupingProp=propertyName;return swimlaneSeries;});}const swimlaneProps={xAxisTitle:xAxisLabel||'',yAxisTitle:yAxisLabel||'',gridLines:true,legend:false,stacked:false,grouping:!!groupingDataPoint,hoverDetails,seriesData:[...seriesData],isMaintainable,dataProps:{barValueProp,barColorProp,categoryIndexProp,startValueProp,endValueProp,xAxisLabelProp},formatProp,renderButtonText:dataPoint=>{const{name,displayValue}=dataPoint;return"Open details for ".concat(name,": ").concat(displayValue);}};if(stacked){swimlaneProps.stacked=true;}if(xAxisRangeFrom||xAxisRangeFrom===0){swimlaneProps.minX=xAxisRangeFrom;}if(xAxisRangeTo||xAxisRangeTo===0){swimlaneProps.maxX=xAxisRangeTo;}if(yAxisRangeFrom||yAxisRangeFrom===0){swimlaneProps.minY=yAxisRangeFrom;}if(yAxisRangeTo||yAxisRangeTo===0){swimlaneProps.maxY=yAxisRangeTo;}return swimlaneProps;}}