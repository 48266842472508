import _defineProperty from"/home/vsts/work/1/s/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";import{Catavolt,TypeNames}from'cv-dialog-sdk';import{constants}from'../constants';import serviceFactory from'../services/serviceFactory';import pageController from'./pageController';import{utilities}from'../utilities';import lang from'../nls/i18n';Catavolt.onSessionExpiration=pageController.logout;/**
 * PSA - No react-native in here!  This must be x-platform!
 */class LoginController{constructor(){var _this=this;_defineProperty(this,"handleUserNameTextChange",(sessionStore,value)=>{sessionStore.setActiveUserValue(constants.session.USER_ID,value);sessionStore.setActiveUserValue(constants.session.PASSWORD,'');});_defineProperty(this,"handlePasswordTextChange",(sessionStore,value)=>{sessionStore.setActiveUserValue(constants.session.PASSWORD,value);});_defineProperty(this,"handleSavePasswordToggle",(sessionStore,value)=>{sessionStore.setActiveUserValue(constants.session.SAVE_PASSWORD,value);});_defineProperty(this,"handleShowPasswordToggle",(sessionStore,value)=>{// If user information is not dirty then we haven't changed anything.
// Since the active user isn't dirty we want to clear the password and then set the toggle.
if(!sessionStore.credentials.get(constants.session.IS_DIRTY)){sessionStore.setActiveUserValue(constants.session.PASSWORD,'');sessionStore.saveActiveUserCredentials();// Immediately save empty password to storage
}sessionStore.setActiveUserValue(constants.session.SHOW_PASSWORD,value);});/**
     * Method to update the matched key, values in ChangeCredentials in SessionStore
     */_defineProperty(this,"handleChangeCredentialTextChanges",(sessionStore,value,key)=>{sessionStore.setActiveUserChangePasswordValue(key,value);});_defineProperty(this,"handleOAuthPress",async function(sessionStore,settingsStore,uiStore,deviceProps){let clientType=arguments.length>4&&arguments[4]!==undefined?arguments[4]:'MOBILE';uiStore.asTransaction(()=>{uiStore.setValueForUIObject(constants.ui.APPLICATION_UI_ID,constants.ui.LOGIN_IN_PROGRESS,true,false);uiStore.clearErrorsForUIObject(constants.ui.APPLICATION_UI_ID);});try{const tenantId=settingsStore.getValue(constants.settings.TENANT_ID);const tenantCapabilities=settingsStore.getValue(constants.settings.TENANT_CAPABILITIES);const{oAuthAuthorizationUrl,oAuthExpectModelAuthorizationResult}=tenantCapabilities;const cryptoProofKey=await serviceFactory.crypto.getSecureRandom(16);if(clientType==='MOBILE'){const initUrl=await serviceFactory.oAuth.fetchOAuthUrl(tenantId,cryptoProofKey,oAuthAuthorizationUrl,oAuthExpectModelAuthorizationResult);const{permissionToken,proofKey}=await serviceFactory.oAuth.authenticate(cryptoProofKey,initUrl,constants.oauth.OAUTH_TOKEN,()=>{// turn off the spinner while we wait for external site input
uiStore.setValueForUIObject(constants.ui.APPLICATION_UI_ID,constants.ui.LOGIN_IN_PROGRESS,false,false);});// show the spinner again while we validate with catavolt server
uiStore.setValueForUIObject(constants.ui.APPLICATION_UI_ID,constants.ui.LOGIN_IN_PROGRESS,true,false);await sessionStore.loginWithSessionToken(permissionToken,proofKey,settingsStore.getValue(constants.settings.TENANT_ID),deviceProps,clientType);uiStore.setValueForUIObject(constants.ui.APPLICATION_UI_ID,constants.ui.LOGIN_IN_PROGRESS,false,false);}else{const initUrl=await serviceFactory.oAuth.fetchOAuthUrl(tenantId,cryptoProofKey.toString('hex'),oAuthAuthorizationUrl,oAuthExpectModelAuthorizationResult);await serviceFactory.oAuth.authenticate(cryptoProofKey,initUrl);}}catch(err){_this.handleError(err,uiStore);}});_defineProperty(this,"handleLoginPress",async function(sessionStore,settingsStore,uiStore,deviceProps,postLoginCallback){let clientType=arguments.length>5&&arguments[5]!==undefined?arguments[5]:'MOBILE';const params=lang.loadingStatus.performingLogin;_this.setGloballyBusy(uiStore,params);uiStore.asTransaction(()=>{uiStore.setValueForUIObject(constants.ui.APPLICATION_UI_ID,constants.ui.LOGIN_IN_PROGRESS,true,false);uiStore.clearErrorsForUIObject(constants.ui.APPLICATION_UI_ID);});await sessionStore.login(settingsStore.getValue(constants.settings.TENANT_ID),deviceProps,clientType).then(session=>{uiStore.setValueForUIObject(constants.ui.APPLICATION_UI_ID,constants.ui.LOGIN_IN_PROGRESS,false,false);if(postLoginCallback){postLoginCallback(session);}_this.clearGloballyBusy(uiStore);}).catch(err=>{/**
             * Condition to check for Password Expired error and update the PasswrodExpired property maintained in SessionStore accordingly.
             */_this.clearGloballyBusy(uiStore);if(err&&err.code===constants.session.PASSWORD_EXPIRATION_CODE){uiStore.setValueForUIObject(constants.ui.APPLICATION_UI_ID,constants.ui.LOGIN_IN_PROGRESS,false,false);sessionStore.setPasswordExpired(true);}else{_this.handleError(err,uiStore);}});});_defineProperty(this,"handleLoginWithSessionToken",async function(sessionStore,settingsStore,uiStore,deviceProps,permissionToken,proofKey){let clientType=arguments.length>6&&arguments[6]!==undefined?arguments[6]:'MOBILE';uiStore.setValueForUIObject(constants.ui.APPLICATION_UI_ID,constants.ui.LOGIN_IN_PROGRESS,true,false);// Get the previously stored proofKey
return sessionStore.loginWithSessionToken(permissionToken,proofKey,settingsStore.getValue(constants.settings.TENANT_ID),deviceProps,clientType).then(session=>{uiStore.setValueForUIObject(constants.ui.APPLICATION_UI_ID,constants.ui.LOGIN_IN_PROGRESS,false,false);return session;}).catch(err=>{_this.handleError(err,uiStore);});});/**
     * Method to handle the ChangePassword and create the new session when user Password is expired
     */_defineProperty(this,"handleChangePasswordAndCreateSessionPress",async function(sessionStore,settingsStore,uiStore,deviceProps,postLoginCallback){let clientType=arguments.length>5&&arguments[5]!==undefined?arguments[5]:'MOBILE';uiStore.asTransaction(()=>{uiStore.setValueForUIObject(constants.ui.APPLICATION_UI_ID,constants.ui.LOGIN_IN_PROGRESS,true,false);uiStore.clearErrorsForUIObject(constants.ui.APPLICATION_UI_ID);});await sessionStore.changePasswordAndCreateSession(settingsStore.getValue(constants.settings.TENANT_ID),deviceProps,clientType).then(session=>{sessionStore.changeCredentials.clear();sessionStore.setChangePasswordPrompt(false);uiStore.setValueForUIObject(constants.ui.APPLICATION_UI_ID,constants.ui.LOGIN_IN_PROGRESS,false,false);if(postLoginCallback){postLoginCallback(session);}}).catch(err=>{sessionStore.setPasswordExpired(true);_this.handleError(err,uiStore);});});/**
     * Method to handle the Change Password when User's account expires in X days
     */_defineProperty(this,"handleChangePasswordPress",async(sessionStore,uiStore,postLoginCallback)=>{uiStore.asTransaction(()=>{uiStore.setValueForUIObject(constants.ui.APPLICATION_UI_ID,constants.ui.LOGIN_IN_PROGRESS,true,false);uiStore.clearErrorsForUIObject(constants.ui.APPLICATION_UI_ID);});await sessionStore.changePassword().then(result=>{uiStore.setValueForUIObject(constants.ui.APPLICATION_UI_ID,constants.ui.LOGIN_IN_PROGRESS,false,false);sessionStore.changeCredentials.clear();sessionStore.setChangePasswordPrompt(false);sessionStore.setPasswordExpiryInXDays(null);if(postLoginCallback){postLoginCallback(result);}}).catch(err=>{sessionStore.setPasswordExpiryInXDays(true);this.handleError(err,uiStore);});});_defineProperty(this,"handleError",(err,uiStore)=>{uiStore.setValueForUIObject(constants.ui.APPLICATION_UI_ID,constants.ui.LOGIN_IN_PROGRESS,false,false);if(err){/**
             * This is a temporary workaround to show error message for invalid tenant.
             * In general this error message should come from the dialog server.
             * */const msg=err.cause?'An Error has occurred.  Please ensure that you are using the correct Tenant ID and/or contact your Administrator for help':this.buildErrorMessage(err);const newError={type:'generic',msg};uiStore.addErrorForUIObject(constants.ui.APPLICATION_UI_ID,newError);}});_defineProperty(this,"buildErrorMessage",err=>{if(err.children!==undefined&&err.children.length>0){return err.children.map(error=>error.message).join('\n');}return err.message;});_defineProperty(this,"applyTenantCapabilities",async function(tenantId,sessionStore){let clientType=arguments.length>2&&arguments[2]!==undefined?arguments[2]:constants.clientType.MOBILE;sessionStore.setActiveUserByTenantId(tenantId);return sessionStore.applyTenantCapabilities(tenantId,clientType).then(tenantCapabilities=>{return tenantCapabilities;});});_defineProperty(this,"isValidTenantId",async function(tenantId,sessionStore){let clientType=arguments.length>2&&arguments[2]!==undefined?arguments[2]:constants.clientType.MOBILE;return sessionStore.getTenantCapabilities(tenantId,clientType).then(tenantCapabilities=>{if(tenantCapabilities.type&&tenantCapabilities.type===TypeNames.TenantCapabilitiesTypeName){return true;}return false;});});_defineProperty(this,"triggerLoaderCancelEvent",(sessionStore,settingsStore)=>{const tenantId=settingsStore.getValue(constants.settings.TENANT_ID);const{userId}=sessionStore.getSanitizedSession();// const { userId } = sessionStore.getUserByTenantId(tenantId);
Catavolt.triggerLoaderEvent(tenantId,userId,'cancelOfflineCrawling');});_defineProperty(this,"setGloballyBusy",function(uiStore){let params=arguments.length>1&&arguments[1]!==undefined?arguments[1]:{};// { loadingText, detailText, menu }
const existingStatusObj=uiStore.getValueForUIObject(constants.ui.LOGIN_IN_PROGRESS,constants.ui.GLOBAL_SDK_BUSY);const alreadyBusy=!!existingStatusObj;const newStatus=new utilities.SdkBusyStatus(null,params,existingStatusObj?existingStatusObj.status:null,serviceFactory.lang);if(!existingStatusObj){const statusObj={status:newStatus};uiStore.setValueForUIObject(constants.ui.LOGIN_IN_PROGRESS,constants.ui.GLOBAL_SDK_BUSY,statusObj);}else if(!newStatus.differsFrom(existingStatusObj.status)){action(()=>{existingStatusObj.status=newStatus;})();}return alreadyBusy;});_defineProperty(this,"clearGloballyBusy",uiStore=>{uiStore.setValueForUIObject(constants.ui.LOGIN_IN_PROGRESS,constants.ui.GLOBAL_SDK_BUSY,null);});}}const loginController=new LoginController();export default loginController;