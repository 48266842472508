// import { rootStore } from 'cv-react-core';

const getStyles = () => {
    // const { themeStore } = rootStore;
    // const theme = themeStore.getSanitizedTheme();

    return {
        container: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center' },
        check: {
            padding: '0px',
            width: '16px',
            height: '16px',
            color: '#808184'},
    };
};

export default getStyles;