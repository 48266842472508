import { rootStore } from 'cv-react-core';

const getTheme = () => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    // Populate the component parts with theme styles
    return {
        container: {
        },

        row: {
            alternatingColors: theme.dataTableListAlternatingColors,
        },

        rowError: {
            borderColor: theme.colors.error,
        },

        rowSelected: {
            backgroundColor: theme.listSelectedUnderlayColor,
        },
    };
};

export default getTheme;
