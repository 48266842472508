import { rootStore } from 'cv-react-core';

const getStyles = (contextStyles = {}) => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    // Populate the component parts with theme styles
    return {
        spacer: {
            backgroundColor: '#ffffff',
            borderRightWidth: 1,
            borderRightColor: theme.dataTableGridBorderColor,
            borderRightStyle: 'solid',
            display: 'flex',
            flexGrow: 1,
            height: '100%',
            ...contextStyles.spacer,
        },
    };
};

export default getStyles;
