import React from 'react';
import PropTypes from 'prop-types';
import { constants } from 'cv-react-core';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import SimpleModal from './SimpleModal';

const ConfirmModal = observer(({ uiStore, dialogId }) => {
    const modalOpenProps = toJS(uiStore.getValueForUIObject(dialogId, constants.ui.MODAL_OPEN_PROPS));
    if (modalOpenProps) {
        const props = {
             ...modalOpenProps,
             show: true,
        };
        return <SimpleModal { ...props } />;
    }
    return null;
});

ConfirmModal.propTypes = {
    uiStore: PropTypes.object,
    dialogId: PropTypes.string,
};

export default ConfirmModal;