import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { DeTableCell } from 'cv-library-react-web';
// import { Log } from 'cv-dialog-sdk';

import TableCell from './TableCell';
import getStyles from './BaseTableCell.styles';

const DOT_REPLACEMENT = '__$__';

class BaseTabelCell extends PureComponent {
    static propTypes = {
        style: PropTypes.object,
        fixedColumn: PropTypes.bool,
        position: PropTypes.number,
        backgroundColor: PropTypes.string,
        rowId: PropTypes.number,
        column: PropTypes.object,
        fetchCellPropertyProps: PropTypes.func,
        selected: PropTypes.bool,
    }

    static defaultProps = {
        style: {},
        fixedColumn: false,
        selected: false,
    }

    render() {
        const {
            style,
            backgroundColor,
            fixedColumn,
            position,
            column,
            rowId,
            fetchCellPropertyProps,
            selected,
            ...restProps } = this.props;

        const styles = getStyles(backgroundColor, fixedColumn, position);
        const { name } = column;
        const propertyName = name.replace(DOT_REPLACEMENT, '.');

        // if (!this.rowCounter) this.rowCounter = 0;
        // this.rowCounter += 1;
        // Log.debug(`cellCounter: ${this.rowCounter} RowRenderId: ${rowId}`);

        return (
            <DeTableCell
                style={ { ...styles, ...style } }
                position={ position }
                column={ column }
                { ...restProps }>
                { React.createElement(TableCell, { ...fetchCellPropertyProps(rowId, propertyName), selected } ) }
            </DeTableCell>
        );
    }
}

export default BaseTabelCell;