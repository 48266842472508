import React, { Component, Fragment } from 'react';
import * as PropTypes from 'prop-types';
import { constants, rootStore, serviceFactory } from 'cv-react-core';
import { Box, HolyGrailLayout, ICON_TYPES, VARIANTS } from 'cv-library-react-web';

import AppBar from '../../base/AppBar';
import AppDrawer from '../../base/AppDrawer';
import Button from '../../base/Button';
import Divider from '../../base/Divider';
import IconButton from '../../base/IconButton';
import IconText from '../../base/IconText';
import MenuItem from '../../base/MenuItem';
import AppHeader from '../../base/AppHeader/AppHeader';
import AppFooter from '../../base/AppFooter/AppFooter';
import TextLabel from '../../base/TextLabel';

import routeNames from '../../../routing/routeNames';
import { getRouteComponent } from '../../../routing/routeComponents';
import getStyles from './AppNavigation.styles';
import Navigator from '../../../routing/Navigator';
import NotificationsRoute from '../../../routing/NotificationsRoute';
import DialogModal from '../../modal/DialogModal';
import LogoutModal from '../../modal/LogoutModal';

const {
    WORKBENCH,
    ABOUT,
    LOGIN,
} = routeNames;
const { ui: { LOGOUT_MODAL_ID, LOGOUT_MODAL_IS_OPEN }} = constants;
const { lang } = serviceFactory;

class AppNavigation extends Component {
    static propTypes = {
        targets: PropTypes.object,
        defaultRouteName: PropTypes.string,
        navigator: PropTypes.instanceOf(Navigator),
        /** Id used for testing */
        testID: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.drawerRef = React.createRef();
        this.headerRef = React.createRef();
        this.footerRef = React.createRef();
        this.state = {
            drawerOpen: false,
            drawerPermanentOpenFlag: false,
        };
    }

    render() {
        const {
            targets,
            defaultRouteName,
            navigator,
            testID,
        } = this.props;

        const {
            themeStore,
            sessionStore,
            uiStore,
        } = rootStore;

        const {
            drawerOpen,
            drawerPermanentOpenFlag,
        } = this.state;

        // Get styles
        const styles = getStyles();

        // pull the route from the URL
        const currentRouteName = navigator.match.params.route || defaultRouteName;
        const currentWorkbenchId = navigator.match.params.workbenchId;
        const { dialogId } = navigator.match.params;
        const CurrentRoute = getRouteComponent(currentRouteName);

        // pass along the url parameters to the specified route component
        const routeProps = {
            navigator,
            ...navigator.match.params,
            key: dialogId,
            onOpenLauncher: this.handleOpenLauncher,
        };

        // Get userId for AppDrawer
        const { userId, tenantId } = sessionStore.session || {};

        const workbenchTabsProps = {
            contextStyles: {
                container: {},
            },
            initialTab: true,
            onChange: (event, routeId) => {
                const target = targets[routeId];
                const workbenchId = target.params ? target.params.workbenchId : currentWorkbenchId;
                navigator.history.push(`/${tenantId}/${target.route}/${workbenchId || ''}`);
            },
            tabs: [],
        };

        const notificationProps = {
            navigator,
            contextStyles: {
                button: {
                    ...styles.menuButton,
                    marginLeft: '0px',
                },
                icon: styles.menuIcon,
            },
        };
        // builds logout header
        // TODO: Hard coding styles because all of this needs to be moved.
        const links = (
            <Box>
                <TextLabel contextStyles={ {
                    container: {
                        display: 'flex',
                        alignItems: 'center',
                    },
                    text: {
                        ...styles.topBannerInfo,
                    },
                } }>
                    { `${lang.mainMenu.userWelcome} ${userId}!` }
                </TextLabel>
                { sessionStore.notificationsAction && <NotificationsRoute { ...notificationProps } />
                }
                <Button
                    contextStyles={ {
                        container: { display: 'flex' },
                        text: { color: '#fff' },
                    } }
                    key={ LOGIN }
                    onClick={ () => {
                        this.handleLogoutClick();
                    } }
                    text={ lang.mainMenu.logout }
                    variant="text" />
            </Box>
        );

        Object.keys(targets).forEach((targetId) => {
            const target = targets[targetId];
            workbenchTabsProps.tabs.push({
                id: targetId,
                label: target.title,
            });

            // If workbench route
            if (target.route === WORKBENCH) {
                // If route is current tab
                if (targetId === navigator.match.params.workbenchId) {
                    // Set current tab
                    workbenchTabsProps.initialTab = targetId;
                }
            }
        });

        const contentShift = {
            ...styles.content,
            ...this.getOffsetWidth(),
        };

        // Construct layout props
        const layoutProps = {
            contextStyles: {
                container: styles.container,
                header: styles.header,
                content: drawerOpen ? contentShift : styles.content,
                footer: styles.footer,
            },
        };
        if (testID) { layoutProps['data-test-id'] = `${testID}__layout`; }

        const footerItem = {
            id: 'logout',
            label: lang.mainMenu.logout,
        };

        // Join workbenches and other menu items
        const { tabs: workbenches } = workbenchTabsProps;

        workbenches.push({
            id: 'about',
            label: 'About',
        });

        // const appBarCenterElement = this.getAppBarCenterElement(themeStore.appName, themeStore.appTopLogo, styles);
        const header = this.getHeader(tenantId, styles);

        const footer = this.getFooter('exit_to_app', footerItem, styles, testID);

        // Render application layout
        return (
            <Fragment>
                <LogoutModal uiStore={ uiStore } />
                <DialogModal uiStore={ uiStore } />
                <HolyGrailLayout
                    { ...layoutProps }
                    header={
                        <Fragment>
                            <AppHeader
                                ref={ this.headerRef }>
                                <AppBar
                                    menu={ (
                                        <IconButton
                                            contextStyles={ {
                                                button: styles.menuButton,
                                                icon: drawerPermanentOpenFlag ? styles.menuIconDisabled : styles.menuIcon,
                                            } }
                                            disabled={ !!drawerPermanentOpenFlag }
                                            iconName="menu"
                                            onClick={ this.handleDrawerToggle }
                                            testID={ `${testID}__menu-icon-button` } />
                                    ) }
                                    logoSrc={ themeStore.appTopLogo }
                                    title={ themeStore.appName }
                                    toolbar={ links }
                                    userId={ userId } />
                            </AppHeader>
                            <AppDrawer
                                contextStyles={ {
                                container: {
                                    top: 'auto',
                                },
                             } }
                                open={ drawerOpen }
                                onClose={ this.handleDrawerClose }
                                ref={ this.drawerRef }
                                variant={ VARIANTS.PERSISTENT }>
                                { header }
                                <div style={ { overflow: 'auto' } }>
                                    { workbenches.map((workbench) => (
                                        <MenuItem
                                            key={ workbench.label }
                                            onClick={ (event) => { this.handleMenuItemClick(workbench, event); } }
                                            selected={ workbench.id === currentWorkbenchId }
                                            text={ workbench.label }
                                            testID={ `${testID}__menu-item` } />
                                    )) }
                                </div>
                                <Divider />
                                { footer }
                            </AppDrawer>
                        </Fragment>
                }
                    content={ <CurrentRoute { ...routeProps } /> }
                    footer={ <AppFooter ref={ this.footerRef } /> } />
            </Fragment>
        );
    }

    // TODO: formulate plan for using logo and name
    // getAppBarCenterElement = (appName, appTopLogo, styles) => {
    //     if (appName && appName.length > 0 && appTopLogo) {
    //         return (
    //             <Box style={ styles.centerBox }>
    //                 <Image
    //                     contextStyles={ { image: styles.centerImage } }
    //                     imageSrc={ appTopLogo } />
    //                 <TextLabel
    //                     contextStyles={ { text: styles.topBannerInfo } }>
    //                     { appName }
    //                 </TextLabel>
    //             </Box>
    //         );
    //     }
    //     return null;
    // }

    getHeader = (tenantId, styles) => {
        const { drawerPermanentOpenFlag } = this.state;
        return (
            <div>
                <Box style={ styles.fixedHeaderContainer }>
                    <Box style={ {
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    } }>
                        <TextLabel contextStyles={ styles.drawerText }>
                            { tenantId }
                        </TextLabel>
                        <IconButton
                            contextStyles={ {
                                container: styles.headerClose,
                                icon: styles.headerCloseIcon,
                            } }
                            iconName={ drawerPermanentOpenFlag ? 'lock' : 'lock_open' }
                            onClick={ this.handleDrawerToggleSwitch }
                            title={ drawerPermanentOpenFlag ? 'unlock' : 'lock' } />
                    </Box>
                    <Divider />
                </Box>
            </div>
        );
    }

    getFooter = (iconName, footerItem, styles, testID) => {
        const footerShift = this.getOffsetHeight();
        const footerStyles = {
            ...styles.drawerFooter,
            ...footerShift,
        };
        return (
            <IconText
                contextStyles={ {
                container: footerStyles,
            } }
                iconType={ ICON_TYPES.ICON }
                iconSrc={ iconName }
                label={ footerItem.label }
                onClick={ (event) => { this.handleMenuItemClick(footerItem, event); } }
                testID={ testID } />
        );
    };

    getOffsetWidth = () => {
        const drawerContainer = this.drawerRef.current;
        if (drawerContainer) {
            const { offsetWidth } = drawerContainer.firstChild;
            return {
                marginLeft: `${offsetWidth}px`,
            };
        }
        return {
            marginLeft: '0px',
        };
    };

    getOffsetHeight = () => {
        const appHeader = this.headerRef.current;
        const appFooter = this.footerRef.current;
        if (appHeader && appFooter) {
            const { offsetHeight: headerHeight } = appHeader;
            const { offsetHeight: footerHeight } = appFooter;
            return {
                marginBottom: `${ headerHeight + footerHeight }px`,
            };
        }
        return {
            marginBottom: '0px',
        };
    };

    handleDrawerToggleSwitch = () => {
        this.setState((prevState) => {
            return {
                drawerPermanentOpenFlag: !prevState.drawerPermanentOpenFlag,
            };
        });
    }

    handleOpenLauncher = () => {
        const { drawerPermanentOpenFlag } = this.state;
        if ( !drawerPermanentOpenFlag ) {
            this.handleDrawerClose();
        }
    };

    handleDrawerToggle =() => {
        this.setState((prevState) => {
            return {
                drawerOpen: !prevState.drawerOpen,
                drawerPermanentOpenFlag: false,
            };
        });
    };

    handleDrawerClose = () => {
        this.setState({
            drawerOpen: false,
        });
    };

    handleMenuItemClick = (menuItem, nativeEvent) => {
        const { navigator, targets } = this.props;
        const {
            id: routeId,
        } = menuItem;
        const target = targets[routeId];
        const { match: { params } } = navigator;
        const { workbenchId, tenantId } = params;
        switch (routeId) {
            case 'logout':
                this.handleLogoutClick();
                break;
            case 'about': {
                const aboutUrl = `/${tenantId}/${ABOUT}/${workbenchId || ''}`;
                // eslint-disable-next-line no-unused-expressions
                (nativeEvent && nativeEvent.altKey) ? window.open(aboutUrl, '_blank') : navigator.history.push(aboutUrl);
                break;
            }
            default: {
                const selectedWorkbenchId = target && target.params ? target.params.workbenchId : workbenchId;
                const workbenchUrl = `/${tenantId}/${target.route}/${selectedWorkbenchId || ''}`;
                // eslint-disable-next-line no-unused-expressions
                (nativeEvent && nativeEvent.altKey) ? window.open(workbenchUrl, '_blank') : navigator.history.push(workbenchUrl);
            }
        }
    }

    handleLogoutClick = () => {
        const { uiStore } = rootStore;
        uiStore.setValueForUIObject(LOGOUT_MODAL_ID, LOGOUT_MODAL_IS_OPEN, true );
    };
}

export default AppNavigation;
