const getStyles = () => {
    return {
        modal: {
            padding: '0px',
            minWidth: '25vw',
        },
    };
};

export default getStyles;
