import React from 'react';
import * as PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { rootStore, workbenchController } from 'cv-react-core';
import IconButton from '../components/base/IconButton';
import Route from './Route';
import Navigator from './Navigator';

/**
 * This is a dummy route to handle the recieving notifications to the system for routing purposes.
 * Currently this focus is on handling notification links and later handle firebase notifications.
 */
@observer
class NotificationsRoute extends Route {
    static propTypes = {
        contextStyles: PropTypes.shape({
            button: PropTypes.object,
            icon: PropTypes.object,
        }),
        navigator: PropTypes.instanceOf(Navigator),
    };
    static defaultProps = {
        contextStyles: {},
    };
    render() {
        const { contextStyles } = this.props;
        const { uiStore } = rootStore;
        const shouldNavigateToNotification = uiStore.notificationReceived;
        if (shouldNavigateToNotification) {
            this.handleOnClick();
        }
        const iconProps = {
            contextStyles,
            iconName: 'notifications',
            onClick: this.handleOnClick.bind(this),
        };
        return React.createElement(IconButton, iconProps);
    }

    handleOnClick() {
        const {
            sessionStore,
            uiStore,
        } = rootStore;
        const { notificationsAction, selectedWorkbenchStore } = sessionStore;
        uiStore.setNotificationReceived(false);
        workbenchController.performWorkbenchAction(uiStore, selectedWorkbenchStore, this.handleTransition, this.handleError, notificationsAction);
    }
}

export default NotificationsRoute;