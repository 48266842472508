import { rootStore } from 'cv-react-core';

const getTheme = () => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    // Populate the component parts with theme styles
    return {
        actions: {
        },
        content: {
            ...theme.fonts.error,
            color: theme.errorMessageColor,
            fontSize: theme.fonts.fontSizeMedium,
        },
        divider: {
            backgroundColor: theme.errorTitleContentSeparatorColor,
        },
        errorIcon: {
            color: theme.errorIconColor,
        },
        header: {
        },
        headerIcon: {
        },
        infoIcon: {
            color: theme.infoIconColor,
        },
        message: {
        },
        messageTitle: {
            ...theme.fonts.error,
        },
        modal: {
            shadowColor: theme.modalShadowColor,
        },
        overlay: {
            backgroundColor: theme.errorContainerBackgroundColor,
        },
        stackTrace: {
            color: theme.errorStacktraceColor,
        },
        title: {
            ...theme.fonts.default,
            fontWeight: 'bold',
            fontSize: theme.fonts.fontSizeMidLarge,
        },
        warningIcon: {
            color: theme.warningIconColor,
        },
    };
};

export default getTheme;
