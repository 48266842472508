/**
 * If a style does not fit into a 'text' category then it is assumed to be a 'container' style
 * @type {String[]}
 */const WEB_TEXT_STYLES=['color','direction','font','font-family','fontFamily','font-size','fontSize','font-stretch','fontStretch','font-style','fontStyle','font-variant','fontVariant','font-weight','fontWeight','letter-spacing','letterSpacing','line-height','lineHeight','text-align','textAlign','text-align-last','textAlignLast','text-decoration-line','textDecorationLine','text-decoration','textDecoration','text-indent','textIndent','text-justify','textJustify','text-shadow','textShadow','text-transform','textTransform','text-overflow','textOverflow','unicode-bidi','unicodeBidi','unicode-range','unicodeRange','vertical-align','verticalAlign','word-break','wordBreak','white-space','whiteSpace','word-spacing','wordSpacing','word-wrap','wordWrap','writing-mode','writingMode'];/**
 * If a style does not fit into a 'text' category then it is assumed to be a 'container' style
 * @type {String[]}
 */const NATIVE_TEXT_STYLES=['textShadowOffset','color','fontSize','fontStyle','fontWeight','lineHeight','textAlign','textDecorationLine','textShadowColor','fontFamily','textShadowRadius','includeFontPadding','textAlignVertical','fontVariant','letterSpacing','textDecorationColor','textDecorationStyle','textTransform','writingDirection'];/**
 * Categorizes provided styles into 'container' and 'text' styles
 * @param {Object} styles
 * @param {Array} category
 * @return {{ container: Object, text: Object }}
 */const categorizeStyles=(styles,category)=>{const textStyles={};const containerStyles={};Object.keys(styles).forEach(style=>{if(category.find(categoryStyle=>style===categoryStyle)){textStyles[style]=styles[style];}else{containerStyles[style]=styles[style];}});return{text:textStyles,container:containerStyles};};/**
 * Categorizes provided web styles into 'container' and 'text' styles
 * @param {Object} styles
 * @return {{ container: Object, text: Object }}
 */const categorizeWebStyles=styles=>{return categorizeStyles(styles,WEB_TEXT_STYLES);};/**
 * Categorizes provided native styles into 'container' and 'text' styles
 * @param {Object} styles
 * @return {{ container: Object, text: Object }}
 */const categorizeNativeStyles=styles=>{return categorizeStyles(styles,NATIVE_TEXT_STYLES);};const styleHelper={categorizeWebStyles,categorizeNativeStyles};export default styleHelper;