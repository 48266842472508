const getStyles = () => {
    return {
        column: {
        },

        container: {
            paddingTop: '10px',
        },

        row: {
        },
    };
};

export default getStyles;
