import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Panel } from 'cv-library-react-web';

import Button, { BUTTON_VARIANT } from '../base/Button';
import TextLabel from '../base/TextLabel';
import IconButton from '../base/IconButton';
import getStyles from './styles/CalculateStatisticsForm.style';
import Table from '../base/Table';

// Lang
import lang from '../../nls/i18n';

export default class CalculateStatisticsForm extends Component {
    static defaultProps = {
        contextStyles: {},
    }

    static propTypes = {
        /** Contains the Statistical columns */
        columns: PropTypes.arrayOf(PropTypes.object),

        /** Styles for this component */
        contextStyles: PropTypes.shape({
            /** Styles for the Button wrapper container */
            buttonContainer: PropTypes.object,

            /** Styles for the Button container */
            buttonsContainer: PropTypes.object,

            /** Common styles for the table columns */
            cell: PropTypes.object,

            /** Styles for the Panel container */
            container: PropTypes.object,


            /** Styles for the Statistics container */
            statisticsContainer: PropTypes.object,

            /** Styles for the table body */
            tableBody: PropTypes.object,

            /** Styles for the table each column */
            tableColumn: PropTypes.object,

            /** Styles for the table container */
            tableContainer: PropTypes.object,

            /** Styles for the table header */
            tableHeaderRow: PropTypes.object,

            /** Styles for the table row */
            tableRow: PropTypes.object,

            /** Styles for the title button */
            titleButton: PropTypes.object,

            /** Styles for the title component container */
            titleComponentContainer: PropTypes.object,

            /** Text styles for the title component */
            titleComponentText: PropTypes.object,

            /** Styles for the title wrapper div */
            titleContainer: PropTypes.object,

            /** Text styles for the title wrapper div */
            titleTextColumn: PropTypes.object,
        }),

        /** Called on close of the Modal */
        onCancel: PropTypes.func.isRequired,

        /** Contains the Statistical data */
        rows: PropTypes.arrayOf(PropTypes.object),

        /** Provides the title for the Modal */
        title: PropTypes.string,
    }

    render() {
        const {
            contextStyles,
            onCancel,
        } = this.props;
        const styles = getStyles(contextStyles);
        const rows = this.generateTableRows(styles);

        return (
            <Panel
                contextStyles={ { container: styles.container } }
                header={ this.renderTitleComponent(styles) }>
                <div
                    style={ styles.statisticsContainer }>
                    <Table
                        rows={ rows }
                        columnModel={ [] }
                        contextStyles={ {
                            container: styles.tableContainer,
                            row: styles.tableRow,
                            column: styles.tableColumn,
                            body: styles.tableBody,
                        } } />
                </div>
                <div style={ styles.buttonContainer }>
                    <Button
                        contextStyles={ {
                            container: styles.buttonsContainer,
                        } }
                        variant={ BUTTON_VARIANT.OUTLINED }
                        text={ lang.generic.continue }
                        onClick={ onCancel } />
                </div>
            </Panel>
        );
    }

    renderTitleComponent(styles) {
        const { title, onCancel } = this.props;
        const {
            titleContainer,
            titleComponentContainer,
            titleComponentText,
            titleTextColumn,
            titleButton,
            buttonsContainer,
        } = styles;
        return (
            <div style={ titleContainer }>
                <div
                    style={ titleTextColumn }>
                    <TextLabel
                        contextStyles={ {
                            container: titleComponentContainer,
                            text: titleComponentText,
                        } }>
                        { title }
                    </TextLabel>
                </div>
                <div
                    style={ titleButton }>
                    <IconButton
                        contextStyles={ {
                            container: buttonsContainer,
                        } }
                        iconName="close"
                        onClick={ onCancel } />
                </div>
            </div>
        );
    }

    generateTableRows(styles) {
        const { columns, rows } = this.props;
        const { cell, tableHeaderRow } = styles;
        const thColumns = [
            <TextLabel
                contextStyles={ {
                    container: { ...cell, ...tableHeaderRow },
                } }
                key="header_filter">
                Statistics Type
            </TextLabel>,
        ];

        columns.forEach((record) => {
            thColumns.push(
                <TextLabel
                    contextStyles={ {
                    container: { ...cell, ...tableHeaderRow },
                } }
                    key={ `header_${record.heading}` }>
                    { record.heading }
                </TextLabel>
            );
        });
        const rowsData = rows.map((colStatsData) => {
            const rowBGColor = styles[`${colStatsData.id.toLowerCase && colStatsData.id.toLowerCase()}Row`] || {};
            const row = [
                <TextLabel
                    contextStyles={ {
                    container: { ...cell, ...tableHeaderRow, ...rowBGColor },
                } }
                    key={ `header_filter_${colStatsData.id}` }>
                    { colStatsData.id }
                </TextLabel>,
            ];
            colStatsData.record.forEach((property) => {
                row.push(
                    <TextLabel
                        contextStyles={ {
                        container: { ...cell, ...rowBGColor },
                    } }
                        key={ `column_${property}` }>
                        { ( typeof property === 'string' ? property.trim() : property ) || '\u00A0' }
                    </TextLabel>
                );
            });

            return row;
        });
        return [
            thColumns,
            ...rowsData,
        ];
    }
}