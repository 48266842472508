// import { rootStore } from 'cv-react-core';

const getStyles = ( /* index */ ) => {
    // const { themeStore } = rootStore;
    // const theme = themeStore.getSanitizedTheme();
    // const colors = theme.colors.listAlternatingColors.split(',').map((m) => m.trim());
    // let backgroundColor = theme.listUnderlayColor;
    // if (colors.length) {
    //     const colorIndex = index % colors.length;
    //     backgroundColor = colors[colorIndex];
    // }
   return {
        container: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            paddingTop: '5px',
            paddingBottom: '5px',
            // backgroundColor,
        },
        anchor: {
            display: 'flex',
            flex: 1,
        },
        buttons: {
            container: {
                paddingLeft: '5px',
            },
        },
    };
};

export default getStyles;
