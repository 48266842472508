const getStyles = () => {
    return {
        column: {
        },

        row: {
        },
    };
};

export default getStyles;
