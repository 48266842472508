import ActionIconComponentBuilder from './ActionIconComponentBuilder';
import ColorPicker from '../../components/base/ColorPicker';

class ColorPickerComponentBuilder extends ActionIconComponentBuilder {
    constructor() {
        super();
        this.setActionIconComponent(ColorPicker);
    }

    /** Begin Context Styles */
    setPreviewStyles(previewStyles) {
        return this.setContextStyles({
            ...this.getContextStyles(),
            preview: previewStyles,
        });
    }
    getPreviewStyles() {
        const { preview } = this.getContextStyles();
        return preview || {};
    }
    /** End Context Styles */

    /** Begin Props */
    setOnChangeTextHandler(onChange) {
        if (typeof onChange === 'function') {
            this.props.onChange = onChange;
        }
    }

    showColorPicker() {
        const isReadMode = this.getReadMode();
        this.props.showColorPicker = !isReadMode;
    }
    /** End Props */

    processChangeHandler() {
        const onValueChangeHandler = this.getOnValueChangeHandler();
        const isReadMode = this.getReadMode();
        const property = this.getProperty();
        const { name: propertyName = '' } = property;

        if (!isReadMode && !!propertyName && !!onValueChangeHandler) {
            this.setOnChangeTextHandler((propertyValue) => {
                onValueChangeHandler(propertyName, propertyValue);
            });
        }

        // Show color picker when not in read mode
        this.showColorPicker();
        super.processChangeHandler();
    }

    processAction() {
        // Populate custom action data
        this.processActionData('COLOR_CHOOSER');

        // Finish action processing after custom action data populated
        super.processAction();
    }

    processStyles() {
        this.setPreviewStyles({
            ...this.getPreviewStyles(),
        });

        super.processStyles();
    }
}

export default ColorPickerComponentBuilder;
