import { rootStore } from 'cv-react-core';

/**
 * Helper method to get the styles for the component
 * @param {Object} contextStyles - Context styles for the Component
 */
const getTheme = (contextStyles = {}) => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();
    const baselayout = {
        marging: '5px',
        backgroundColor: theme.actionSeparatorTextBackgroundColor,
        alignItems: 'flex-start',
    };

    return {
        separatorNoText: {
            height: '2px',
            marginLeft: '15px',
            marginRight: '15px',
            backgroundColor: theme.actionSeparatorColor,
            ...contextStyles.separatorNoText,
        },
        separatorLineWithText: {
            borderColor: theme.actionSeparatorColor,
            borderTopWidth: theme.separatorLineWithText,
            ...baselayout,
            ...contextStyles.separatorNoText,
        },
        separatorNoLineWithText: {
            ...baselayout,
            ...contextStyles.separatorNoLineWithText,
        },
        text: {
            marginLeft: '5px',
            marginRight: '5px',
            ...theme.fonts.menuTextseparator,
            ...contextStyles.text,
        },
    };
};

export default getTheme;
