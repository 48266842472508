import { rootStore } from 'cv-react-core';

const coreStyles = {
    container: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        backgroundColor: 'transparent',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        overflow: 'auto',
    },
};

const getStyles = (contextStyles) => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    return {
        container: {
            ...coreStyles.container,
            backgroundColor: theme.loginBackgroundColor,
            backgroundImage: `url(${theme.loginBackgroundImage})`,
            ...contextStyles.container,
        },

        textLabel: {
            paddingBottom: '14px',
        },
    };
};

export default getStyles;
