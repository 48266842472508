import { rootStore } from 'cv-react-core';

const coreStyles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },

    title: {
    },

    titleContainer: {
        margin: '1rem 0rem 1rem 2.85rem',
    },

    versionContainer: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '1rem',
        marginLeft: '2.85rem',
    },

    versionLabel: {
    },

    versionLabelContainer: {
    },

    versionValue: {
    },

    versionValueContainer: {
        marginLeft: '1rem',
    },
};

const getStyles = (contextStyles = {}) => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    return {
        container: {
            ...coreStyles.container,
            backgroundColor: theme.colors.panel,
            backgroundImage: `url(${theme.pageBackgroundImage})`,
            ...contextStyles.container,
        },

        title: {
            ...coreStyles.title,
            ...theme.fonts.panelTitle,
            ...contextStyles.title,
        },

        titleContainer: {
            ...coreStyles.titleContainer,
            ...contextStyles.titleContainer,
        },

        versionContainer: {
            ...coreStyles.versionContainer,
            ...contextStyles.versionContainer,
        },

        versionLabel: {
            ...coreStyles.versionLabel,
            ...theme.fonts.detailsLabel,
            ...contextStyles.versionLabel,
        },

        versionLabelContainer: {
            ...coreStyles.versionLabelContainer,
            ...contextStyles.versionLabelContainer,
        },

        versionValue: {
            ...coreStyles.versionValue,
            ...theme.fonts.detailsAttribute,
            ...contextStyles.versionValue,
        },

        versionValueContainer: {
            ...coreStyles.versionValueContainer,
            ...contextStyles.versionValueContainer,
        },
    };
};

export default getStyles;
