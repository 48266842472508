import { rootStore } from 'cv-react-core';

const getTheme = () => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    // Populate the component parts with theme styles
    return {
        container: {
        },

        icon: {
            fontSize: theme.buttonIconSize,
        },

        iconContainer: {
        },

        primary: {
            backgroundColor: theme.buttonPrimaryBackgroundColor,
            borderColor: theme.buttonPrimaryBorderColor,
        },

        primaryHover: {
            backgroundColor: theme.buttonUnderlayColor,
        },

        primaryIcon: {
            color: theme.buttonIconPrimaryColor,
        },

        primaryText: {
            ...theme.fonts.buttonPrimary,
        },

        secondary: {
            backgroundColor: theme.buttonSecondaryBackgroundColor,
            borderColor: theme.buttonSecondaryBorderColor,
        },

        secondaryHover: {
            borderColor: theme.buttonUnderlayColor,
        },

        secondaryIcon: {
            color: theme.buttonIconSecondaryColor,
        },

        secondaryText: {
            ...theme.fonts.buttonSecondary,
        },

        text: {
            ...theme.fonts.default,
        },

        textContainer: {
        },
    };
};

export default getTheme;
