import React from 'react';
import * as PropTypes from 'prop-types';
import { Box as BoxBase } from 'cv-library-react-web';
import RWSaltComponent from './RWSaltComponent';

export default class RWBox extends RWSaltComponent {
    static propTypes = {
        // allows styling of the 'exterior' scroll pane itself
        // some values don't make sense here and are not allowed (i.e. align-items)
        style: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.arrayOf(PropTypes.object),
        ]),

        // Allows styling of the 'interior' content wrapper in a scroll pane
        scrollContentStyle: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.arrayOf(PropTypes.object),
        ]),
        children: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.arrayOf(PropTypes.element),
        ]),
        scroll: PropTypes.string,
    };

    render() {
        const {
            style,
            scrollContentStyle,
            children,
            scroll,
        } = this.props;

        // Log.debug('PLATFORM COMPONENT: BOX: RENDER: ', this.props);

        // Combine style arrays
        let combinedStyles = {};
        if (Array.isArray(style)) {
            style.forEach((styleObj) => {
                combinedStyles = {
                    ...combinedStyles,
                    ...styleObj,
                };
            });
        }
        else {
            combinedStyles = style;
        }

        // Combine scroll style arrays
        let combinedScrollStyles = {};
        if (Array.isArray(scrollContentStyle)) {
            scrollContentStyle.forEach((styleObj) => {
                combinedScrollStyles = {
                    ...combinedScrollStyles,
                    ...styleObj,
                };
            });
        }
        else {
            combinedScrollStyles = style;
        }

        return (
            <BoxBase
                contextStyles={ {
                    container: combinedStyles,
                    content: combinedScrollStyles,
                } }
                scroll={ scroll }>
                { children }
            </BoxBase>
        );
    }
}
