import { Log } from 'cv-dialog-sdk';

/* eslint-disable class-methods-use-this */

// @TODO - here's a good example of using deep linking with react-navigator
// https://medium.com/react-native-training/deep-linking-your-react-native-app-d87c39a1ad5e

class Linking {
    openUrl(url) {
        Log.warning(`Deep linking for url: '${url}' not supported.`, 'openUrl', 'Linking');
    }

    subscribe(id, urlRegEx) {
        Log.warning(`Deep linking for id: '${id} ${urlRegEx}' not supported.`, 'subscribe', 'Linking');
    }

    unsubscribe(id) {
        Log.warning(`Deep linking for id: '${id}' not supported.`, 'unsubscribe', 'Linking');
    }

    notify = (event) => {
        Log.warning(`Deep linking for event: '${event}' not supported.`, 'notify', 'Linking');
    }

    /**
     *
     * @param {string} url
     * @param {string} fileName
     * @param {Object} options - Setting 'viewFile' to 'true' will view .pdf as a blob in a new window
     */
    downloadFile(url, fileName, options) {
        if (options.viewFile) {
            // Open in new tab
            window.open(url, '_blank');
        }
        else {
            // download file
            const link = document.createElement('a');
            link.href = url;
            link.download = fileName;
            link.setAttribute('target', '_blank');
            link.click();
        }
    }
}

const linking = new Linking();
export default linking;
