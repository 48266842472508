import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import TableSelectionCell from './TableSelectionCell';
import TableTextWithImageCell from './TableTextWithImageCell';
import TableTextCell from './TableTextCell';

import getStyles from './TableCell.styles';

class TabelCell extends PureComponent {
    static propTypes = {
        rowId: PropTypes.number,
        columnName: PropTypes.string,
        tipText: PropTypes.string,
        textString: PropTypes.string,
        imageUrl: PropTypes.string,
        isPlacementRight: PropTypes.bool,
        isPlacementStretchUnder: PropTypes.bool,
        isPlacementUnder: PropTypes.bool,
        isPlacementCenter: PropTypes.bool,
        isPlacementLeft: PropTypes.bool,
        isNumericType: PropTypes.bool,
        isTextWithImage: PropTypes.bool,
        isLargeProperty: PropTypes.bool,
        selected: PropTypes.bool,
        onClick: PropTypes.func,
        fetchLargeProperty: PropTypes.func,
        fetchAnnotationStyles: PropTypes.func,
    }

    static defaultProps = {
        isTextWithImage: false,
        isPlacementRight: false,
        isPlacementStretchUnder: false,
        isPlacementUnder: false,
        isPlacementLeft: false,
        isPlacementCenter: false,
        isNumericType: false,
        onClick: () => {},
        fetchLargeProperty: () => {},
        fetchAnnotationStyles: () => {},
    }

    render() {
        const {
            columnName,
            selected,
            rowId,
            onClick,
            tipText,
            isNumericType,
            fetchAnnotationStyles } = this.props;

        const annotationStyles = fetchAnnotationStyles(columnName, rowId, selected);
        const cellStyles = getStyles(annotationStyles); // Styles used to cascade down to lower level components.

        if (columnName.localeCompare('selection_column') === 0) {
            const selectionProps = {
                selected,
                rowId,
                onClick,
            };
            return (
                <div style={ { ...cellStyles } }>
                    { React.createElement(TableSelectionCell, selectionProps) }
                </div>
            );
        }

        const { isLargeProperty, fetchLargeProperty } = this.props;

        // Use component builder for large property images
        if (isLargeProperty) {
            return (
                <div style={ { ...cellStyles } }>
                    { fetchLargeProperty(columnName, rowId) }
                </div>
            );
        }

        const {
            isTextWithImage,
            isPlacementCenter,
            isPlacementRight,
            isPlacementStretchUnder,
            textString,
            isPlacementUnder,
            isPlacementLeft,
            imageUrl } = this.props;
        // Apply image annotation styles
        if (isTextWithImage) {
            const cellProperties = {
                textString: isPlacementCenter ? '' : textString,
                imageUrl,
                isPlacementCenter,
                isPlacementRight,
                isPlacementStretchUnder,
                isPlacementUnder,
                isPlacementLeft,
                tipText,
                isNumericType,
            };
            return (
                <div style={ { ...cellStyles } }>
                    { React.createElement(TableTextWithImageCell, cellProperties) }
                </div>
            );
        }

        const cellProperties = {
            textString,
            tipText,
            isNumericType,
        };

        return (
            <div style={ { ...cellStyles } }>
                { React.createElement(TableTextCell, cellProperties) }
            </div>
        );
    }
}

export default TabelCell;