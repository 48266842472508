import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { serviceFactory } from 'cv-react-core';

import Prompt from '../../base/Prompt';
import Button, { BUTTON_VARIANT } from '../../base/Button';
import lang from '../../../nls/i18n';

import getStyles from './ChangePassword.styles';


// TODO: this components logic can be lifted to the LoginRoute component and most likely converted to a library functional component for improved performance
@observer
class PasswordExpiryPrompt extends Component {
    static defaultProps = {
        contextStyles: {},
    };

    static propTypes = {
        contextStyles: PropTypes.object,
        sessionStore: PropTypes.object,
        passwordExpiryInXDays: PropTypes.bool,
        uiStore: PropTypes.object,
    };

    render() {
        const {
            sessionStore,
            contextStyles,
            passwordExpiryInXDays,
        } = this.props;
        const styles = getStyles(contextStyles);

        const {
            passwordExpired,
            changePasswordPrompt,
        } = sessionStore;

        let promptTitle = lang.login.passwordExpiredPromptTitle;

        if (passwordExpiryInXDays){
            promptTitle = lang.login.passwordExpiryPromptTitle;
        }

        if ( ( passwordExpired || passwordExpiryInXDays ) && !changePasswordPrompt){
            return (
                <Prompt
                    actions={ [
                        <Button
                            key="Yes"
                            onClick={ this.handleProceedAction }
                            text="Yes"
                            variant={ BUTTON_VARIANT.CONTAINED } />,
                        <Button
                            key="No"
                            onClick={ this.handleCancelAction }
                            text="No"
                            variant={ BUTTON_VARIANT.OUTLINED } />,
                    ] }
                    contextStyles={ {
                        actions: styles.buttonContainer,
                        divider: styles.modalDivider,
                        title: styles.modalTitle,
                    } }
                    title={ promptTitle }>
                    <p>
                        { passwordExpiryInXDays ? sessionStore.getPasswordExpriyXDaysFromSession() : lang.login.passwordExpiredHelperText }
                    </p>
                </Prompt>
            );
        }

        return null;
    };

    handleProceedAction = () => {
        const { sessionStore } = this.props;
        sessionStore.setChangePasswordPrompt(true);
    }

    handleCancelAction = () => {
        this.getDeviceProps();
        const {
            sessionStore,
        } = this.props;

        sessionStore.changeCredentials.clear();

        if (sessionStore.passwordExpired){
            sessionStore.setPasswordExpired(false);
        }
        else if (sessionStore.passwordExpiryInXDays){
            sessionStore.setPasswordExpiryInXDays(false);
        }

        sessionStore.setChangePasswordPrompt(false);
    }

    getDeviceProps = () => {
        const { uiStore } = this.props;
        return serviceFactory.device.getDeviceProps(uiStore, serviceFactory.device.deviceSize);
    };
}

export default PasswordExpiryPrompt;
