import { rootStore } from 'cv-react-core';

const getTheme = () => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    // Populate the component parts with theme styles
    return {
        container: {
            backgroundColor: theme.drawerMenuItemBackgroundColor,
            color: theme.drawerMenuItemTextColor,
            fontFamily: theme.drawerMenuItemFontFamily,
        },

        dividerTop: {
            borderTopColor: theme.drawerMenuSeparatorLineColor,
        },

        menuButton: {
        },

        menuIcon: {
            color: theme.buttonIconPrimaryColor,
        },

        typography: {
            backgroundColor: theme.drawerMenuItemBackgroundColor,
            color: theme.drawerMenuItemTextColor,
        },

        text: {
            ...theme.fonts.default,
        },
    };
};

export default getTheme;
