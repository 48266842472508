import _initializerDefineProperty from"/home/vsts/work/1/s/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty";import _defineProperty from"/home/vsts/work/1/s/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";import _applyDecoratedDescriptor from"/home/vsts/work/1/s/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor";import _initializerWarningHelper from"/home/vsts/work/1/s/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper";var _class,_descriptor,_descriptor2,_descriptor3,_descriptor4,_descriptor5;import{LogLevel,Catavolt,FetchClient}from'cv-dialog-sdk';import{action,observable,toJS}from'mobx';import{BuildGlobals}from'cv-react-core';import{constants}from'..';let SettingsStore=(_class=class SettingsStore{constructor(rootStore){_initializerDefineProperty(this,"settings",_descriptor,this);_initializerDefineProperty(this,"FCMToken",_descriptor2,this);_defineProperty(this,"tenantIdChanged",false);_defineProperty(this,"loadingFromCache",false);_initializerDefineProperty(this,"setValue",_descriptor3,this);_defineProperty(this,"getValue",key=>{return this.settings.get(key);});_defineProperty(this,"clearAllSettings",()=>{this.settings.clear();const{cacheStore}=this.rootStore;cacheStore.clearCacheType(cacheStore.cacheStores.SETTINGS);});_defineProperty(this,"getSanitizedSettings",()=>{const result=toJS(this.settings);// Provide defaults where appropriate.
if(result[constants.settings.LOGGING_LEVEL]===undefined){result[constants.settings.LOGGING_LEVEL]=LogLevel.WARN;}if(result[constants.settings.SERVER_URL]===undefined){result[constants.settings.SERVER_URL]=Catavolt.getDefaultServerUrl();}return result;});_initializerDefineProperty(this,"fromSanitizedSettings",_descriptor4,this);_defineProperty(this,"updateSettingsFromCache",()=>{if(this.tenantIdChanged){const{cacheStore}=this.rootStore;const cachedSettings=cacheStore.getCacheType(cacheStore.cacheStores.SETTINGS);// Add cached settings for new tenant
this.settings.set(constants.storage.USER_CREDS,cachedSettings[constants.storage.USER_CREDS]);// this.settings.set(constants.storage.THEME, cachedSettings[constants.storage.THEME]);
}});_defineProperty(this,"saveSettingsCache",()=>{const{cacheStore}=this.rootStore;cacheStore.setCacheType(cacheStore.cacheStores.SETTINGS,this.getSanitizedSettings());});_defineProperty(this,"restoreFromCache",cacheData=>{const{settings}=cacheData;this.fromSanitizedSettings(settings,false);this.loadingFromCache=false;});_initializerDefineProperty(this,"setFCMToken",_descriptor5,this);_defineProperty(this,"FCMToken",()=>{return this.FCMToken;});this.rootStore=rootStore;}},(_descriptor=_applyDecoratedDescriptor(_class.prototype,"settings",[observable],{configurable:true,enumerable:true,writable:true,initializer:function(){return new Map();}}),_descriptor2=_applyDecoratedDescriptor(_class.prototype,"FCMToken",[observable],{configurable:true,enumerable:true,writable:true,initializer:function(){return null;}}),_descriptor3=_applyDecoratedDescriptor(_class.prototype,"setValue",[action],{configurable:true,enumerable:true,writable:true,initializer:function(){var _this=this;return function(key,value){let cacheData=arguments.length>2&&arguments[2]!==undefined?arguments[2]:true;// Check and see if we are getting a new tenant value. If so we need to restore cached instance.
if(key===constants.session.TENANT_ID&&value!==_this.settings.get(constants.session.TENANT_ID)){_this.tenantIdChanged=true;}// Set the new value
_this.settings.set(key,value);if(cacheData){_this.saveSettingsCache();_this.updateSettingsFromCache();}};}}),_descriptor4=_applyDecoratedDescriptor(_class.prototype,"fromSanitizedSettings",[action],{configurable:true,enumerable:true,writable:true,initializer:function(){var _this2=this;return function(settings){let cacheData=arguments.length>1&&arguments[1]!==undefined?arguments[1]:true;if(settings&&settings[constants.settings.TENANT_ID]){_this2.settings.clear();Object.keys(settings).forEach(key=>{_this2.setValue(key,settings[key],false);});if(cacheData){_this2.saveSettingsCache();_this2.updateSettingsFromCache();}// Look at the cv-dialog-sdk for default server if you can't determine
// what the default server should be. You can also clear your cache and
// reload the app to get the default server. We are not resetting the development
// server settings on purpose to reduce the number of locations this value is stored.
if(settings[constants.settings.USE_DEVELOPMENT_SERVER]&&settings[constants.settings.SERVER_URL]&&settings[constants.settings.SERVER_URL].length>0){const serverUrl=settings[constants.settings.SERVER_URL];if(BuildGlobals.isXHA()||!BuildGlobals.isOfflineCapable()){// xha custom server or no offline
Catavolt.initDialogApiWithServerAndClient(serverUrl,new FetchClient());}else{// custom server with defaults (offline capable)
Catavolt.initDialogApi(serverUrl);}}else if(BuildGlobals.isXHA()){// default server, xha
Catavolt.initDialogApiWithClient(new FetchClient());}else{// defaults (offline capable)
Catavolt.initDialogApi();}// Load theme for this tenant
// const { themeStore } = this.rootStore;
// themeStore.setTheme(themes.themeFactory(this.rootStore).getTheme(settings.tenantId));
}};}}),_descriptor5=_applyDecoratedDescriptor(_class.prototype,"setFCMToken",[action],{configurable:true,enumerable:true,writable:true,initializer:function(){return value=>{this.FCMToken=value;};}})),_class);export{SettingsStore as default};