import { themes } from 'cv-react-core';

// Get theme method
import getTheme from '../Image/Image.theme';
import LargePropertyImage from './LargePropertyImage';

// Get theme component method
const { themeComponent } = themes;

// Generate and export a themed component
export default themeComponent(LargePropertyImage, getTheme);
