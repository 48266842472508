import React from 'react';
import * as PropTypes from 'prop-types';
import styleHelper from 'cv-react-core/src/utilities/styleHelper';
import RWSaltComponent from './RWSaltComponent';
import BaseImage from '../components/base/Image';
import BaseIcon from '../components/base/Icon';

class RWImage extends RWSaltComponent {
    static propTypes = {
        children: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.arrayOf(PropTypes.element),
        ]),
        source: PropTypes.string.isRequired,
        style: PropTypes.object,
        xStyle: PropTypes.object,
        resizeMode: PropTypes.string,
        capInsets: PropTypes.shape({
            top: PropTypes.number,
            right: PropTypes.number,
            bottom: PropTypes.number,
            left: PropTypes.number,
        }),
        params: PropTypes.shape({
            autosizeMeta: PropTypes.shape({ width: PropTypes.number }),
        }),
    };
    static defaultProps = {
        style: {},
        xStyle: {},
        resizeMode: 'contain',
    };

    render() {
        const {
            style,
            xStyle,
            source,
            resizeMode,
            children,
        } = this.props;

        /*
            this results in the separation of text-related style values and all others via 2 fields:
            styles.text and styles.container
            Do we need this?  The utility 'categorizeWebStyles' needs to be made more efficient if so...
        */
        const styles = styleHelper.categorizeWebStyles(style);
        if (source.startsWith('icon:')) {
            return this.renderIcon(styles);
        }
        /*
            All styles are delivered to the underlaying 'Image' component via 2 fields on contextStyles
            contextStyles.image and contextStyles.container
        */
        const contextStyles = { ...xStyle };

        // Convert the resize mode to an object-fit CSS
        if (resizeMode) {
            contextStyles.image = contextStyles.image || {};
            if (resizeMode === 'repeat') {
                contextStyles.image.backgroundRepeat = resizeMode;
            }
            else if (resizeMode === 'stretch') {
                contextStyles.image.objectFit = 'fill';
            }
            else {
                contextStyles.image.objectFit = resizeMode;
            }
        }

        // If no image sizing is present, then assume the container size.
        contextStyles.image.width = contextStyles.image.width || '`100%';
        contextStyles.image.height = contextStyles.image.height || '100%';

        // Default the container styles to centering. Can be overridden with xStyle.container
        contextStyles.container = {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            ...contextStyles.container,
            ...styles.container,
        };

        const imageProps = {
            contextStyles,
            imageSrc: source,
        };

        // It's a background image
        if (children) {
            const containerProps = {
                className: 'abs-image__container',
                style: styles.container,
            };
            containerProps.style.position = 'relative';
            return (
                <div { ...containerProps }>
                    <BaseImage { ...imageProps } />
                    { children }
                </div>
            );
        }

        // It's an image
        return (
            <BaseImage { ...imageProps } />
        );
    }

    renderIcon(styles) {
        const {
            iconProps,
            source,
        } = this.props;
        const props = {
            iconName: source.substring(5),
            contextStyles: {
                container: styles.container,
                icon: styles.text,
            },
            ...iconProps,
        };
        return (
            <BaseIcon { ...props } />
        );
    }
}

export default RWImage;
