import React from 'react';

import PageActivityIndicator from '../base/PageActivityIndicator';

const LoadingPage = () => (
    <PageActivityIndicator
        isOpen
        indicatorThickness={ 5 } />
);

export default LoadingPage;
