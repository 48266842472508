import React, { PureComponent } from 'react';
import { BuildGlobals } from 'cv-react-core';

import ColumnWidthTest from './ColumnWidthTest';

export const testComponents = {
    ColumnWidthTest,
};

class TestComponent extends PureComponent {
    render() {
        const testComponent = BuildGlobals.getTestComponent();
        if (testComponent && Object.keys(testComponents).includes(testComponent)) {
            return React.createElement(testComponents[testComponent], this.props);
        }
        return (
            <div>
                <span>No test component found!</span>
            </div>
        );
    }
};

export default TestComponent;
