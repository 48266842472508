import { BarCodeActionIcon } from 'cv-library-react-web';
import { themes } from 'cv-react-core';

// Get theme method
import getTheme from './BarCodeActionIcon.theme';

// Get theme component method
const { themeComponent } = themes;

// Generate and export a themed component
export default themeComponent(BarCodeActionIcon, getTheme);
