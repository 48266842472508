import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import { serviceFactory } from 'cv-react-core';
import { observer } from 'mobx-react';
import Paper from '@material-ui/core/Paper';

import TextLabel from '../TextLabel';
import IconButton from '../IconButton';
import getStyles from './TableSelectionTray.styles';

@observer
class TableSelectionTray extends PureComponent {
    static propTypes = {
        dialogStore: PropTypes.object,
        onChooseNone: PropTypes.func,
    };

    render() {
        const {
            dialogStore,
            onChooseNone } = this.props;
        const selectedRowCount = dialogStore.selectedRecordCount;
        if (selectedRowCount === 0) return null;
        const styles = getStyles();
        const { lang } = serviceFactory;

        return (
            <Paper style={ styles.container }>
                <TextLabel contextStyles={ {
                    text: styles.text,
                } }>
                    { `${selectedRowCount} ${lang.list.selected}` }
                </TextLabel>
                <IconButton
                    contextStyles={ {
                        button: styles.closeButton,
                        icon: styles.closeButtonIcon,
                    } }
                    onClick={ onChooseNone }
                    iconSize={ 12 }
                    iconName="clear"
                    title={ lang.list.clearSelection } />
            </Paper>
        );
    }
}

export default TableSelectionTray;
