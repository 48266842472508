import { rootStore } from 'cv-react-core';

const getStyles = () => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    return {
        container: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            height: '100%',
        },
        scrollContainer: {
            margin: '10px',
            flexGrow: 1,
            flexDirection: 'column',
            overflow: 'auto',
        },
        columnControl: {
            margin: '10px',
            flexDirection: 'column',
            display: 'flex',
        },
        controlledRowContainer: {
            display: 'flex',
            flexDirection: 'row',
            flexShrink: 1,
        },
        controledColumnContainer: {
            flexDirection: 'column',
            display: 'flex',
        },
        boldLabels: {
            ...theme.fonts.default,
            fontWeight: 'bold',
        },
        buttons: {
            container: {
                marginBottom: '10px',
                paddingLeft: '5px',
            },
        },
    };
};

export default getStyles;
