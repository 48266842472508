import { rootStore } from 'cv-react-core';

// Core component styles
const coreStyles = {
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        flexShrink: 0,
        flexGrow: 0,
        flexBasis: 'auto',
        flexWrap: 'wrap',
        backgroundColor: '#ccc',
        padding: '0px',
        paddingRight: '5px',
    },

    icon: {
        color: '#000',
        fontSize: 45,
    },

    iconAndTitleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },

    iconTextContainer: {
    },

    titleText: {
        fontSize: '16px',
    },
};

/**
 * Helper method to get the styles for the component
 * @param {Object} contextStyles - Context styles for the Component
 */
const getStyles = (contextStyles = {}) => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    return {
        container: {
            ...coreStyles.container,
            backgroundColor: theme.panelBackgroundColor,
            ...contextStyles.container,
        },

        icon: {
            ...coreStyles.icon,
            color: theme.panelIconColor,
            ...contextStyles.icon,
        },

        iconAndTitleContainer: {
            ...coreStyles.iconAndTitleContainer,
            ...contextStyles.iconAndTitleContainer,
        },

        iconTextContainer: {
            ...coreStyles.iconTextContainer,
            ...contextStyles.iconTextContainer,
        },

        titleText: {
            ...theme.fonts.panelTitle,
            ...coreStyles.titleText,
            ...contextStyles.titleText,
        },
    };
};

export default getStyles;
