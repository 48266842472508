import React from 'react';
import { BaseComponentBuilder } from 'cv-react-core';


class CompositeComponentBuilder extends BaseComponentBuilder {
    constructor(primaryComponentBuilder = null, secondaryComponentBuilder = null) {
        super();
        this.setPrimaryComponentBuilder(primaryComponentBuilder);
        this.setSecondaryComponentBuilder(secondaryComponentBuilder);
    }

    setPrimaryComponentBuilder(builder) {
        this.data.primaryComponentBuilder = builder;
        return this;
    }
    getPrimaryComponentBuilder() {
        return this.data.primaryComponentBuilder;
    }

    setSecondaryComponentBuilder(builder) {
        this.data.secondaryComponentBuilder = builder;
        return this;
    }
    getSecondaryComponentBuilder() {
        return this.data.secondaryComponentBuilder;
    }


    /**
     * DIALOG BASED PROPERTIES
     */

    // N/A


    /**
     * COMPONENT BASED PROPERTIES
     */

    // N/A


    /**
     * PROCESS AND UPDATE COMPONENT PROPERTIES
     */

    processStyle() {
        const primaryComponentBuilder = this.getPrimaryComponentBuilder();
        const secondaryComponentBuilder = this.getSecondaryComponentBuilder();
        const categorizedStyles = BaseComponentBuilder.styleHelper.categorizeNativeStyles(this.getStyle());

        // Default to row layout
        this.setContainerStyles({
            ...categorizedStyles.container,
            ...this.getContainerStyles(),
        });

        // Pass text styles down to the sub-builders
        primaryComponentBuilder.setStyle(categorizedStyles.text);
        secondaryComponentBuilder.setStyle(categorizedStyles.text);

        // TODO: This is a temp fix until we can manage style better for these components
        // Without it drop downs shrink
        primaryComponentBuilder.setContainerStyles({
            flexGrow: 1,
            ...primaryComponentBuilder.getContainerStyles(),
        });

        // Add some default space between the components
        secondaryComponentBuilder.setContainerStyles({
            marginLeft: 8,
            ...secondaryComponentBuilder.getContainerStyles(),
        });
    }

    buildProps() {
        const primaryComponentBuilder = this.getPrimaryComponentBuilder();
        const secondaryComponentBuilder = this.getSecondaryComponentBuilder();
        const dialogProps = this.getDialogProps();

        // For this compound component, extract the key prop to use for the wrapping component instead
        // of passing it down to both sub-components which would result in duplicate key warnings
        const { key, ...componentProps } = this.getProps();

        // Pass dialog and component props to sub-builders
        primaryComponentBuilder
            .setDialogProps(dialogProps)
            .setProps(componentProps);

        secondaryComponentBuilder
            .setDialogProps(dialogProps)
            .setProps(componentProps);

        // Update compound component styles
        this.processStyle();
    }


    /**
     * BUILD AND RETURN COMPONENT AS REACT ELEMENT
     */

    build() {
        // Process and construct final props
        this.buildProps();

        // Get individual component builders
        const primaryComponentBuilder = this.getPrimaryComponentBuilder();
        const secondaryComponentBuilder = this.getSecondaryComponentBuilder();

        const {
            // Get styles for wrapping container
            contextStyles,

            // Get key as it was excluded from being automatically passed to sub-components
            key = null,
        } = this.getProps();

        const coreStyles = {
            container: {
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                flexShrink: 1,
            },
        };

        // Build components to elements and wrap with a container to treat as a single component
        return (
            <div
                key={ key }
                style={ {
                    ...coreStyles.container,
                    ...contextStyles.container,
                } }>
                { primaryComponentBuilder.build() }
                { secondaryComponentBuilder.build() }
            </div>
        );
    }
}

export default CompositeComponentBuilder;
