import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { rootStore, constants, serviceFactory } from 'cv-react-core';
import { Log } from 'cv-dialog-sdk';

import routeNames from './routeNames';
import AppLayout from '../components/layouts/AppNavigation/AppNavigation';
import AuthLayout from '../components/layouts/AuthNavigation/AuthNavigation';
import Navigator from './Navigator';

const {
    LOGIN,
    SETTINGS,
    WORKBENCH,
} = routeNames;

const allowedRoutes = [
    LOGIN,
    SETTINGS,
  ];


const setUpDeviceProperties = (uiStore) => {
    const deviceProperties = {
        ...serviceFactory.device.deviceSize,
        GPSSupported: 'false',
        platform: 'browser-react',
    };
    uiStore.setValueForUIObject(
        constants.ui.APPLICATION_UI_ID,
        constants.ui.DEVICE_PROPERTIES,
        deviceProperties,
        false,
    );
};

/**
 * Build Application Navigation Stack
 * @param {Array<Object>} workbenches
 */
export const NavRoute = (props) => {
    const { sessionStore } = rootStore;
    const { workbenches } = sessionStore;
    const targets = {};

    // Cycle through the workbenches to create remaining targets
    for (let i = 0; i < workbenches.length; i += 1) {
        const workbench = workbenches[i];
        targets[workbench.id] = {
            route: WORKBENCH,
            params: { workbenchId: workbench.id },
            title: workbench.name,
        };
    }
    const navigator = new Navigator(props);

    return (
        <AppLayout
            targets={ targets }
            defaultRouteName={ WORKBENCH }
            navigator={ navigator }
            testID="app-layout" />
    );
};

const AuthRoute = (props) => {
    // these routes will show up as menu items
    const targets = {
        [LOGIN]: {
            route: LOGIN,
            name: 'Login',
        },
        [SETTINGS]: {
            route: SETTINGS,
            name: 'Settings',
        },
    };

    const navigator = new Navigator(props);
    const { route, tenantId } = navigator.match.params;

    // check we have a valid tenant id if one provided in route.
    const { settingsStore } = rootStore;
    // This value is set only when the application mounts in the componentDidMount method inside the App.js
    const invalidUrlTenant = settingsStore.getValue('INVALID_TENANT_URL');
    if (invalidUrlTenant) {
        settingsStore.setValue('INVALID_TENANT_URL', false);
        const invalidTenantId = settingsStore.getValue('INVALID_TENANT_ID_USED');
        const { lang: { login } } = serviceFactory;
        const errorMessage = `${login.invalidTenantUrlPath} ${invalidTenantId}; ${login.lastRecognizedTenant} ${login.bookmarkLoginPage}`;
        serviceFactory.notify.showError(errorMessage);
        Log.warn(errorMessage);
    }

    // Did we get a valid tenant id route from url? If we did not, we will move to the settings page
    // and inform the user with the message above.
    // This is an odd place to put this lookup, but since we are not using routing
    // to navigate all the time, wanted to move this prop out of the route since i can't
    // get it down to the component yet. Wanted to remove the observer from the route
    // and push this in since we are already observing. Needs to be used as a routing
    // switch here instead of pushing to another wrapper to again perform the same function.
    const useOauth = settingsStore.getValue(constants.settings.USE_OAUTH);
    const redirectLocation = invalidUrlTenant ? SETTINGS : LOGIN;
    return allowedRoutes.includes(route) && !invalidUrlTenant ?
        (<AuthLayout
            useOauth={ useOauth }
            targets={ targets }
            defaultRouteName={ route }
            navigator={ navigator }
            testID="auth-layout" />) :
        (<Redirect to={ { pathname: `/${tenantId || '#'}/${redirectLocation}` } } />);
};

const renderUnauthenticated = () => {
    const {
        uiStore,
        settingsStore,
    } = rootStore;
    uiStore.clearAll();
    // below code is to show error to the use if there is a invalid session in the deep link url
    // If we set in the UI store in app.js, it is getting cleared in above uistore.clear call. So to persist
    // saving the error in setting store and now adding for display
    const deepLinkingError = settingsStore.getValue('DEEP_LINKING_ERROR');
    if (deepLinkingError) {
        const newError = {
            type: 'generic',
            msg: deepLinkingError,
        };
        uiStore.addErrorForUIObject(constants.ui.APPLICATION_UI_ID, newError);
        settingsStore.setValue('DEEP_LINKING_ERROR', null);
    }
    return AuthRoute;
};

const renderNotLoggedIn = (tenantId, route) => (
    <Router>
        <Switch>
            <Route path="/:tenantId/:route">
                { renderUnauthenticated() }
            </Route>
            <Route path="/">
                <Redirect to={ { pathname: `/${tenantId || '#'}/${route}` } } />
            </Route>
        </Switch>
    </Router>
);

const renderLoggedIn = () => {
    const { sessionStore: { workbenches, initialAction, session: { tenantId } } } = rootStore;
    let defaultWorkbench = workbenches[0];
    let workBenchId;

    // We are setting SelectedWorkbench store only during Workbench OAuth flow manually ( only if it satisfies both checks )
    const params = new URLSearchParams(window.location.search);
    const oAuthToken = params.get(constants.oauth.OAUTH_CALLBACK_TOKEN);
    if (oAuthToken) {
        const { sessionStore, uiStore } = rootStore;
        workBenchId = uiStore.getValueForUIObject(constants.ui.WORKBENCH_UI_OBJECT_ID, constants.ui.WORKBENCH_ACTIVE_ID);
        sessionStore.setOAuthToken(oAuthToken);
        if (workBenchId) {
            sessionStore.setSelectedWorkbenchById(workBenchId);
        }
    }

    workBenchId = workBenchId || initialAction?.workbenchId;
    if (workBenchId) {
        defaultWorkbench = workbenches.find((workbench) => workbench.id === workBenchId) || defaultWorkbench;
    }

    return (
        <Router>
            <Switch>
                <Route
                    path="/:tenantId/:route/:workbenchId/:dialogId?"
                    component={ NavRoute } />
                <Route path="/">
                    <Redirect to={ { pathname: `/${tenantId}/${WORKBENCH}/${defaultWorkbench.id}` } } />
                </Route>
            </Switch>
        </Router>
    );
};

const renderHome = () => {
    const {
        sessionStore,
        settingsStore,
        uiStore } = rootStore;

    const { session } = sessionStore;
    const { workbenches } = sessionStore;
    const hasWorkbenches = !!(workbenches && workbenches.length > 0);
    const tenantId = settingsStore && settingsStore.getValue(constants.settings.TENANT_ID) || '';
    if (session && sessionStore.isLoggedIn() && hasWorkbenches) {
        // Temporary setting for using virtual table until we take the virtual table out.
        const useVirtualTable = settingsStore.getValue('TOGGLE_TO_GRID_TABLE');
        uiStore.setValueForUIObject(constants.ui.APPLICATION_UI_ID, 'TOGGLE_TO_GRID_TABLE', useVirtualTable);
        settingsStore.setValue('TOGGLE_TO_GRID_TABLE', false);
        // End of temp chagnge.
        setUpDeviceProperties(uiStore);
        return renderLoggedIn();
    }

    // Always route the user to the settings page if we do not have a tenant
    const route = !tenantId || tenantId.length === 0 ? SETTINGS : LOGIN;
    return renderNotLoggedIn(tenantId, route);
};


export const route = () => (
    <Router>
        <Switch>
            <Route
                path="/">
                { renderHome() }
            </Route>
        </Switch>
    </Router>
);