import React from 'react';
import * as PropTypes from 'prop-types';
import { constants } from 'cv-react-core';
import { SingleForm as SingleFormBase } from 'cv-library-react-web';

import getStyles from './SingleForm.styles';

const SingleForm = (props) => {
    const {
        formElement,
        ...rest
    } = props;
    const styles = getStyles();
    const { ui } = constants;

    return (
        <SingleFormBase
            contextStyles={ styles }
            formElement={ formElement }
            scrollIdentifier={ ui.SCROLL_CONTAINER }
            { ...rest } />
    );
};

SingleForm.propTypes = {
    formElement: PropTypes.element.isRequired,
};


export default SingleForm;
