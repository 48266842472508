import { rootStore } from 'cv-react-core';

const getStyles = (contextStyles) => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    // Populate the component parts with theme styles
    return {
        // TODO: AppBar needs to support raw elements and control the layout
        // Need to provide a container element due to the TODO in this file.
        container: {
            display: 'flex',
            flexGrow: 1,
            overflow: 'hidden',
            paddingRight: '.5em',
            ...contextStyles.container,
        },

        link: {
            ...theme.fonts.navigationBarHeader,
            ...contextStyles.link,
            '$.hover': {
                cursor: 'pointer',
            },
        },

        // This will control the text overlap and provide an ellipsis
        overlap: {
            direction: 'rtl',
            overflow: 'hidden',
            textAlign: 'left',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            ...contextStyles.overlap,
        },

        spacer: {
            direction: 'ltr',
            unicodeBidi: 'bidi-override',
            ...theme.fonts.navigationBarHeader,
            ...contextStyles.link,
            ...contextStyles.spacer,
        },
    };
};

export default getStyles;
