/* eslint-disable putout/multiple-properties-destructuring */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { serviceFactory } from 'cv-react-core';
import { observer } from 'mobx-react';

import FilterSelector from './FilterSelector';

@observer
class FilterOperationComponent extends Component {
    static defaultProps = {
        // styles: {},
        onSetFilterOperation: () => {},
    }

    static propTypes = {
        propertyName: PropTypes.string.isRequired,
        onSetFilterOperation: PropTypes.func,
        searchDialogStore: PropTypes.object,
    }

    constructor(props) {
        super(props);
        this.handleOnOperationChange = this.handleOnOperationChange.bind(this);
        this.getAvailableValues = this.getAvailableValues.bind(this);
    }

    render() {
        const dropDownProps = {
            ...this.props,
            ...this.getPropData(),
            onChange: this.handleOnOperationChange,
        };

        return React.createElement(FilterSelector, dropDownProps);
    }

    getPropData = () => {
        const { searchDialogStore, propertyName } = this.props;
        const filterOperationProperty = searchDialogStore.getSearchOpPropertyForName(propertyName);
        const { value: propertyOperationValue, name: propertyOperationName } = filterOperationProperty;
        const { lang } = serviceFactory;
        return {
            key: `${propertyOperationName}_${propertyName}`,
            value: propertyOperationValue && propertyOperationValue.description ? propertyOperationValue.description : '',
            itemsResolver: this.getAvailableValues,
            allSelectedText: lang.details.dropdown.allSelected,
            multipleSelectionsText: lang.details.dropdown.multipleSelections,
        };
    }

    getAvailableValues() {
        const { searchDialogStore, propertyName } = this.props;
        const filterOperationProperty = searchDialogStore.getSearchOpPropertyForName(propertyName);
        const { name } = filterOperationProperty;
        const values = searchDialogStore.getAvailableValues(name);
        if (values) {
            return values;
        }
        return searchDialogStore.updateAvailableValues(name)
            .then(() => searchDialogStore.getAvailableValues(name));
    }

    handleOnOperationChange(event, propData) {
        const { value } = propData;
        const { onSetFilterOperation, propertyName } = this.props;
        if (onSetFilterOperation) onSetFilterOperation(propertyName, value);
    }
}

export default FilterOperationComponent;
