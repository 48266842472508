import _initializerDefineProperty from"/home/vsts/work/1/s/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty";import _defineProperty from"/home/vsts/work/1/s/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";import _applyDecoratedDescriptor from"/home/vsts/work/1/s/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor";import _initializerWarningHelper from"/home/vsts/work/1/s/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper";var _class,_descriptor;import{action,computed,observable}from'mobx';/**
 * The purpose of this class is to help orchestrate the selection state of an array
 * of records that are loaded into the store.
 */let SelectedRecordsStore=(_class=class SelectedRecordsStore{constructor(){_initializerDefineProperty(this,"recordSelectionState",_descriptor,this);_defineProperty(this,"lastSelectedRecordId",undefined);}/**
     * Get the last item mark as selected.
     */get lastSelectedRecord(){return this.lastSelectedRecordId;}/**
     * Returns all the records current selection state.
     * @returns Map
     */getRecordsSelectionState(){return this.recordSelectionState;}/**
     * Returns an array of only records who state value is true/selected
     * @returns Array
     */getSelectedRecords(){return Object.fromEntries(Object.entries(this.recordSelectionState).filter(_ref=>{let[key,value]=_ref;return value===true;}));}/**
     * Set the selection state of all records to false
     */clearSelectedRecords(){const selectedRecords=this.getSelectedRecords();Object.entries(selectedRecords).forEach(_ref2=>{let[key]=_ref2;this.setRecordSelectionState(key,false,false);});}/**
     * @param {string} id record Id
     * @returns {undefined} indicator the value was not set.
     */setLastSelectedRecordId(id){if(!id)return;this.lastSelectedRecordId=id;}/**
     * 
     * @param {string} id Record Id 
     * @param {boolean} isSelected selection state
     * @returns {undefined} indicator the value was not set.
     */setRecordSelectionState(id){let isSelected=arguments.length>1&&arguments[1]!==undefined?arguments[1]:false;let setLastRecordSelection=arguments.length>2&&arguments[2]!==undefined?arguments[2]:true;if(!id)return;this.recordSelectionState[id]=isSelected;if(setLastRecordSelection){this.lastSelectedRecordId=id;}}/**
     * Sets up the map of records to be observed
     * @param {array} records 
     */refreshSelectedRecord(records){const reSelectRecordsIfExists=this.getSelectedRecords();this.recordSelectionState={};// Reset all selection content.
records.forEach(record=>{const retainSelection=reSelectRecordsIfExists[record.id];this.setRecordSelectionState(record.id,retainSelection,false);});}/**
     * Adds presaved records to the map so that selection is retained
     * @param {array} savedRecords 
     */restoreSavedSelections(){let savedRecords=arguments.length>0&&arguments[0]!==undefined?arguments[0]:[];savedRecords.forEach(record=>{this.setRecordSelectionState(record.id,true);});}/**
     * Refresh the counter of selected records
     */get selectedRecordCount(){return Object.keys(this.getSelectedRecords()).length;}/**
     * Returns boolean indicating if the record selection state of true or false
     * @param {string} id Record Id 
     * @returns {boolean} indicating the record is selected or not
     */isRecordSelected(id){return this.recordSelectionState[id]||false;}},(_descriptor=_applyDecoratedDescriptor(_class.prototype,"recordSelectionState",[observable],{configurable:true,enumerable:true,writable:true,initializer:function(){return{};}}),_applyDecoratedDescriptor(_class.prototype,"lastSelectedRecord",[computed],Object.getOwnPropertyDescriptor(_class.prototype,"lastSelectedRecord"),_class.prototype),_applyDecoratedDescriptor(_class.prototype,"clearSelectedRecords",[action],Object.getOwnPropertyDescriptor(_class.prototype,"clearSelectedRecords"),_class.prototype),_applyDecoratedDescriptor(_class.prototype,"setLastSelectedRecordId",[action],Object.getOwnPropertyDescriptor(_class.prototype,"setLastSelectedRecordId"),_class.prototype),_applyDecoratedDescriptor(_class.prototype,"setRecordSelectionState",[action],Object.getOwnPropertyDescriptor(_class.prototype,"setRecordSelectionState"),_class.prototype),_applyDecoratedDescriptor(_class.prototype,"refreshSelectedRecord",[action],Object.getOwnPropertyDescriptor(_class.prototype,"refreshSelectedRecord"),_class.prototype),_applyDecoratedDescriptor(_class.prototype,"restoreSavedSelections",[action],Object.getOwnPropertyDescriptor(_class.prototype,"restoreSavedSelections"),_class.prototype),_applyDecoratedDescriptor(_class.prototype,"selectedRecordCount",[computed],Object.getOwnPropertyDescriptor(_class.prototype,"selectedRecordCount"),_class.prototype)),_class);export{SelectedRecordsStore as default};