import { rootStore } from 'cv-react-core';

const getStyles = () => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    // Populate the component parts with theme styles
    return {
        backgroundColor: theme.appBarButtonBackgroundColor,
        padding: theme.appBarButtonPadding,
        borderRadius: theme.appBarButtonBorderRadius,
        minWidth: theme.appBarButtonMinWidth,
        margin: theme.appBarButtonMargin,
        width: theme.appBarButtonWidth,
        height: theme.appBarButtonWidth,
    };
};

export default getStyles;
