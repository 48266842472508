import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { pageController, serviceFactory, constants } from 'cv-react-core';
import { Log } from 'cv-dialog-sdk';

import Prompt from '../base/Prompt';
import Button, { BUTTON_VARIANT } from '../base/Button';
import TextLabel from '../base/TextLabel';
import getStyles from './styles/LogoutModal.styles';

const { lang } = serviceFactory;
const { ui: { LOGOUT_MODAL_ID, LOGOUT_MODAL_IS_OPEN }} = constants;

@observer
class LogoutModal extends PureComponent {
    static propTypes = {
        uiStore: PropTypes.object,
        history: PropTypes.object,
    };

    render() {
        const { uiStore } = this.props;

        // Get styles
        const styles = getStyles();
        const isOpen = uiStore.getValueForUIObject(LOGOUT_MODAL_ID, LOGOUT_MODAL_IS_OPEN) || false;

        return (
            <Prompt
                contextStyles={ styles.logout }
                isModalOpen={ isOpen }
                isTransparentOverlay={ false }
                onClose={ this.handleLogoutCancel }
                title={ lang.mainMenu.logoutConfirmation.title }
                actions={ [
                    <Button
                        onClick={ this.handleLogoutCancel }
                        key="btnCancel"
                        text={ lang.mainMenu.logoutConfirmation.cancelBtn } />,
                    <Button
                        onClick={ this.handleLogout }
                        variant={ BUTTON_VARIANT.OUTLINED }
                        key="btnLogout"
                        text={ lang.mainMenu.logoutConfirmation.confirmBtn } />,
                ] }>
                <TextLabel
                    contextStyles={ styles.logout }>
                    { lang.mainMenu.logoutConfirmation.msg }
                </TextLabel>
            </Prompt>
        );
    }

    handleLogout = () => {
        const { uiStore, history } = this.props;
        uiStore.removeValueForUIObject(LOGOUT_MODAL_ID, LOGOUT_MODAL_IS_OPEN);
        pageController.logout().then(() => {
            history.replace('/');
        })
        .catch((error) => {
            Log.warn(error.message);
            history.replace('/');
        });
    };

    handleLogoutCancel = () => {
        const { uiStore } = this.props;
        uiStore.removeValueForUIObject(LOGOUT_MODAL_ID, LOGOUT_MODAL_IS_OPEN);
    };
}

export default withRouter(LogoutModal);
