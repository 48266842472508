import { rootStore, themes } from 'cv-react-core';

const getStyles = (contextStyles = {}) => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    // Populate the component parts with theme styles
    return {
        headerCell: {
            display: 'flex',
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            overflow: 'hidden',
            borderRightWidth: 1,
            borderRightStyle: 'solid',
            height: '100%',
            paddingLeft: '5px',
            paddingRight: '5px',
            cursor: 'pointer',
            borderRightColor: theme.dataTableGridBorderColor,
            backgroundColor: theme.dataTableHeadingBackgroundColor,
            borderBottomWidth: 1,
            borderBottomStyle: 'inset',
            borderTopStyle: 'none',
            boxSizing: 'border-box',
            ...contextStyles.headerCell,
        },
        headerCellButtonSection: {
            ...contextStyles.headerCellButtonSection,
        },
        headerCellFiltered: {
            backgroundColor: themes.colors.hexagonPallet27GreyLightest,
            ...contextStyles.headerCellFiltered,
        },
        headerCellMenuIcon: {
            ...theme.fonts.dataTableHeading,
            ...contextStyles.headerCellMenuIcon,
        },
        headerCellSortButton: {
            padding: '0px',
            margin: '0px',
            marginLeft: '2px',
            marginRight: '2px',
            ...contextStyles.headerCellSortButton,
        },
        headerCellSortButtonBadge: {
            color: theme.dataTableHeadingIconColor,
            ...contextStyles.headerCellSortButtonBadge,
        },
        headerCellSortButtonIcon: {
            color: theme.dataTableHeadingIconColor,
            fontSize: theme.buttonIconSize,
            ...contextStyles.headerCellSortButtonIcon,
        },
        headerCellText: {
            cursor: 'pointer',
            padding: '2px',
            paddingBottom: '7px',
            ...theme.fonts.dataTableHeading,
            ...contextStyles.headerCellText,
        },
    };
};

export default getStyles;
