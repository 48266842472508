import { MenuSeparator } from 'cv-library-react-web';
import { themes } from 'cv-react-core';

// Get theme method
import getTheme from './MenuSeparator.theme';

// Get theme component method
const { themeComponent } = themes;

// Generate and export a themed component with themed parts
export default themeComponent(MenuSeparator, getTheme);
