import React from 'react';
import getStyles from './ActionSeparator.styles';

/**
 * An ActionSeparator is a visual separator between groups of ActionTools.
 */
const ActionSeparator = () => {
    const styles = getStyles();
    return (
        <div
            className="c-action-separator__container"
            style={ styles.container } />
    );
};

export default ActionSeparator;
