/* eslint-disable putout/multiple-properties-destructuring */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import FilterSelector from './FilterSelector';

export default class FilterValueSelectorComponent extends Component {
    static defaultProps = {
        // styles: {},
        onSetFilterValue: () => {},
    }

    static propTypes = {
        // styles: PropTypes.object,
        propertyName: PropTypes.string.isRequired,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.array,
            PropTypes.object,
        ]),
        isMultiselect: PropTypes.bool,
        onSetFilterValue: PropTypes.func,
        searchDialogStore: PropTypes.object,
        propertyValueName: PropTypes.string,
    }

    constructor(props) {
        super(props);
        this.handleOnDropDownChange = this.handleOnDropDownChange.bind(this);
    }

    render() {
        const dropDownProps = {
            ...this.props,
            onChange: this.handleOnDropDownChange,
        };

        return React.createElement(FilterSelector, dropDownProps);
    }

    componentDidMount() {
        const { propertyValueName, searchDialogStore } = this.props;
        searchDialogStore.updateAvailableValues(propertyValueName)
            .then(() => searchDialogStore.getAvailableValues(propertyValueName));
    }

    handleOnDropDownChange(event, childProps) {
        const { item } = childProps;
        const { value, onSetFilterValue, propertyName, isMultiselect } = this.props;

        if (isMultiselect) {
            if ( Array.isArray(value) ) {
                const index = value.findIndex((propValue) => propValue.code === item.code);
                if (index > -1) {
                    value.splice(index, 1);
                }
                else {
                    value.push(childProps.item);
                }
                return onSetFilterValue(propertyName, value );
            }
            return onSetFilterValue(propertyName, [ item ] );
        }

        return onSetFilterValue(propertyName, item );
    }
}
