import { rootStore, themes } from 'cv-react-core';

const getTheme = () => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();
    const {
        color: colorHeading,
    } = theme.fonts.dataTableHeading;

    const {
        color: colorRow,
    } = theme.fonts.dataTableRow;

    // Populate the component parts with theme styles
    return {
        activityIndicator: {
            color: theme.statusLoadingIndicatorColor,
        },
        cell: {
            paddingLeft: '6px',
            paddingRight: '6px',
            ...theme.fonts.dataTableRow,
            borderRightWidth: 1,
            borderRightStyle: 'solid',
            borderRightColor: theme.dataTableGridBorderColor,
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word',
        },
        cellImage: {
            maxHeight: theme.imageSizeList,
            maxWidth: theme.imageSizeList,
        },
        cellSelected: {
            backgroundColor: theme.listSelectedUnderlayColor,
        },
        container: {
        },
        headerCell: {
            borderRightWidth: 1,
            borderRightStyle: 'solid',
            borderRightColor: theme.dataTableGridBorderColor,
            backgroundColor: theme.dataTableHeadingBackgroundColor,
        },
        headerCellFiltered: {
            backgroundColor: themes.colors.hexagonPallet27GreyLightest,
        },
        headerCellButton: {
            padding: '0px',
            margin: '0px',
            marginLeft: '2px',
            marginRight: '2px',
        },
        headerCellButtonBadge: {
            color: theme.dataTableHeadingIconColor,
        },
        headerCellButtonIcon: {
            color: colorHeading,
            fontSize: theme.buttonIconSize,
        },
        headerCellFilterButton: {
        },
        headerCellFilterButtonIcon: {
            color: theme.dataTableHeadingIconColor,
        },
        headerCellLockButton: {
        },
        headerCellLockButtonIcon: {
            color: theme.dataTableHeadingIconColor,
        },
        headerCellLockButtonSection: {
            backgroundColor: theme.dataTableHeadingBackgroundColor,
        },
        headerCellText: {
            padding: '2px',
            paddingBottom: '7px',
            ...theme.fonts.dataTableHeading,
        },
        headerRow: {
            backgroundColor: theme.dataTableHeadingBackgroundColor,
        },

        // only visible when cells have transparent background color
        row: {
            verticalAlign: 'top',
            alternatingColors: theme.dataTableListAlternatingColors,
        },

        // only visible when cells have transparent background color
        rowSelected: {
            backgroundColor: theme.listSelectedUnderlayColor,
        },
        rowSelectionCell: {
        },
        rowSelectionCellCheck: {
            color: theme.checkBoxUnCheckedBackgroundColor,
        },
        rowSelectionCellChecked: {
            color: theme.checkBoxCheckedBackgroundColor,
        },
        rowSelectionCellSelected: {
        },
        rowSelectionTray: {
            backgroundColor: theme.listSelectedUnderlayColor,
        },
        rowSelectionTrayCloseButton: {
        },
        rowSelectionTrayCloseButtonIcon: {
            color: colorRow,
            fontSize: theme.buttonIconSize,
        },
        rowSelectionTrayText: {
            ...theme.fonts.dataTableRow,
        },
    };
};

export default getTheme;
