import _defineProperty from"/home/vsts/work/1/s/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";/*       */import jsonata from'jsonata';export default class FormUtil{static inspect(o){if(o!==321123.321123){// Prevent lint from complaining
// Log.trace(o);
}}static arrayAt(json,expression){// XML ordering is preserved via a $$ insert child.
const r=jsonata(expression).evaluate(json);const xx='$$';if(r.length===1&&!!r[0][xx]){// Quite confusing, but there are two result types...
return r[0][xx];// Result type A, where order is preserved in r[0]['$$']
}return r;// Result type B, where ordering is not in question
}static adjustFontSize(lineHeight){let fontFamily=arguments.length>1&&arguments[1]!==undefined?arguments[1]:'arial';// These calculations are an attempt to get the font sizes rendered in react to match the font
// sizes of the forms designer.
return lineHeight*FormUtil.calcFontFactor(lineHeight,fontFamily);}static calcFontFactor(lineHeight,fontFamily){// These calculations are an attempt to get the font sizes rendered in react to match the font
// sizes of the forms designer.  If the font name is changed these adjustments will need to be
// tweaked.
let factor=0.95;if(fontFamily.toLowerCase()==='arial'){if(lineHeight<10){factor=0.85;}else if(lineHeight>=10&&lineHeight<15){factor=1;}}return factor;}static cheatTop(boxStyleSet,fontSize){const resultStyleSet=boxStyleSet;const{top}=boxStyleSet.style;const tweak=fontSize*0.28;resultStyleSet.style.top=top-tweak;resultStyleSet.style.height+=tweak/2;return resultStyleSet;}static valueAt(json,expression){const value=jsonata(expression).evaluate(json);return Array.isArray(value)?value[0]:value;}static nameOf(json){// XML parsing inserts the name with #name.
return json&&json['#name']||'';}static newWithName(name){return{'#name':name};}static getAlignment(alignment){// This is a temporary work around map as react native doesn't recognize left and right values.
const alignmentMap={left:'flex-start',right:'flex-end',center:'center'};return alignmentMap[alignment]||alignment;}// @TODO remove this
// This does not work on the server-side
// we can no longer access this because the server is doing the transpile.  however,
// this shouldn't matter - we shouldn't need to know this as salt MUST be cross-platform
// The client Salt rendering implementations should make these adjustments, if there's a platform difference
// @TODO remove this
static isXNA(){return!FormUtil.isXHA;}}_defineProperty(FormUtil,"isXHA",false);