/**
 * A View represents a Catavolt 'Pane' definition.  A Pane can be thought of as a 'panel' or UI component
 * that is responsible for displaying a data record or records. The Pane describes 'how' and 'where' the data will be
 * displayed, as well as surrounding 'meta' data (i.e. the Pane title, the Pane's menus).  The Pane itself does not contain
 * the record or records to be displayed, but may be combined with a {@link Record}(s) to display the data.
 */export class View{/* @TODO Leftover from PaneDef */ /*
    readonly label:string;
    readonly viewDescs:Array<ViewDesc>;
    readonly recordDef:RecordDef;
    readonly dialogRedirection:DialogRedirection;
    readonly settings:StringDictionary;
    */ /**
     * Find the title for this Pane
     * @returns {string}
     */findTitle(){let result=this.title?this.title.trim():'';result=result==='null'?'':result;if(result===''){result=this.title?this.title.trim():'';result=result==='null'?'':result;}return result;}/**
     * Find a menu def on this View with the given actionId
     * @param actionId
     * @returns {Menu}
     */findMenuAt(actionId){if(this.menu){return this.menu.findAtActionId(actionId);}return null;}}