import { rootStore } from 'cv-react-core';

const getTheme = () => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    // Populate the component parts with theme styles
    return {
        input: {
            paddingTop: '3px',
            paddingBottom: '3px',
            ...theme.fonts.default,
        },

        hoover: {
            paddingTop: '3px',
            paddingBottom: '3px',
        },

        underline: {
            borderColor: theme.textInputUnderlineColor,
        },

        underlineFocus: {
            borderColor: theme.textInputUnderlineColor,
        },

        underlineHover: {
            borderColor: theme.textInputUnderlineColor,
        },
    };
};

export default getTheme;
