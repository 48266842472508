import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import { observer } from 'mobx-react';
// import { trace } from 'mobx';
// import { Log } from 'cv-dialog-sdk';

import getStyles from './GMLRecord.styles';

export const propTypes = {
    onClick: PropTypes.func,
    onDoubleClick: PropTypes.func,
    onContextMenu: PropTypes.func,
    onRenderItem: PropTypes.func,
    record: PropTypes.object,
    index: PropTypes.number,
    recordsSelectionState: PropTypes.object,
    contextStyles: PropTypes.object,
};

@observer
class Record extends PureComponent {
    static propTypes = propTypes;

    static defaultProps = {
        onClick: () => Promise.resolve(),
        onDoubleClick: () => Promise.resolve(),
        onContextMenu: () => Promise.resolve(),
        onRenderItem: () => Promise.resolve(),
        contextStyles: {},
    };

    render() {
        // trace(true);
        const {
            record,
            onRenderItem,
            index,
            onClick,
            onDoubleClick,
            recordsSelectionState,
            contextStyles,
            onContextMenu } = this.props;

        // if (!this.rowCounter) this.rowCounter = 0;
        // this.rowCounter += 1;
        // Log.debug(`rowCounter: ${this.rowCounter} RowRenderId: ${record.id}`);

        const selected = recordsSelectionState[record.id];
        return (
            <div
                id={ record.id }
                style={ getStyles(selected, index, contextStyles) }
                onClick={ onClick }
                onDoubleClick={ onDoubleClick }
                onContextMenu={ onContextMenu }>
                { onRenderItem({ item: record }) }
            </div>
        );
    }
}
export default Record;
