import { rootStore } from 'cv-react-core';

const getTheme = () => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    // Populate the component parts with theme styles
    return {
        overlay: {
            shadowColor: theme.modalShadowColor,
        },
        modal: {
            backgroundColor: theme.modalBackgroundColor,
        },
    };
};

export default getTheme;
