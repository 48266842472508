import BasePropsFactory from'./BasePropsFactory';import{constants}from'../../constants';import{utilities}from'../../utilities';/**
 * A builder for creating pie chart components
 */export default class PieChartPropsFactory extends BasePropsFactory{static create(view,records,propDefs,orientation,chartFilteredItem){const{dataPoints,identityDataPoint,groupingDataPoint,filterDataPoints}=view;let donut=true;// Collect property names to identify axis tick label values
let labelPropertyNames;if(groupingDataPoint){labelPropertyNames=groupingDataPoint.map(_ref=>{let{propertyName}=_ref;return propertyName;});}else{labelPropertyNames=identityDataPoint.map(_ref2=>{let{propertyName}=_ref2;return propertyName;});}// Collect the property for Filtering and also created default FiterItems object
const filterProperty=filterDataPoints&&filterDataPoints.map(_ref3=>{let{propertyName,legendKey}=_ref3;return{propertyName,legendKey};});let filterItems={filterArray:[],type:'',minDate:'',maxDate:'',minRange:'',maxRange:''};const{endDateIndex,fromDateIndex,rangeIndex}=utilities.chartHelper.retrievedMatchedIndices(chartFilteredItem);// Loop through dataPoints
const pieData=dataPoints.map(dataPoint=>{const{// Get the legend key for the series legend item
// legendKey,
// Get the property name for finding the record property value
propertyName,plotType,// Get the color for the data set
seriesColor}=dataPoint;if(plotType!=='DONUT'){donut=false;}// Then loop through the records
let pieDataPoints=records.map(record=>{// Then for each record
const{// Collect record annotations
annotations:recordAnnotations,// Get record properties to search for data point property value
properties}=record;// Search the record properties array
const prop=properties.find(property=>{const{name}=property;// Get the property with the data point property name
return name===propertyName;})||{};// Logic to filter the records and return filtered Items and filter property
let filterProp;const{isUndefined,filteredItems,filterPropItem}=utilities.chartHelper.getFilteredItems(filterProp,filterProperty,properties,propDefs,filterItems,chartFilteredItem,fromDateIndex,endDateIndex,rangeIndex);filterItems=filteredItems;filterProp=filterPropItem;if(isUndefined){return undefined;}// Search the record properties array again for the desired label properties
const labels=[];labelPropertyNames.forEach(labelPropertyName=>{const labelProp=properties.find(property=>{const{name}=property;// Get the property with the data point label property name
return name===labelPropertyName;});if(labelProp&&labelProp.value){labels.push(labelProp.value);}});const{// Collect property annotations
annotations:propertyAnnotations,// Collect property name
name,// Collect property value
value}=prop;// Get formatted display value
const propDef=propDefs.find(def=>def.propertyName===name);const displayValue=BasePropsFactory.uiHelper.formatPropertyForRead(prop,propDef)||value;// Process annotations
const recAnnotations=BasePropsFactory.processAnnotations(recordAnnotations,'color');const propAnnotations=BasePropsFactory.processAnnotations(propertyAnnotations,'color');// Construct minimum slice details object
const pieSlice={displayValue,label:labels.join(','),id:record.id,value:BasePropsFactory.processValue(propDef,value)};// Add series color if supplied
if(seriesColor){pieSlice.color=BasePropsFactory.formatColor(seriesColor);}// Add annotation props
return{filter:filterProp?filterProp.value:'',...pieSlice,...recAnnotations,...propAnnotations};});pieDataPoints=pieDataPoints.filter(Boolean);return pieDataPoints;});let pieProps={xAxisTitle:'',yAxisTitle:'',gridLines:false,seriesData:[...pieData],legend:true,grouping:!!groupingDataPoint,filterable:false,filterLabel:filterProperty&&filterProperty[0]&&filterProperty[0].legendKey,filterArray:[],horizontal:orientation===constants.devices.ORIENTATION_LANDSCAPE,renderButtonText:dataPoint=>{const{name,displayValue}=dataPoint;return"Open details for ".concat(name,": ").concat(displayValue);}};if(donut){pieProps.donut=true;}// updating the chartProps with filters to shows and filterable variable
const rangeValue=rangeIndex!==-1?chartFilteredItem[rangeIndex].code:[filterItems.minRange,filterItems.maxRange];pieProps=utilities.chartHelper.updateChartFilterProps(pieProps,filterProperty,filterItems,rangeValue,chartFilteredItem);return pieProps;}}