import React from 'react';
import PropTypes from 'prop-types';

import Route from './Route';
import Navigator from './Navigator';
import LoginPage from '../components/pages/LoginPage/LoginPage';
import OAuthPage from '../components/pages/OAuthPage/OAuthPage';
import lang from '../nls/i18n';

class LoginRoute extends Route {
    static propTypes = {
        navigator: PropTypes.instanceOf(Navigator),
        useOauth: PropTypes.bool,
        sessionStore: PropTypes.object,
        settingsStore: PropTypes.object,
        uiStore: PropTypes.object,
        themeStore: PropTypes.object,
    };

    render() {
        const {
            useOauth,
            navigator,
            settingsStore,
            sessionStore,
            uiStore,
            themeStore } = this.props;

        const params = {
            sessionStore,
            settingsStore,
            uiStore,
            themeStore,
            navigator,
            onPostLogin: this.handleOnLogin,
        };
        // Set browser tab name to the workbench name.
        // Doing this on each route for consistency for now.
        document.title = lang.login.defaultBrowserTitle;
        const page = useOauth ? OAuthPage : LoginPage;
        return React.createElement(page, params);
    }
}

export default LoginRoute;
