import { SwimlaneChart } from 'cv-library-react-web';
import { themes } from 'cv-react-core';

// Get theme method
import getTheme from './SwimlaneChart.theme';

// Get theme component method
const { themeComponent } = themes;

// Generate and export a themed component
export default themeComponent(SwimlaneChart, getTheme);
