import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { rootStore, constants } from 'cv-react-core';
import { HolyGrailLayout } from 'cv-library-react-web';
import AppBar from '../../base/AppBar';
import AppHeader from '../../base/AppHeader/AppHeader';
import AppFooter from '../../base/AppFooter/AppFooter';
import Button from '../../base/Button';

import { getRouteComponent } from '../../../routing/routeComponents';
import getStyles from './AuthNavigation.styles';
import Navigator from '../../../routing/Navigator';
import LoadingIndicator from '../../modal/LoadingIndicator';

/* eslint-disable react/prefer-stateless-function */

class AuthNavigation extends Component {
    static propTypes = {
        targets: PropTypes.object,
        defaultRouteName: PropTypes.string,
        navigator: PropTypes.instanceOf(Navigator),
        useOauth: PropTypes.bool,
        /** Id used for testing */
        testID: PropTypes.string,
    };

    static defaultProps = {
        useOauth: false,
    }

    render() {
        const {
            targets,
            testID,
            defaultRouteName,
            navigator,
            useOauth } = this.props;

        const {
            sessionStore,
            settingsStore,
            uiStore,
            themeStore } = rootStore;

        const styles = getStyles();

        // pull the route from the URL
        const currentRouteName = navigator.match.params.route || defaultRouteName;
        const CurrentRoute = getRouteComponent(currentRouteName);

        // builds links for header
        const links = Object.keys(targets).map((routeName) => {
            // Prevent current route link from showing
            if (routeName !== currentRouteName) {
                const routeObj = targets[routeName];
                const { route } = routeObj;
                return (
                    <Button
                        contextStyles={ {
                            text: { color: '#fff' },
                        } }
                        key={ routeName }
                        onClick={ () => {
                            /**
                             * clears the error object when routing between the screens
                             */
                            uiStore.clearErrorsForUIObject(constants.ui.APPLICATION_UI_ID);
                            const { tenantId } = navigator.match.params;
                            navigator.history.push(`/${tenantId || '#'}/${route}`);
                        } }
                        text={ routeName }
                        variant="text" />
                );
            }
            return null;
        });

        // Construct layout props
        const layoutProps = {
            contextStyles: {
                container: styles.container,
                header: styles.header,
                content: styles.content,
                footer: styles.footer,
            },
        };
        if (testID) { layoutProps['data-test-id'] = `${testID}__layout`; }

        // Render authentication layout
        return (
            <>
                <LoadingIndicator
                    objectId={ constants.ui.LOGIN_IN_PROGRESS }
                    uiStore={ uiStore } />
                <HolyGrailLayout
                    { ...layoutProps }
                    header={
                        <AppHeader>
                            <AppBar
                                showMenu={ false }
                                title={ themeStore.appName }
                                toolbar={ links }
                                logoSrc={ themeStore.appTopLogo } />
                        </AppHeader>
                    }
                    content={ <CurrentRoute
                        useOauth={ useOauth }
                        sessionStore={ sessionStore }
                        settingsStore={ settingsStore }
                        uiStore={ uiStore }
                        themeStore={ themeStore }
                        navigator={ navigator } /> }
                    footer={ <AppFooter /> } />
            </>
        );
    }
}

export default AuthNavigation;
