import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';

// Local components
import CvModal from '../components/base/SimpleModal';
import CalculateStatisticsForm from '../components/forms/CalculateStatisticsForm';

/**
 * Calculate Statistics component to show the statistical data of list
 */
class RWCalculateStatistics extends PureComponent {
    static propTypes = {
        xStyle: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        title: PropTypes.string,
        onCancel: PropTypes.func,
        columns: PropTypes.arrayOf(PropTypes.object),
        rows: PropTypes.arrayOf(PropTypes.object),
    };

    render() {
        const {
            onCancel,
            xStyle,
            ...restProps
        } = this.props;

        // Combine style arrays
        const combine = (style) => {
            let result = {};
            if (Array.isArray(style)) {
                style.forEach((styleObj) => {
                    result = {
                        ...result,
                        ...styleObj,
                    };
                });
            }
            else {
                result = style || {};
            }
            return result;
        };

        const calculateStatisticsFormProps = {
            contextStyles: combine(xStyle),
            onCancel,
            ...restProps,
        };
        const contextStyles = {
            modal: {
                overflow: 'hidden',
            },
            modalColumnContainer: {
                overflow: 'auto',
            },
        };
        return (
            <CvModal
                contextStyles={ contextStyles }
                onPressOverlay={ onCancel }>
                { React.createElement(CalculateStatisticsForm, { ...calculateStatisticsFormProps }) }
            </CvModal>
        );
    }
}

export default RWCalculateStatistics;