import React from 'react';
import { CodeRef, ObjectRef } from 'cv-dialog-sdk';
import { BaseComponentBuilder } from 'cv-react-core';
import { PaperCheckbox } from 'cv-library-react-web';


/**
 * A builder for creating the print checkbox component
 */
class PaperCheckboxComponentBuilder extends BaseComponentBuilder {
    /**
     * COMPONENT BASED PROPERTIES
     */

    /** Begin Props */

    setChecked(value) {
        this.props.checked = value;
        return this;
    }
    getChecked() {
        return this.props.checked;
    }
    setOnChangeTextHandler(onChange) {
        if (typeof onChange === 'function') {
            this.props.onChange = onChange;
        }
        return this;
    }
    setNumberType() {
        this.props.type = 'number';
        return this;
    }
    getType() {
        return this.props.type;
    }

    setText(value) {
        if (typeof value === 'string') {
            this.props.value = value;
        }
        return this;
    }
    getText() {
        return this.props.value;
    }
    /** End Props */

    get isGroup() {
        return this.groupCheckedValue !== undefined;
    }
    get groupCheckedValue() {
        return this.getContextStyles().paperCheckbox.checkedValue;
    }
    get booleanValue() {
        const property = this.getProperty();
        const propDef = this.getPropDef();
        const stagedValue = this.getStagedValue();
        return this.resolveBooleanValue(stagedValue, property, propDef);
    }
    get textValue() {
        const property = this.getProperty();
        const propDef = this.getPropDef();
        const stagedValue = this.getStagedValue();
        let result;
        if (property.value instanceof CodeRef) {
            result = stagedValue || property.value.code;
        }
        else if (property.value instanceof ObjectRef) {
            result = stagedValue || property.value.objectId;
        }
        else {
            result = this.resolveTextValue(stagedValue, property, propDef);
        }
        return result;
    }
    /**
     * PROCESS AND UPDATE COMPONENT PROPERTIES
     */

    processDisplay() {
        // Initialize component value
        if (this.isGroup) {
            const propDef = this.getPropDef();
            const { isNumericType } = propDef;
            // Removes trailing zeroes in some cases for numeric type
            const textValue = isNumericType ? Number(this.textValue).toString() : this.textValue;
            this.setChecked(textValue === this.groupCheckedValue);
        }
        else {
            this.setChecked(this.booleanValue);
        }
    }

    processStyle() {
        const categorizedStyles = BaseComponentBuilder.styleHelper.categorizeNativeStyles(this.getStyle());

        this.setContainerStyles({
            ...categorizedStyles.container,
            ...this.getContainerStyles(),
        });
    }

    processChangeHandler() {
        const onValueChangeHandler = this.getOnValueChangeHandler();
        const isReadMode = this.getReadMode();
        const property = this.getProperty();
        const { name: propertyName = '' } = property;

        if (!isReadMode && !!propertyName && !!onValueChangeHandler) {
            this.setOnChangeTextHandler((event, props) => {
                const { checked } = props;
                let propertyValue = !checked; // Toggle the checked value
                if (this.groupCheckedValue !== undefined) { // If it's a group, use a value instead of the toggle
                    propertyValue = this.groupCheckedValue;
                    if (this.getPropDef().isCodeRefType) { // If it's a CodeRef, wrap the value in a CodeRef
                        propertyValue = new CodeRef(propertyValue, 'Unknown description');
                    }
                }
                onValueChangeHandler(propertyName, propertyValue);
            });
        }
    }

    processInputProps() {
        const propDef = this.getPropDef();
        if (propDef.isNumericType) { this.setNumberType(); }
    }

    buildProps() {
        // Update the display text
        this.processDisplay();

        // Update the input style
        this.processStyle();

        // Update change handler to support property / value data
        this.processChangeHandler();

        // Set input properties based on dialog data
        this.processInputProps();
    }


    /**
     * BUILD AND RETURN COMPONENT AS REACT ELEMENT
     */

    build() {
        // Process and construct final props
        this.buildProps();

        return (
            <PaperCheckbox { ...this.getProps() } />
        );
    }
}

export default PaperCheckboxComponentBuilder;
