import React from 'react';
import * as PropTypes from 'prop-types';
import { rootStore, uiHelper } from 'cv-react-core';

import TextLabel from '../../base/TextLabel';
import getStyles from './AboutPage.styles';

const AboutPage = (props) => {
    const {
        contextStyles,
    } = props;
    const {
        container,
        title,
        titleContainer,
        versionContainer,
        versionLabel,
        versionLabelContainer,
        versionValue,
        versionValueContainer,
    } = getStyles(contextStyles);
    const { sessionStore } = rootStore;
    const clientInformation = uiHelper.getClientInformation(sessionStore);
    return (
        <div
            className="p-about-page"
            style={ container }>
            <TextLabel
                contextStyles={ {
                    container: titleContainer,
                    text: title,
                } }>
                About
            </TextLabel>
            {
                clientInformation.map((version) => (
                    version.value &&
                    <div
                        key={ version.name }
                        style={ versionContainer }>
                        <TextLabel
                            contextStyles={ {
                                container: versionLabelContainer,
                                text: versionLabel,
                            } }>
                            { version.name }
                        </TextLabel>
                        <TextLabel
                            contextStyles={ {
                                container: versionValueContainer,
                                text: versionValue,
                            } }>
                            { version.value }
                        </TextLabel>
                    </div>
                ))
            }
        </div>
    );
};

AboutPage.propTypes = {
    /** ContextStyles for branding this page */
    contextStyles: PropTypes.shape({
        /** Styles for the main wrapping container */
        container: PropTypes.object,

        /** Styles for the about page title */
        title: PropTypes.object,

        /** Styles for the about page title container */
        titleContainer: PropTypes.object,

        /** Styles for the version container */
        versionContainer: PropTypes.object,

        /** Styles for the version label */
        versionLabel: PropTypes.object,

        /** Styles for the version label container */
        versionLabelContainer: PropTypes.object,

        /** Styles for the version value */
        versionValue: PropTypes.object,

        /** Styles for the version value container */
        versionValueContainer: PropTypes.object,
    }),
};

AboutPage.defaultProps = {
    contextStyles: {},
};

export default AboutPage;
