import { rootStore } from 'cv-react-core';

const getStyles = () => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();
    // Populate the component parts with theme styles
    return {
        container: {
            display: 'flex',
            alignItems: 'center',
            padding: '0 8px',
            zIndex: 700,
            backgroundColor: theme.listSelectedUnderlayColor,
        },
        closeButton: {
            marginLeft: '8px',
            padding: '6px',
        },
        closeButtonIcon: {
            color: theme.fonts.dataTableRow.color,
            fontSize: theme.buttonIconSize,
        },
        errorContainer: {
            backgroundColor: theme.colors.error,
        },
        text: {
            color: '#fff',
            ...theme.fonts.dataTableRow,
        },
    };
};

export default getStyles;
