import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
// import { trace } from 'mobx';
import { observer } from 'mobx-react';
// import { Log } from 'cv-dialog-sdk';

import { DeTableRow } from 'cv-library-react-web';
import getRowStyle from './TableRow.styles';

@observer
class TableRow extends PureComponent {
    static propTypes = {
        recordId: PropTypes.string,
        rowBaseProps: PropTypes.object,
        onClick: PropTypes.func,
        onDoubleClick: PropTypes.func,
        onRowsLoaded: PropTypes.func,
        onRowContextMenu: PropTypes.func,
        recordsSelectionState: PropTypes.object,
        rowStyle: PropTypes.object,
    };

    static defaultProps = {
        onClick: () => {},
        onDoubleClick: () => {},
        onRowsLoaded: () => {},
        onRowContextMenu: () => {},
        rowStyle: {},
    }
    constructor(props) {
        super(props);
        this.handleOnClick = this.handleOnClick.bind(this);
        this.handleOnVisible = this.handleOnVisible.bind(this);
        this.handleRowContextMenu = this.handleRowContextMenu.bind(this);
    }

    render() {
        // trace(true);
        const {
            recordId,
            rowStyle,
            recordsSelectionState,
            rowBaseProps } = this.props;

        const selected = recordsSelectionState[recordId];

        const { tableRow, children } = rowBaseProps;
        const { rowId } = tableRow;

        // if (!this.rowCounter) this.rowCounter = 0;
        // this.rowCounter += 1;
        // Log.debug(`rowCounter: ${this.rowCounter} RowRenderId: ${rowId}`);

        const styles = getRowStyle(rowId, selected, rowStyle);
        const rowProps = {
            ...rowBaseProps,
            style: { ...styles },
            onClick: this.handleOnClick,
            onDoubleClick: this.handleOnClick,
            onContextMenu: this.handleRowContextMenu,
        };

        // return React.createElement(DeTableRow, { ...rowProps }, children);
        const childrenWithProps = React.Children.map(children, (child) => {
            // checking isValidElement is the safe way and avoids a typescript error too
            if (React.isValidElement(child)) {
              return React.cloneElement(child, { selected, rowbackgroundcolor: styles.backgroundColor });
            }
            return child;
          });
        return React.createElement(DeTableRow, { ...rowProps }, childrenWithProps);
    }

    componentDidMount() {
        const { rowBaseProps } = this.props;
        const { tableRow } = rowBaseProps;
        const { rowId } = tableRow;
        this.handleOnVisible(rowId);
    }

    handleOnClick(event) {
        event.preventDefault();
        event.stopPropagation();
        const {
            onClick,
            onDoubleClick,
            rowBaseProps } = this.props;
        const { tableRow } = rowBaseProps;
        const { rowId } = tableRow;

        if (event.type === 'click') {
            onClick(rowId, event);
        }
        if (event.type === 'dblclick') {
            onDoubleClick(rowId, event);
        }
    }

    handleOnVisible(row) {
        const { onRowsLoaded } = this.props;
        onRowsLoaded(row);
    };

    handleRowContextMenu(event) {
        event.preventDefault();
        event.stopPropagation();
        const { onRowContextMenu, rowBaseProps } = this.props;
        const { tableRow } = rowBaseProps;
        const { rowId } = tableRow;
        onRowContextMenu(event, rowId );
    };
}

export default TableRow;
