import { TextField, INPUT_TYPES } from 'cv-library-react-web';
import { themes } from 'cv-react-core';

// Get theme method
import getTheme from './TextField.theme';

// Get theme component method
const { themeComponent } = themes;

// Generate and export a themed component with themed parts
export default themeComponent(TextField, getTheme);
export {
    INPUT_TYPES,
};