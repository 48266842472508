import React from 'react';
import { BaseComponentBuilder } from 'cv-react-core';


class ActionIconComponentBuilder extends BaseComponentBuilder {
    setActionIconComponent(actionComponent) {
        this.actionComponent = actionComponent;
        return this;
    }
    getActionIconComponent() {
        return this.actionComponent;
    }


    /**
     * DIALOG BASED PROPERTIES
     */

    // N/A


    /**
     * COMPONENT BASED PROPERTIES
     */

    /** Begin Context Styles */
    setIconStyles(iconStyles) {
        return this.setContextStyles({
            ...this.getContextStyles(),
            icon: iconStyles,
        });
    }
    getIconStyles() {
        const { icon } = this.getContextStyles();
        return icon || {};
    }
    /** End Context Styles */


    /** Begin Props */
    setDisabled(disabled) {
        this.props.disabled = disabled;
        return this;
    }
    getDisabled() {
        return this.props.disabled;
    }

    setOnClick(onClick) {
        this.props.onClick = onClick;
        return this;
    }
    getOnClick() {
        return this.props.onClick;
    }

    setSize(size) {
        this.props.size = size;
        return this;
    }
    getSize() {
        return this.props.size;
    }

    setValue(value) {
        this.props.value = value;
        return this;
    }
    getValue() {
        return this.props.value;
    }
    /** End Props */


    /**
     * PROCESS AND UPDATE COMPONENT PROPERTIES
     */

    processActionData(cFunction) {
        const viewDef = this.getViewDef();
        const { actions } = viewDef;
        if (actions && actions.length) {
            const action = actions.find((a) => a.clientFunction === cFunction);
            if (action) { this.setActionData(action); }
        }
        return this;
    }

    processValue() {
        const property = this.getProperty();
        const propDef = this.getPropDef();
        this.setValue(BaseComponentBuilder.uiHelper.formatPropertyForWrite(property, propDef));
    }

    processAction() {
        const onClick = this.getActionOnPressHandler();
        const actionData = this.getActionData();
        if (onClick) {
            if (actionData) {
                // Inject and pass action data to callback
                this.setOnClick((...onClickArgs) => {
                    onClick(actionData, ...onClickArgs);
                });
                this.setToolTip(actionData.label);
            }
            else {
                this.setOnClick(onClick);
            }
        }
    }

    processChangeHandler() {
        return this;
    }

    processStyles() {
        const categorizedStyles = BaseComponentBuilder.styleHelper.categorizeWebStyles(this.getStyle());

        this.setContainerStyles({
            ...categorizedStyles.container,
            ...this.getContainerStyles(),
        });
        this.setIconStyles({
            ...categorizedStyles.text,
            ...this.getIconStyles(),
        });
    }

    buildProps() {
        // Set action value
        this.processValue();

        // Set action handler
        this.processAction();

        // Set Change handler (this is needed for color picker)
        this.processChangeHandler();

        // Set action styles
        this.processStyles();
    }


    /**
     * BUILD AND RETURN COMPONENT AS REACT ELEMENT
     */

    build() {
        // Process and construct final props
        this.buildProps();

        const ActionIconComponent = this.getActionIconComponent();

        if (ActionIconComponent) {
            return (
                <ActionIconComponent { ...this.getProps() } />
            );
        }

        return null;
    }
}

export default ActionIconComponentBuilder;
