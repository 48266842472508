/* eslint-disable import/prefer-default-export */
import { Log } from 'cv-dialog-sdk';

export const defaultPromiseHandler = (aPromise) => aPromise.catch((err) => {
        if (err) {
            Log.error('defaultPromiseHandler::Caught unhandled error:');
            Log.error(err.message);
            Log.error(err.stack);
        }
    });

export const LogLabels = {
    ROUTENAV: 'route:nav',
};