export const getNavigationPathTenant = () => {
    // Get current URL path name
    let pathName = window.location.pathname;
    const params = new URLSearchParams(window.location.search);
    const tenantID = params.get('tenantid');
    if (tenantID) return tenantID;

    // If leading slash
    if (pathName.charAt(0) === '/') {
        // If not root path (ie: protocol://hostname/xha)
        if (pathName.length > 1) {
            // Remove leading slash from path (ie: protocol://hostname/xha -> xha)
            pathName = pathName.substring(1);
        }

        // Root path (ie: protocol://hostname)
        else {
            // Empty path
            pathName = '';
        }
    }

    // Handle trailing slash  (ie: protocol://hostname/xha/)
    if (pathName && pathName.charAt(pathName.length - 1) === '/') {
        // Remove trailing slash from path (ie: protocol://hostname/xha/ -> xha)
        pathName = pathName.substring(0, pathName.length - 1);
    }

    return pathName;
};

export default {
    getNavigationPathTenant,
};
