import React, { Fragment } from 'react';
import { IconButton } from 'cv-library-react-web';
import { BaseComponentBuilder } from 'cv-react-core';

class SelectActionComponentBuilder extends BaseComponentBuilder {
    setMultiple(multiple = false) {
        this.props.multiple = multiple;
        return this;
    }

    getMultiple() {
        return this.props.multiple;
    }

    setSelectAllProps(props) {
        this.props.selectAll = props;
        return this;
    }

    getSelectAllProps() {
        return this.props.selectAll;
    }

    setSelectNoneProps(props) {
        this.props.selectNone = props;
        return this;
    }

    getSelectNoneProps() {
        return this.props.selectNone;
    }

    performSelectAll() {
        const onValueChangeHandler = this.getOnValueChangeHandler();
        const availableValues = this.getAvailableValues();
        const allItems = availableValues();
        const property = this.getProperty();
        const { name: propertyName = '' } = property;
        if (allItems) {
            onValueChangeHandler(propertyName, allItems || []);
        }
        else {
            const itemsResolver = this.getAvailableValuesLoader();
            if (itemsResolver) {
                Promise.resolve(itemsResolver())
                    .then((data) => {
                        onValueChangeHandler(propertyName, data || []);
                    });
            }
        }
    }

    performSelectNone() {
        const onValueChangeHandler = this.getOnValueChangeHandler();
        const property = this.getProperty();
        const { name: propertyName = '' } = property;
        onValueChangeHandler(propertyName, []);
    }

    processDisplay() {
        const propDef = this.getPropDef();
        const viewDef = this.getViewDef();
        const { actions } = viewDef;
        const { cardinality } = propDef;

        if (propDef.isCodeRefType && cardinality === '*') {
            this.setMultiple(true);
        }

        if (actions && actions.length) {
            const selectAllAction = actions.find((action) => {
                const { actionId } = action;
                return actionId.indexOf('selectAll:') !== -1;
            });
            const selectNoneAction = actions.find((action) => {
                const { actionId } = action;
                return actionId.indexOf('selectNone:') !== -1;
            });
            if (selectAllAction) {
                this.setSelectAllProps({
                    iconName: 'done_all',
                    onClick: () => {
                        this.performSelectAll();
                    },
                    title: selectAllAction.label,
                });
            }
            if (selectNoneAction) {
                this.setSelectNoneProps({
                    iconName: 'clear_all',
                    onClick: () => {
                        this.performSelectNone();
                    },
                    title: selectNoneAction.label,
                });
            }
        }
    }

    buildProps() {
        // Update the display text
        this.processDisplay();
    }
    /**
     * BUILD AND RETURN COMPONENT AS REACT ELEMENT
     */

    build() {
        // Process and construct final props
        this.buildProps();
        const multiple = this.getMultiple();
        const selectAllProps = this.getSelectAllProps();
        const selectNoneProps = this.getSelectNoneProps();
        if (multiple) {
            return (
                <Fragment>
                    { selectAllProps && <IconButton { ...selectAllProps } /> }
                    { selectNoneProps && <IconButton { ...selectNoneProps } /> }
                </Fragment>
                );
            }
        return null;
    }
}

export default SelectActionComponentBuilder;