import BasePropsFactory from'./BasePropsFactory';import{utilities}from'../../utilities';/**
 * A property factory for creating bar graph component props
 */export default class BarChartPropFactory extends BasePropsFactory{static create(view,records,propDefs,chartFilteredItem){const{dataPoints,// Removed for bug item 14040. Since this can't be turned on from extender
// I don't see any reason for the prop and just harded to true below.
// https://dev.azure.com/HexagonXalt/Xalt%20Mobility/_workitems/edit/14040
// displayQuadrantLines,
filterDataPoints,groupingDataPoint,identityDataPoint,xAxisLabel,xAxisRangeFrom,xAxisRangeTo,yAxisLabel,yAxisRangeFrom,yAxisRangeTo}=view;let stacked=true;// Collect property names to identify axis tick label values
let labelPropertyNames;if(groupingDataPoint){labelPropertyNames=groupingDataPoint.map(_ref=>{let{propertyName}=_ref;return propertyName;});}else{labelPropertyNames=identityDataPoint.map(_ref2=>{let{propertyName}=_ref2;return propertyName;});}// Collect the property for Filtering and also created defaulkt FiterItems object
const filterProperty=filterDataPoints&&filterDataPoints.map(_ref3=>{let{propertyName,legendKey}=_ref3;return{propertyName,legendKey};});let filterItems={filterArray:[],type:'',minDate:'',maxDate:'',minRange:'',maxRange:''};const{endDateIndex,fromDateIndex,rangeIndex}=utilities.chartHelper.retrievedMatchedIndices(chartFilteredItem);// Loop through dataPoints
const seriesData=dataPoints.map(dataPoint=>{const{// Get the legend key for the series legend item
legendKey,// Get plot type (stacked)
plotType,// Get the property name for finding the record property value
propertyName,// Get the color for the data set
seriesColor}=dataPoint;// Handle stacked charts
// If any data point is not stacked then the whole chart is not stacked
if(plotType!=='STACKED_BAR'){stacked=false;}// Generate chart series data
const barSeries={barColor:BasePropsFactory.formatColor(seriesColor),legendText:legendKey};// Then loop through the records
barSeries.dataPoints=records.map(record=>{// Then for each record
const{// Collect record annotations
annotations:recordAnnotations,// Get record properties to search for data point property value
properties}=record;// Search the record properties array for the main property
const prop=properties.find(property=>{const{name}=property;// Get the property with the data point property name
return name===propertyName;})||{};// Logic to filter the records and return filtered Items and filter property
let filterProp;const{isUndefined,filteredItems,filterPropItem}=utilities.chartHelper.getFilteredItems(filterProp,filterProperty,properties,propDefs,filterItems,chartFilteredItem,fromDateIndex,endDateIndex,rangeIndex);filterItems=filteredItems;filterProp=filterPropItem;if(isUndefined){return undefined;}// Search the record properties array again for the desired label properties
const labels=[];labelPropertyNames.forEach(labelPropertyName=>{const labelProp=properties.find(property=>{const{name}=property;// Get the property with the data point label property name
return name===labelPropertyName;});if(labelProp&&labelProp.value){labels.push(labelProp.value);}});const{// Collect property annotations
annotations:propertyAnnotations,// Collect property name
name,// Collect property value
value}=prop;// Get formatted display value
const propDef=propDefs.find(def=>def.propertyName===name);const displayValue=BasePropsFactory.uiHelper.formatPropertyForRead(prop,propDef)||value;// Process annotations
const recAnnotations=BasePropsFactory.processAnnotations(recordAnnotations,'barColor');const propAnnotations=BasePropsFactory.processAnnotations(propertyAnnotations,'barColor');// Construct a bar detail object
return{axisLabel:labels.join(','),displayValue,id:record.id,value:BasePropsFactory.processValue(propDef,value),filter:filterProp?filterProp.value:'',...recAnnotations,...propAnnotations};});barSeries.dataPoints=barSeries.dataPoints.filter(Boolean);return barSeries;});let barProps={xAxisTitle:xAxisLabel||'',yAxisTitle:yAxisLabel||'',gridLines:true,legend:true,stacked:false,grouping:!!groupingDataPoint,filterable:false,filterLabel:filterProperty&&filterProperty[0]&&filterProperty[0].legendKey,filterArray:[],seriesData:[...seriesData],renderButtonText:dataPoint=>{const{name,displayValue}=dataPoint;return"Open details for ".concat(name,": ").concat(displayValue);}};if(stacked){barProps.stacked=true;}if(xAxisRangeFrom||xAxisRangeFrom===0){barProps.minX=xAxisRangeFrom;}if(xAxisRangeTo||xAxisRangeTo===0){barProps.maxX=xAxisRangeTo;}if(yAxisRangeFrom||yAxisRangeFrom===0){barProps.minY=yAxisRangeFrom;}if(yAxisRangeTo||yAxisRangeTo===0){barProps.maxY=yAxisRangeTo;}// updating the chartProps with filters to shows and filterable variable
const rangeValue=rangeIndex!==-1?chartFilteredItem[rangeIndex].code:[filterItems.minRange,filterItems.maxRange];barProps=utilities.chartHelper.updateChartFilterProps(barProps,filterProperty,filterItems,rangeValue,chartFilteredItem);return barProps;}}