/* eslint-disable class-methods-use-this */
/* eslint-disable react/jsx-indent */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { BaseTooltip } from 'cv-library-react-web';
import DropDown from '../DropDown';

export default class ActionDropDown extends PureComponent {
    static defaultProps = {
        contextStyles: {},
        items: [],
    };

  	static propTypes = {
  	    /** Styles for this component */
  	    contextStyles: PropTypes.object,
  	    /** Items to present in the Drop Down */
        items: PropTypes.array,
        /** ItemSelectedId */
        itemSelected: PropTypes.object,
  	    /** Tooltip for hoovering over image */
  	    tooltip: PropTypes.string,
  	    /** Handler for pressing the image */
  	    onAction: PropTypes.func,
  	};

  	render() {
  	    const {
            contextStyles,
            items,
            itemSelected,
            tooltip,
        } = this.props;

        const styles = this.formatStyleForDropDown(contextStyles);
  	    return (
            <BaseTooltip tooltip={ tooltip }>
                <DropDown
                    items={ items }
                    onChange={ this.handleOnAction }
                    renderItem={ this.onHandleRenderItem }
                    value={ itemSelected && itemSelected.title }
                    contextStyles={ styles } />
            </BaseTooltip>
  	    );
      }

    handleOnAction = (event, item) => {
        const { onAction } = this.props;
        if (onAction && item && typeof item.value === 'object') onAction(item.value);
    }

    onHandleRenderItem = (MenuItem, item) => (
        <MenuItem
            value={ item.name }
            text={ item.title }
            key={ item.id }
            item={ item } />
    )

    formatStyleForDropDown(contextStyles) {
        // TODO: Have to be explicit until we remove all the specific styling
        return {
            dropDown: {
                ...contextStyles,
            },
        };
    }
}