/* eslint-disable array-bracket-newline */
/* eslint-disable array-element-newline */
/* eslint-disable putout/multiple-properties-destructuring */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { constants, uiHelper, serviceFactory, utilities, rootStore } from 'cv-react-core';
import { TimeValue, CodeRef, Catavolt } from 'cv-dialog-sdk';
import { observer } from 'mobx-react';

import TextField from '../base/TextField';
import Toggle from '../base/Switch';
import FilterValueSelectorComponent from './FilterValueSelectorComponent';
import DateTimePicker, { DATE_TYPES, DATE_VIEWS, DATETIME_PICKER_VARIANTS } from '../base/DateTimePicker';

const { searchSort } = constants;
const { FILTER_TYPES } = searchSort;
const { calendarHelper } = utilities;

@observer
class FilterValueComponent extends Component {
    static defaultProps = {
        // styles: {},
        onSetFilterValue: () => {},
    }

    static propTypes = {
        propertyName: PropTypes.string.isRequired,
        onSetFilterValue: PropTypes.func,
        placeholderText: PropTypes.string,
        searchDialogStore: PropTypes.object,
    }

    constructor(props) {
        super(props);
        this.handleDateOnChange = this.handleDateOnChange.bind(this);
        this.handleTextFieldOnChange = this.handleTextFieldOnChange.bind(this);
        this.handleSwitchOnChange = this.handleSwitchOnChange.bind(this);
        this.handleOnFieldValueChange = this.handleOnFieldValueChange.bind(this);
    }

    render() {
        // TODO: This needs to be cleaned up.
        const { value, placeholderText, propertyValueDefinition, propertyAttribute, ...restProps } = this.getPropData();
        const propertyType = this.getPropertyType(propertyAttribute, propertyValueDefinition);

        if (propertyType === FILTER_TYPES.BOOLEAN) {
                return (
                    <Toggle
                        checked={ value || false }
                        onChange={ this.handleSwitchOnChange } />
                );
        }
        if (propertyType === FILTER_TYPES.DATE) {
            return this.renderDateComponent(propertyValueDefinition, value);
        }
        if (propertyAttribute.isDropDownEntryMethod) {
            const { onSetFilterValue, searchDialogStore } = this.props;
            const filterSelectorProps = {
                value,
                placeholderText,
                ...restProps,
                searchDialogStore,
                onSetFilterValue,
            };
            return React.createElement(FilterValueSelectorComponent, { ...filterSelectorProps });
        }

        return (
            <TextField
                onChange={ this.handleTextFieldOnChange }
                placeholder={ placeholderText }
                type={ propertyType }
                value={ (value || value === 0) ? value : '' } />
        );
    };

    renderDateComponent(propertyValueDefinition, value) {
        const { placeholderText } = this.props;
        const is24Hour = uiHelper.getIs24HourFormat();
        const dateFormat = uiHelper.getDateFormatString();
        const firstDayofWeek = calendarHelper.getFirstDayofWeek(rootStore);

        const dateTimeProps = {
            mode: DATE_TYPES.DATE,
            format: dateFormat,
            views: [],
            // since we are using picker variant as INLINE, the autoOK should be set as TRUE as INLINE variant doesnt show set/clear/cancel Buttons.
            autoOk: true,
            placeholder: placeholderText,
            onChange: this.handleDateOnChange,
            selectedDate: value || null,
            is24Hour,
            firstDayofWeek,
        };

        if (propertyValueDefinition.isDateType) {
            dateTimeProps.variant = DATETIME_PICKER_VARIANTS.INLINE;
            dateTimeProps.views = [ DATE_VIEWS.DATE ];
        }
        if (propertyValueDefinition.isTimeType) {
            const format = TimeValue.getFormatString(Catavolt.locale.langCountryString);
            dateTimeProps.mode = DATE_TYPES.TIME;
            dateTimeProps.format = format; // Needs to be NLS aware
            dateTimeProps.variant = DATETIME_PICKER_VARIANTS.INLINE;
            dateTimeProps.views = [ DATE_VIEWS.HOURS, DATE_VIEWS.MINUTES ];
            dateTimeProps.selectedDate = value instanceof TimeValue ? value.toDateValue() : null;
        }
        if (propertyValueDefinition.isDateTimeType) {
            const format = is24Hour ? `${dateFormat}, HH:mm` : `${dateFormat}, hh:mm a`;
            dateTimeProps.mode = DATE_TYPES.DATETIME;
            dateTimeProps.format = format; // Needs to be NLS aware
            dateTimeProps.variant = DATETIME_PICKER_VARIANTS.INLINE;
            dateTimeProps.views = [ DATE_VIEWS.DATE, DATE_VIEWS.HOURS, DATE_VIEWS.MINUTES ];
            dateTimeProps.hideTabs = true;
        }
        return React.createElement(DateTimePicker, { ...dateTimeProps });
    }

    getPropData = () => {
        const { propertyName, searchDialogStore } = this.props;
        const filterValueProperty = searchDialogStore.getSearchValuePropertyForName(propertyName);
        const { name, value } = filterValueProperty;

        // ******************************* TODO ******************************************************
        /**
         * Currently we have an issue persiting the updates for code-ref values coming from search results
         * in an array. This changes force an update by creating a new object type to trick the save routine.
         * Need to dive into this process and how search handles in general for save functionality. This is
         * really unique for how search is saved.
         */
         const filterValueDefinition = searchDialogStore.getSearchValuePropertyDefForName(propertyName);
         let newCodeRefValues;
         if (filterValueDefinition.format === 'code-ref') {
             if (Array.isArray(value)) {
                 newCodeRefValues = value.map((val) => new CodeRef(val.code, val.description));
             }
         }
         // ************************************ END TODO ***********************************************

        const { dialog } = searchDialogStore;
        const { view } = dialog;
        const { attributeCellsByPropName } = view;

        const propertyValueDefinition = searchDialogStore.getSearchValuePropertyDefForName(propertyName);
        const { propertyName: propertyValueName } = propertyValueDefinition;
        const { isComboBoxEntryMethod, autoFillCapable } = attributeCellsByPropName[name];
        const { isCodeRefType, cardinality } = propertyValueDefinition;

        const { lang } = serviceFactory;
        return {
            key: `${propertyValueName}_${propertyName}`,
            propertyName,
            propertyValueName,
            value: newCodeRefValues || value,
            placeholderText: lang.searchSort.filterValuePlaceholderText,
            items: searchDialogStore.getAvailableValues(propertyValueName),
            isMultiselect: isCodeRefType && cardinality === '*',
            isComboBox: isComboBoxEntryMethod || autoFillCapable || false,
            propertyAttribute: attributeCellsByPropName[name],
            allSelectedText: lang.details.dropdown.allSelected,
            multipleSelectionsText: lang.details.dropdown.multipleSelections,
            propertyValueDefinition,
        };
    }

    getPropertyType = (propertyAttribute, propertyValueDefinition) => {
        let filterType = FILTER_TYPES.STRING;

        if (propertyAttribute.isTextFieldEntryMethod) {
            if (propertyValueDefinition.isStringType) { filterType = FILTER_TYPES.STRING; }
            if (propertyValueDefinition.isTextBlockType) { filterType = FILTER_TYPES.STRING; }
            if (propertyValueDefinition.isBooleanType) { filterType = FILTER_TYPES.BOOLEAN; }
            if (propertyValueDefinition.isDateType) { filterType = FILTER_TYPES.DATE; }
            if (propertyValueDefinition.isTimeType) { filterType = FILTER_TYPES.DATE; }
            if (propertyValueDefinition.isDateTimeType) { filterType = FILTER_TYPES.DATE; }
            if (propertyValueDefinition.isNumericType) { filterType = FILTER_TYPES.INTEGER; }
            if (propertyValueDefinition.isIntType) { filterType = FILTER_TYPES.INTEGER; }
            if (propertyValueDefinition.isDecimalType) { filterType = FILTER_TYPES.DECIMAL; }
            if (propertyValueDefinition.isMoneyType) { filterType = FILTER_TYPES.DECIMAL; }
            if (propertyValueDefinition.isPercentType) { filterType = FILTER_TYPES.DECIMAL; }
        }

        return filterType;
    };

    handleSwitchOnChange(event) {
        const { target } = event;
        const { checked: value } = target;
        this.handleOnFieldValueChange(value);
    }

    handleDateOnChange(date) {
        // eslint-disable-next-line no-restricted-globals
        if (date && isNaN(date.getTime())) {
            // ignore invalid dates
            return;
        }
        this.handleOnFieldValueChange(date);
    }

    handleTextFieldOnChange(event) {
        const { target } = event;
        const { value } = target;
        this.handleOnFieldValueChange(value);
    }

    handleOnFieldValueChange(value) {
        const { onSetFilterValue, propertyName } = this.props;
        if (onSetFilterValue) onSetFilterValue(propertyName, value);
    }
}

export default FilterValueComponent;