/* eslint-disable camelcase */ /* eslint-disable no-bitwise, no-plusplus */const decodePolyLinePoints=encoded=>{const points=[];let index=0;const len=encoded.length;let lat=0;let lng=0;while(index<len){let b;let shift=0;let result=0;do{// Find ASCII and subtract it by 63
b=encoded.charAt(index++).charCodeAt(0)-63;result|=(b&0x1f)<<shift;shift+=5;}while(b>=0x20);lat+=(result&1)!==0?~(result>>1):result>>1;shift=0;result=0;do{b=encoded.charAt(index++).charCodeAt(0)-63;result|=(b&0x1f)<<shift;shift+=5;}while(b>=0x20);lng+=(result&1)!==0?~(result>>1):result>>1;points.push({latitude:lat/1e5,longitude:lng/1e5});}return points;};/* eslint-enable no-bitwise, no-plusplus */ /**
 * @param {String|LatLng} origin
 * @param {String|LatLng} destination
 * @returns {Promise<Array<Route>|Error>}
 */const getGoogleRoutesBetweenLocations=function(origin,destination,maxNumberOfRoutes){let routeColors=arguments.length>3&&arguments[3]!==undefined?arguments[3]:[];if(!origin||!destination){return Promise.reject(new Error("Invalid origin or destination: '".concat(origin," to ").concat(destination,"'")));}const language='en';const alternatives=true;const units='imperial';// imperial / metric
const apiKey='AIzaSyC5WpEECHQ9kJqI9KTZh4tTIYAbEqJpRUE';// Convert origin and destination into query parameters
let routeStart=origin;let routeEnd=destination;if(typeof origin==='object'&&origin.latitude&&origin.longitude){routeStart="".concat(origin.latitude,",").concat(origin.longitude);}if(typeof destination==='object'&&destination.latitude&&destination.longitude){routeEnd="".concat(destination.latitude,",").concat(destination.longitude);}// Define the URL to call. Only add default parameters to the URL if it's a string.
let url='https://maps.googleapis.com/maps/api/directions/json';url+="?origin=".concat(routeStart,"&destination=").concat(routeEnd,"&key=").concat(apiKey,"&language=").concat(language,"&alternatives=").concat(alternatives,"&units=").concat(units);let failureMessage='';return fetch(url).then(response=>response.json()).then(json=>{if(json.status!=='OK'){failureMessage="cv-react-core::mapHelper: Status: ".concat(json.status," \r\n Message: ").concat(json.error_message);}if(json.status==='ZERO_RESULTS'){return[];}// Convert route data into statistics and PolyLine data
if(json.routes.length){return json.routes.map((route,idx)=>({leg:route.legs.reduce((carry,curr)=>{return curr;}),distance:route.legs.reduce((carry,curr)=>{return carry+curr.distance.value;},0)/1000,duration:route.legs.reduce((carry,curr)=>{return carry+curr.duration.value;},0)/60,coordinates:decodePolyLinePoints(route.overview_polyline.points),color:routeColors[idx]}));}return Promise.reject(failureMessage);}).then(routes=>{return routes.slice(0,maxNumberOfRoutes);});};/**
 * @param {String} addressText
 * @returns {Promise<LatLng|Error>}
 */const getGoogleCoordinatesForAddress=addressText=>{if(!addressText){const msg="Invalid address: '".concat(addressText,"'");return Promise.reject(msg);}const apiKey='AIzaSyC5WpEECHQ9kJqI9KTZh4tTIYAbEqJpRUE';return fetch("https://maps.googleapis.com/maps/api/geocode/json?address=".concat(encodeURIComponent(addressText),"&key=").concat(apiKey)).then(response=>response.json()).then(responseJson=>{const{results}=responseJson;const[result]=results;const{geometry:{location:{lat,lng}},formatted_address}=result;return{latitude:lat,longitude:lng,address:formatted_address};});};/**
 * @param {String} coordinates
 * @returns {Promise<Address|Error>}
 */const getGoogleAddressForCoordinates=coordinates=>{if(!coordinates){const msg="Invalid coordinates: ".concat(coordinates);return Promise.reject(msg);}const apiKey='AIzaSyC5WpEECHQ9kJqI9KTZh4tTIYAbEqJpRUE';return fetch("https://maps.googleapis.com/maps/api/geocode/json?latlng=".concat(encodeURIComponent(coordinates),"&key=").concat(apiKey)).then(response=>response.json()).then(responseJson=>{const{results}=responseJson;if(results[0]){return results[0].formatted_address;}return'Dropped Pin: No Address Found';});};const mapHelper={getGoogleAddressForCoordinates,getGoogleCoordinatesForAddress,getGoogleRoutesBetweenLocations,decodePolyLinePoints};export default mapHelper;