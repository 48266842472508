import React from 'react';
import * as PropTypes from 'prop-types';
import { HolyGrailLayout } from 'cv-library-react-web';

import { rootStore } from 'cv-react-core';

import AppBar from '../../base/AppBar';
import AppHeader from '../../base/AppHeader/AppHeader';
import AppFooter from '../../base/AppFooter/AppFooter';
import Layout from '../../layouts/BasePage/BasePage';
import BrowserSupportList from '../../base/BrowserSupportList';
import Logo from '../../../assets/images/logos/xalt_login_logo.png';

import getStyles from './BrowserSupportPage.styles';

const BrowserSupportPage = (props) => {
    const {
        contextStyles,
    } = props;

    const styles = getStyles(contextStyles);

    const {
        themeStore,
    } = rootStore;

    return (
        <HolyGrailLayout
            header={
                <AppHeader>
                    <AppBar
                        showMenu={ false }
                        title={ themeStore.appName }
                        logoSrc={ themeStore.appTopLogo } />
                </AppHeader>
            }
            content={
                <Layout
                    contextStyles={ {
                        container: styles.container,
                    } }>
                    <BrowserSupportList
                        contextStyles={ {
                            container: {
                                marginTop: '26px',
                                width: '600px',
                            },
                            media: {
                                backgroundSize: '75%',
                            },
                        } }
                        imageSrc={ Logo } />
                </Layout>
            }
            footer={ <AppFooter /> } />
    );
};

BrowserSupportPage.propTypes = {
    contextStyles: PropTypes.shape({
        container: PropTypes.object,
    }),
};

BrowserSupportPage.defaultProps = {
    contextStyles: {},
};

export default BrowserSupportPage;
