import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import { constants } from 'cv-react-core';

import { DeTableLoading } from 'cv-library-react-web';
import LoadingTableHeaderCell from './LoadingTableHeaderCell';
import LoadingTableHeaderCellContent from './LoadingTableHeaderCellContent';

const { searchSort } = constants;
const { DOT_REPLACEMENT } = searchSort;

class LoadingTable extends PureComponent {
    static propTypes = {
        columns: PropTypes.array,
        overrideMessage: PropTypes.string,
    };

    static defaultProps = {
        overrideMessage: '',
    };

    render() {
        const { columns, overrideMessage } = this.props;

        return (
            <DeTableLoading
                columns={ this.getColumnData(columns) }
                headerCell={ LoadingTableHeaderCell }
                headerCellContent={ LoadingTableHeaderCellContent }
                overrideMessages={ { noData: overrideMessage } } />
        );
    }

    getColumnData = (columns) => {
        this.columnData = columns.map((c) => {
            return {
                // Handle property names with dots
                name: c.propertyName.replace('.', DOT_REPLACEMENT),
                title: c.heading,
            };
        });
        return this.columnData;
    };
}

export default LoadingTable;
