const getStyles = (contextStyles = {}) => {
    // Populate the component parts with theme styles
    return {
        carouselContainer: {
            ...contextStyles.carouselContainer,
        },

        container: {
            overflow: 'auto',
            ...contextStyles.container,
        },

        displayImage: {
            height: '100%',
            minHeight: '100px',
            objectFit: 'contain',
            width: '100%',
            ...contextStyles.displayImage,
        },

        imageDescription: {
            textAlign: 'center',
            ...contextStyles.imageDescription,
        },

        imageDescriptionContainer: {
            padding: '10px',
            ...contextStyles.imageDescriptionContainer,
        },

        leftNavIcon: {
            fontSize: '4rem',
            ...contextStyles.leftNavIcon,
        },

        leftNavIconContainer: {
            left: 0,
            position: 'absolute',
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 4,
            ...contextStyles.leftNavIconContainer,
        },

        mainImage: {
            cursor: 'pointer',
            height: '100%',
            maxHeight: '500px',
            minHeight: '200px',
            objectFit: 'contain',
            width: '100%',
            ...contextStyles.mainImage,
        },

        placeholderIcon: {
            fontSize: 100,
            ...contextStyles.placeholderIcon,
        },

        rightNavIcon: {
            fontSize: '4rem',
            ...contextStyles.rightNavIcon,
        },

        rightNavIconContainer: {
            position: 'absolute',
            right: 0,
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 4,
            ...contextStyles.rightNavIconContainer,
        },

        thumbnailImage: {
            cursor: 'pointer',
            height: '100%',
            minHeight: '100px',
            objectFit: 'contain',
            width: '100%',
            ...contextStyles.thumbnailImage,
        },
    };
};

export default getStyles;