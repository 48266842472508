import _defineProperty from"/home/vsts/work/1/s/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";/*       */import Context from'../../Context';import{GENERAL}from'../../exportClassConstants';import SimpleStringAttribute from'../SimpleStringAttribute';import StyleSet from'../../StyleSet';/**
 * A Scroll Attribute identifies whether scrolling is permitted for a given box.  Possible values from
 * GML include true/false.  This attribute is constructed with horizontal or vertical value.
 */export default class Scroll extends SimpleStringAttribute{constructor(value){super('scroll',value,[GENERAL]);_defineProperty(this,"isHorizontal",()=>{return this.value==='horizontal';});_defineProperty(this,"isVertical",()=>{return!this.isHorizontal();});}// eslint-disable-next-line no-unused-vars
exportTo(context,styleSet){// const { style } = styleSet;
// if (SimpleStringAttribute.hasConstantRef(this.value)) {
//     SimpleStringAttribute.reportExportIssue('scroll', this.value);
// }
// if (context.parentContext) {
//     if (context.parentContext.flexDirection.value === context.flexDirection.value) {
//         style.flex = 1;
//     } else {
//         style['align-items'] = 'stretch';
//     }
// }
}}