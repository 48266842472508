import { DateTimePicker, DATE_TYPES, DATE_VIEWS, DATETIME_PICKER_VARIANTS } from 'cv-library-react-web';
import { themes } from 'cv-react-core';

// Get theme method
import getTheme from './DateTimePicker.theme';

// Get theme component method
const { themeComponent } = themes;

// Generate and export a themed component
export default themeComponent(DateTimePicker, getTheme);
export {
    DATE_TYPES,
    DATE_VIEWS,
    DATETIME_PICKER_VARIANTS,
};
