import BasePropsFactory from'./BasePropsFactory';import{utilities}from'../../utilities';import serviceFactory from'../../services/serviceFactory';/**
 * A builder for creating scatter chart components
 */export default class BubbleChartPropsFactory extends BasePropsFactory{static create(view,records,propDefs,chartFilteredItem){const{dataPoints,displayQuadrantLines,identityDataPoint,filterDataPoints,xAxisLabel,xAxisRangeFrom,xAxisRangeTo,yAxisLabel,yAxisRangeFrom,yAxisRangeTo}=view;// Collect property names to identify axis tick label values
const labelPropertyNames=identityDataPoint.map(_ref=>{let{propertyName}=_ref;return propertyName;});// Collect the property for Filtering and also created default FiterItems object
const filterProperty=filterDataPoints&&filterDataPoints.map(_ref2=>{let{propertyName,legendKey}=_ref2;return{propertyName,legendKey};});let filterItems={filterArray:[],type:'',minDate:'',maxDate:'',minRange:'',maxRange:''};const{endDateIndex,fromDateIndex,rangeIndex}=utilities.chartHelper.retrievedMatchedIndices(chartFilteredItem);// Get number of records
const recordCount=records.length;// Calculate symbol size based on record count
let symbolSize=40;if(recordCount>50){symbolSize*=0.8;}if(recordCount>200){symbolSize*=0.6;}let errorText;const{lang}=serviceFactory;// Loop through dataPoints
const seriesData=dataPoints.map(dataPoint=>{const{bubbleRadiusName,// Get the respective data point radius
legendKey,// Get the legend key for the series legend item
propertyName,// Get the property name for finding the record property value
seriesColor,// Get the color for the data set
xAxisName// Get the respective data point property
}=dataPoint;// Generate chart series data
const bubbleSeries={legendText:legendKey,symbolColor:BasePropsFactory.formatColor(seriesColor),valueLabel:true};if(!xAxisName){errorText=lang.errors.scatterBubbleError;bubbleSeries.dataPoints=[];return bubbleSeries;}// Then loop through the records
bubbleSeries.dataPoints=records.map(record=>{// Then for each record
const{// Collect record annotations
annotations:recordAnnotations,// Get record properties to search for data point property value
properties}=record;// Search the record properties array for the Y prop
const yProp=properties.find(property=>{const{name}=property;// Get the property with the data point property name
return name===propertyName;})||{};// Search the record properties array for the X prop
const xProp=properties.find(property=>{const{name}=property;// Get the property with the data point property name
return name===xAxisName;})||{};// Logic to filter the records and return filtered Items and filter property
let filterProp;const{isUndefined,filteredItems,filterPropItem}=utilities.chartHelper.getFilteredItems(filterProp,filterProperty,properties,propDefs,filterItems,chartFilteredItem,fromDateIndex,endDateIndex,rangeIndex);filterItems=filteredItems;filterProp=filterPropItem;if(isUndefined){return undefined;}// Search the record properties array again for the desired label properties
const labels=[];labelPropertyNames.forEach(labelPropertyName=>{const labelProp=properties.find(property=>{const{name}=property;// Get the property with the data point label property name
return name===labelPropertyName;});if(labelProp&&labelProp.value){labels.push(labelProp.value);}});const{// Collect Y property annotations
annotations:yPropertyAnnotations,// Collect Y property value
value:yValue}=yProp;const{// Collect X property annotations
annotations:xPropertyAnnotations,// Collect X property value
value:xValue}=xProp;let radiusValue=symbolSize;let radiusPropertyAnnotations=[];// Specifically check for bubble charts without a radius property
if(bubbleRadiusName){// Search the record properties array for the RADIUS prop
const radiusProp=properties.find(property=>{const{name}=property;// Get the property with the data point property name
return name===bubbleRadiusName;});const{// Collect RADIUS property annotations
annotations:rPropertyAnnotations,// Collect RADIUS property value
value:rValue}=radiusProp;// Set radius values
radiusValue=rValue*20;radiusPropertyAnnotations=rPropertyAnnotations;}// Process annotations
const recAnnotations=BasePropsFactory.processAnnotations(recordAnnotations,'symbolColor');const yPropAnnotations=BasePropsFactory.processAnnotations(yPropertyAnnotations,'symbolColor');const xPropAnnotations=BasePropsFactory.processAnnotations(xPropertyAnnotations,'symbolColor');const radiusPropAnnotations=BasePropsFactory.processAnnotations(radiusPropertyAnnotations,'symbolColor');// Construct a point data object
return{bubbleRadiusName,filter:filterProp?filterProp.value:'',id:record.id,name:labels.join(','),symbolSize:radiusValue,value:[xValue,yValue],...recAnnotations,...yPropAnnotations,...xPropAnnotations,...radiusPropAnnotations};});bubbleSeries.dataPoints=bubbleSeries.dataPoints.filter(Boolean);return bubbleSeries;});let bubbleChartProps={seriesData:[...seriesData],xAxisTitle:xAxisLabel||'',yAxisTitle:yAxisLabel||'',gridLines:displayQuadrantLines,legend:true,errorText,filterable:false,filterLabel:filterProperty&&filterProperty[0]&&filterProperty[0].legendKey,filterArray:[],renderButtonText:dataPoint=>{const{name,value}=dataPoint;return"Open details for ".concat(name,": ").concat(value[1]);}};// Set chart series data
if(xAxisRangeFrom||xAxisRangeFrom===0){bubbleChartProps.minX=xAxisRangeFrom;}if(xAxisRangeTo||xAxisRangeTo===0){bubbleChartProps.maxX=xAxisRangeTo;}if(yAxisRangeFrom||yAxisRangeFrom===0){bubbleChartProps.minY=yAxisRangeFrom;}if(yAxisRangeTo||yAxisRangeTo===0){bubbleChartProps.maxY=yAxisRangeTo;}// updating the chartProps with filters to shows and filterable variable
const rangeValue=rangeIndex!==-1?chartFilteredItem[rangeIndex].code:[filterItems.minRange,filterItems.maxRange];bubbleChartProps=utilities.chartHelper.updateChartFilterProps(bubbleChartProps,filterProperty,filterItems,rangeValue,chartFilteredItem);return bubbleChartProps;}}