import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
// import { observer } from 'mobx-react';
// import { trace } from 'mobx';
// import { Log } from 'cv-dialog-sdk';

import Image from '../Image';
import getStyles from './TableTextWithImageCell.styles';

// TODO: THIS NEEDS A LOT OF WORK!
// @observer
class TableTextWithImageCell extends PureComponent {
    static propTypes = {
        textString: PropTypes.string,
        imageUrl: PropTypes.string,
        isPlacementRight: PropTypes.bool,
        isPlacementLeft: PropTypes.bool,
        isPlacementStretchUnder: PropTypes.bool,
        isPlacementUnder: PropTypes.bool,
        isPlacementCenter: PropTypes.bool,
        tipText: PropTypes.string,
        isNumericType: PropTypes.bool,
    }

    static defaultProps = {
        imageUrl: PropTypes.string,
        isPlacementRight: false,
        isPlacementLeft: false,
        isPlacementStretchUnder: false,
        isPlacementUnder: false,
        isNumericType: false,
    }

    render() {
        const {
            textString,
            imageUrl,
            isPlacementRight,
            isPlacementStretchUnder,
            isPlacementUnder,
            isPlacementCenter,
            isPlacementLeft,
            tipText,
            isNumericType } = this.props;

        const placement = {
            isPlacementRight,
            isPlacementStretchUnder,
            isPlacementUnder,
            isPlacementCenter,
            isPlacementLeft,
        };
        const styles = getStyles(isNumericType, placement, imageUrl);

        return (
            <div style={ { width: '100%' } }>
                <div style={ { ...styles.cellRowStyle } }>
                    { imageUrl && !isPlacementRight && !isPlacementStretchUnder && !isPlacementUnder &&
                        <Image
                            contextStyles={ {
                                        container: styles.imageContainerStyles,
                                        image: styles.imageStyles,
                                    } }
                            toolTip={ tipText }
                            imageSrc={ imageUrl } />
                    }
                    { textString &&
                        <div style={ { ...styles.cellColumnStyle } }>
                            <span
                                style={ { ...styles.textStyles } }
                                title={ tipText }>
                                { textString }
                            </span>
                        </div>
                    }
                    { imageUrl && isPlacementRight &&
                        <Image
                            contextStyles={ {
                                        container: styles.imageContainerStyles,
                                        image: styles.imageStyles,
                                    } }
                            toolTip={ tipText }
                            imageSrc={ imageUrl } />
                    }
                </div>
            </div>
        );
    };
};

export default TableTextWithImageCell;
