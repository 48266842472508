import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SortComponent from './SortComponent';
import Button, { BUTTON_VARIANT } from '../base/Button';
import TextLabel from '../base/TextLabel';

// Styles
import getStyles from './styles/Control.styles';

// Lang
import lang from '../../nls/i18n';

export default class SortControl extends Component {
    static defaultProps = {
        // styles: {},
    }

    static propTypes = {
        sortTerms: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string.isRequired,
            isAscending: PropTypes.bool.isRequired,
        })),
        sortableValues: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            label: PropTypes.string,
            isAscending: PropTypes.bool,
        })).isRequired,
        onSortTermsChange: PropTypes.func,
        // styles: PropTypes.object,
    }

    constructor(props) {
        super(props);
        this.handleRemove = this.handleRemove.bind(this);
        this.handleOnSortChange = this.handleOnSortChange.bind(this);
        this.handleRemove = this.handleRemove.bind(this);
        this.handleOnAddColumn = this.handleOnAddColumn.bind(this);
        this.handleOnSortTermChange = this.handleOnSortTermChange.bind(this);
    }

    render() {
        const { sortTerms, sortableValues } = this.props;
        const styles = getStyles();
        return (
            <div
                style={ styles.container }>
                <div
                    key="sort_control__container"
                    style={ styles.scrollContainer }>
                    <TextLabel style={ styles.boldLabels }>
                        { lang.searchSort.sortCriteriaTitle }
                    </TextLabel>
                    <div
                        style={ styles.controlledRowContainer }>
                        <div
                            style={ styles.controledColumnContainer }>
                            { sortTerms && sortTerms.length > 0 &&
                            sortTerms.map((sort, index) => {
                                const { name: propertyName } = sort;
                                const sortControlProps = {
                                    ...sort,
                                    key: `sort_control__${propertyName}_${index}`,
                                    propertyName,
                                    index,
                                    sortableValues,
                                    remainingSortableValues: this.remainingSortableValues,
                                    onSortTermChange: this.handleOnSortTermChange,
                                    onChangeSortDirection: this.handleOnSortChange,
                                    onRemove: this.handleRemove,
                                };
                                return React.createElement(SortComponent, { ...sortControlProps });
                            })
                    }
                        </div>
                    </div>
                </div>
                <Button
                    contextStyles={ styles.buttons }
                    disabled={ this.remainingSortableValues.length < 1 }
                    variant={ BUTTON_VARIANT.CONTAINED }
                    iconName="add"
                    text={ lang.searchSort.sortCriteriaAddColumnButtonText }
                    onClick={ this.handleOnAddColumn } />
            </div>
        );
    }

    get remainingSortableValues() {
        const { sortableValues, sortTerms } = this.props;
        return sortableValues.filter((sortValue) => sortTerms.findIndex((sortTerm) => sortTerm.name === sortValue.name) < 0);
    }

    handleOnSortTermChange(index, propertyName, isAscending) {
        const {
            onSortTermsChange,
            sortableValues,
            sortTerms } = this.props;
        const sortItem = sortTerms[index];

        if (!sortItem || (sortItem && sortItem.name === propertyName)) {
            return; // Do nothing
        }

        let propVal = propertyName;
        if (typeof propertyName === 'object') {
            propVal = propertyName.name;
        }
        const newSortItem = sortableValues.find((sort) => sort.name === propVal);
        sortItem.name = newSortItem.name;
        sortItem.isAscending = isAscending;
        onSortTermsChange(sortTerms);
    }

    handleOnSortChange(index, isAscending) {
        const { onSortTermsChange, sortTerms } = this.props;
        const sortItem = sortTerms[index];
        sortItem.isAscending = !isAscending;
        onSortTermsChange(sortTerms);
    }

    handleRemove(index) {
        const { onSortTermsChange, sortTerms } = this.props;
        sortTerms.splice(index, 1);
        onSortTermsChange(sortTerms);
    }

    handleOnAddColumn() {
        const { onSortTermsChange, sortTerms } = this.props;
        const dummyRecord = this.remainingSortableValues[0];
        sortTerms.push(dummyRecord);
        onSortTermsChange(sortTerms);
    }
}
