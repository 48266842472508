import { rootStore } from 'cv-react-core';

/**
* Helper method to get the styles for Icon component
* @param {Object} contextStyles - Context styles for Icon Component
*/
const getStyles = (annotationStyles = {}) => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    return {
        userSelect: 'none',
        paddingLeft: '6px',
        paddingRight: '6px',
        paddingTop: '0px',
        paddingBottom: '0px',
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-word',
        ...theme.fonts.dataTableRow,
        ...annotationStyles,
    };
};

export default getStyles;
