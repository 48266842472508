import LocalizedStrings from'localized-strings';import{languages}from'./en';/**
 * Options for setting up language provider.
 * @see https://www.npmjs.com/package/localized-strings for more details
 * @see 'Custom getInterfaceLanguage method' for more info
 * @property { function } customLanguageInterface - custom function that returns device language.
 * @property { boolean } logsEnabled - Output issues finding strings and references
 * @see Pseudo Helper for more info
 * @property { boolean } pseudo - Helper for finding string that is implemented.
 * @see Pseudo Helper for more info
 * @property { boolean } pseudoMultipleLanguages - Helper for preparing multiple langauges.
 */export const defaultLanguageOptions={customLanguageInterface:()=>{return'en-US';},logsEnabled:false,pseudo:false,pseudoMultipleLanguages:false};/**
 * This class supports the localization of laguages. Current default language is english.
 */export class LocalizedLanguage{/**
   * Constructor for creating a new instance of the language class.
   * @param { object } languageObjectStrings - Object containing strings for localization
   * @see defaultLanguageOptions for details
   * @param { object } options - options for setting up the localization data.
   */constructor(){let languageObjectStrings=arguments.length>0&&arguments[0]!==undefined?arguments[0]:languages.english;let options=arguments.length>1&&arguments[1]!==undefined?arguments[1]:defaultLanguageOptions;this.localized=new LocalizedStrings(languageObjectStrings,options);}/**
   * Returns localization object that contains strings and information about localized data.
   */get localizedStringValues(){return this.localized;}set languageCode(newLanguageCode){if(newLanguageCode===void 0){newLanguageCode='en-US';}this.localized.setLanguage(newLanguageCode);}get languageCode(){return this.localized.getLanguage();}}// export const localizedLanguage; // = new LocalizedLanguage();