import React from 'react';
import * as PropTypes from 'prop-types';
import { constants } from 'cv-react-core';
import { ThreeOneUnderForm as ThreeOneUnderFormBase } from 'cv-library-react-web';

import getStyles from './ThreeOneUnderForm.styles';

const ThreeOneUnderForm = (props) => {
    const {
        formElements,
        onResizeStop,
        ...rest
    } = props;
    const styles = getStyles();
    const { ui } = constants;

    const handleResizeStop = (e, dir, ref) => {
        const viewId = ref.getAttribute('viewid');
        const {
            style: {
                width,
                height,
            },
        } = ref;
        onResizeStop(viewId, width, height);
    };

    return (
        <ThreeOneUnderFormBase
            contextStyles={ styles }
            formElements={ formElements }
            onResizeStop={ handleResizeStop }
            scrollIdentifier={ ui.SCROLL_CONTAINER }
            { ...rest } />
    );
};

ThreeOneUnderForm.propTypes = {
    formElements: PropTypes.arrayOf(PropTypes.element),
    onResizeStop: PropTypes.func,
};

ThreeOneUnderForm.defaultProps = {
    formElements: {},
};

export default ThreeOneUnderForm;
