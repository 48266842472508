import { rootStore } from 'cv-react-core';

const getStyles = () => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    // Populate the component parts with theme styles
    return {
        spinnerColor: {
            color: theme.statusLoadingIndicatorColor,
        },

        spinnerSize: 50,

        modal: {
            borderColor: 'black',
            borderWidth: 1,
            padding: 4,
            backgroundColor: theme.statusLoadingBackgroundColor,
        },

        overlay: {
        },

        spinnerTextContainer: {
            display: 'flex',
            flexDirection: 'row',
            flexGrow: 1,
            alignItems: 'center',
            justifyContent: 'space-around',
        },

        majorText: {
            flexWrap: 'wrap',
            ...theme.fonts.loadingStatusMajor,
            marginLeft: 8,
        },
        separatorContainer: {
            flexDirection: 'row',
            justifyContent: 'center',
        },
        separator: {
            height: 1,
            flex: 1,
            marginTop: 10,
            backgroundColor: 'grey',
        },
        minorText: {
            ...theme.fonts.loadingStatusMinor,
            marginTop: 8,
        },
    };
};

export default getStyles;
