import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Image from '../Image';

// eslint-disable-next-line react/forbid-foreign-prop-types
const { defaultProps: imageDefaultProps, propTypes } = Image;


export default class LargePropertyImage extends Component {
    static defaultProps = {
        ...imageDefaultProps,
        asyncDataCallback: () => Promise.resolve(),
    };

    static propTypes = {
        ...propTypes,
        largeProperty: PropTypes.oneOfType([
            PropTypes.object, // Large property
            PropTypes.string, // URI
        ]),
        asyncDataCallback: PropTypes.func,
    };

    state = {};

    render() {
        const {
            largeProperty,
            asyncDataCallback,
            ...rest
        } = this.props;
        return (
            <Image
                { ...rest }
                { ...this.getLargeImageProps() } />
        );
    }

    getLargeImageProps() {
        const { largeProperty, asyncDataCallback } = this.props;
        const { largePropertyFromState, urlFromState } = this.state;
        const largeImageProps = {};
        let showLoadingIndicator = false;

        if (largeProperty === null) {
            largeImageProps.imageSrc = null;
        }
        else if (this.callPending) {
            showLoadingIndicator = true;
        }
        else if (largeProperty.isLoaded) {
            largeImageProps.imageSrc = largeProperty.toUrl();
        }
        else if (largeProperty === largePropertyFromState) {
            largeImageProps.imageSrc = urlFromState;
        }
        else {
            this.callPending = true;
            showLoadingIndicator = true;
            asyncDataCallback().then((largePropertyFromCall) => {
                this.callPending = false;
                if (largePropertyFromCall) {
                    this.setState({
                        largePropertyFromState: largeProperty,
                        urlFromState: largePropertyFromCall.toUrl(),
                    });
                }
                else {
                    this.setState({
                        largePropertyFromState: largeProperty,
                        urlFromState: null,
                    });
                }
            });
        }
        largeImageProps.showLoadingIndicator = showLoadingIndicator;
        return largeImageProps;
    }
}
