import { toast, Slide } from 'react-toastify';

toast.configure({
    position: toast.POSITION.TOP_CENTER,
    hideProgressBar: true,
    newestOnTop: false,
    closeOnClick: true,
    rtl: false,
    pauseOnVisibilityChange: true,
    draggable: true,
    pauseOnHover: true,
    transition: Slide,
    });

class Notify {
    // ************** public ***************************************/

    showInfo = (message) => this.toastInfo(message);

    showError = (message) => this.toastError(message);

    showSuccess = (message) => this.toastSuccess(message);

    showWarning = (message) => this.toastWarning(message);

    isToastActive = (toastId) => toast.isActive(toastId);

    dismissToastById = (toastId) => toast.dismiss(toastId);

    dismissAllToasts = () => {
        toast.dismiss();
    }


    // ************** private **************************************/
    toastInfo = (message) => toast.info(message)

    toastError = (message) => toast.error(message)

    toastSuccess = (message) => toast.success(message)

    toastWarning = (message) => toast.warn(message)
}

const notify = new Notify();
export default notify;
