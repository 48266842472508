import React from 'react';
import * as PropTypes from 'prop-types';

import getStyles from './SpacerCell.styles';

const SpacerCell = (props) => {
    const {
        contextStyles,
    } = props;
    const { spacer } = getStyles(contextStyles);

    return (
        <span style={ spacer } />
    );
};

SpacerCell.propTypes = {
    contextStyles: PropTypes.shape({
        spacer: PropTypes.object,
    }),
};

SpacerCell.defaultProps = {
    contextStyles: {},
};

export default SpacerCell;
