import React from 'react';
import SaltComponent from './RWSaltComponent';
import GridList from './RWGridList';

class RWCompactList extends SaltComponent {
    static propTypes = GridList.propTypes;
    static defaultProps = GridList.defaultProps;

    render() {
        return (
            <GridList { ...this.props } />
        );
    }
}

export default RWCompactList;
