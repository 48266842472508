import { rootStore } from 'cv-react-core';

const coreStyles = {
    container: {
        overflow: 'auto',
        flexGrow: 1,
    },

    launcher: {
    },

    launcherTitleContainer: {
        margin: '1rem 0rem 1rem 2.85rem',
    },

    launcherTitle: {
    },

    launcherIcon: {
    },

    launcherList: {
    },

    launcherText: {
    },
};

const getStyles = (contextStyles) => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    return {
        container: {
            ...coreStyles.container,
            backgroundColor: theme.workbenchLauncherBackgroundColor,
            backgroundImage: `url(${theme.pageBackgroundImage})`,
            ...contextStyles.container,
        },

        launcher: {
            ...coreStyles.launcher,
            ...contextStyles.launcher,
        },

        launcherTitleContainer: {
            ...coreStyles.launcherTitleContainer,
            ...contextStyles.launcherTitleContainer,
        },

        launcherTitle: {
            color: theme.workbenchLauncherTitleColor,
            fontSize: `${theme.workbenchLauncherTitleFontSize}px`,
            ...coreStyles.launcherTitle,
            ...contextStyles.launcherTitle,
        },

        launcherIcon: {
            ...coreStyles.launcherIcon,
            ...contextStyles.launcherIcon,
        },

        launcherList: {
            ...coreStyles.launcherList,
            ...contextStyles.launcherList,
        },

        launcherText: {
            ...coreStyles.launcherText,
            ...contextStyles.launcherText,
        },
    };
};

export default getStyles;
