import React, { Fragment } from 'react';
import * as PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { DialogComponentBuilder, rootStore, utilities, engineConstants, constants, searchController } from 'cv-react-core';
import { Log } from 'cv-dialog-sdk';

import RWSaltComponent from './RWSaltComponent';
import DataTable, { DataTableCell, DataTableRow, SORT_DIRECTIONS } from '../components/base/DataTable';
import Menu from '../components/base/Menu';
import MenuItem from '../components/base/MenuItem';
import Image from '../components/base/Image';
import lang from '../nls/i18n';

const {
    uiStore,
} = rootStore;
const {
    annotationHelper,
    listHelper,
} = utilities;
const DOT_REPLACEMENT = '__$__';
const PAGE_SIZE = 50;
const LIST_ROW_CONTEXT_MENU_POSITION = 'LIST_ROW_CONTEXT_MENU_POSITION';
const LIST_COLUMN_ORDER = 'LIST_COLUMN_ORDER';
const LIST_FIXED_COLUMNS = 'LIST_FIXED_COLUMNS';
const LIST_ORIGINAL_COLUMN_ORDER = 'LIST_ORIGINAL_COLUMN_ORDER';

@observer
class RWGridList extends RWSaltComponent {
    static propTypes = {
        asyncDataCallback: PropTypes.func,
        availableMenuItems: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            icon: PropTypes.string,
            menuText: PropTypes.string,
        })),
        columns: PropTypes.array,
        columnWidths: PropTypes.array,
        fixedColumnCount: PropTypes.number,
        hasMoreRecords: PropTypes.bool,
        doubleClickForDefaultAction: PropTypes.bool,

        // onChooseItem implies intent to 'fire an action' or toggle selection, depending on the selection mode
        onChooseItem: PropTypes.func,
        onChooseNone: PropTypes.func,
        onMenuAction: PropTypes.func,
        onRefresh: PropTypes.func,
        onRequestMore: PropTypes.func,
        onSortColumn: PropTypes.func,

        // onSelectItem implies intent to force selection of the item (no action)
        onSelectItem: PropTypes.func,
        orientation: PropTypes.string,
        recordDef: PropTypes.object,
        records: PropTypes.array,

        style: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        viewType: PropTypes.string,
        xStyle: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        xMenu: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
        })),
    };

    static defaultProps = {
        availableMenuItems: [],
        onChooseItem: () => Promise.resolve(),
        onChooseNone: () => Promise.resolve(),
        onMenuAction: () => Promise.resolve(),
        onRefresh: () => Promise.resolve(),
        onRequestMore: () => Promise.resolve(),
        onSelectItem: () => Promise.resolve(),
        style: {},
        xStyle: {},
        xMenu: [],
        doubleClickForDefaultAction: false,
    };

    columnData = [];
    sortData = [];

    constructor(props) {
        super(props);
        const {
            columns,
        } = props;

        // Instantiate Dialog Component Builder
        this.dialogComponentBuilder = new DialogComponentBuilder();
        this.instanceID = `LIST_ID_${Math.random()}`;
        this.setLastSelections();
        const columnNames = columns.map((c) => (c.propertyName.replace('.', DOT_REPLACEMENT)));

        uiStore.setValueForUIObject(this.instanceID, LIST_COLUMN_ORDER, columnNames);
        uiStore.setValueForUIObject(this.instanceID, LIST_ORIGINAL_COLUMN_ORDER, columnNames);
        uiStore.setValueForUIObject(this.instanceID, LIST_FIXED_COLUMNS, []);
        uiStore.setValueForUIObject(this.instanceID, LIST_ROW_CONTEXT_MENU_POSITION, null);
    }

    render() {
        const listRowContextMenuPosition = uiStore.getValueForUIObject(this.instanceID, LIST_ROW_CONTEXT_MENU_POSITION);
        return (
            <Fragment>
                { this.renderDataTable() }
                { listRowContextMenuPosition && this.renderMenu(listRowContextMenuPosition) }
            </Fragment>
        );
    }

    componentDidCatch(error, errorInfo) {
        // Handle uncaught errors
        const ERROR_MESSAGES = [ 'The columnWidths property of the TableColumnResizing plugin is given an invalid value.' ];
        if (ERROR_MESSAGES.includes(error.message)) {
            // These errors are caused by the internal state of Grid
            // Until we find better ways to handle these errors, we swallow the error and let the eventual rerenders fix the crash
            // Refer: Bug 15960: XHA: Application getting crashed on changing list views
            Log.error(error);
            Log.trace(errorInfo);
            /** **************** HACK ALERT HACK ALERT *******************************/
            /** **************** HACK ALERT HACK ALERT *******************************/
            /** **************** HACK ALERT HACK ALERT *******************************/
            /** 2020-09-30 Alan Resha
             * TODO: THIS NEEDS TO BE REMOVED AS SOON AS A FIX CAN BE FOUND
             * It is pretty consist for me that when the selector componet fials, this happens
             * Make me think that one, the selector component needs to be fixed to address the out-of-rage
             * issues that fire constently is resolved and then check to see if this is resolved. Then we
             * need to see why/how we are ever sending content to the grid that wouldn't allow for knowing it's size, this should never happen.
             * We should never swallow these message and not do anything.
             /** Adding a forced refresh so the customer doesn't have to do it. A simple refresh fixes the "Issue"
             */
            const { onRefresh } = this.props;
            onRefresh();
            /** **************** HACK ALERT HACK ALERT *******************************/
            /** **************** HACK ALERT HACK ALERT *******************************/
            /** **************** HACK ALERT HACK ALERT *******************************/
        }
        else {
            // This won't let other errors silently fail
            throw new Error(error);
        }
    }

    renderDataTable = () => {
        const {
            columns,
            columnWidths,
            dialogStore,
            records,
            hasMoreRecords,
            onSortColumn,
            doubleClickForDefaultAction,
        } = this.props;

        const {
            queryInProgress,
            isRefreshTriggeredByTimer,
            refreshInProgress } = dialogStore;
        const isLoading = refreshInProgress && !isRefreshTriggeredByTimer;

        // TODO: This needs to be removed once this table is refactored and state is lifted into core to stop lower level data mutation.
        // The isColumnFiltered, needs to be lifted up so it is not rendering while the search modal is in progress.
        // Sort terms need to be lifted as well.
        // The purpose of this odd routine is for ther purpose of transition.
        // The searchDialogStore is writen on updates and the buffer is cleared which causes screen
        // flickering around sort. This is to stop that flickering.
        const { searchDialogStore } = dialogStore;
        const { isSearchFormOpen } = searchDialogStore || { isSearchFormOpen: false };
        const shouldPauseUpdate = isSearchFormOpen || refreshInProgress;
        // ****************

        // Generate columns prop
        const cols = shouldPauseUpdate ? this.columnData : this.getColumnData(columns);

        // Generate rows prop
        const rows = this.getRowData(records);

        // Generate fixed column prop
        const fixedLeftColumns = uiStore.getValueForUIObject(this.instanceID, LIST_FIXED_COLUMNS);

        // Generate column order
        const columnOrder = uiStore.getValueForUIObject(this.instanceID, LIST_COLUMN_ORDER);

        // Generate selected records prop
        const selectedRecords = utilities.listHelper.getSelectedRecords(uiStore, dialogStore);
        const selectedRowIndexes = this.getSelectedRowIndexes(records, selectedRecords);

        // Get any existing user supplied sorting directives
        // TODO: See todo above about removing this usage of isSearchFormOpen.
        const sortTerms = searchDialogStore && searchDialogStore.sortValues ? searchDialogStore.sortValues : [];
        const sortDirectives = shouldPauseUpdate ? this.sortData : this.sortData = sortTerms.map((t) => {
                return {
                    columnName: t.name.replace('.', DOT_REPLACEMENT),
                    direction: t.isAscending ? SORT_DIRECTIONS.ASC : SORT_DIRECTIONS.DESC,
                    priority: t.priority,
                };
            });

        return (
            <DataTable
                columnWidths={ columnWidths }
                cellComponent={ this.generateCell }
                columnOrder={ columnOrder }
                columns={ cols }
                fixedLeftColumns={ fixedLeftColumns }
                hasMoreRecords={ hasMoreRecords }
                loading={ isLoading }
                multiSelect
                onColumnFrozen={ this.handleColumnFrozen }
                onColumnsReordered={ this.handleColumnsReordered }
                onColumnsResized={ this.handleColumnsResized }
                onLoadMoreData={ this.handleLoadMoreData }
                onRowContextMenu={ this.handleRowContextMenu }
                onRowSelectionChange={ this.handleRowSelectionChange }
                onRowSelectionClear={ this.handleRowSelectionClear }
                onSortColumn={ onSortColumn }
                pageSize={ PAGE_SIZE }
                rowComponent={ this.generateRow }
                rows={ rows }
                selectedRowIndexes={ selectedRowIndexes }
                showSelectionTray={ selectedRowIndexes && selectedRowIndexes.length > 0 }
                sortDirectives={ sortDirectives }
                testID="RWGridList"
                overrideMessages={ { noData: lang.list.noRecords } }
                queryInProgress={ queryInProgress && !isRefreshTriggeredByTimer }
                doubleClickForDefaultAction={ doubleClickForDefaultAction } />
        );
    };

    renderMenu = (anchorPosition) => {
        const {
            availableMenuItems,
            onMenuAction,
            xMenu,
        } = this.props;

        const extendedMenu = xMenu.find((f) => (f.id === engineConstants.action.clientActions.copyToClipboard));
        const extendedMenuItems = [ ...availableMenuItems ];

        if (extendedMenu) {
            extendedMenuItems.push({
                ...extendedMenu,
                menuText: lang.list.copyToClipboard,
            });
        }

        if (anchorPosition && extendedMenuItems.length) {
            return (
                <Menu
                    anchorPosition={ anchorPosition }
                    onClose={ () => {
                        uiStore.setValueForUIObject(this.instanceID, LIST_ROW_CONTEXT_MENU_POSITION, null);
                    } }
                    onSelect={ (event, item) => {
                        if (onMenuAction) {
                            onMenuAction(item);
                        }
                    } }
                    open>
                    { extendedMenuItems.map((item) => (
                        <MenuItem
                            key={ `${item.id}${item.menuText}` }
                            id={ item.id }
                            onClick={ (nativeEvent) => {
                                const modifiers = (nativeEvent && nativeEvent.altKey) ? { [constants.transitionModifiers.OPEN_IN_TAB]: true } : undefined;
                                if (onMenuAction) {
                                    onMenuAction({...item, modifiers});
                                }
                            } }
                            text={ item.menuText } />
                    )) }
                </Menu>
            );
        }

        return null;
    };

    // eslint-disable-next-line no-return-assign
    getColumnData = (columns) => (
        this.columnData = columns.map((c) => {
            const { dialogStore: listDialogStore } = this.props;

            // TODO: Need to move column data higher to stop component data mutation.
            const isColumnFiltered = searchController.isPropertyNameFiltered({listDialogStore, propertyName: c.propertyName });
            // ****************

            return {
                // Handle property names with dots
                name: c.propertyName.replace('.', DOT_REPLACEMENT),
                title: c.heading,
                isFiltered: isColumnFiltered,
            };
        })
    );

    getRowData = (rows) => (
        rows.map((record) => {
            // Get record properties
            const {
                properties,
            } = record;

            // Initialize row property:value container
            const row = {};

            // For each property
            properties.forEach((property) => {
                // Get property name:value set
                const {
                    name,
                    value,
                } = property;

                // Add property to row (handle property names with dots)
                row[name.replace('.', DOT_REPLACEMENT)] = value;
            });

            return row;
        })
    );

    getSelectedRowIndexes = (records, selectedRecordIds) => (
        Object.keys(selectedRecordIds).map((selectedRecordId) => (
            records.findIndex((record) => (record.id === selectedRecordId))
        ))
    );

    handleColumnFrozen = (columnName) => {
        const columnOrder = uiStore.getValueForUIObject(this.instanceID, LIST_COLUMN_ORDER);
        const fixedLeftColumns = uiStore.getValueForUIObject(this.instanceID, LIST_FIXED_COLUMNS);
        const originalColumnOrder = uiStore.getValueForUIObject(this.instanceID, LIST_ORIGINAL_COLUMN_ORDER);

        // Initialize newly fixed left columns array
        let updatedFixedLeftColumns = [];

        // Determine if this column is locked
        const columnLocked = fixedLeftColumns.includes(columnName);

        // Pull out column
        let updatedColumnOrder = columnOrder.filter((c) => (c !== columnName));

        // If column is locked
        if (columnLocked) {
            // Pull column from locked columns list
            updatedFixedLeftColumns = fixedLeftColumns.filter((c) => (c !== columnName));

            // Reorder the columns, injecting frozen column in appropriate location
            updatedColumnOrder = [
                ...updatedFixedLeftColumns,
                ...originalColumnOrder.filter((c) => !updatedFixedLeftColumns.includes(c)),
            ];
        }
        else {
            // Add column to locked columns list
            updatedFixedLeftColumns = fixedLeftColumns.concat(columnName);

            // Reorder the columns, injecting frozen column in appropriate location
            updatedColumnOrder.splice(fixedLeftColumns.length, 0, columnName);
        }

        uiStore.setValueForUIObject(this.instanceID, LIST_COLUMN_ORDER, updatedColumnOrder);
        uiStore.setValueForUIObject(this.instanceID, LIST_FIXED_COLUMNS, updatedFixedLeftColumns);
    };

    handleColumnsReordered = (columnsNamesInOrder) => {
        uiStore.setValueForUIObject(this.instanceID, LIST_COLUMN_ORDER, columnsNamesInOrder);
        // When columns reordered, reset the original colum order to the current column order
        uiStore.setValueForUIObject(this.instanceID, LIST_ORIGINAL_COLUMN_ORDER, columnsNamesInOrder);
    };

    handleColumnsResized = (columnsWithWidths) => { // eslint-disable-line
    };

    handleLoadMoreData = () => { // eslint-disable-line
        const {
            onRequestMore,
        } = this.props;

        if (onRequestMore) {
            onRequestMore();
        }
    };

    handleRowContextMenu = (event, tableRow, selectedRowIndexes) => {
        const {
            onSelectItem,
            records,
        } = this.props;
        const {
            clientX,
            clientY,
        } = event;
        const {
            rowId,
        } = tableRow;
        const record = records[rowId];

        const foundIndex = selectedRowIndexes.find((row) => row === rowId);
        if (!foundIndex) this.handleRowSelectionClear();

        if (onSelectItem && record) {
            onSelectItem(record.id, true, 'DESKTOP');
        }

        uiStore.setValueForUIObject(this.instanceID, LIST_ROW_CONTEXT_MENU_POSITION, {
            left: clientX,
            top: clientY,
        });
    };

    /**
     * Called on row selection
     * @param {Array.<Number>} previousSelectionIndexes
     * @param {Number} newSelectionIndex
     * @param {SyntheticEvent} event
     * @returns {void}
     */
    handleRowSelectionChange = (previousSelectionIndexes, newSelectionIndex, event) => {
        /** Reset this selection state on filters change */
        const {
            lastSelectedId,
            lastMultipleSelection,
        } = this;

        const { doubleClickForDefaultAction } = this.props;

        const selectionMode = event.shiftKey || event.ctrlKey || event.metaKey
            || event.target.type === 'checkbox' || (doubleClickForDefaultAction && event.type === 'click');

        const modifiers = event.altKey ? { [constants.transitionModifiers.OPEN_IN_TAB]: true } : undefined;
        let selectedRowIndexes;

        /** Handle Shift + Click */
        if (event.shiftKey && previousSelectionIndexes.length) {
            let newLastSelectedId = newSelectionIndex;
            selectedRowIndexes = [ ...previousSelectionIndexes ];

            /** Filter the selection rows to not include the last multiple selected rows */
            if (lastMultipleSelection) {
                selectedRowIndexes = previousSelectionIndexes.filter((row) => !lastMultipleSelection.includes(row));
            }

            if (lastSelectedId || lastSelectedId === 0) {
                newLastSelectedId = lastSelectedId;
            }
            else if (selectedRowIndexes.length) {
                newLastSelectedId = selectedRowIndexes[selectedRowIndexes.length - 1];
            }

            const newLastMultipleSelection = [];

            /** Get the new multiple selected rows and from this update the selected rows */
            let start = Math.min(newLastSelectedId, newSelectionIndex);
            const end = Math.max(newLastSelectedId, newSelectionIndex);

            while (start <= end) {
                newLastMultipleSelection.push(start);

                if (!selectedRowIndexes.includes(start)) {
                    selectedRowIndexes.push(start);
                }
                start += 1;
            }

            /** Update the local instance with new selection and multiple selected rows. This will be used on next selection click */
            if (lastSelectedId === null) {
                this.lastSelectedId = newLastSelectedId;
            }
            this.lastMultipleSelection = newLastMultipleSelection;
        }
        else if (event.type === 'dblclick' && doubleClickForDefaultAction) {
            // eslint-disable-next-line no-param-reassign
            previousSelectionIndexes = [];
            selectedRowIndexes = [ newSelectionIndex ];
            /** Update the state with the lastSelectedId. This will be used on next multi selection click */
            this.setLastSelections(newSelectionIndex);
        }
        else {
            /** Handle CTRL + click or Command + click or click on selection checkbox */
            if (event.ctrlKey || event.metaKey || event.target.type === 'checkbox') {
                /** If already selected, we want to unselect it. Filter the selection rows to not include the last selected one */
                selectedRowIndexes = previousSelectionIndexes.includes(newSelectionIndex) ?
                    previousSelectionIndexes.filter((row) => (row !== newSelectionIndex)) :
                    [
                        ...previousSelectionIndexes,
                        newSelectionIndex,
                    ];
            }
            else if (event.type === 'click' && doubleClickForDefaultAction) {
                this.handleRowSelectionClear();
                // eslint-disable-next-line no-param-reassign
                previousSelectionIndexes = [];
                selectedRowIndexes = [ newSelectionIndex ];
            }
            else {
                // eslint-disable-next-line no-param-reassign
                previousSelectionIndexes = [];
                selectedRowIndexes = [ newSelectionIndex ];
            }

            /** Update the state with the lastSelectedId. This will be used on next multi selection click */
            this.setLastSelections(newSelectionIndex);
        }

        this.saveSelectionState(selectedRowIndexes, previousSelectionIndexes, selectionMode, modifiers);
    };

    /**
     * Called to persist the selection state
     * @param {Array.<Number>} newSelectionIndexes
     * @param {Array.<Number>} previousSelectionIndexes
     * @param {Boolean} selectionMode
     * @returns {void}
     */
    saveSelectionState = (newSelectionIndexes, previousSelectionIndexes, selectionMode, modifiers) => {
        const {
            onChooseItem,
            records,
        } = this.props;

        /** New rows that are not already selected */
        const rowsForSelection = newSelectionIndexes.filter((rowIndex) => (!previousSelectionIndexes.includes(rowIndex)));

        /** Existing rows that needs to be unselected */
        const rowsForUnselection = previousSelectionIndexes.filter((rowIndex) => (!newSelectionIndexes.includes(rowIndex)));
        const finalSelection = [
            ...rowsForUnselection,
            ...rowsForSelection,
        ];

        /** Call onChooseItem so that existing selected rows will be unselected and new rows will be selected */
        finalSelection.forEach((selectIndex) => {
            const record = records[selectIndex];

            if (onChooseItem && record) {
                onChooseItem(record.id, selectionMode, 'DESKTOP', modifiers);
            }
        });
    };

    /**
     * Helper method to set last selections
     * @param {Number} lastSelectedId
     * @param {Array.<Number>} lastMultipleSelection
     */
    setLastSelections = (lastSelectedId = null, lastMultipleSelection = null) => {
        this.lastSelectedId = lastSelectedId;
        this.lastMultipleSelection = lastMultipleSelection;
    }

    handleRowSelectionClear = () => {
        const {
            onChooseNone,
        } = this.props;

        if (onChooseNone) {
            onChooseNone();
        }
    };

    // @TODO - make this work with context menu (see RNCompactList)
    // handleLongPress = (rowIndex) => {
    //     const { records, onSelectItem } = this.props;
    //     const objectId = records[rowIndex].id;
    //
    //     // Force selection on the item
    //     onSelectItem(objectId);
    //     this.contextMenu.show();
    // };

    /**
     * @typedef {Object} RowProps
     * @property {React.ReactNodeArray} children - React node(s) used to render the row content.
     * @property {Function} onClick - The row click handler.
     * @property {Function} onDoubleClick - The row Double click handler.
     * @property {Object} style - Styles added to the cell <td> container.
     * @property {TableRow} tableRow - Specifies a DXG table row.
     */
    /**
     * @param {RowProps} rowProps
     * @returns {React.ReactNode}
     */
    generateRow = (rowProps) => { // eslint-disable-line
        return (
            <DataTableRow { ...rowProps } />
        );
    };

    /**
     * @typedef {Object} TableColumn - DXG Table Column Object
     * @property {'left'|'right'|'center'} align - Specifies the table’s column alignment.
     * @property {Column} column - Specifies the associated user column.
     * @property {'left'|'right'} fixed - Specifies the fixed table’s column alignment.
     * @property {String} key - A unique table column identifier.
     * @property {Symbol} type - Specifies the table column type. The specified value defines which cell template is used to render the column.
     * @property {Number} width - Specifies the table column width.
     */
    /**
     * @typedef {Object} TableRow - DXG Table Row Object
     * @property {Number|String} height - Specifies the table row height.
     * @property {String} key - A unique table row identifier.
     * @property {Object} row - Specifies the associated user row.
     * @property {Number|String} rowId - Specifies the associated row’s ID.
     * @property {Symbol} type - Specifies the table row type. The specified value defines which cell template is used to render the row.
     */
    /**
     * @typedef {Object} Column - Specifies the user column.
     * @property {String} name - column reference name
     * @property {String} title - column display text
     */
    /**
     * @typedef {Object} CellProps
     * @property {React.ReactNode|React.ReactNodeArray} children - React node(s) used to render the cell content.
     * @property {String} className - Classes added to the cell <td> container.
     * @property {Number} colSpan - The count of columns that the root cell element spans.
     * @property {Column} column - Specifies the cells associated user column.
     * @property {Object} row - Specifies the cell's row.
     * @property {Number} rowSpan - The count of rows that the root cell element spans.
     * @property {Object} style - Styles added to the cell <td> container.
     * @property {TableColumn} tableColumn - Specifies a DXG table column.
     * @property {TableRow} tableRow - Specifies a DXG table row.
     * @property {*} value - Specifies a value to be rendered within the cell.
     */
    /**
     * @param {CellProps} cellProps
     * @returns {React.ReactNode}
     */
    generateCell = (cellProps) => {
        // Note: Compared with the native sister project, usage of the component builders
        // is currently not done for this list for performance reasons.  The following
        // code is directly styling and formatting the cell and its contents.

        const { // eslint-disable-line
            // Unused available props
            // children,
            // className,
            // colSpan,
            // row,
            // rowSpan,
            // tableColumn,
            // value,
            column,
            style,
            tableRow,
        } = cellProps;
        const {
            rowId,
            selected: isSelected,
        } = tableRow;
        const {
            name,
        } = column;
        const {
            asyncDataCallback,
            columns,
            recordDef,
            records,
            viewType,
        } = this.props;
        const record = records[rowId];
        const propertyName = name.replace(DOT_REPLACEMENT, '.');
        const property = record && record.propAtName(propertyName) || {};
        const propDef = recordDef.propDefAtName(propertyName) || {};
        const viewDef = columns.find((col) => (col.propertyName === propertyName));
        const isImageWithURL = viewDef.displayMediaInline && propDef.isURLType;
        const isTextWithImage = !!((property && property.imageName) || (record && record.imageName)) || isImageWithURL;
        const isLargeImage = propDef.isLargePropertyType;
        const isNumericType = propDef.isNumericType || propDef.isDecimalType;
        const rowStyle = annotationHelper.getBackgroundAsStyle(record);
        const { tipText } = property;
        const textStyles = { };
        const imageContainerStyles = {};
        const imageStyles = {
            objectFit: 'contain',
            verticalAlign: 'middle',
            ...style.cellImage,
        };
        let textString = '';
        let imageUrl = '';
        let baseStyles = {};
        let cellStyle = {
            ...style,
        };

        // Check for row annotation background color
        if (rowStyle.backgroundColor && !isSelected) {
            // Make the cell background color the row annotation background color
            // This will be overridden by any cell annotation background color
            cellStyle.backgroundColor = rowStyle.backgroundColor;
        }

        // Use component builder for large property images
        let LargePropertyImage = null;
        if (isLargeImage && property && asyncDataCallback) {
            LargePropertyImage = this.dialogComponentBuilder
                .setAsyncDataCallback(() => asyncDataCallback(property.name, record.id))
                .setViewType(viewType)
                .setViewDef(viewDef)
                .setProperty(property)
                .setPropDef(propDef)
                .build();
        }

        // Text only
        else {
            textString = listHelper.formatGridData(record, property, propDef, textString);

            // Don't apply the background from the record because the list handles that
            // and applying it to the property interferes with the selection highlighting.
            if (!isSelected) {
                baseStyles = annotationHelper.getBackgroundAsStyle(null, property, baseStyles);
                baseStyles = annotationHelper.getAsStyle(record, property, baseStyles);
                cellStyle = {
                    ...cellStyle,
                    ...baseStyles,
                };
            }

            if (isNumericType) {
                cellStyle.textAlign = 'right';
            }

            // Apply image annotation styles
            if (isTextWithImage) {
                imageUrl = isImageWithURL ? property.value : (property.imageName || record.imageName);

                // Replace text with centered image
                if (property.isPlacementCenter || record.isPlacementCenter || isImageWithURL) {
                    textString = '';
                    cellStyle.textAlign = 'center';
                    imageContainerStyles.display = 'flex';
                    imageContainerStyles.justifyContent = 'center';
                }

                // Set image right of text
                if (property.isPlacementRight || record.isPlacementRight) {
                    imageContainerStyles.display = 'inline-block';
                    imageContainerStyles.verticalAlign = 'middle';
                    textStyles.marginRight = '8px';
                }

                // Set image left of text
                if (property.isPlacementLeft || record.isPlacementLeft) {
                    imageContainerStyles.display = 'inline-block';
                    imageContainerStyles.verticalAlign = 'middle';
                    textStyles.marginLeft = '8px';

                    // Re-right align numeric data for aesthetics
                    if (isNumericType) {
                        cellStyle.justifyContent = 'flex-end';
                    }
                }

                // Stack text over stretched image
                if (property.isPlacementStretchUnder || record.isPlacementStretchUnder) {
                    cellStyle.position = 'relative';
                    textStyles.display = 'block';
                    textStyles.position = 'absolute';
                    textStyles.left = '8px';
                    textStyles.zIndex = 1;
                    imageContainerStyles.display = 'block';
                    imageStyles.width = '100%';
                    imageStyles.maxWidth = '100%';
                    imageStyles.objectFit = 'fill';
                }

                // Set cell background image
                if (property.isPlacementUnder || record.isPlacementUnder) {
                    cellStyle.position = 'relative';
                    textStyles.display = 'block';
                    textStyles.position = 'absolute';
                    textStyles.left = '8px';
                    textStyles.zIndex = 1;
                    imageStyles.width = '100%';
                    imageStyles.maxWidth = '100%';
                }
            }
        }

        return (
            <DataTableCell
                { ...cellProps }
                style={ cellStyle }
                value={ textString }>
                { isTextWithImage && imageUrl && !property.isPlacementRight && !property.isPlacementStretchUnder && ! property.isPlacementUnder &&
                    <Image
                        contextStyles={ {
                                    container: imageContainerStyles,
                                    image: imageStyles,
                                } }
                        toolTip={ tipText }
                        imageSrc={ imageUrl } />
                }
                { textString &&
                    <span
                        style={ textStyles }
                        title={ tipText }>
                        { textString }
                    </span>
                }
                { isTextWithImage && imageUrl && (property.isPlacementRight || property.isPlacementStretchUnder || property.isPlacementUnder) &&
                    <Image
                        contextStyles={ {
                                    container: imageContainerStyles,
                                    image: imageStyles,
                                } }
                        toolTip={ tipText }
                        imageSrc={ imageUrl } />
                }
                { LargePropertyImage }
            </DataTableCell>
        );
    };
}

export default RWGridList;
