import React from 'react';
import * as PropTypes from 'prop-types';
import { constants } from 'cv-react-core';
import { MultipleForm as MultipleFormBase } from 'cv-library-react-web';

import getStyles from './MultipleForm.styles';

const MultipleForm = (props) => {
    const {
        formElements,
        onResizeStop,
        ...rest
    } = props;
    const styles = getStyles();
    const { ui } = constants;

    const handleResizeStop = (e, dir, ref) => {
        const viewId = ref.getAttribute('viewid');
        const {
            style: {
                width,
                height,
            },
        } = ref;
        onResizeStop(viewId, width, height);
    };

    return (
        <MultipleFormBase
            contextStyles={ styles }
            formElements={ formElements }
            onResizeStop={ handleResizeStop }
            scrollIdentifier={ ui.SCROLL_CONTAINER }
            { ...rest } />
    );
};

MultipleForm.propTypes = {
    formElements: PropTypes.arrayOf(PropTypes.element),
    onResizeStop: PropTypes.func,
};

MultipleForm.defaultProps = {
    formElements: [],
};

export default MultipleForm;
