import { rootStore } from 'cv-react-core';

const getTheme = () => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();
    const {
        workbenchLauncherIconBackgroundColor,
        workbenchLauncherTextBackgroundColor,
        workbenchLauncherTileBackgroundColor,
        workbenchLauncherTileBorderColor,
        workbenchLauncherTileBorderRadius,
    } = theme;

    // Populate the component parts with theme styles
    return {
        container: {
        },

        launcher: {
            backgroundColor: workbenchLauncherTileBackgroundColor,
            borderRadius: workbenchLauncherTileBorderRadius,
            borderColor: workbenchLauncherTileBorderColor,
        },

        launcherIcon: {
        },

        launcherIconContainer: {
            backgroundColor: workbenchLauncherIconBackgroundColor,
            borderTopLeftRadius: workbenchLauncherTileBorderRadius,
            borderBottomLeftRadius: workbenchLauncherTileBorderRadius,
        },

        launcherIconLoadingIndicator: {
            color: theme.statusLoadingIndicatorColor,
        },

        launcherText: {
            ...theme.fonts.workbenchLauncher,
        },

        launcherTextContainer: {
            backgroundColor: workbenchLauncherTextBackgroundColor,
            borderTopRightRadius: workbenchLauncherTileBorderRadius,
            borderBottomRightRadius: workbenchLauncherTileBorderRadius,
        },
    };
};

export default getTheme;
