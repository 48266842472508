import React from 'react';
import { BaseComponentBuilder } from 'cv-react-core';
import TextLabelComponentBuilder from './TextLabelComponentBuilder';

import TextLabelWithImage from '../../components/base/TextLabelWithImage';

/**
 * A builder for creating text with image components
 */
class TextWithImageComponentBuilder extends TextLabelComponentBuilder {
    /**
     * COMPONENT BASED PROPERTIES
     */

    /** Begin Context Styles */
    setImageStyles(imageStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            image: imageStyles,
        });
    }
    getImageStyles() {
        const { image } = this.getContextStyles();
        return image || {};
    }

    setLoadingIndicatorStyles(loadingIndicatorStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            loadingIndicator: loadingIndicatorStyles,
        });
    }
    getLoadingIndicatorStyles() {
        const { loadingIndicator } = this.getContextStyles();
        return loadingIndicator || {};
    }
    /** End Context Styles */


    /** Begin Props */
    setDisabled(disabled = true) {
        this.props.disabled = disabled;
        return this;
    }
    getDisabled() {
        return this.props.disabled;
    }

    setImageHeight(imageHeight) {
        this.props.imageHeight = imageHeight;
        return this;
    }
    getImageHeight() {
        return this.props.imageHeight;
    }

    setImageSource(imageSrc) {
        this.props.imageSrc = imageSrc;
        return this;
    }
    getImageSource() {
        return this.props.imageSrc;
    }

    setImageStretch(imageStretch = true) {
        this.props.imageStretch = imageStretch;
        return this;
    }
    getImageStretch() {
        return this.props.imageStretch;
    }

    setImageWidth(imageWidth) {
        this.props.imageWidth = imageWidth;
        return this;
    }
    getImageWidth() {
        return this.props.imageWidth;
    }

    setReverse(reverse = true) {
        this.props.reverse = reverse;
        return this;
    }
    getReverse() {
        return this.props.reverse;
    }

    setShowLoadingIndicator(showLoadingIndicator = true) {
        this.props.showLoadingIndicator = showLoadingIndicator;
        return this;
    }
    getShowLoadingIndicator() {
        return this.props.showLoadingIndicator;
    }

    setStacked(stacked = true) {
        this.props.stacked = stacked;
        return this;
    }
    getStacked() {
        return this.props.stacked;
    }


    /**
     * PROCESS AND UPDATE COMPONENT PROPERTIES
     */

    processImage() {
        const property = this.getProperty();
        const propDef = this.getPropDef();
        const record = this.getRecord();
        const isCompactView = this.getCompactState();
        const imageUrl = property.imageName || record.imageName;
        const textStyles = this.getTextStyles();
        const containerStyle = {
            ...this.getContainerStyles(),
        };

        // Apply image annotation styles
        if (imageUrl) {
            // Set image on left of text by default
            this.setReverse(true);

            if (property.isPlacementCenter || record.isPlacementCenter) {
                this.setText('');
            }

            if (property.isPlacementRight || record.isPlacementRight) {
                this.setReverse(false);
            }

            if (property.isPlacementLeft || record.isPlacementLeft) {
                // Re-right align numeric data for aesthetics
                if (!isCompactView && this.getViewType() === BaseComponentBuilder.typeNames.ListTypeName && (propDef.isNumericType || propDef.isDecimalType)) {
                    containerStyle.display = 'flex';
                    containerStyle.justifyContent = 'flex-start';
                }
            }

            if (property.isPlacementStretchUnder || record.isPlacementStretchUnder) {
                this.setImageStretch(true);
                this.setReverse(false);
                this.setStacked(true);

                // containerStyle.flex = 1;
            }

            if (property.isPlacementUnder || record.isPlacementUnder) {
                this.setStacked(true);
                this.setReverse(false);
            }

            this.setImageSource(imageUrl);
        }

        this.setContainerStyles(containerStyle);
        this.setTextStyles(textStyles);
    }

    buildProps() {
        // Update the display text
        this.processDisplay();

        // Update the display text style
        this.processStyle();

        // Override the display text style with annotations
        this.processAnnotations();

        // Set text properties based on dialog data
        this.processTextProps();

        // Set image properties based on dialog data
        this.processImage();
    }


    /**
     * BUILD AND RETURN COMPONENT AS REACT ELEMENT
     */

    build() {
        // Process and construct final props
        this.buildProps();

        // Get children prop for text
        const { children, ...restProps } = this.getProps();

        // Build and return component as React element
        return (
            <TextLabelWithImage
                text={ children }
                { ...restProps } />
        );
    }
}

export default TextWithImageComponentBuilder;
