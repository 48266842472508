import { rootStore } from 'cv-react-core';

const getTheme = () => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    // Populate the component parts with theme styles
    return {
        container: {
        },

        check: {
            color: theme.checkBoxUnCheckedBackgroundColor,
        },

        checked: {
            color: theme.checkBoxCheckedBackgroundColor,
        },

        disabled: {
        },
    };
};

export default getTheme;
