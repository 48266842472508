// import { rootStore } from 'cv-react-core';

const getTheme = () => {
    // const { themeStore } = rootStore;
    // const theme = themeStore.getSanitizedTheme();

    // Populate the component parts with theme styles
    return {
        // overlay: {
        // },
        //
        // modal: {
        //     backgroundColor: theme.modalBackgroundColor,
        //     shadowColor: theme.modalShadowColor,
        // },
    };
};

export default getTheme;
