import { rootStore } from 'cv-react-core';

// Core component styles
const coreStyles = {
    container: {
        width: 2,
        height: 20,
        backgroundColor: '#000',
        marginRight: 2,
        marginLeft: 14,
    },
};

/**
 * Helper method to get the styles for the component
 * @param {Object} contextStyles - Context styles for the Component
 */
const getStyles = (contextStyles = {}) => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    return {
        container: {
            ...coreStyles.container,
            backgroundColor: theme.actionSeparatorColor,
            ...contextStyles.container,
        },
    };
};

export default getStyles;
