import { rootStore } from 'cv-react-core';

const getTheme = () => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    // Populate the component parts with theme styles
    return {
        actions: {
            backgroundColor: theme.modalBackgroundColor,
            shadowColor: theme.modalShadowColor,
        },
        actionButton: {
            backgroundColor: theme.buttonPrimaryBackgroundColor,
            borderColor: theme.buttonPrimaryBorderColor,
        },
        actionButtonText: {
            ...theme.fonts.buttonPrimary,
        },
        cancelButton: {
            backgroundColor: theme.buttonSecondaryBackgroundColor,
            borderColor: theme.buttonSecondaryBorderColor,
        },
        cancelButtonText: {
            ...theme.fonts.buttonSecondary,
        },
        content: {
            backgroundColor: theme.modalBackgroundColor,
            shadowColor: theme.modalShadowColor,
        },
        divider: {
        },
        header: {
            backgroundColor: theme.modalBackgroundColor,
            shadowColor: theme.modalShadowColor,
        },
        menu: {
        },
        menuItem: {
            ...theme.fonts.default,
        },
        menuItemFocused: {
        },
        modal: {
            backgroundColor: theme.modalBackgroundColor,
            shadowColor: theme.modalShadowColor,
        },
        overlay: {
        },
        signatureStyle: {
        },
        title: {
            ...theme.fonts.fontSizeMidLarge,
        },
        undoButton: {
            backgroundColor: theme.buttonSecondaryBackgroundColor,
            borderColor: theme.buttonSecondaryBorderColor,
        },
        undoButtonText: {
            ...theme.fonts.buttonSecondary,
        },
    };
};

export default getTheme;
