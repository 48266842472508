import { rootStore } from 'cv-react-core';

const getStyles = (contextStyles = {}, styleParams) => {
    const {
        firstRow,
        lastRow,
        lastSection,
        spacerLines,
        suppressHeading,
    } = styleParams;
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    // Adjusting the cell container styles based on cell type and count
    const containerStyles = {};
    if (suppressHeading && firstRow) {
        containerStyles.borderTopWidth = 1;
        containerStyles.borderTopStyle = 'solid';
    }
    if ((spacerLines || lastSection) && lastRow) {
        containerStyles.borderBottomWidth = 1;
        containerStyles.borderBottomStyle = 'solid';
    }

    // Populate the component parts with theme styles
    return {
        container: {
            display: 'flex',
            flexGrow: 1,
            height: '100%',
            borderRightWidth: 1,
            borderColor: theme.dataTableGridBorderColor,
            borderRightStyle: 'solid',
            boxSizing: 'border-box',
            ...containerStyles,
            ...contextStyles.container,
        },
        cellText: {
            width: '100%',
            height: '100%',
            minHeight: '100%',
            maxHeight: '100%',
            whiteSpace: 'pre',
            wordBreak: 'break-word',
            ...theme.fonts.dataTableRow,
            ...contextStyles.cellText,
        },
        propertyContainer: {
            display: 'flex',
            paddingLeft: '6px',
            paddingRight: '6px',
            flexGrow: 1,
            alignItems: 'center',
            verticalAlign: 'middle',
            ...contextStyles.propertyContainer,
        },
    };
};

export default getStyles;
