export const supportedBrowsers = {
    chrome: {
        name: 'Google Chrome',
        agent: 'Chrome/',
        minimumVersion: 49,
    },
    safari: {
        name: 'Apple Safari',
        agent: 'Safari/',
        minimumVersion: 10,
    },
    firefox: {
        name: 'Mozilla Firefox',
        agent: 'Firefox/',
        minimumVersion: 52,
    },
    edge: {
        name: 'Microsoft Edge',
        agent: 'Edg/',
        minimumVersion: 14,
        operatingSystem: 'Macintosh',
    },
    edgeWindows: {
        name: 'Microsoft Edge',
        agent: 'Edg/',
        minimumVersion: 85,
        operatingSystem: 'Windows NT',
    },
    // Observed that old Egde browsers are having the User Agent as Edge/XXX where as New Edge browser( Chromium based) as Edg/XXX,
    // which is causing the belwo first condition check for chrome broswer is getting validated true.
    // So added this new depricatedEdge conditon as a check to validate old Edge browser too.
    // Old Edge User Agent ::: Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 Edge/18.18362
    // New Edge User Agent ::: Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/87.0.4280.88 Safari/537.36 Edg/87.0.664.57
    depricatedEdge: {
        agent: 'Edge/',
    },
};

/**
 * Gets browser information and support
 * @returns {{name: String, version: String, supported: Boolean}}
 */
const getBrowserSupport = () => {
    // https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
    const { userAgent } = window.navigator;
    const agentParts = userAgent.split(' ');
    let supportedBrowser = false;
    let browserName = 'Unsupported';
    let browserVersion = 'Unsupported';

    // Chrome
    if (
        userAgent.includes(supportedBrowsers.chrome.agent) &&
        !userAgent.includes(supportedBrowsers.edge.agent) &&
        !userAgent.includes(supportedBrowsers.depricatedEdge.agent) &&
        !userAgent.includes('Chromium/') &&
        !userAgent.includes('OPR/') &&
        !userAgent.includes('Opera/')
    ) {
        browserName = supportedBrowsers.chrome.name;
        browserVersion = agentParts
            .find((part) => (part.includes(supportedBrowsers.chrome.agent))) // Find part
            .split('/')[1] // Split name and version returning only version
            .split('.')[0]; // Split version returning only main number

        // Verify minimum browser version
        // eslint-disable-next-line radix
        if (parseInt(browserVersion) >= supportedBrowsers.chrome.minimumVersion) { supportedBrowser = true; }
    }

    // Safari
    else if (
        userAgent.includes(supportedBrowsers.safari.agent) &&
        !userAgent.includes(supportedBrowsers.chrome.agent)
    ) {
        browserName = supportedBrowsers.safari.name;
        browserVersion = agentParts
            .find((part) => (part.includes('Version/'))) // Find part
            .split('/')[1] // Split name and version returning only version
            .split('.')[0]; // Split version returning only main number

        // Verify minimum browser version
        // eslint-disable-next-line radix
        if (parseInt(browserVersion) >= supportedBrowsers.safari.minimumVersion) { supportedBrowser = true; }
    }

    // Firefox
    else if (
        userAgent.includes(supportedBrowsers.firefox.agent) &&
        !userAgent.includes('Seamonkey/')
    ) {
        browserName = supportedBrowsers.firefox.name;
        browserVersion = agentParts
            .find((part) => (part.includes(supportedBrowsers.firefox.agent))) // Find part
            .split('/')[1] // Split name and version returning only version
            .split('.')[0]; // Split version returning only main number

        // Verify minimum browser version
        // eslint-disable-next-line radix
        if (parseInt(browserVersion) >= supportedBrowsers.firefox.minimumVersion) { supportedBrowser = true; }
    }

    // Edge
    else if (userAgent.includes(supportedBrowsers.edge.operatingSystem) && userAgent.includes(supportedBrowsers.edge.agent)) {
        browserName = supportedBrowsers.edge.name;
        browserVersion = agentParts
            .find((part) => (part.includes(supportedBrowsers.edge.agent))) // Find part
            .split('/')[1] // Split name and version returning only version
            .split('.')[0]; // Split version returning only main number

        // Verify minimum browser version
        // eslint-disable-next-line radix
        if (parseInt(browserVersion) >= supportedBrowsers.edge.minimumVersion) { supportedBrowser = true; }
    }
    else if (userAgent.includes(supportedBrowsers.edgeWindows.operatingSystem) && userAgent.includes(supportedBrowsers.edge.agent)) {
        browserName = supportedBrowsers.edge.name;
        browserVersion = agentParts
            .find((part) => (part.includes(supportedBrowsers.edge.agent))) // Find part
            .split('/')[1] // Split name and version returning only version
            .split('.')[0]; // Split version returning only main number

        // Verify minimum browser version
        // eslint-disable-next-line radix
        if (parseInt(browserVersion) >= supportedBrowsers.edgeWindows.minimumVersion) { supportedBrowser = true; }
    }

    return {
        name: browserName,
        version: browserVersion,
        supported: supportedBrowser,
    };
};

export default getBrowserSupport;
