import { BaseComponentBuilder } from 'cv-react-core';
import ActionIconComponentBuilder from './ActionIconComponentBuilder';
import Image from '../../components/base/Image';

class ImageActionComponentBuilder extends ActionIconComponentBuilder {
    constructor() {
        super();
        this.setActionIconComponent(Image);
    }

    setImageSize(size) {
        return this.setContextStyles({
            ...this.getContextStyles(),
            image: {
                width: size,
                height: size,
            },
        });
    }

    setImageUrl(imageSrc) {
        this.props.imageSrc = imageSrc;
    }

    processAction() {
        // Get action data
        const actionData = this.getActionData();

        // Get action icon URL
        const { iconUrl } = actionData;

        // If we have an action icon URL
        if (iconUrl) {
            // Set the action icon URL
            this.setImageUrl(iconUrl);

            // If not already specified in context styles, set a size for the image.
            const { image } = this.getContextStyles();
            const { width, height } = image || {};
            if (width === undefined && height === undefined) {
                this.setImageSize(ImageActionComponentBuilder.deriveImageSize(this.getStyle()));
            }
        }

        // Finish action processing after image url populated
        super.processAction();
    }

    static deriveImageSize(styles = {}) {
        const categorizedStyles = BaseComponentBuilder.styleHelper.categorizeWebStyles(styles);
        if (categorizedStyles.text.fontSize <= 10) {
            return 15;
        }
        if (categorizedStyles.text.fontSize <= 20) {
            return 20;
        }
        return 30;
    }
}

export default ImageActionComponentBuilder;
