import React from 'react';
import * as PropTypes from 'prop-types';

import getStyles from './BasePage.styles';

/**
 * TODO: add description
 */
const BasePage = (props) => {
    const {
        children,
        className,
        contextStyles,
    } = props;

    const styles = getStyles(contextStyles);

    // Generate container props
    const containerProps = {
        className: `l-base-page__container${className && ` ${className}`}`,
        style: { ...styles.container },
    };

    // const favIcon = document.getElementById('fav-icon');
    // const { page } = styles;
    // const { icon, name } = page;
    // if (favIcon && icon) {
    //     favIcon.href = icon;
    // }

    // const domAppName = document.getElementById('app-name');
    // if (domAppName && name) {
    //     domAppName.innerHTML = name;
    // }

    return (
        <div { ...containerProps }>
            { children }
        </div>
    );
};

BasePage.defaultProps = {
    contextStyles: {},
};

BasePage.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    contextStyles: PropTypes.shape({
        container: PropTypes.object,
    }),
};

export default BasePage;
