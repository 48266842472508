import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'cv-library-react-web';

import BaseImage from '../base/Image';
import Button, { BUTTON_VARIANT } from '../base/Button';
import IconButton from '../base/IconButton';
import Icon from '../base/Icon';
import LargePropertyImage from '../base/LargePropertyImage';
import PageActivityIndicator from '../base/PageActivityIndicator';
import Prompt from '../base/Prompt';
import TextLabel from '../base/TextLabel';
import getStyles from './styles/CvImagePicker.styles';
import lang from '../../nls/i18n';

/**
 * This component provides Image Carousel functionality.
 */
class ImagePicker extends Component {
    static propTypes = {
        /** Method to load large binary data */
        asyncDataCallback: PropTypes.func,

        /** Styles for this component */
        contextStyles: PropTypes.shape({
            /** Styles for the carousel container */
            carouselContainer: PropTypes.object,

            /** Styles for the container */
            container: PropTypes.object,

            /** Styles for the image displayed as part of open image action */
            displayImage: PropTypes.object,

            /** Styles for the main image description label */
            imageDescription: PropTypes.object,

            /** Styles for the main image description container */
            imageDescriptionContainer: PropTypes.object,

            /** Styles for the left navigation icon */
            leftNavIcon: PropTypes.object,

            /** Styles for the left navigation icon container */
            leftNavIconContainer: PropTypes.object,

            /** Styles for the main image */
            mainImage: PropTypes.object,

            /** Styles for the placeholder icon */
            placeholderIcon: PropTypes.object,

            /** Styles for the right navigation icon */
            rightNavIcon: PropTypes.object,

            /** Styles for the right navigation icon container */
            rightNavIconContainer: PropTypes.object,

            /** Styles for the thumbnail image */
            thumbnailImage: PropTypes.object,
        }),

        /** Items to be displayed in carousel */
        items: PropTypes.array,

        /** Icon  name for left navigation icon */
        leftNavigationIconName: PropTypes.string,

        /**
         * Called on item selection i.e., either clicking on thumbnails or clicking on navigation buttons
         * @param {Number} currentIndex
         */
        onChooseItem: PropTypes.func,

        /**
         * Called on click of main image
         */
        onClickItem: PropTypes.func,

        /**
         * Called on close of display image
         */
        onCloseItem: PropTypes.func,

        /** Defines whether we need to open image. This deals with #openImage action */
        openImage: PropTypes.bool,

        /** Icon  name for placeholder image icon */
        placeholderImageIconName: PropTypes.string,

        /** Defines whether list refresh in progress */
        refreshInProgress: PropTypes.bool,

        /** Record id that is currently selected. This is used for #openImage action */
        selectedRecord: PropTypes.string,

        /** Icon  name for right navigation icon */
        rightNavigationIconName: PropTypes.string,
    };

    static defaultProps = {
        contextStyles: {},
        items: [],
        leftNavigationIconName: 'navigate_before',
        placeholderImageIconName: 'image',
        rightNavigationIconName: 'navigate_next',
    };

    render() {
        const {
            contextStyles,
            onChooseItem,
            onClickItem,
            openImage,
            refreshInProgress,
        } = this.props;
        this.styles = getStyles(contextStyles);

        return (
            <div style={ this.styles.container }>
                { refreshInProgress ?
                    <PageActivityIndicator isOpen /> :
                    <Carousel
                        contextStyles={ {
                            container: this.styles.carouselContainer,
                        } }
                        // eslint-disable-next-line no-return-assign
                        imageRef={ (r) => this.imageRef = r }
                        items={ this.getItems() }
                        onClickItem={ onClickItem }
                        onSlide={ onChooseItem }
                        renderLeftNav={ this.renderLeftNav }
                        renderRightNav={ this.renderRightNav } />
                }
                { openImage && this.renderDisplayPrompt() }
            </div>
        );
    }

    componentDidUpdate() {
        const {
            items,
            onChooseItem,
            refreshInProgress,
            selectedRecord,
        } = this.props;

        if (items.length) {
            const itemIndex = items.findIndex((item) => item.id === selectedRecord);
            const selectedRowIndex = itemIndex > -1 ? itemIndex : 0;

            if (itemIndex === -1) {
                // When rendered for the first time, update the default selected item so that it will be available for menu actions
                onChooseItem(selectedRowIndex);
            }

            // This is helpful to scroll back to the last selected item when records refresh.
            if (!refreshInProgress) {
                this.imageRef.slideToIndex(selectedRowIndex);
            }
        }
    }

    /**
     * Helper method to render custom left navigation
     */
    renderLeftNav = (onClick, disabled) => {
        const { leftNavigationIconName } = this.props;
        return (
            <IconButton
                contextStyles={ {
                    container: this.styles.leftNavIconContainer,
                    icon: this.styles.leftNavIcon,
                } }
                disabled={ disabled }
                iconName={ leftNavigationIconName }
                onClick={ onClick } />
        );
    }

    /**
     * Helper method to render custom right navigation
     */
    renderRightNav = (onClick, disabled) => {
        const { rightNavigationIconName } = this.props;
        return (
            <IconButton
                contextStyles={ {
                    container: this.styles.rightNavIconContainer,
                    icon: this.styles.rightNavIcon,
                } }
                disabled={ disabled }
                iconName={ rightNavigationIconName }
                onClick={ onClick } />
        );
    }

    /**
     * Helper method to render custom image component.
     * A base image card component can be created for this so that it looks much better.
     * @param {Object} item
     */
    renderItem = (item) => {
        const { label } = item;
        const imageStyles = {
            image: this.styles.mainImage,
        };
        return (
            <div>
                { this.renderImage(item, imageStyles) }
                {
                label && (
                    <TextLabel
                        contextStyles={ {
                            container: this.styles.imageDescriptionContainer,
                            text: this.styles.imageDescription,
                        } }>
                        { label }
                    </TextLabel>
                )
            }
            </div>
        );
    }

    /**
     * Helper method to render custom thumbnail image
     * @param {Object} item
     */
    renderThumbInner = (item) => {
        const imageStyles = {
            image: this.styles.thumbnailImage,
        };
        return (
            <div>
                { this.renderImage(item, imageStyles) }
            </div>
        );
    }

    /**
     * Helper method to display openImage action
     */
    renderDisplayPrompt = () => {
        const {
            items,
            onCloseItem,
            selectedRecord,
        } = this.props;
        const imageStyles = {
            image: this.styles.displayImage,
        };
        const record = items.find((item) => item.id === selectedRecord);

        return (
            <Prompt
                actions={ [
                    <Button
                        key="cancel"
                        onClick={ onCloseItem }
                        text={ lang.generic.close }
                        variant={ BUTTON_VARIANT.OUTLINED } />,
                ] }
                title={ lang.generic.displayImageTitle }>
                { this.renderImage(record, imageStyles) }
            </Prompt>
        );
    }

    /**
     * Helper method to return image or icon element
     * @param {Object} item
     * @param {Object} contextStyles
     */
    renderImage = (item, contextStyles = {}) => {
        const {
            asyncDataCallback,
            placeholderImageIconName,
        } = this.props;
        const {
            id,
            image,
            largeImage,
            largeImagePropertyName,
        } = item;
        let Image = null;

        const imageProps = {
            contextStyles,
            key: id,
        };

        if (largeImage) {
            Image = (
                <LargePropertyImage
                    { ...imageProps }
                    asyncDataCallback={ () => asyncDataCallback(largeImagePropertyName, id) }
                    key={ id }
                    largeProperty={ largeImage } />
            );
        }
        else if (image) {
            Image = (
                <BaseImage
                    { ...imageProps }
                    imageSrc={ image } />
            );
        }
        else {
            // If no image, show placeholder icon
            Image = (
                <Icon
                    contextStyles={ {
                        icon: this.styles.placeholderIcon,
                    } }
                    iconName={ placeholderImageIconName }
                    key={ id } />
            );
        }

        return Image;
    }

    /**
     * Helper method to build and return items for ImageGallery
     */
    getItems = () => {
        const { items } = this.props;
        return items.map((item) => {
            return {
                ...item,
                original: item.id,
                renderItem: this.renderItem,
                renderThumbInner: this.renderThumbInner,
            };
        });
    }
}

export default ImagePicker;
