import React, { useState } from 'react';
import * as PropTypes from 'prop-types';

import TabsList from '../../base/Tabs';

const TabPanel = (props) => {
    /* eslint-disable react/prop-types */
    const {
        children,
        value,
        id,
    } = props;
    const panelProps = {
        style: {
            display: 'flex',
            height: (value === id) ? '100%' : '0px',
            overflow: 'hidden',
            flexGrow: (value === id) ? 1 : undefined,
        },
    };
    return (
        <div { ...panelProps }>
            { children }
        </div>
    );
};

const TabForm = (props) => {
    const {
        activeIndex,
        onChangeTab,
        labeledFormElements: tabs,
    } = props;

    // Log.debug('TAB FORM: RENDER: ', props); // eslint-disable-line

    const [
        currentTab,
        setCurrentTab,
    ] = useState(activeIndex);

    // Generate tabs list props
    const tabsListProps = {
        initialTab: currentTab,
        onChange: (event, tabIndex) => {
            // Log.debug('TAB FORM: TAB CHANGE: ', event, tabIndex); // eslint-disable-line
            setCurrentTab(tabIndex);
            if (onChangeTab) {
                onChangeTab({ i: tabIndex });
            }
        },
        tabs: tabs.map((tab, idx) => {
            return {
                id: idx,
                label: tab.title,
            };
        }),
    };

    return (
        <div
            className="l-tab-form__container"
            style={ {
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                flexShrink: 1,
                overflow: 'hidden',
            } }>
            <TabsList { ...tabsListProps } />
            { tabs.map((tab, idx) => {
                const {
                    childElement,
                    title,
                } = tab;
                const {
                    props: childProps,
                    type: TabComponent,
                } = childElement;
                return (
                    <TabPanel
                        value={ currentTab }
                        id={ idx }
                        key={ title }>
                        <TabComponent { ...childProps } />
                    </TabPanel>
                );
            }) }
        </div>
    );
};

TabForm.propTypes = {
    activeIndex: PropTypes.number,
    labeledFormElements: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        childElement: PropTypes.element,
    })),
    onChangeTab: PropTypes.func,
};

TabForm.defaultProps = {
    activeIndex: 0,
    labeledFormElements: [],
};

export default TabForm;
