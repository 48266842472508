const coreStyles = {
    container: {
    },

    header: {
    },

    headerNavigation: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '1rem',
    },

    headerImage: {
        height: 40,
        width: '100%',
    },

    content: {
    },

    footer: {
    },
};

const getStyles = (contextStyles = {}) => {
    return {
        content: {
            ...coreStyles.content,
            ...contextStyles.content,
        },

        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },

        footer: {
            ...coreStyles.footer,
            ...contextStyles.footer,
        },

        header: {
            ...coreStyles.header,
            ...contextStyles.header,
        },

        headerImage: {
            ...coreStyles.headerImage,
            ...contextStyles.headerImage,
        },

        headerNavigation: {
            ...coreStyles.headerNavigation,
            ...contextStyles.headerNavigation,
        },
    };
};

export default getStyles;
