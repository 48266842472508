import { rootStore } from 'cv-react-core';

const getStyles = (contextStyles = {}) => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    // Populate the component parts with theme styles
    return {
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.dataTableHeadingBackgroundColor,
            ...contextStyles.container,
        },

        indicator: {
            color: theme.statusLoadingIndicatorColor,
            ...contextStyles.indicator,
        },
    };
};

export default getStyles;
