import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';

import getStyles from './GridCell.styles';

class GridCell extends PureComponent {
    static propTypes = {
        /** Column properties */
        column: PropTypes.object,

        /** Extended styles for this component */
        contextStyles: PropTypes.shape({
            /** Styles for the cell text */
            cellText: PropTypes.object,

            /** Styles for the cell container */
            container: PropTypes.object,

            /** Styles for the property container */
            propertyContainer: PropTypes.object,
        }),

        /** property specifying last section grid */
        lastSection: PropTypes.bool,

        /** Row  node */
        node: PropTypes.object,

        /** Called to render property */
        onRenderItem: PropTypes.func,

        /** Array of records */
        records: PropTypes.array,

        /** Row index of the record */
        rowIndex: PropTypes.number,

        /** property specifying grid has spacerLines */
        spacerLines: PropTypes.number,

        /** property specifying grid has supress heading */
        suppressHeading: PropTypes.bool,
    };

    static defaultProps = {
        contextStyles: {},
    };

    render() {
        const {
            column: { colDef },
            contextStyles,
            lastSection,
            node: { selected },
            onRenderItem,
            records,
            rowIndex,
            spacerLines,
            suppressHeading,
        } = this.props;
        const styleParams = {
            firstRow: rowIndex === 0,
            lastRow: rowIndex === records.length - 1,
            lastSection,
            spacerLines,
            suppressHeading,
        };
        const {
            cellText,
            container,
            propertyContainer,
        } = getStyles(contextStyles, styleParams);
        const record = records[rowIndex];
        const propertyName = colDef.field;
        const { tipText } = record && record.propAtName(propertyName) || {};
        const propertyProps = {
            allowAnnotations: !selected,
            name: propertyName,
            xStyle: {
                container: propertyContainer,
                input: cellText,
                text: cellText,
            },
        };
        return (
            <span
                key={ `${rowIndex}_${propertyName}` }
                style={ container }
                title={ tipText }>
                { onRenderItem(record, propertyProps) }
            </span>
        );
    }
}

export default GridCell;
