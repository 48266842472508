import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import Menu from '../base/Menu';

export const propTypes = {
    /** Styling options for the Menu control. Follow Menu style guides for styling options. */
    contextStyles: PropTypes.object,
    /** Postition where you want the context menu. */
    anchorPosition: PropTypes.shape({
        top: PropTypes.number,
        left: PropTypes.number,
    }),
    /** Opens the context menu. Show handles this by default. */
    open: PropTypes.bool,
    /** Function called when a menu item is selected. */
    onMenuAction: PropTypes.func,
    /** Function to handle the close operations. Returns Synthetic event and reason */
    onClose: PropTypes.func,
    /** Menu Items that needs to be displayed inside the Menu */
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};

export default class CvContextMenu extends PureComponent {
    static propTypes = propTypes;

    static defaultProps = {
        open: false,
        anchorPosition: { top: 0, left: 0 },
        onClose: () => {},
        onMenuAction: () => {},
    };

    constructor(props) {
        super(props);
        const { anchorPosition, open } = props;
        this.show = this.show.bind(this);
        this.handleOnClose = this.handleOnClose.bind(this);
        this.handleOnMenuAction = this.handleOnMenuAction.bind(this);

        this.state = {
            open,
            anchorPosition,
        };
    }

    render() {
        const { children, ...restProps } = this.props;
        const { open, anchorPosition } = this.state;

        const menuProps = {
            ...restProps,
            onMenuAction: this.handleOnMenuAction,
            onClose: this.handleOnClose,
            open,
            anchorPosition,
        };

        return (
            <Menu { ...menuProps }>
                { children }
            </Menu>
        );
    }

    /**
     * This function will show the context menu. Pass an object containing
     * { top: 0, left: 0 } position of where you want the menu to appear. The
     * menu will default to { top: 0, left: 0 }.
     * @param {object} anchorPosition object containing top left postion
     */
    show(anchorPosition = { top: 0, left: 0 }) { /* eslint-disable no-restricted-globals */
        // Stop the default behavior to display the browser context menu.
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }

        this.setState({
            open: true,
            anchorPosition,
        });
    }

    /**
     * Place holder until we propogate the event into core. This reduces duplicate handling of returned params.
     * @param {object} event Synthetic event object
     * @param {menuItem} item Selected menu item
     */
    handleOnMenuAction(event, item) {
        const { onMenuAction } = this.props;
        onMenuAction(item);
    }

    /** Function handler to close the context menu. */
    handleOnClose(event, reason) {
        this.setState({
            open: false,
        });
        const { onClose } = this.props;
        onClose(event, reason);
    }
}
