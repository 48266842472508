import { rootStore } from 'cv-react-core';

const coreStyles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
    },
};

const getStyles = (contextStyles) => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    return {
        container: {
            ...coreStyles.container,
            backgroundColor: theme.colors.panel,
            ...contextStyles.container,
        },
    };
};

export default getStyles;
