import { rootStore } from 'cv-react-core';

const coreStyles = {
    container: {
        flex: 1,
        backgroundColor: 'transparent',
        overflow: 'auto',
    },
};

const getStyles = (contextStyles) => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    return {
        container: {
            ...coreStyles.container,
            backgroundColor: theme.pageBackgroundColor,
            ...contextStyles.container,
        },
        settingsFormComponent: {
            textField: {
                marginTop: '.25em',
                padding: '.1em',
            },
        },
    };
};

export default getStyles;
