import React from 'react';
import * as PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';

import TextLabel from '../TextLabel';
import IconButton from '../IconButton';
import getStyles from './GridSelectionTray.styles';

const GridSelectionTray = (props) => {
    const {
        clearTitle,
        contextStyles,
        error,
        onClear,
        text,
    } = props;
    const {
        closeButton,
        closeButtonIcon,
        container,
        errorContainer,
        text: textStyles,
    } = getStyles(contextStyles);

    return (
        <Paper style={ error ? { ...container, ...errorContainer } : container }>
            <TextLabel contextStyles={ {
                text: textStyles,
            } }>
                { text }
            </TextLabel>
            <IconButton
                contextStyles={ {
                    button: closeButton,
                    icon: closeButtonIcon,
                } }
                onClick={ onClear }
                iconSize={ 12 }
                iconName="clear"
                title={ clearTitle } />
        </Paper>
    );
};

GridSelectionTray.propTypes = {
    /** Extended styles for this component */
    contextStyles: PropTypes.shape({
        /** Styles for the row selection tray close button */
        closeButton: PropTypes.object,

        /** Styles for the row selection tray close button icon */
        closeButtonIcon: PropTypes.object,

        /** Styles for the row selection tray */
        container: PropTypes.object,

        /** Styles for the container ehen error */
        errorContainer: PropTypes.object,

        /** Styles for the row selection tray text */
        text: PropTypes.object,
    }),

    /** Title for icon button */
    clearTitle: PropTypes.string,

    /** Indicates whether we are showing error or selection tray */
    error: PropTypes.bool,

    /** Called on click of clear selections button */
    onClear: PropTypes.func,

    /** Text to be shown on the tray */
    text: PropTypes.string,
};

GridSelectionTray.defaultProps = {
    onClear: () => {},
};

export default GridSelectionTray;
