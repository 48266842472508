/*       */import AbstractGml from'./AbstractGml';import Base from'./Base';import GmlUtil from'../GmlUtil';import{DETAIL_A}from'../gmlConstants';import{ACTION,GENERAL,GENERAL_FLEX,VIEW_DETAIL}from'../exportClassConstants';import Context from'../Context';import StyleSet from'../StyleSet';export default class Detail extends AbstractGml{asSalt(parentContext){const myContext=this.updateContext(parentContext);const children=[];this.addBackgroundImage(myContext,children);const hasParent=!!parentContext.parentContext;this.buildHeaderBodyFooterChildren(myContext,parentContext,hasParent).forEach(e=>children.push(e));// This is the View Salt component.  It's children is the [ header, body, footer ] children array.
const viewStyleSet=new StyleSet();this.exportStyleTo(viewStyleSet,myContext,[GENERAL,VIEW_DETAIL]);// Regardless of what was requested, this view must be column based to provide a header body and footer.
viewStyleSet.flexPolicy.setFlexColumn();viewStyleSet.style.overflow='hidden';// this.setMainFlex(myContext, viewStyleSet);
this.setMainFlexAlt(viewStyleSet);viewStyleSet.flexPolicy.setAlignItemsStretchSafe();// If there is no parent or this is V1, grow big.
if(!hasParent||parentContext.version.isV1()){viewStyleSet.flexPolicy.setStandardGrow();viewStyleSet.flexPolicy.setStandardShrink();viewStyleSet.flexPolicy.setAlignSelfStretch();}return{view:{id:parentContext.document.viewId,...viewStyleSet.asStyleAttribute(),...viewStyleSet.asXStyleAttribute(),children}};}buildHeaderBodyFooterChildren(myContext,parentContext,hasParent){const{version,document}=parentContext;// Collect the headers and footers.  Only applicable for V1.
const headerChildren=Base.children(myContext).filter(f=>Base.isHeader(f.json,version)).map(c=>c.asSalt(myContext));const footerChildren=Base.children(myContext).filter(f=>Base.isFooter(f.json,version)).map(c=>c.asSalt(myContext));// Record whether headers/footers are being used
document.setIsDetailWithHeaderOrFooter(!!(headerChildren.length||footerChildren.length));// Collect the regular children.  Exclude header and footers, although do not exclude them
// if this is post V1 as headers and footers are ignored after V1.
const regChildren=Base.children(myContext).filter(f=>{return!Base.isHeader(f.json,version)&&!Base.isFooter(f.json,version);}).map(c=>c.asSalt(myContext));// Add the edit/save/cancel buttons as appropriate
footerChildren.unshift({when:{assert:{expr:'$dialog.view.editable'},children:[{when:{assert:{expr:'$dialog.isWriteMode and $dialog.view.editable'},children:[this.newSaveCancelButtonBox([this.newCancelButton(myContext),this.newSaveButton(myContext)])],'else-children':[this.newEditButtonBox([this.newEditButton()])]}}]}});const children=[];// **** Header Content ****
if(headerChildren.length){const styleSet=new StyleSet({gmlId:'gmlHeader'});styleSet.flexPolicy.setFlexColumn();styleSet.flexPolicy.setAlignSelfStretch();children.push({box:{...styleSet.asStyleAttribute(),...GmlUtil.asRowChildren(myContext,styleSet,headerChildren)}});}// **** Main Content ****
const regStyleSet=new StyleSet({gmlId:'gmlBody'});this.exportStyleTo(regStyleSet,myContext,[GENERAL_FLEX]);regStyleSet.style.overflow='hidden';const holdAlignItems=regStyleSet.flexPolicy.alignItems;// this.setMainFlex(myContext, regStyleSet);
this.setMainFlexAlt(regStyleSet);if(!holdAlignItems){this.setCrossFlex(myContext,regStyleSet);}if(!hasParent||version.isV1()||document.isDetailWithHeaderOrFooter){regStyleSet.flexPolicy.setStandardGrow();regStyleSet.flexPolicy.setStandardShrink();}if(!holdAlignItems&&(!hasParent||version.isV1())){regStyleSet.flexPolicy.setAlignItemsStretch();}const scroll=this.getScrollWithVersion(myContext);const canScroll=scroll&&Object.keys(scroll).length;const myContextToUpdate=myContext;if(canScroll){if(scroll.scroll==='vertical'){myContextToUpdate.columnBound=false;}else if(scroll.scroll==='horizontal'){myContextToUpdate.rowBound=false;}regStyleSet.prepForScroll(myContext,scroll);}const preppedChildren=GmlUtil.asRowChildren(myContext,regStyleSet,Base.flattenChildren(regChildren)).children;// const preppedChildren = Base.flattenChildren(regChildren);
children.push({box:{...regStyleSet.asStyleAttribute(),...regStyleSet.asXStyleAttribute(),...regStyleSet.asContentContainerStyle(),...scroll,children:preppedChildren}});// **** Footer Content ****
if(footerChildren.length){const styleSet=new StyleSet({gmlId:'gmlFooter'});styleSet.flexPolicy.setFlexColumn();styleSet.flexPolicy.setAlignSelfStretch();children.push({box:{...styleSet.asStyleAttribute(),...GmlUtil.asRowChildren(myContext,styleSet,footerChildren)}});}return children;}getScrollWithVersion(myContext){const scroll={};const{version,document}=myContext;if(version.isV1()||document.isDetailWithHeaderOrFooter){// V1 always scrolls vertically.
scroll.scroll='vertical';}else{// After V1 scroll must be explicitly requested as the designer can add scroll
// sections however they choose.
const scrollDirectionA=this.getScrollAttribute(myContext);if(scrollDirectionA){scroll.scroll=scrollDirectionA.value;}}return scroll;}// eslint-disable-next-line no-unused-vars
// findFillers = (parentContext:Context): FillerSearch => {
//     // On a detail expand must be explicit.  Is on V1.
//     return new FillerSearch();
// }
isMarkupEdit(){const result=GmlUtil.getValueForExpr(this.json,[DETAIL_A.markupEdit]);return result?result==='true':false;}isMarkupSaveCancel(){const result=GmlUtil.getValueForExpr(this.json,[DETAIL_A.markupSaveCancel]);return result?result==='true':false;}newEditButtonBox(children){const expr="".concat(this.isMarkupEdit().toString()," = false");return{box:{assert:{expr},style:{'flex-direction':'row'},children}};}newSaveCancelButtonBox(children){const expr="".concat(this.isMarkupSaveCancel().toString()," = false");return{box:{assert:{expr},style:{'flex-direction':'row'},children}};}newEditButton(){return{action:{id:'#edit',type:'button',style:{width:'100%'},'x-style':{button:{width:'100%'}}}};}newCancelButton(myContext){const styleSet=new StyleSet();this.exportStyleTo(styleSet,myContext,[ACTION]);return{action:{id:'#cancel',type:'button',tooltip:"Esc",hotkey:{eventType:"keydown",eventCodes:[{key:"keyCode",value:27}]},'x-style':{buttonType:'secondary',button:{...GmlUtil.resolveMargin(styleSet.xStyle.layout)}}}};}newSaveButton(myContext){const styleSet=new StyleSet();this.exportStyleTo(styleSet,myContext,[ACTION]);return{action:{id:'#save',type:'button',tooltip:"Ctrl + Enter",hotkey:{eventType:"keydown",eventCodes:[{key:"ctrlKey",value:true},{key:"keyCode",value:13}]},'x-style':{button:{...GmlUtil.resolveMargin(styleSet.xStyle.layout)}}}};}// setMainFlex(myContext:Context, styleSet:StyleSet) {
//     const { flexPolicy } = styleSet;
//     const { flexDirection } = flexPolicy;
//     if (GmlUtil.doesMainAxisFlex(myContext, flexDirection, this.getScrollAttribute(myContext))) {
//         flexPolicy.setStandardGrow();
//         flexPolicy.setStandardShrink();
//     }
// }
setMainFlexAlt(styleSet){const{flexPolicy}=styleSet;flexPolicy.setStandardGrow();flexPolicy.setStandardShrink();}setCrossFlex(myContext,styleSet){const{flexPolicy}=styleSet;const{flexDirection}=flexPolicy;if(GmlUtil.doesCrossAxisFlexOfAnyChild(myContext,flexDirection)){flexPolicy.setAlignItemsStretch();}else{flexPolicy.setAlignItemsStart();// flexPolicy.setAlignSelfStart();
}}}