import React from 'react';
import * as PropTypes from 'prop-types';
import { PowerBI } from 'cv-library-react-web';

const RWPowerBI = (props) => {
    const {
        accessToken,
        embedUrl,
        id,
    } = props;

    return (
        <PowerBI
            accessToken={ accessToken }
            embedUrl={ embedUrl }
            id={ id } />
    );
};

RWPowerBI.propTypes = {
    /** Access token for thr report */
    accessToken: PropTypes.string,

    /** Embed URL of the report */
    embedUrl: PropTypes.string,

    /** Report Id */
    id: PropTypes.string,
};

export default RWPowerBI;
