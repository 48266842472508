import React, { forwardRef } from 'react';
import * as PropTypes from 'prop-types';

import getStyles from './AppHeader.styles';

/**
 * The application header
 */
const AppHeader = forwardRef((props, ref) => {
    const {
        children,
        contextStyles,
        testID,
    } = props;

    const styles = getStyles(contextStyles);

    // Generate container props
    const containerProps = {
        style: styles.container,
    };
    if (testID) { containerProps['test-id'] = testID; }

    return (
        <div
            ref={ ref }
            { ...containerProps }>
            { children }
        </div>
    );
});

AppHeader.propTypes = {
    /** Child content */
    children: PropTypes.node,

    /** Styles for this component */
    contextStyles: PropTypes.shape({
        /** Styling for the main container */
        container: PropTypes.object,
    }),

    /** Id used for testing */
    testID: PropTypes.string,
};

AppHeader.defaultProps = {
    contextStyles: {},
};

AppHeader.displayName = 'AppHeader';

export default AppHeader;
