import randomBytes from 'randombytes';

/* eslint-disable class-methods-use-this */
class Crypto {
    /**
     * @param length
     * @returns {Promise<any>}
     */
    getSecureRandom(length) {
        return new Promise((resolve, reject) => {
            randomBytes(length, (err, bytes) => {
                if (err) {
                    reject(err);
                }
                else {
                    resolve(bytes);
                }
            });
        });
    }
}

const crypto = new Crypto();
export default crypto;
