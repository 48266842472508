// Core component styles
const coreStyles = {
    buttonContainer: {
        display: 'flex',
        flexGrow: 1,
        flexDirection: 'row',
        padding: '8px',
    },

    editorContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },

    errorMessage: {
        paddingLeft: '8px',
    },
};

/**
 * Helper method to get styles for the XMLEditor
 * @param {Object} contextStyles - Custom styles for the XMLEditor component
 */
const getStyles = (contextStyles = {}) => {
    return {
        buttonContainer: {
            ...coreStyles.buttonContainer,
            ...contextStyles.buttonContainer,
        },

        editorContainer: {
            ...coreStyles.editorContainer,
            ...contextStyles.editorContainer,
        },

        errorMessage: {
            ...coreStyles.errorMessage,
            ...contextStyles.errorMessage,
        },
    };
};

export default getStyles;
