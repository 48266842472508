import { rootStore } from 'cv-react-core';

const getTheme = () => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();
    const {
        color,
        fontSize,
    } = theme.fonts.default;

    // Populate the component parts with theme styles
    return {
        container: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        inputContainer: {},
        inputText: {
            borderWidth: theme.htmlDateTimeBorderWidth,
            borderStyle: theme.htmlDateTimeBorderStyle,
            borderColor: theme.htmlDateTimeBorderColor,
            borderRadius: theme.htmlDateTimeBorderRadius,
            backgroundColor: theme.detailAttributeBackgroundColor,
            paddingLeft: '8px',
            ...theme.fonts.default,
        },
        inputUnderline: {
            borderColor: theme.textInputUnderlineColor,
        },
        inputUnderlineFocus: {
            borderColor: theme.textInputUnderlineColor,
        },
        inputUnderlineHover: {
            borderColor: theme.textInputUnderlineColor,
        },
        icon: {
            color,
            fontSize,
        },
        iconContainer: {},
        dialogContainer: {},
        dialogContentContainer: {},

        pickerToolbar: {},
        pickerToolbarButton: {},
        pickerToolbarButtonLabels: {},
        pickerDateTimeToolbarSeparator: {},

        pickerTabs: {},
        pickerTabButtons: {},
        pickerTabIndicator: {},

        pickerView: {},
        pickerCalendarSwitchHeader: {},
        pickerCalendarButton: {},
        pickerCalendarButtonIcon: {},
        pickerCalendarHeaderLabel: {},
        pickerCalendarDaysHeader: {},
        pickerCalendarDayLabel: {},
        pickerCalendarContainer: {},
        pickerCalendarWeek: {},

        pickerDay: {},
        pickerDayCurrent: {},
        pickerDaySelected: {},
        pickerDayDisabled: {},
        pickerDayHidden: {},

        pickerYearContainer: {},
        pickerYear: {},
        pickerYearSelected: {},
        pickerYearDisabled: {},

        pickerClockContainer: {},
        pickerClock: {},
        pickerClockPin: {},
        pickerClockNumber: {},
        pickerClockNumberSelected: {},
        pickerClockPointer: {},
        pickerClockPointerThumb: {},
        pickerClockPointerNoPoint: {},

        dialogActionsContainer: {},
        dialogActionsButtons: {},
        dialogActionsLabels: {},
    };
};

export default getTheme;
