import { rootStore } from 'cv-react-core';

const getStyles = (contextStyles = {}) => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    // Populate the component parts with theme styles
    return {
        container: {
            display: 'flex',
            flexGrow: 1,
            height: '100%',
            borderRightWidth: 1,
            borderRightColor: theme.dataTableGridBorderColor,
            borderRightStyle: 'solid',
            borderBottomWidth: '1px',
            borderBottomStyle: 'inset',
            boxSizing: 'border-box',
            overflow: 'hidden',
            ...contextStyles.container,
        },
        cellText: {
            width: '100%',
            height: '100%',
            minHeight: '100%',
            maxHeight: '100%',
            whiteSpace: 'pre',
            backgroundColor: 'transparent',
            borderWidth: 0,
            ...theme.fonts.dataTableRow,
            ...contextStyles.cellText,
        },
        propertyContainer: {
            width: '100%',
            height: '100%',
            ...contextStyles.propertyContainer,
        },
    };
};

export default getStyles;
