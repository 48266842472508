// import { rootStore } from 'cv-react-core';

/**
* Helper method to get the styles for Icon component
* @param {Object} contextStyles - Context styles for Icon Component
*/
const getStyles = (isNumericType, placement, imageUrl) => {
    // const { themeStore } = rootStore;
    // const theme = themeStore.getSanitizedTheme();
    const {
        isPlacementCenter,
        isPlacementLeft,
        isPlacementStretchUnder,
        isPlacementUnder } = placement;

        const cellRowStyle = {
            display: 'flex',
            flexDirection: 'row',
            flexGrow: 1,
            alignItems: 'center',
        };
        const cellColumnStyle = {
            display: 'flex',
            flexDirection: 'column',
            flexShrink: 1,
        };
        const imageContainerStyles = {
            ...cellColumnStyle,
        };
        const textStyles = {
            // ...theme.fonts.dataTableRow,
        };
        const imageStyles = {
            objectFit: 'contain',
            verticalAlign: 'middle',
            maxWidth: '40px',
            maxHeight: '40px',
        };

        if (isPlacementCenter) {
            imageContainerStyles.display = 'flex';
            imageContainerStyles.justifyContent = 'center';
            cellRowStyle.justifyContent = 'center';
        }

        // Set image left of text
        if (isPlacementLeft) {
            cellColumnStyle.marginLeft = '8px';

            // Re-right align numeric data for aesthetics
            if (isNumericType) {
                cellRowStyle.justifyContent = 'flex-end';
            }
        }

        // Stack text over stretched image
        if (isPlacementStretchUnder) {
            cellColumnStyle.flexShrink = 'unset';
            cellColumnStyle.flexGrow = 1;
            cellColumnStyle.backgroundImage = `url(${imageUrl})`;
            cellColumnStyle.backgroundSize = 'cover';
            cellColumnStyle.backgroundRepeat = 'no-repeat';
            cellColumnStyle.height = '40px';
            cellColumnStyle.backgroundPosition = 'center';
        }

        // Set cell background image
        if (isPlacementUnder) {
            cellColumnStyle.backgroundImage = `url(${imageUrl})`;
            cellColumnStyle.flexGrow = 1;
            cellColumnStyle.backgroundSize = 'contain';
            cellColumnStyle.backgroundRepeat = 'no-repeat';
            cellColumnStyle.height = '40px';
            cellColumnStyle.backgroundPosition = 'center';
        }

    return {
        cellColumnStyle,
        cellRowStyle,
        textStyles,
        imageContainerStyles,
        imageStyles,
    };
};

export default getStyles;
