import { rootStore } from 'cv-react-core';

const getStyles = (contextStyles = {}) => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();
    return {
        avgRow: {
            backgroundColor: '#FFC2BF',
            ...contextStyles.avgRow,
        },

        buttonContainer: {
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'flex-end',
            flexDirection: 'row',
            padding: '10px',
            ...contextStyles.buttonContainer,
        },

        buttonsContainer: {
            paddingLeft: '5px',
            ...contextStyles.buttonsContainer,
        },

        cell: {
            padding: '6px',
            ...contextStyles.cell,
        },

        container: {
            margin: '0px',
            overflow: 'auto',
            ...contextStyles.container,
        },

        countRow: {
            backgroundColor: '#CFD0D1',
            ...contextStyles.countRow,
        },

        maxRow: {
            backgroundColor: '#F8FAAC',
            ...contextStyles.maxRow,
        },

        minRow: {
            backgroundColor: '#B2FCC7',
            ...contextStyles.minRow,
        },

        statisticsContainer: {
            display: 'flex',
            flexDirection: 'row',
            flexShrink: 1,
            flexGrow: 1,
            overflow: 'auto',
            ...contextStyles.statisticsContainer,
        },

        sumRow: {
            backgroundColor: '#CAD1FD',
            ...contextStyles.sumRow,
        },

        tableBody: {
            borderCollapse: 'collapse',
            ...contextStyles.tableBody,
        },

        tableColumn: {
            ...theme.fonts.dataTableRow,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: theme.dataTableGridBorderColor,
            ...contextStyles.tableColumn,
        },

        tableContainer: {
            padding: '0 10px',
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'row',
            marginBottom: '15px',
            ...contextStyles.tableContainer,
        },

        tableHeaderRow: {
            backgroundColor: theme.dataTableHeadingBackgroundColor,
            ...theme.fonts.dataTableHeading,
            ...contextStyles.tableHeaderRow,
        },

        tableRow: {
            ...contextStyles.tableRow,
        },

        titleButton: {
            display: 'flex',
            flexDirection: 'column',
            ...contextStyles.titleButton,
        },

        titleComponentContainer: {
            display: 'flex',
            flexDirection: 'row',
            flexGrow: 1,
            padding: '5px',
            ...contextStyles.titleComponentContainer,
        },
        titleComponentText: {
            ...theme.fonts.panelTitle,
            ...contextStyles.titleComponentText,
        },

        titleContainer: {
            display: 'flex',
            flexDirection: 'row',
            flexGrow: 1,
            ...contextStyles.titleContainer,
        },

        titleTextColumn: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            ...contextStyles.titleTextColumn,
        },
    };
};

export default getStyles;
