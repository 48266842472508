import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { ActivityIndicator } from 'cv-library-react-web';

import getStyles from './GridActivityIndicator.styles';

@observer
class GridActivityIndicator extends PureComponent {
    render() {
        const {
            contextStyles,
            isLoading,
        } = this.props;
        const {
            container,
            indicator,
        } = getStyles(contextStyles);

        if (isLoading()) {
            return (
                <div style={ container }>
                    <ActivityIndicator contextStyles={ { indicator } } />
                </div>
            );
        }
        return null;
    }
};

GridActivityIndicator.propTypes = {
    contextStyles: PropTypes.shape({
        container: PropTypes.object,
        indicator: PropTypes.object,
    }),
    isLoading: PropTypes.func,
};

GridActivityIndicator.defaultProps = {
    contextStyles: {},
};

export default GridActivityIndicator;
