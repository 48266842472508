import BasePropsFactory from'./BasePropsFactory';import{utilities}from'../../utilities';import serviceFactory from'../../services/serviceFactory';/**
 * A builder for creating scatter chart components
 */export default class ScatterChartPropsFactory extends BasePropsFactory{static create(view,records,propDefs,chartFilteredItem){const{dataPoints,displayQuadrantLines,filterDataPoints,identityDataPoint,xAxisLabel,xAxisRangeFrom,xAxisRangeTo,yAxisLabel,yAxisRangeFrom,yAxisRangeTo}=view;// Collect property names to identify axis tick label values
const labelPropertyNames=identityDataPoint.map(_ref=>{let{propertyName}=_ref;return propertyName;});// Collect the property for Filtering and also created default FiterItems object
const filterProperty=filterDataPoints&&filterDataPoints.map(_ref2=>{let{propertyName,legendKey}=_ref2;return{propertyName,legendKey};});let filterItems={filterArray:[],type:'',minDate:'',maxDate:'',minRange:'',maxRange:''};const{endDateIndex,fromDateIndex,rangeIndex}=utilities.chartHelper.retrievedMatchedIndices(chartFilteredItem);// Get number of records
const recordCount=records.length;// Calculate symbol size based on record count
let symbolSize=30;if(recordCount>50){symbolSize*=0.8;}if(recordCount>200){symbolSize*=0.6;}let errorText;const{lang}=serviceFactory;// Loop through dataPoints
const seriesData=dataPoints.map(dataPoint=>{const{// Get the legend key for the series legend item
legendKey,// Get the property name for finding the record property value
propertyName,// Get the color for the data set
seriesColor,// Get the respective data point property
xAxisName}=dataPoint;// Generate chart series data
const scatterSeries={legendText:legendKey,symbolColor:BasePropsFactory.formatColor(seriesColor),symbolSize};if(!xAxisName){errorText=lang.errors.scatterBubbleError;scatterSeries.dataPoints=[];return scatterSeries;}// Then loop through the records
scatterSeries.dataPoints=records.map(record=>{// Then for each record
const{// Collect record annotations
annotations:recordAnnotations,// Get record properties to search for data point property value
properties}=record;// Search the record properties array for the Y prop
const yProp=properties.find(property=>{const{name}=property;// Get the property with the data point property name
return name===propertyName;})||{};// Search the record properties array for the X prop
const xProp=properties.find(property=>{const{name}=property;// Get the property with the data point property name
return name===xAxisName;})||{};// Logic to filter the records and return filtered Items and filter property
let filterProp;const{isUndefined,filteredItems,filterPropItem}=utilities.chartHelper.getFilteredItems(filterProp,filterProperty,properties,propDefs,filterItems,chartFilteredItem,fromDateIndex,endDateIndex,rangeIndex);filterItems=filteredItems;filterProp=filterPropItem;if(isUndefined){return undefined;}// Search the record properties array again for the desired label properties
const labels=[];labelPropertyNames.forEach(labelPropertyName=>{const labelProp=properties.find(property=>{const{name}=property;// Get the property with the data point label property name
return name===labelPropertyName;});if(labelProp&&labelProp.value){labels.push(labelProp.value);}});const{// Collect Y property annotations
annotations:yPropertyAnnotations,// Collect Y property value
value:yValue}=yProp;const{// Collect X property annotations
annotations:xPropertyAnnotations,// Collect X property value
value:xValue}=xProp;// Process annotations
const recAnnotations=BasePropsFactory.processAnnotations(recordAnnotations,'symbolColor');const yPropAnnotations=BasePropsFactory.processAnnotations(yPropertyAnnotations,'symbolColor');const xPropAnnotations=BasePropsFactory.processAnnotations(xPropertyAnnotations,'symbolColor');// Construct a point data object
return{filter:filterProp?filterProp.value:'',id:record.id,name:labels.join(','),value:[xValue,yValue],...recAnnotations,...yPropAnnotations,...xPropAnnotations};});scatterSeries.dataPoints=scatterSeries.dataPoints.filter(Boolean);return scatterSeries;});let scatterProps={seriesData:[...seriesData],xAxisTitle:xAxisLabel||'',yAxisTitle:yAxisLabel||'',gridLines:displayQuadrantLines,legend:true,errorText,filterable:false,filterLabel:filterProperty&&filterProperty[0]&&filterProperty[0].legendKey,filterArray:[],renderButtonText:dataPoint=>{const{name,value}=dataPoint;return"Open details for ".concat(name,": ").concat(value[1]);}};if(xAxisRangeFrom||xAxisRangeFrom===0){scatterProps.minX=xAxisRangeFrom;}if(xAxisRangeTo||xAxisRangeTo===0){scatterProps.maxX=xAxisRangeTo;}if(yAxisRangeFrom||yAxisRangeFrom===0){scatterProps.minY=yAxisRangeFrom;}if(yAxisRangeTo||yAxisRangeTo===0){scatterProps.maxY=yAxisRangeTo;}// updating the chartProps with filters to shows and filterable variable
const rangeValue=rangeIndex!==-1?chartFilteredItem[rangeIndex].code:[filterItems.minRange,filterItems.maxRange];scatterProps=utilities.chartHelper.updateChartFilterProps(scatterProps,filterProperty,filterItems,rangeValue,chartFilteredItem);return scatterProps;}}