import ActionIconComponentBuilder from './ActionIconComponentBuilder';
import BarCodeActionIcon from '../../components/base/BarCodeActionIcon';

class BarCodeActionComponentBuilder extends ActionIconComponentBuilder {
    constructor() {
        super();
        this.setActionIconComponent(BarCodeActionIcon);
    }

    processAction() {
        // Populate custom action data
        this.processActionData('BARCODE_SCAN');

        // Finish action processing after custom action data populated
        super.processAction();
    }
}

export default BarCodeActionComponentBuilder;
