import { TypeNames } from 'cv-dialog-sdk';
import { constants, engineConstants, rootStore } from 'cv-react-core';
import * as PropTypes from 'prop-types';
import React from 'react';

import ActionButtonImage from '../components/base/ActionButtonImage/ActionButtonImage';
import ActionDropDown from '../components/base/ActionDropDown/ActionDropDown';
import BasePanel from '../components/base/Panel/Panel';
import TapActionToolSalt from '../components/base/TapActionTool/TapActionToolSalt';
import lang from '../nls/i18n';
import RWSaltComponent from './RWSaltComponent';

const isUrl = (url) => {  // eslint-disable-line
    // Cloned see: LauncherListItem, RNPanel
    return new RegExp('^(https?:\\/\\/.*):?(\\d*)\\/?(.*)$').test(url);

    // The more sophisticated test below did not handle an ip address with an associated port.
    // return new RegExp('^((http[s]?|ftp):\\/)?\\/?([^:\\/\\s]+)((\\/\\w+)*\\/)([\\w\\-\\.]+[^#?\\s]+)(.*)?(#[\\w\\-]+)?$').test(url);
};

class RWPanel extends RWSaltComponent {
    static propTypes = {
        // Allows styling of the 'exterior' scroll pane itself
        // Some values don't make sense here and are not allowed (i.e. align-items)
        // style: PropTypes.oneOfType([
        //     PropTypes.object,
        //     PropTypes.array,
        // ]),
        // xStyle: PropTypes.oneOfType([
        //     PropTypes.object,
        //     PropTypes.array,
        // ]),
        //
        // Is this panel in a 'maximized' state in the current form?
        // isExpanded: PropTypes.bool,

        // Standard Menu - Array of normalized actions to shown on menu
        menu: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            icon: PropTypes.string,
            menuText: PropTypes.string,
        })),
        // Application Bar menu's that are button type menu's to display in the app
        // Contains an array of menu's
        applicationBarMenu: PropTypes.array,

        // Available Views are the selector views for changing the current view
        // Contains array of selectable views and the selected view id.
        availableViews: PropTypes.shape({
            viewDescriptors: PropTypes.array,
            selectedViewDescriptor: PropTypes.object,
            onOpenView: PropTypes.func,
        }),

        onMenuAction: PropTypes.func,

        // panelId: PropTypes.string,

        // Is this panel in a form with other panels? (i.e. should it show an expansion tool?)
        showFormTools: PropTypes.bool,
        showMenu: PropTypes.bool,
        showTitle: PropTypes.bool,
        showTools: PropTypes.bool,
        title: PropTypes.string,

        // One of view type names found in { TypeNames } from cv-dialog-sdk
        viewType: PropTypes.string,

        // Extended Menu - Additional available actions that may be OPTIONALLY shown elsewhere (i.e. toolbar)
        xMenu: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            icon: PropTypes.string,
            menuText: PropTypes.string,
        })),
    };
    render() {
        if (this.menu === undefined) {
            this.initMenus();
        }

        const {
            showMenu,
            showTitle,
            showTools,
            title,
        } = this.props;

        // Properties controlling icons change.  Must build each time.
        this.initToolGroupings();

        return (
            <BasePanel
                groups={ showTools ? this.groups : [] }
                menu={ showMenu ? this.menu : [] }
                onGroupAction={ this.handleMenuAction }
                onMenuAction={ this.handleMenuAction }
                title={ showTitle ? title : '' }>
                { this.props.children }
            </BasePanel>
        );
    }

    handleMenuAction = (groupEvent, nativeEvent) => {
        const { onMenuAction } = this.props;
        const modifiers = (nativeEvent && nativeEvent.altKey) ? { [constants.transitionModifiers.OPEN_IN_TAB]: true } : undefined;
        const event = {
            ...groupEvent,
            modifiers,
        };
        onMenuAction(event);
    }

    initMenus() {
        const {
            menu,
            xMenu,
            viewType,
        } = this.props;
        this.menu = menu.map((m) => {
            const newMenu = { ...m };

            // Promote the menuText to a property called label.
            newMenu.label = newMenu.menuText;

            // If the icon is actually a URL, then promote to the imageURL property.
            if (newMenu.icon && isUrl(newMenu.icon)) {
                newMenu.imageURL = newMenu.icon;
                delete newMenu.icon;
            }
            return newMenu;
        });

        // If viewType is Graph we are not adding copy to clipboard option
        if (viewType !== TypeNames.GraphTypeName) {
            const extendedMenu = xMenu.find((f) => (f.id === engineConstants.action.clientActions.copyToClipboard));

            if (extendedMenu) {
                this.menu.push({
                    ...extendedMenu,
                    label: lang.list.copyToClipboard,
                });
            }
        }
    }

    // Build a map by viewType representing the tools that will be displayed on the Panel.
    initToolGroupings() {
        const {
            isExpanded,
            showFormTools,
            menu,
            xMenu,
            viewType,
            availableViews,
            applicationBarMenu,
        } = this.props;
        const {
            themeStore,
        } = rootStore;
        const theme = themeStore.getSanitizedTheme();
        this.groups = [];
        let tool = null;
        let tools = null;
        let groupingItems = false;
        let groupIndex = -1;
        let tapActionToolGroupIndex = -1;

        // Attempt to add first grouping of panel header items
        if (availableViews) {
            if (availableViews.viewDescriptors && availableViews.viewDescriptors.length > 0) {
                const toolProps = {
                    items: availableViews.viewDescriptors,
                    itemSelected: availableViews.selectedViewDescriptor,
                    onAction: availableViews.onOpenView,
                    contextStyles: { ...theme.fonts.changeView },
                };
                tool = {
                    id: 'queryItems',
                    ...toolProps,
                    componentClass: ActionDropDown,
                };

                groupIndex = 0;
                this.groups.push([ tool ]);
            }
        }

        // Put the second grouping of items, the App bar buttons
        if (applicationBarMenu) {
            // eslint-disable-next-line no-shadow
            applicationBarMenu.forEach((menu) => {
                const toolProps = {
                    id: menu.id,
                    tooltip: menu.menuText,
                    imageSource: menu.icon,
                };
                tool = {
                    ...toolProps,
                    componentClass: ActionButtonImage,
                };

                if (groupingItems && groupIndex === this.groups.length - 1) {
                    this.groups[groupIndex].push(tool);
                }
                else {
                    groupIndex = this.groups.length;
                    groupingItems = true;
                    this.groups.push([ tool ]);
                }
            });
        }


        // Extended Tools specific to view types
        switch (viewType) {
            case TypeNames.ListTypeName:
                tools = [];
                tool = xMenu.find((f) => (f.id === engineConstants.action.clientActions.changeDefaultAction));

                if (tool) {
                    const tapMenu = menu.filter((m) => (m.selectionRequired));

                    if (tapMenu.length > 1) {
                        const tapActionLabel = tool.currentTapActionLabel
                            || ((tool.currentTapActionId !== engineConstants.action.clientActions.select) && tool.defaultTapActionLabel)
                            || '(select)';
                        tool = { ...tool };
                        tool.componentClass = TapActionToolSalt;
                        tool.menu = tapMenu;
                        tool.icon = 'arrow_drop_down';
                        tool.tooltip = 'Define what tapping a list item does';
                        tool.iconStyles = {
                            color: theme.actionIconColor,
                            fontSize: theme.actionIconSize,
                        };
                        tool.label = `Tap: ${tapActionLabel}`;
                        tools.push(tool);
                    }
                    groupIndex = this.groups.length;
                    tapActionToolGroupIndex = groupIndex;
                    this.groups.push([ tool ]);
                }
                break;
            case TypeNames.DetailsTypeName:
                // More stuff here (added to prevent lint error)
                break;
            default:
                // Default here
                break;
        }

        // Add the Form level tools also
        if (showFormTools) {
            tool = xMenu.find((f) => (f.id === engineConstants.action.clientActions.toggleExpand));

            if (tool) {
                tool = { ...tool };
                tool.icon = isExpanded ? 'unfold_less' : 'unfold_more';
                tool.tooltip = isExpanded ? 'Return to multi-pane view' : 'Expand to single-pane view';
                // We want to group these tools with the tap action list tools. Otherwise we just add them to the group stack.
                if (tapActionToolGroupIndex >= 0) {
                    this.groups[tapActionToolGroupIndex].push(tool);
                }
                else {
                    groupIndex = this.groups.length;
                    this.groups.push([ tool ]);
                }
            }
        }
    }
}

export default RWPanel;
