import React from 'react';
import { BaseComponentBuilder } from 'cv-react-core';
import BarChart from '../../components/base/BarChart';
import LoadingPage from '../../components/pages/LoadingPage';


/**
 * A builder for creating bar chart components
 */
class BarChartComponentBuilder extends BaseComponentBuilder {
    /**
     * COMPONENT BASED PROPERTIES
     */

    /** Begin Props */
    // Using base builder methods
    /** End Props */


    /**
     * PROCESS AND UPDATE COMPONENT PROPERTIES
     */

    processAnnotations(annotations = [], notationColorProp) {
        const notation = {};
        annotations.forEach((annotation) => {
            const {
                name: propAnnoName,
                value: propAnnoValue,
            } = annotation;
            // Set the bar color
            if (propAnnoName === 'FGND_COLOR') { notation[notationColorProp] = this.getColor(propAnnoValue); }
        });
        return notation;
    }

    processDisplay() {
        // All the props are specified via context styles.
        const contextStyles = this.getContextStyles();
        const {
            barChartProp,
            container,
        } = contextStyles;
        const {
            animate: animation,
            barColor,
            minValue,
            maxValue,
            orientation,
            reverse,
            valueCenter,
            valueCenterX,
            valueCenterY,
            valueColor,
            valueFormat,
            valuePlacement,
            valueTextSize,
            valueWidth,
        } = barChartProp;
        const {
            borderWidth,
            borderColor,
            backgroundColor,
            borderStyle,
            ...restContextContainerStyles // Separate container styles from specialized style props
        } = container || {};
        const property = this.getProperty();
        const record = this.getRecord();
        const isHorizontal = orientation === 'horizontal';

        const { annotations: recordAnnotations } = record;
        const { annotations: propertyAnnotations } = property;

        // Process annotations
        const recAnnotations = this.processAnnotations(recordAnnotations, 'barColor');
        const propAnnotations = this.processAnnotations(propertyAnnotations, 'barColor');

        this.setProps({
            animation,
            contextStyles: {
                container: {
                    ...restContextContainerStyles,
                    backgroundColor: backgroundColor || restContextContainerStyles.backgroundColor || 'transparent',

                    // If a background color IS specified from specialized props
                    // then apply any border styles to the container
                    ...((backgroundColor && borderWidth) ? {
                        border: `${borderWidth}px ${borderStyle} ${borderColor}`,
                    } : {}),
                    boxSizing: 'border-box',
                },
            },
            gridLines: false,
            gridPosition: {
                bottom: 0,
                left: 0,
                right: 0,
                top: 0,
            },
            horizontal: isHorizontal,
            horizontalGridLines: false,
            legend: false,
            maxX: isHorizontal ? maxValue : null,
            maxY: isHorizontal ? null : maxValue,
            minX: isHorizontal ? minValue : null,
            minY: isHorizontal ? null : minValue,
            reverse,
            seriesData: [
                {
                    // If a background color is NOT specified then apply
                    // any border styles to the bar
                    ...((!backgroundColor && borderWidth) ? {
                        barBorderColor: borderColor,
                        barBorderWidth: borderWidth,
                    } : {}),
                    barColor,
                    barWidth: '100%',
                    dataPoints: [
                        {
                            axisLabel: '',
                            errorMessage: property.errorMessage,
                            value: property.value,
                            ...recAnnotations,
                            ...propAnnotations,
                            // valueLabel: true,
                            // valueLabelColor: valueColor,
                            // valueLabelFormat: valueFormat.replace('${0}', '{value}'),
                            // valueLabelSize: valueTextSize,
                            // valueLabelWidth: valueWidth,
                        },
                    ],
                },
            ],
            stacked: false,
            title: '',
            tooltip: false,
            // eslint-disable-next-line no-template-curly-in-string
            valueLabelFormat: valueFormat.replace('${0}', '{value}'),
            verticalGridLines: false,
            xAxis: false,
            xAxisTitle: '',
            yAxis: false,
            yAxisTitle: '',
            zoom: false,

            value: property.value,
            valueCenterX: valueCenterX || valueCenter,
            valueCenterY: valueCenterY || valueCenter,
            valueColor,
            valueFormat,
            valuePlacement,
            valueTextSize,
            valueWidth,
        });
    }

    processStyle() {
        const categorizedStyles = BaseComponentBuilder.styleHelper.categorizeNativeStyles(this.getStyle());
        const { container } = categorizedStyles;

        this.setContainerStyles({
            ...container,
            ...this.getContainerStyles(),
            minHeight: 'auto',
            height: '100%',
        });
    }

    buildProps() {
        // Update the selected state
        this.processDisplay();

        // Update the input style
        this.processStyle();
    }


    /**
     * BUILD AND RETURN COMPONENT AS REACT ELEMENT
     */

    build() {
        // Process and construct final props
        this.buildProps();

        const {
            isLoading,
            value,
            valueCenterX,
            valueCenterY,
            valueColor,
            valueFormat,
            valuePlacement,
            valueTextSize,
            valueWidth,
            ...restBarChartProps
        } = this.getProps();

        // console.log('BAR CHART BUILDER: ', restBarChartProps); // eslint-disable-line

        if (isLoading) {
            return (
                <LoadingPage />
            );
        }

        // Build and return component as React element
        return (
            <React.Fragment>
                <BarChart { ...restBarChartProps } />
                <div style={ {
                    position: valuePlacement,
                    top: `${valueCenterY * 100}%`,
                    left: `${valueCenterX * 100}%`,
                    transform: `translate(-${valueCenterX * 100}%, -${valueCenterY * 100}%)`,
                    color: valueColor,
                    fontSize: valueTextSize,
                    width: valueWidth,
                } }>
                    { valueFormat ?
                        // eslint-disable-next-line no-template-curly-in-string
                        valueFormat.replace('${0}', value) :
                        value
                    }
                </div>
            </React.Fragment>
        );
    }
}

export default BarChartComponentBuilder;
