import { rootStore } from 'cv-react-core';

const getTheme = () => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    // Populate the component parts with theme styles
    return {
        buttonText: {
            ...theme.fonts.default,
        },

        buttonPrimary: {
            backgroundColor: theme.buttonPrimaryBackgroundColor,
            borderColor: theme.buttonPrimaryBorderColor,
        },

        buttonPrimaryHover: {
            backgroundColor: theme.buttonUnderlayColor,
        },

        buttonPrimaryText: {
            ...theme.fonts.buttonPrimary,
        },

        buttonSecondary: {
            backgroundColor: theme.buttonSecondaryBackgroundColor,
            borderColor: theme.buttonSecondaryBorderColor,
        },

        buttonSecondaryHover: {
            borderColor: theme.buttonUnderlayColor,
        },

        buttonSecondaryText: {
            ...theme.fonts.buttonSecondary,
        },

        switchThumb: {
            color: theme.checkBoxButtonColor,
        },

        switchTrack: {
            backgroundColor: theme.checkBoxUnCheckedBackgroundColor,
        },

        switchTrackSelected: {
            backgroundColor: theme.checkBoxCheckedBackgroundColor,
        },

        textField: {
            ...theme.loginFormTextColor,
        },

        textLabel: {
            ...theme.fonts.default,
            color: theme.loginFormTextColor,
        },

        imageLoadingIndicator: {
            color: theme.statusLoadingIndicatorColor,
        },
    };
};

export default getTheme;
