import React, { Component } from 'react';
import { observer } from 'mobx-react';

import AboutPage from '../components/pages/AboutPage/AboutPage';
import lang from '../nls/i18n';

@observer
class AboutRoute extends Component {
    render() {
        // Set browser tab name to the workbench name.
        // Doing this on each route for consistency for now.
        document.title = lang.mainMenu.about;
        return (
            <AboutPage />
        );
    }
}

export default AboutRoute;
