/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import { TextLabel, IconButton, ICON_SIZE } from 'cv-library-react-web';
import { constants } from 'cv-react-core';
// import { Log } from 'cv-dialog-sdk';

import getStyles from './TableHeaderCellContent.styles';

const CARRIAGE_RETURN_REPLACEMENT = '\r\n';
const { searchSort} = constants;
const { DOT_REPLACEMENT } = searchSort;
export const SORT_DIRECTIONS = {
    ASC: 'asc',
    DESC: 'desc',
    NONE: null,
};

class TableHeaderCellContent extends PureComponent {
    static propTypes = {
        onColumnFrozen: PropTypes.func,
        onSortColumn: PropTypes.func,
        isAscending: PropTypes.bool,
        priority: PropTypes.number,
        fixedColumn: PropTypes.bool,
        title: PropTypes.string,
        name: PropTypes.string,
        isSearchFormOpen: PropTypes.bool,
    };

    static defaultProps = {
        isAscending: false,
        isSearchFormOpen: false,
        onColumnFrozen: () => {},
        onSortColumn: () => {},
    };

    constructor(props) {
        super(props);
        const { name } = this.props;
        this.columnName = name.replace(DOT_REPLACEMENT, '.');
    }

    render() {
        // trace(true);

        const {
            fixedColumn,
            title,
            priority,
            isAscending,
            isSearchFormOpen } = this.props;

        const styles = getStyles();

        if (!isSearchFormOpen) {
            this.isAscending = isAscending;
            this.priority = priority;
        }
        // To have carriage returns in header, replace \r or \n or \r\n with \r\n
        const cTitle = title.replace(/\r\n|\n\r|\n|\r/g, CARRIAGE_RETURN_REPLACEMENT);
        const numberOfLines = cTitle.split(CARRIAGE_RETURN_REPLACEMENT).length;
        const fixedIcon = fixedColumn ? 'lock' : 'lock_open';
        const fixedTitle = fixedColumn ? 'unlock column' : 'lock column';
        const showButtons = this.columnName.localeCompare('selection_column') !== 0;
        const badgeText = this.priority !== undefined && this.priority + 1;
        return (
            <>
                <TextLabel
                    contextStyles={ {
                        container: { ...styles.headerCellTextContainer },
                        text: { ...styles.headerCellText }} }
                    onClick={ this.handleSorting }
                    numberOfLines={ numberOfLines }>
                    { cTitle }
                </TextLabel>
                { showButtons &&
                    <div style={ styles.headerCellButtonColumn }>
                        <div style={ styles.headerCellButtonRowContainer }>
                            { this.priority !== undefined &&
                                <IconButton
                                    badgeText={ `${badgeText}` }
                                    contextStyles={ {
                                        container: styles.headerCellButtonRow,
                                        badge: styles.headerCellButtonBadge,
                                        button: styles.headerCellButton,
                                        icon: {
                                            // Invert sort descending icon for sort ascending as one was not in the core library
                                            transform: this.isAscending ? 'scaleY(-1)' : '',
                                            ...styles.headerCellButtonIcon,
                                        },
                                    } }
                                    iconName="arrow_downward"
                                    iconSize={ ICON_SIZE.SMALL }
                                    onClick={ this.handleSorting }
                                    title={ this.isAscending ? 'ascending' : 'descending' } />
                            }
                            <IconButton
                                contextStyles={ {
                                    container: styles.headerCellButtonRow,
                                    button: styles.headerCellButton,
                                    icon: styles.headerCellButtonIcon,
                                } }
                                iconName={ fixedIcon }
                                iconSize={ ICON_SIZE.SMALL }
                                onClick={ this.handleColumnFrozen }
                                title={ fixedTitle } />
                        </div>
                    </div>
                }
            </>
        );
    }

    /**
     * Called on lock icon click
     * @param {SyntheticEvent} event
     * @returns {void}
     */
    handleColumnFrozen = (event) => {
        event.stopPropagation();
        const { onColumnFrozen } = this.props;
        // const { name } = column;
        onColumnFrozen(this.columnName);
    }

    /**
     * Called on sorting
     * @returns {void}
     */
    handleSorting = (event) => {
        event.stopPropagation();
        const { onSortColumn } = this.props;

        const direction = this.isAscending ? SORT_DIRECTIONS.DESC : SORT_DIRECTIONS.ASC;

        const { nativeEvent } = event;
        if (nativeEvent.ctrlKey || nativeEvent.metaKey) {
            onSortColumn(this.columnName, direction);
        }
        else {
            // A decision was made if we are sorting a column that already contains a sort order of desc, clear the sort and let it go back to default.
            const sortDirection = this.priority && this.priority >= 0 && direction === SORT_DIRECTIONS.ASC ? SORT_DIRECTIONS.NONE : direction;
            onSortColumn(this.columnName, sortDirection, false);
        }
    }
}

export default TableHeaderCellContent;
