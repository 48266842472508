import { rootStore } from 'cv-react-core';

const getTheme = () => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    // Populate the component parts with theme styles
    return {
        body: {
            fontFamily: '"Open Sans"',
        },

        button: {
            backgroundColor: theme.buttonPrimaryBackgroundColor,
        },

        buttonText: {
            fontFamily: '"Open Sans"',
        },

        container: {
        },

        itemText: {
            fontFamily: '"Open Sans"',
        },

        media: {
        },

        subtitle: {
            fontFamily: '"Open Sans"',
        },

        title: {
            fontFamily: '"Open Sans"',
        },
    };
};

export default getTheme;
