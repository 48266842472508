/* eslint-disable no-console */
import React from 'react';
import { GaugeChart } from 'cv-library-react-web';
import { BaseComponentBuilder } from 'cv-react-core';
import LoadingPage from '../../components/pages/LoadingPage';


/**
 * A builder for creating bar chart components
 */
class GaugeChartComponentBuilder extends BaseComponentBuilder {
    /**
     * COMPONENT BASED PROPERTIES
     */

    /** Begin Context Styles */
    // Using base builder methods
    /** End Props */

    /**
     * PROCESS AND UPDATE COMPONENT PROPERTIES
     */

    processDisplay() {
        // All the props are specified via context styles.
        const contextStyles = this.getContextStyles();
        const {
            container,
            gaugeChartProp,
        } = contextStyles;

        const {
            animate: animation,
            gaugeImageURL,
            minDegrees,
            maxDegrees,
            minValue,
            maxValue,
            pointerColor,
            pointerThickness,
            reverse,
            valueCenterX,
            valueCenterY,
            valueColor,
            valueFormat,
            valuePlacement,
            valueTextSize,
        } = gaugeChartProp;
        const {
            borderColor,
            borderStyle,
            borderWidth,
            backgroundColor,
            ...restContextContainerStyles // Separate container styles from specialized style props
        } = container;
        const property = this.getProperty();

        let minDegree = minDegrees;

        if ( minDegrees === 0 ){
            minDegree = 1;
        }

        this.setProps({
            animation,
            contextStyles: {
                container: {
                    ...restContextContainerStyles,
                    backgroundColor: backgroundColor || restContextContainerStyles.backgroundColor || 'transparent',

                    // If a background color IS specified from specialized props
                    // then apply any border styles to the container
                    ...((backgroundColor && borderWidth) ? {
                        border: `${borderWidth}px ${borderStyle} ${borderColor}`,
                    } : {}),
                    boxSizing: 'border-box',
                },
                pointer: {
                    color: pointerColor,
                },
            },
            endAngle: maxDegrees,
            gaugeBackgroundImage: gaugeImageURL,
            gaugeNameColor: valueColor,
            gaugeNameTextSize: valueTextSize,
            gaugeValue: property.value,
            gaugeValueColor: valueColor,
            gaugeValueFormatter: valueFormat,
            gaugeValueTextSize: valueTextSize,
            maxValue,
            minValue,
            pointerWidth: pointerThickness,
            reverse,
            startAngle: minDegree,
            valueColor,
            valueCenterX,
            valueCenterY,
            valueFormat,
            valuePlacement,
            valueTextSize,
        });
    }

    processStyle() {
        const categorizedStyles = BaseComponentBuilder.styleHelper.categorizeNativeStyles(this.getStyle());
        const { container } = categorizedStyles;

        this.setContainerStyles({
            ...container,
            ...this.getContainerStyles(),
            height: '100%',
            minHeight: 'auto',
        });
    }

    buildProps() {
        // Update the selected state
        this.processDisplay();

        // Update the input style
        this.processStyle();
    }

    /**
     * BUILD AND RETURN COMPONENT AS REACT ELEMENT
     */

    build() {
        // Process and construct final props
        this.buildProps();

        const {
            isLoading,
            value,
            valueCenterX,
            valueCenterY,
            valueColor,
            valueFormat,
            valuePlacement,
            valueTextSize,
            valueWidth,
            ...restGaugeChartProps
        } = this.getProps();

        if (isLoading) {
            return <LoadingPage />;
        }

         // Build and return component as React element
         return (
             <GaugeChart { ...restGaugeChartProps } />
        );
    }
}

export default GaugeChartComponentBuilder;
