import { rootStore } from 'cv-react-core';

const coreStyles = {
    modalDivider: {},
};

const getStyles = (contextStyles) => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    return {
        modalDivider: {
            ...coreStyles.modalDivider,
            ...contextStyles.modalDivider,
            backgroundColor: theme.errorTitleContentSeparatorColor,
        },

        modalTitle: {
            ...theme.fonts.default,
            fontWeight: 'bold',
            fontSize: theme.fonts.fontSizeMidLarge,
        },

        buttonContainer: {
            display: 'flex',
            flexDirection: 'row',
            marginTop: '1rem',
            justifyContent: 'center',
        },

        modalContainer: {
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '80%',
            maxHeight: '80%',
            padding: '0 1rem 1rem 1rem',
            width: '60%',
            backgroundColor: 'white',
        },

        passwordContainer: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: '1rem',
        },
    };
};

export default getStyles;
