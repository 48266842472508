const getStyles = (contextStyles = {}) => {
    // Populate the component parts with theme styles
    return {
        container: {
            display: 'flex',
            flexGrow: 1,
            flexShrink: 1,
            height: '100%',
            minHeight: '290px',
            flexDirection: 'column',
            margin: '15px',
            ...contextStyles.container,
        },

        checkBoxContainer: {
            display: 'flex',
            alignItems: 'center',
            ...contextStyles.checkBoxContainer,
        },
    };
};

export default getStyles;