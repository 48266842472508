import React from 'react';
import { constants, rootStore, BaseComponentBuilder } from 'cv-react-core';

import Icon from '../../components/base/Icon';
import Image from '../../components/base/Image';
import ImagePickerMenu, { IMAGE_ACTIONS } from '../../components/base/ImagePickerMenu';
import LargePropertyImage from '../../components/base/LargePropertyImage';


/**
 * A builder for creating image components
 */
class ImageComponentBuilder extends BaseComponentBuilder {
    constructor() {
        super();
        this.data.signatureProps = {};
    }

    /**
     * COMPONENT BASED PROPERTIES
     */

    /** Begin Context Styles */
    setImageStyles(imageStyles) {
        return this.setContextStyles({
            ...this.getContextStyles(),
            image: imageStyles,
        });
    }
    getImageStyles() {
        const { image } = this.getContextStyles();
        return image || {};
    }

    setLoadingIndicatorStyles(loadingIndicatorStyles) {
        return this.setContextStyles({
            ...this.getContextStyles(),
            loadingIndicator: loadingIndicatorStyles,
        });
    }
    getLoadingIndicatorStyles() {
        const { loadingIndicator } = this.getContextStyles();
        return loadingIndicator || {};
    }
    /** End Context Styles */


    /** Begin Props */
    setAltText(altText) {
        this.props.altText = altText;
        return this;
    }
    getAltText() {
        return this.props.altText;
    }

    setDisabled(disabled = true) {
        this.props.isDisabled = disabled;
        return this;
    }
    getDisabled() {
        return this.props.isDisabled;
    }

    setImageSrc(imageSrc) {
        this.props.imageSrc = imageSrc;
        return this;
    }

    getImageSrc() {
        return this.props.imageSrc;
    }

    setOnClick(onClick) {
        this.props.onClick = onClick;
        return this;
    }
    getOnClick() {
        return this.props.onClick;
    }

    setShowLoadingIndicator(showLoadingIndicator = true) {
        this.props.showLoadingIndicator = showLoadingIndicator;
        return this;
    }

    getShowLoadingIndicator() {
        return this.props.showLoadingIndicator;
    }


    /**
     * IMAGE BASED NON-PROPERTIES
     */

    setLargeProperty(isLargeProperty = true) {
        this.data.isLargeProperty = isLargeProperty;
        return this;
    }
    getLargeProperty() {
        return this.data.isLargeProperty;
    }

    setPlaceholderIcon(placeholderIcon) {
        this.data.placeholderIcon = placeholderIcon;
        return this;
    }
    getPlaceholderIcon() {
        return this.data.placeholderIcon;
    }

    setShowPlaceholder(showPlaceholder = true) {
        this.data.showPlaceholder = showPlaceholder;
        return this;
    }
    getShowPlaceholder() {
        return this.data.showPlaceholder;
    }


    /**
     * IMAGE PICKER PROPS
     */

    setActionsStyles(actionsStyles) {
        return this.setContextStyles({
            ...this.getContextStyles(),
            actions: actionsStyles,
        });
    }
    getActionsStyles() {
        const { actions } = this.getContextStyles();
        return actions || {};
    }

    setActionButtonStyles(actionButtonStyles) {
        return this.setContextStyles({
            ...this.getContextStyles(),
            actionButton: actionButtonStyles,
        });
    }
    getActionButtonStyles() {
        const { actionButton } = this.getContextStyles();
        return actionButton || {};
    }

    setActionButtonTextStyles(actionButtonTextStyles) {
        return this.setContextStyles({
            ...this.getContextStyles(),
            actionButtonText: actionButtonTextStyles,
        });
    }
    getActionButtonTextStyles() {
        const { actionButtonText } = this.getContextStyles();
        return actionButtonText || {};
    }

    setCancelButtonStyles(cancelButtonStyles) {
        return this.setContextStyles({
            ...this.getContextStyles(),
            cancelButton: cancelButtonStyles,
        });
    }
    getCancelButtonStyles() {
        const { cancelButton } = this.getContextStyles();
        return cancelButton || {};
    }

    setCancelButtonTextStyles(cancelButtonTextStyles) {
        return this.setContextStyles({
            ...this.getContextStyles(),
            cancelButtonText: cancelButtonTextStyles,
        });
    }
    getCancelButtonTextStyles() {
        const { cancelButtonText } = this.getContextStyles();
        return cancelButtonText || {};
    }

    setContentStyles(xxxStyles) {
        return this.setContextStyles({
            ...this.getContextStyles(),
            xxx: xxxStyles,
        });
    }
    getContentStyles() {
        const { xxx } = this.getContextStyles();
        return xxx || {};
    }

    setDividerStyles(dividerStyles) {
        return this.setContextStyles({
            ...this.getContextStyles(),
            divider: dividerStyles,
        });
    }
    getDividerStyles() {
        const { divider } = this.getContextStyles();
        return divider || {};
    }

    setHeaderStyles(headerStyles) {
        return this.setContextStyles({
            ...this.getContextStyles(),
            header: headerStyles,
        });
    }
    getHeaderStyles() {
        const { header } = this.getContextStyles();
        return header || {};
    }

    setMenuStyles(menuStyles) {
        return this.setContextStyles({
            ...this.getContextStyles(),
            menu: menuStyles,
        });
    }
    getMenuStyles() {
        const { menu } = this.getContextStyles();
        return menu || {};
    }

    setMenuItemStyles(menuItemStyles) {
        return this.setContextStyles({
            ...this.getContextStyles(),
            menuItem: menuItemStyles,
        });
    }
    getMenuItemStyles() {
        const { menuItem } = this.getContextStyles();
        return menuItem || {};
    }

    setMenuItemFocusedStyles(menuItemFocusedStyles) {
        return this.setContextStyles({
            ...this.getContextStyles(),
            menuItemFocused: menuItemFocusedStyles,
        });
    }
    getMenuItemFocusedStyles() {
        const { menuItemFocused } = this.getContextStyles();
        return menuItemFocused || {};
    }

    setModalStyles(modalStyles) {
        return this.setContextStyles({
            ...this.getContextStyles(),
            modal: modalStyles,
        });
    }
    getModalStyles() {
        const { modal } = this.getContextStyles();
        return modal || {};
    }

    setOverlayStyles(overlayStyles) {
        return this.setContextStyles({
            ...this.getContextStyles(),
            overlay: overlayStyles,
        });
    }
    getOverlayStyles() {
        const { overlay } = this.getContextStyles();
        return overlay || {};
    }

    setTitleStyles(titleStyles) {
        return this.setContextStyles({
            ...this.getContextStyles(),
            title: titleStyles,
        });
    }
    getTitleStyles() {
        const { title } = this.getContextStyles();
        return title || {};
    }

    getAvailableActions() {
        return this.data.availableActions;
    }
    setAvailableActions(actions) {
        if (Array.isArray(actions)) {
            this.data.availableActions = actions;
        }
        return this;
    }

    setOnChangeImageHandler(onChangeHandler) {
        if (typeof onChangeHandler === 'function') {
            this.data.onChangeImage = onChangeHandler;
        }
        return this;
    }
    getOnChangeImageHandler() {
        return this.data.onChangeImage;
    }


    /**
     * SIGNATURE IMAGE PROPS
     */

    setSignatureLineColor(color) {
        this.data.signatureProps.lineColor = color;
        return this;
    }

    setSignatureLineThickness(thickness) {
        this.data.signatureProps.lineThickness = thickness;
        return this;
    }

    setSignatureBackgroundColor(color) {
        this.data.signatureProps.backgroundColor = color;
        return this;
    }

    setSignatureWidth(width) {
        this.data.signatureProps.width = width;
        return this;
    }

    setSignatureHeight(height) {
        this.data.signatureProps.height = height;
        return this;
    }

    setSignatureAutoFocus(signatureAutoFocus = true) {
        this.data.signatureProps.autoFocus = signatureAutoFocus;
        return this;
    }

    getSignatureProps() {
        return this.data.signatureProps;
    }
    /** End Props */


    /**
     * PROCESS AND UPDATE COMPONENT PROPERTIES
     */

    processPaperFormsImagePickerActions() {
        const allowedTest = (id, defaultAllow = true) => {
            // actionOverrides are only injected from cv-react-core:
            // Paper Forms - Paper Model: Image component
            // actionOverrides are not styles but allowable image actions
            // TODO: inject into another property?
            const { actionOverrides } = this.getContextStyles();

            // If not provided, the action is allowed.
            if (!actionOverrides || !actionOverrides[id]) return defaultAllow;

            return actionOverrides[id] === 'true';
        };

        const allowDisplayAction = () =>
            // Display is different from other actions in that if not present, the action is prohibited
            allowedTest(constants.imagePickerMenuIds.display, false);

        const allowTakePhotoAction = () =>
            // Allowed unless excluded with a context style
            allowedTest(constants.imagePickerMenuIds.takePhoto);

        const allowImportAction = () =>
            // Allowed unless excluded with a context style
            allowedTest(constants.imagePickerMenuIds.import);

        const allowDeleteAction = () =>
            // Allowed unless excluded with a context style
            allowedTest(constants.imagePickerMenuIds.delete);

        const allowAnnotateAction = () =>
            // Allowed unless excluded with a context style
            allowedTest(constants.imagePickerMenuIds.annotate);

        const allowSignatureAction = () =>
            // Allowed unless excluded with a context style
            allowedTest(constants.imagePickerMenuIds.signature);

        const isReadMode = this.getReadMode();
        const propDef = this.getPropDef();
        const viewType = this.getViewType();

        // For signature types
        const { actionOverrides } = this.getContextStyles();
        // If signature and editing and allowed to perform action
        if ((propDef.isSignatureType || ( actionOverrides && actionOverrides[constants.imagePickerMenuIds.signature])) && !isReadMode && allowSignatureAction()) {
            // Initialize available actions with first action
            const actions = [];
            if (allowDisplayAction()) actions.push(IMAGE_ACTIONS[constants.imagePickerMenuIds.display]);
            actions.push(IMAGE_ACTIONS[constants.imagePickerMenuIds.signature]);
            this.setAvailableActions(actions);
        }

        // Read mode for all other image types
        else if (isReadMode) {
            // If allowed to perform display action
            if (allowDisplayAction() || viewType !== BaseComponentBuilder.typeNames.ListTypeName) {
                // Initialize available actions with first action
                this.setAvailableActions([ IMAGE_ACTIONS[constants.imagePickerMenuIds.display] ]);
            }

            // No display action allowed
            else {
                // Empty available actions
                this.setAvailableActions([]);
            }
        }

        // Edit mode for all other image types
        else {
            const actions = [];

            if (allowDisplayAction()) actions.push(IMAGE_ACTIONS[constants.imagePickerMenuIds.display]);
            if (allowAnnotateAction()) actions.push(IMAGE_ACTIONS[constants.imagePickerMenuIds.annotate]);
            if (allowDeleteAction()) actions.push(IMAGE_ACTIONS[constants.imagePickerMenuIds.delete]);
            if (allowImportAction()) actions.push(IMAGE_ACTIONS[constants.imagePickerMenuIds.import]);
            if (allowTakePhotoAction()) actions.push(IMAGE_ACTIONS[constants.imagePickerMenuIds.takePhoto]);

            this.setAvailableActions(actions);
        }
    }

    processChangeHandler() {
        const onValueChangeHandler = this.getOnValueChangeHandler();
        const isReadMode = this.getReadMode();
        const property = this.getProperty();
        const { name: propertyName = '' } = property;

        if (!isReadMode && !!propertyName && !!onValueChangeHandler) {
            this.setOnChangeImageHandler(({ /* uri, */ data, mimeType }) => {
                onValueChangeHandler(propertyName, data, mimeType);
            });
        }
    }

    processImageProps() {
        const property = this.getProperty();
        const propDef = this.getPropDef();
        const { value } = property;
        const { isURLType } = propDef;

        // Do not display image even though a value exists
        if (value === null) {
            this.setShowPlaceholder(true);
            this.setPlaceholderIcon('broken_image');
        }

        // Property is a string with URL usage
        else if (isURLType) {
            if (value) { this.setImageSrc(value); }
        }

        // TODO: move this condition to the builder factory as a new builder type
        // Handle large binary image data type
        else if (propDef.isLargePropertyType) {
            // If no large property binary data available
            if (!value || (value.isLoaded && !value.encodedData)) {
                // Clear image source and show placeholder icon
                this.setImageSrc(null);
                this.setShowPlaceholder(true);
                this.setPlaceholderIcon('image');
            }

            // Large image data is available
            else if (value) {
                // If the image binary data is available from the encodedData property
                if (value.encodedData) {
                    // Set image source to inline binary data
                    this.setImageSrc(BaseComponentBuilder.uiHelper.binaryDataAsUri(value.encodedData, value.contentType).uri);
                }

                // If inline binary data is not available, get large property image using the asyncDataCallback
                else if (this.getAsyncDataCallback()) {
                    // Flag as large property image type
                    this.setLargeProperty(true);

                    // Show image loading indicator while image binary data is fetched using asyncDataCallback
                    this.setShowLoadingIndicator(true);
                }

                // TODO: add fallback?
                // Fallback scenario: no encodedData and no callback to fetch image data
                // else {
                //     this.setImageSrc(null);
                //     this.setShowPlaceholder(true);
                //     this.setPlaceholderIcon('broken_image');
                // }
            }
        }
    }

    processStyle() {
        const { themeStore } = rootStore;
        const theme = themeStore.getSanitizedTheme();
        const viewType = this.getViewType();
        const imageStyles = this.getImageStyles();
        const propDef = this.getPropDef();
        const isFocused = this.getFocusedState();

        // Check for non-image injected signature styles from the core project
        const { actionOverrides } = this.getContextStyles();
        if (propDef.isSignatureType || ( actionOverrides && actionOverrides[constants.imagePickerMenuIds.signature])) {
            const { signatureStyle } = this.getContextStyles();
            if (isFocused) { this.setSignatureAutoFocus(); }
            if (signatureStyle) {
                if (signatureStyle.lineColor) { this.setSignatureLineColor(signatureStyle.lineColor); }
                if (signatureStyle.lineThickness) { this.setSignatureLineThickness(signatureStyle.lineThickness); }
                if (signatureStyle.backgroundColor) { this.setSignatureBackgroundColor(signatureStyle.backgroundColor); }
                if (signatureStyle.width) { this.setSignatureWidth(signatureStyle.width); }
                if (signatureStyle.height) { this.setSignatureHeight(signatureStyle.height); }
            }
        }

        // If no size has been supplied
        if (!imageStyles.width && !imageStyles.height) {
            // Get new size based on list/details settings
            const imageSize = viewType === BaseComponentBuilder.typeNames.ListTypeName ? theme.imageSizeList : theme.imageSizeDetail;

            // Combine existing image styles with size overrides
            this.setImageStyles({
                objectFit: 'contain',
                width: imageSize,
                height: imageSize,
                ...imageStyles,
            });
        }
    }

    buildProps() {
        // Discern props
        this.processImageProps();

        // Update the default styles
        this.processStyle();

        // Update change handler to support property / value data
        this.processChangeHandler();

        // Add menu options for paper forms
        this.processPaperFormsImagePickerActions();
    }


    /**
     * BUILD AND RETURN COMPONENT AS REACT ELEMENT
     */

    build() {
        // Process and construct final props
        this.buildProps();

        const property = this.getProperty();
        const asyncDataCallback = this.getAsyncDataCallback();
        const isLargeProperty = this.getLargeProperty();
        const imageSrc = this.getImageSrc();
        const placeholderIcon = this.getPlaceholderIcon();
        const availableActions = this.getAvailableActions();
        const { value } = property;
        const hasImageContent = !!value || !!imageSrc;
        let ImageElement;
        let actions = availableActions;
        let RawImageElement;

        // If a placeholder was set because of no image source, data or callback
        if (this.getShowPlaceholder() && placeholderIcon) {
            // Display the desired placeholder icon
            const contextStyles = {};
            contextStyles.container = this.getContextStyles().container || {};
            contextStyles.icon = this.getContextStyles().icon || {};
            ImageElement = (
                <Icon
                    iconName={ placeholderIcon }
                    contextStyles={ contextStyles } />
            );
        }

        // For large property images that are not inline binary data and need loading
        else if (isLargeProperty && asyncDataCallback && !imageSrc) {
            // Load image data using provided callback
            ImageElement = (
                <LargePropertyImage
                    { ...this.getProps() }
                    largeProperty={ value }
                    asyncDataCallback={ asyncDataCallback } />
            );
        }

        // For images with inline binary data or a URL
        else if (imageSrc) {
            // Show image using inline data/URL
            ImageElement = (
                <Image { ...this.getProps() } />
            );
        }

        // TODO: add fallback?
        // Fallback due to missing image source or callback
        // else {
        //     ImageElement = (
        //         <Icon iconName="broken_image" />
        //     );
        // }

        // Clone the Image Element tp pass down the Raw Image ( without context styles for image) if image content is available
        if (hasImageContent) {
            RawImageElement = React.cloneElement(
                ImageElement,
                {
                    contextStyles: {
                        ...ImageElement.props.contextStyles,
                        image: {
                            minHeight: 50,
                        },
                    },
                });
        }
        else {
            // Remove certain actions if image content is missing
            actions = availableActions && availableActions.filter((action) => (
                // Do not allow display for empty images
                action.id !== constants.imagePickerMenuIds.display &&

                // Do not allow delete for empty images
                action.id !== constants.imagePickerMenuIds.delete
            ));
        }

        // Return image only if no image actions
        if (actions && actions.length === 0) {
            return (
                ImageElement
            );
        }

        // Wrap with menu for available actions
        return (
            <ImagePickerMenu
                actions={ actions }
                contextStyles={ this.getContextStyles() }
                onChangeImage={ this.getOnChangeImageHandler() }
                RawImage={ RawImageElement }
                signatureProps={ this.getSignatureProps() }>
                { ImageElement }
            </ImagePickerMenu>
        );
    }
}

export default ImageComponentBuilder;
