import React, { Component } from 'react';
import * as PropTypes from 'prop-types';

// Local components
import CvModal from '../components/base/SimpleModal';
import SearchSortForm from '../components/forms/SearchSortForm';

/**
 * Search component that is a PureComponent for handling search routines.
 */
class RWSearchSort extends Component {
    static propTypes = {
        style: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        xStyle: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
        title: PropTypes.string,
        showSearch: PropTypes.bool,
        sortTerms: PropTypes.array,
        filterTerms: PropTypes.array,
        sortableValues: PropTypes.array,
        onSubmit: PropTypes.func,
        onClear: PropTypes.func,
        onCancel: PropTypes.func,
        onSearchTermChange: PropTypes.func,
        onSortClear: PropTypes.func,
        onSortTermsChange: PropTypes.func,
        onSetFilterOperation: PropTypes.func,
    };

    render() {
        const {
            showSearch,
            onCancel,
            ...restProps } = this.props;
        const contextStyles = {
            modal: {
                height: '60%',
            },
        };
        const searchSortFormProps = {
            onCancel,
            ...restProps,
        };
        return (
            showSearch
            && (
                <CvModal
                    onPressOverlay={ onCancel }
                    contextStyles={ contextStyles }>
                    { React.createElement(SearchSortForm, { ...searchSortFormProps }) }
                </CvModal>
            )
        );
    }
}

export default RWSearchSort;