// import { rootStore } from 'cv-react-core';

// Core component styles
const coreStyles = {
    button: {
        padding: '2px',
    },

    container: {
    },

    icon: {
    },

    text: {
    },
};

/**
 * Helper method to get the styles for the component
 * @param {Object} contextStyles - Context styles for the Component
 */
const getStyles = (contextStyles = {}) => {
    // const { themeStore } = rootStore;
    // const theme = themeStore.getSanitizedTheme();

    return {
        button: {
            ...coreStyles.button,
            ...contextStyles.button,
        },

        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },

        icon: {
            ...coreStyles.icon,

            // color: theme.actionButtonBackgroundColor,
            ...contextStyles.icon,
        },

        text: {
            ...coreStyles.text,
            ...contextStyles.text,
        },
    };
};

export default getStyles;
