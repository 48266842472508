/* eslint-disable no-unused-vars */
import { rootStore } from 'cv-react-core';
/**
* Helper method to get the styles for Icon component
* @param {Object} contextStyles - Context styles for Icon Component
*/
const getStyles = (contextStyles = {}) => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();
    const {
        color: colorHeading,
    } = theme.fonts.dataTableHeading;

    return {
        headerCellTextContainer: {
            flexGrow: 1,
            ...contextStyles.headerCell,
        },
        headerCellButtonRowContainer: {
            display: 'flex',
            flexDirection: 'row',
        },
        headerCellButtonRow: {
            display: 'flex',
            flexDirection: 'row',
        },
        headerCellButtonColumn: {
            display: 'flex',
            flexDirection: 'column',
            ...contextStyles.headerCellButtonSection,
        },
        headerCellButton: {
            padding: '0px',
            margin: '0px',
            marginLeft: '2px',
            marginRight: '2px',
            ...contextStyles.headerCellButton,
        },

        headerCellButtonBadge: {
            ...theme.fonts.dataTableHeading,
            height: '12px',
            minWidth: '8px',
            fontSize: '8px',
            ...contextStyles.headerCellButtonBadge,
        },

        headerCellButtonIcon: {
            color: colorHeading,
            fontSize: '14px',
            ...contextStyles.headerCellButtonIcon,
        },

        headerCellText: {
            cursor: 'pointer',
            padding: '2px',
            ...theme.fonts.dataTableHeading,
            ...contextStyles.headerCellText,
        },
    };
};

export default getStyles;
