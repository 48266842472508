import{Log,TypeNames}from'cv-dialog-sdk';import{GmlV1ToSalt}from'cv-gml';import{PaperXmlToSalt}from'cv-paper';import{classicTranspiler,engineUtils,rootStore,serviceFactory}from'cv-react-core';import BuildGlobals from'../provider/BuildGlobals';class Transpiler{generateSalt(dialog){const{view}=dialog;const{gmlMarkup,paperMarkup}=view;let saltDocument;if(gmlMarkup){// const start = Date.now();
// Log.debug(`perf::transpileGML starting at ${start} for dialog ${dialog.id}`);
GmlV1ToSalt.xmlParser=serviceFactory.xmlParser;// not necessary to set this for server-side transpilation
const transpiler=new GmlV1ToSalt();const viewId=dialog.view.persistentId||dialog.view.id;// Below line of code has been added to STOP displaying GML issues to the users in XNA
const errorCallBack=BuildGlobals.isXHA()?serviceFactory.notify.showError:()=>{};transpiler.perform(gmlMarkup,viewId,rootStore.sessionStore.getTenantLevelGML(),errorCallBack);// Log.debug(`perf::transpileGML finished in ${Date.now() - start} ms for dialog ${dialog.id}`);
if(transpiler.errorString){Log.error(transpiler.errorString);serviceFactory.notify.showError(transpiler.errorString);}else if(transpiler.salt){Log.debug('Success');saltDocument=engineUtils.camelCase(transpiler.salt);}else{// The GML requested to be ignored
}// Log.debug(gmlMarkup);
}if(paperMarkup){PaperXmlToSalt.xmlParser=serviceFactory.xmlParser;// not necessary to set this for server-side transpilation
const transpiler=new PaperXmlToSalt();PaperXmlToSalt.setClientIsXHA(BuildGlobals.isXHA());// This doesn't worku for server-side transpilation (not necessary)
const viewId=dialog.view.persistentId||dialog.view.id;transpiler.perform(paperMarkup,viewId,serviceFactory.notify.showError);if(transpiler.errorString){Log.error(transpiler.errorString);serviceFactory.notify.showError(transpiler.errorString);}else if(transpiler.salt){saltDocument=engineUtils.camelCase(transpiler.salt);}}// Check for attached salt and perform default generation if necessary
if(!saltDocument){if(view.type===TypeNames.FormTypeName||view.type===TypeNames.CalendarTypeName||view.type===TypeNames.DetailsTypeName||view.type===TypeNames.ListTypeName||view.type===TypeNames.BarcodeScanTypeName||view.type===TypeNames.GraphTypeName||view.type===TypeNames.MapTypeName||view.type===TypeNames.PowerBITypeName||view.type===TypeNames.ImagePickerTypeName||view.type===TypeNames.VizTypeName||view.type===TypeNames.NFCScanTypeName||view.type===TypeNames.GpsReadingTypeName){saltDocument=classicTranspiler.generateSalt(dialog);}else{Log.error("No salt transpiler set up for this view type: ".concat(view.type));}}return saltDocument;}}const transpiler=new Transpiler();export default transpiler;