import { Log, setCvStorageApi, Catavolt, FetchClient } from 'cv-dialog-sdk';

// eslint-disable-next-line no-undef
// eslint-disable-next-line prefer-destructuring
const env = process.env;
// set log level from config
Log.setLevelByName(env.REACT_APP_LOG_LEVEL);
// Set a filter from config
Log.setRegExFilter(env.REACT_APP_LOG_REGEX, env.REACT_APP_LOG_REGEX_FLAGS);
// turn this on to debug log every DialogService method call
// Catavolt.initDebugDialogApi()
setCvStorageApi(localStorage);

const dialogServerUrl = env.REACT_APP_DIALOG_SERVER_URL;

const serverUrl = dialogServerUrl && dialogServerUrl !== '' ? dialogServerUrl : `${window.location.protocol}//${window.location.host}`;
Catavolt.setDefaultServerUrl(serverUrl);
// note: we turn off offline for html by using FetchClient directly
Catavolt.initDialogApiWithClient(new FetchClient());
