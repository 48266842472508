import { rootStore } from 'cv-react-core';
import recursiveRouteManager from './RecursiveRouteManager';

export default class Navigator {
    constructor(props) {
        const {
            history,
            location,
            match,
        } = props;
        this.history = history;
        this.location = location;
        this.match = match;

        const { sessionStore } = rootStore;
        const { params: { workbenchId } } = this.match;
        if (workbenchId) sessionStore.setSelectedWorkbenchById(workbenchId);
        recursiveRouteManager.addRoute(match, location);
    }
}