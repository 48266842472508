import React from 'react';
import { BaseComponentBuilder } from 'cv-react-core';
import { TypeNames } from 'cv-dialog-sdk';
import TextField from '../../components/base/TextField';
import PasswordField from '../../components/base/PasswordField';
import XMLEditor from '../../components/forms/XMLEditor';


/**
 * A builder for creating text input field components
 */
class TextFieldComponentBuilder extends BaseComponentBuilder {
    /**
     * COMPONENT BASED PROPERTIES
     */

    /** Begin Context Styles */
    setInputStyles(inputStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            input: inputStyles,
        });
    }
    getInputStyles() {
        const { input } = this.getContextStyles();
        return input || {};
    }
    setUnderlineStyles(underlineStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            underline: underlineStyles,
            underlineFocus: underlineStyles,
            underlineHover: underlineStyles,
        });
    }
    getUnderlineStyles() {
        const { underline } = this.getContextStyles();
        return underline || {};
    }
    /** End Context Styles */


    /** Begin Props */
    setAutoFocus(autoFocus = true) {
        this.props.autoFocus = autoFocus;
        return this;
    }
    getAutoFocus() {
        return this.props.autoFocus;
    }

    setAutoGrow(autoGrow = true) {
        this.props.autoGrow = autoGrow;
        return this;
    }
    getAutoGrow() {
        return this.props.autoGrow;
    }

    setDisabled(disabled = true) {
        this.props.disabled = disabled;
        return this;
    }
    getDisabled() {
        return this.props.disabled;
    }

    setMaxLength(maxLength) {
        if (typeof maxLength === 'number' && maxLength >= 0) {
            this.props.maxLength = maxLength;
        }
        return this;
    }
    getMaxLength() {
        return this.props.maxLength;
    }

    setMultiLine(multiline = true) {
        this.props.multiline = multiline;
        return this;
    }
    getMultiLine() {
        return this.props.multiline;
    }

    setOnChangeTextHandler(onChange) {
        if (typeof onChange === 'function') {
            this.props.onChange = onChange;
        }
        return this;
    }
    getOnChangeTextHandler() {
        return this.props.onChange;
    }

    // TODO: check
    // setPlaceholder(placeholder) {
    //     if (typeof placeholder === 'string') {
    //         this.props.placeholder = placeholder;
    //     }
    //     return this;
    // }
    // getPlaceholder() {
    //     return this.props.placeholder;
    // }

    setMaxRows(maxRows) {
        this.props.rowsMax = maxRows;
        return this;
    }
    getMaxRows() {
        return this.props.rowsMax;
    }

    setTextType() {
        this.props.type = 'text';
        return this;
    }
    setPasswordType() {
        this.props.type = 'password';
        return this;
    }
    setEmailType() {
        this.props.type = 'email';
        return this;
    }
    setNumberType() {
        this.props.type = 'number';
        return this;
    }
    setFormatXmlAction(formatXmlAction) {
        this.props.formatXmlAction = formatXmlAction;
        return this;
    }
    getFormatXmlAction() {
        return this.props.formatXmlAction;
    }
    setIconOffsetWidth() {
        this.props.iconOffsetWidth = 15;
        return this;
    }
    getType() {
        return this.props.type;
    }

    setText(value) {
        if (typeof value === 'string') {
            this.props.value = value;
        }
        else if (value === null){
            this.props.value = '';
        }
        return this;
    }
    getText() {
        return this.props.value;
    }

    setFullWidth(value = false) {
        this.props.fullWidth = value;
    }
    getFullWidth() {
        return this.props.fullWidth;
    }

    setVariant(value) {
        this.props.variant = value;
        return this;
    }

    setRows(value) {
        if (value) {
            this.props.rows = value;
        }
        return this;
    }

    /** End Props */

    validateInput(propertyValue) {
        const propDef = this.getPropDef();
        let regExpPattren = '';
        if (propDef.isColorPickerType) {
            regExpPattren = /^#[0-9a-f]{0,6}$/i;
        }
        else if (propDef.isNumericType) {
            // Modified logic to look for length vaidation from propertyDef.
            // And we will be removing validation on displayLength once every validation is passed through Length property.
            let displayLength = propDef.length || propDef.displayLength;
            if (displayLength) {
                // Display length is for actual digits.
                // If we have any special character (+, - or .), increment display length by 1 so that actual digits will get the provided length.
                if (propertyValue.match(/[+-.]/)) {
                    displayLength += 1;
                }
                regExpPattren = `^[0-9+-.]{0,${ displayLength }}$`;
            }
        }
        if (regExpPattren && propertyValue){
            const validationPatternExp = new RegExp(regExpPattren);
            return !!validationPatternExp.test(propertyValue);
        }
        return true;
    }

    /**
     * PROCESS AND UPDATE COMPONENT PROPERTIES
     */

    processDisplay() {
        const property = this.getProperty();
        const propDef = this.getPropDef();
        const stagedValue = this.getStagedValue();
        const textValue = this.resolveTextValue(stagedValue, property, propDef);
        this.setText(textValue);
    }

    processStyle() {
        const propDef = this.getPropDef();
        const viewType = this.getViewType();
        const categorizedStyles = BaseComponentBuilder.styleHelper.categorizeWebStyles(this.getStyle());

        this.setContainerStyles({
            ...categorizedStyles.container,
            ...this.getContainerStyles(),
        });

        // This is a temporary solution for textblock styles in master branch. This will not be of any use in the latest UX.
        const multilineStyles = (propDef.isTextBlock || propDef.isMultiLineText) && viewType === TypeNames.ListTypeName ? {
            minWidth: '400px',
        } : {};

        this.setInputStyles({
            minWidth: '160px',
            ...multilineStyles,
            ...categorizedStyles.text,
            ...this.getInputStyles(),
        });
        this.setUnderlineStyles({
            ...categorizedStyles.underline,
            ...this.getUnderlineStyles(),
        });
        // We will default variant to outlined.
        this.setVariant('filled');
    }

    processChangeHandler() {
        const onValueChangeHandler = this.getOnValueChangeHandler();
        const isReadMode = this.getReadMode();
        const property = this.getProperty();
        const { name: propertyName = '' } = property;


        if (!isReadMode && !!propertyName && !!onValueChangeHandler) {
            this.setOnChangeTextHandler((event) => {
                const propertyValue = event.target.value;
                if (propertyValue) {
                    if (this.validateInput(propertyValue)) {
                        onValueChangeHandler(propertyName, propertyValue);
                    }
                }
                else {
                    onValueChangeHandler(propertyName, propertyValue);
                }
            });
        }
    }

    processInputProps() {
        const viewDef = this.getViewDef();
        const { actions = [] } = viewDef;
        const propDef = this.getPropDef();
        const isFocused = this.getFocusedState();
        this.setFullWidth(false);

        if (isFocused) { this.setAutoFocus(isFocused); }

        // Modified logic to look for length vaidation from propertyDef.
        // And we will be removing validation on displayLength once every validation is passed through Length property.
        const displayLength = propDef.length || propDef.displayLength;
        if (displayLength) { this.setMaxLength(displayLength); }
        if (propDef.isEmailType) { this.setEmailType(); }
        if (propDef.isNumericType) {
            this.setNumberType();
            this.setIconOffsetWidth();
        }
        if (propDef.isPasswordType) { this.setPasswordType(); }
        // This is sizing the control to the content.  Without it, entry fields are sized to
        // a random fixed with and large fields are chopped when they should not be.
        const { autoGrow, input } = this.getContextStyles();
        const { rows, variant } = input;
        if (variant) {
            this.setVariant(variant);
        }
        if (propDef.isTextBlock || propDef.isMultiLineText) {
            this.setMultiLine();
            this.setRows(rows);
        }

        const autoGrowAdjusted = autoGrow === undefined ? true : autoGrow;
        if (!propDef.isTextBlock && autoGrowAdjusted) { // TextBlocks are a mess with autoGrow
            this.setAutoGrow();
        }

        const formalXmlAction = actions.find((action) => {
            const { clientFunction } = action;
            return clientFunction === 'FORMAT_XML';
        });
        if (formalXmlAction) {
            this.setFormatXmlAction(formalXmlAction);
        }
    }

    buildProps() {
        // Update the display text
        this.processDisplay();

        // Update the input style
        this.processStyle();

        // Set input properties based on dialog data
        this.processInputProps();

        // Update change handler to support property / value data
        this.processChangeHandler();
    }


    /**
     * BUILD AND RETURN COMPONENT AS REACT ELEMENT
     */

    build() {
        // Process and construct final props
        this.buildProps();
        const containerStyle = {
            display: 'flex',
            flexShrink: 1,
            flexGrow: 1,
            overflow: 'auto',
        };
        if (this.getType() === 'password') {
            return (
                <div
                    className="div-password-text_field_component_builder"
                    style={ containerStyle }>
                    <PasswordField { ...this.getProps() } />
                </div>
            );
        }

        // If we have XML action, return XML editor
        if (this.getFormatXmlAction()) {
            return <XMLEditor { ...this.getProps() } />;
        }

        // Build and return component as React element
        return (
            <TextField { ...this.getProps() } />
        );
    }
}
export default TextFieldComponentBuilder;