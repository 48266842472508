import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { constants, loginController, serviceFactory } from 'cv-react-core';
import { Log } from 'cv-dialog-sdk';
import Layout from '../../layouts/BasePage/BasePage';
import LoginForm from '../../base/LoginForm';

import ChangePassword from './ChangePassword';
import PasswordExpiryPrompt from './PasswordExpiryPrompt';

import getStyles from './LoginPage.styles';

const {
    session,
    ui,
    settings,
} = constants;

const {
    PASSWORD,
    SAVE_PASSWORD,
    SHOW_PASSWORD,
    USER_ID,
} = session;
const {
    TENANT_CAPABILITIES,
} = settings;
const {
    APPLICATION_UI_ID,
    LOGIN_IN_PROGRESS,
} = ui;

// TODO: this components logic can be lifted to the LoginRoute component and most likely converted to a library functional component for improved performance
@observer
class LoginPage extends Component {
    static defaultProps = {
        contextStyles: {},
        sessionStore: null,
        onPostLogin: () => {},
    };

    static propTypes = {
        contextStyles: PropTypes.shape({
            container: PropTypes.object,
        }),
        sessionStore: PropTypes.object,
        settingsStore: PropTypes.object,
        themeStore: PropTypes.object,
        uiStore: PropTypes.object,
        onPostLogin: PropTypes.func,
    };

    render() {
        const {
            contextStyles,
            sessionStore,
            uiStore,
            themeStore,
            settingsStore,
        } = this.props;
        const {
            credentials,
            passwordExpiryInXDays,
        } = sessionStore;
        const tenantCapabilities = settingsStore.getValue(TENANT_CAPABILITIES);
        const showSavePassword = tenantCapabilities ? tenantCapabilities.savePasswordAllowed : true;
        // Since the LoginForm requires userid and password we have to set an empty string
        const userName = credentials.get(USER_ID) ? credentials.get(USER_ID) : '';
        const password = credentials.get(PASSWORD) ? credentials.get(PASSWORD) : '';
        const savePassword = credentials.get(SAVE_PASSWORD) ? credentials.get(SAVE_PASSWORD) : false;

        const {
            getErrorsForUIObject,
            getValueForUIObject,
        } = uiStore;

        const styles = getStyles(contextStyles);
        const isLoggingIn = getValueForUIObject(APPLICATION_UI_ID, LOGIN_IN_PROGRESS);

        // Generate error object
        let err = null;
        if (getErrorsForUIObject(APPLICATION_UI_ID).length) {
            err = getErrorsForUIObject(APPLICATION_UI_ID)[0].msg;
            serviceFactory.notify.showError(err);
        }

        return (
            <Layout
                className="p-login-page__layout"
                contextStyles={ {
                    container: styles.container,
                } }>
                <div
                    style={ {
                        display: 'flex',
                        justifyContent: 'center',
                        padding: '4rem',
                    } }>
                    <LoginForm
                        contextStyles={ { textLabel: styles.textLabel } }
                        appLogo={ themeStore.appLogo }
                        bannerLogo={ themeStore.bannerLogo }
                        isLoggingIn={ isLoggingIn }
                        onLoginPress={ this.handleLoginPress }
                        onPasswordChange={ this.handlePasswordChange }
                        onSavePasswordChange={ this.handleSavePasswordChange }
                        onShowPasswordChange={ this.handleShowPasswordChange }
                        onUserNameChange={ this.handleUserNameChange }
                        password={ password }
                        savePassword={ !!savePassword }
                        showPassword={ !!credentials.get(SHOW_PASSWORD) }
                        userName={ userName }
                        showSavePassword={ showSavePassword } />
                </div>
                <PasswordExpiryPrompt
                    sessionStore={ sessionStore }
                    passwordExpiryInXDays={ passwordExpiryInXDays }
                    uiStore={ uiStore }
                    settingsStore={ settingsStore } />

                <ChangePassword
                    sessionStore={ sessionStore }
                    passwordExpiryInXDays={ passwordExpiryInXDays }
                    uiStore={ uiStore }
                    settingsStore={ settingsStore } />

            </Layout>
        );
    }

    componentDidMount() {
        const {
            uiStore,
        } = this.props;
        loginController.clearGloballyBusy(uiStore);
    }

    handleLoginPress = () => {
        const {
            sessionStore,
            settingsStore,
            uiStore,
            onPostLogin,
        } = this.props;

        serviceFactory.notify.dismissAllToasts(); // On login press, dismissing toasts on the screen.
        loginController.handleLoginPress(sessionStore, settingsStore, uiStore, this.getDeviceProps(), onPostLogin, 'DESKTOP')
            .catch((err) => {
                Log.error('Failed to log in.');
                Log.error(err.message);
                Log.error(err.stack);
            });
    };

    handlePasswordChange = (event) => {
        const { sessionStore, uiStore } = this.props;
        uiStore.clearErrorsForUIObject(constants.ui.APPLICATION_UI_ID);
        const { currentTarget } = event;
        const { value } = currentTarget;
        loginController.handlePasswordTextChange(sessionStore, value);
    };

    handleSavePasswordChange = (event) => {
        const { target } = event;
        const { checked } = target;
        const { sessionStore, uiStore } = this.props;
        uiStore.clearErrorsForUIObject(constants.ui.APPLICATION_UI_ID);
        loginController.handleSavePasswordToggle(sessionStore, checked);
    };

    handleShowPasswordChange = (event) => {
        const { target } = event;
        const { checked } = target;
        const { sessionStore, uiStore } = this.props;
        uiStore.clearErrorsForUIObject(constants.ui.APPLICATION_UI_ID);
        loginController.handleShowPasswordToggle(sessionStore, checked);
    };

    handleUserNameChange = (event) => {
        const { sessionStore, uiStore } = this.props;
        uiStore.clearErrorsForUIObject(constants.ui.APPLICATION_UI_ID);
        const { currentTarget } = event;
        const { value } = currentTarget;
        loginController.handleUserNameTextChange(sessionStore, value);
    };

    getDeviceProps = () => {
        const { uiStore } = this.props;
        return serviceFactory.device.getDeviceProps(uiStore, serviceFactory.device.deviceSize);
    };
}

export default LoginPage;
