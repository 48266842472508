// import { rootStore } from 'cv-react-core';

const getTheme = () => {
    // const { themeStore } = rootStore;
    // const theme = themeStore.getSanitizedTheme();

    // Populate the component parts with theme styles
    return {
        appBar: {
        },

        container: {
        },

        indicator: {
        },

        selectedTab: {
            // For a 'selected' tab
            // '&.Mui-selected': {
            //     color: '#0f0',
            // },
        },

        tab: {
        },

        tabs: {
        },
    };
};

export default getTheme;
