import { rootStore } from 'cv-react-core';

const getTheme = () => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    // Populate the component parts with theme styles
    return {
        container: {
            backgroundColor: theme.drawerMenuItemBackgroundColor,
            color: theme.drawerMenuItemTextColor,
            fontSize: theme.drawerMenuItemTextSize,
            fontWeight: theme.drawerMenuItemFontWeight,
            fontFamily: theme.drawerMenuItemFontFamily,
            borderColor: theme.drawerMenuItemBorderColor,
            borderWidth: theme.drawerMenuBorderWidth,
        },

        focusContainer: {
            backgroundColor: theme.fonts.menu.color,
            color: theme.menuBackgroundColor,
        },

        selected: {
            borderLeftColor: theme.fonts.menu.color,
            color: theme.drawerMenuItemActiveTextColor,
            backgroundColor: `${theme.drawerMenuItemActiveBackgroundColor} !important`,
        },
    };
};

export default getTheme;
