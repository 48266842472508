/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
import { DeTableHeaderRowCell } from 'cv-library-react-web';

import getStyles from './TableHeaderCell.styles';

// TODO: Examples to look at: https://devexpress.github.io/devextreme-reactive/react/grid/docs/guides/column-resizing/#resizing-modes

class LoadingTableHeaderCell extends PureComponent {
    static propTypes = {
        style: PropTypes.object,
        column: PropTypes.object,
    };

    static defaultProps = {
        style: {},
    };

    render() {
        const {
            column,
            style: baseStyle,
            children,
            ...restProps } = this.props;

        const style = getStyles(baseStyle);
        const tableHeaderRowCellProps = {
            style,
            column,
            ...restProps,
        };
        const childrenWithProps = React.Children.map(children, (child) => {
            // checking isValidElement is the safe way and avoids a typescript error too
            if (React.isValidElement(child)) React.cloneElement(child, { ...tableHeaderRowCellProps });
            return child;
          });
        return React.createElement(DeTableHeaderRowCell, { ...tableHeaderRowCellProps }, childrenWithProps);
    }
}

export default LoadingTableHeaderCell;
