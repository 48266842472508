import React from 'react';
import * as PropTypes from 'prop-types';
import { constants } from 'cv-react-core';
import { GridForm as GridFormBase } from 'cv-library-react-web';

import getStyles from './GridForm.styles';

const GridForm = (props) => {
    const {
        formElements,
        ...rest
    } = props;
    const styles = getStyles();
    const { ui } = constants;

    return (
        <GridFormBase
            contextStyles={ styles }
            formElements={ formElements }
            scrollIdentifier={ ui.SCROLL_CONTAINER }
            { ...rest } />
    );
};

GridForm.propTypes = {
    formElements: PropTypes.arrayOf(PropTypes.element),
};

GridForm.defaultProps = {
    formElements: [],
};


export default GridForm;
