import React from 'react';
import * as PropTypes from 'prop-types';
import styleHelper from 'cv-react-core/src/utilities/styleHelper';
import RWSaltComponent from './RWSaltComponent';
import TextLabel from '../components/base/TextLabel';

export default class RWText extends RWSaltComponent {
    static propTypes = {
        style: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.arrayOf(PropTypes.object),
        ]),
        params: PropTypes.shape({
            onPlatformNodeEvent: PropTypes.func,
        }),
    };

    render() {
        const {
            style,
            xStyle,
            children,
        } = this.props;

        // Combine style arrays
        let combinedStyles = {};
        if (Array.isArray(style)) {
            style.forEach((styleObj) => {
                combinedStyles = {
                    ...combinedStyles,
                    ...styleObj,
                };
            });
        }
        else {
            combinedStyles = style;
        }

        // Log.debug('PLATFORM COMPONENT: TEXT: RENDER: ', this.props);

        const {
            container: containerStyles,
            text: textStyles,
        } = styleHelper.categorizeWebStyles(combinedStyles);

        // numberOfLines should be a style prop, and is in native. Handle directly.
        const directProps = {};
        if (combinedStyles.numberOfLines) {
            directProps.numberOfLines = combinedStyles.numberOfLines;
        }

        const inputContextContainer = xStyle && xStyle.container || {};
        const inputContextText = xStyle && xStyle.text || {};

        // Generate text label props
        const textLabelProps = {
            contextStyles: {
                container: {
                    ...containerStyles,
                    ...inputContextContainer,
                },
                text: {
                    ...textStyles,
                    ...inputContextText,
                },
            },
            ...directProps,
        };

        // Log.debug('PLATFORM COMPONENT: TEXT: PROPS: ', textLabelProps);

        return (
            <TextLabel { ...textLabelProps }>
                { children }
            </TextLabel>
        );
    }
}
