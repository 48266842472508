/* eslint-disable react/destructuring-assignment */
import { Log } from 'cv-dialog-sdk';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class ErrorBoundary extends Component {
    static propTypes = {
        errorMessage: PropTypes.string,
        children: PropTypes.oneOfType([
            PropTypes.element,
            PropTypes.arrayOf(PropTypes.element),
        ]),
    };
    constructor(props) {
        super(props);
        this.state = { error: false };
    }

    render() {
        if (this.state.error) {
            return this.renderError(this.state.error);
        }
        return this.props.children;
    }

    renderError(error) {
        const { errorMessage } = this.props;
        return (
            <div style={ { flexDirection: 'column', padding: 20 } }>
                <h1>
                    { errorMessage }
                </h1>
                <h2>
                    { error.message }
                </h2>
                <pre>
                    { error.stack }
                </pre>
            </div>
        );
    }

    componentDidCatch(error, info) {
        this.setState({ error });
        Log.error(error, info);
    }
}
