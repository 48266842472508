import routeNames from './routeNames';
import DialogRoute from './DialogRoute';
import WorkbenchRoute from './WorkbenchRoute';
import LoginRoute from './LoginRoute';
import SettingsRoute from './SettingsRoute';
import AboutRoute from './AboutRoute';

const routeComponents = new Map();
routeComponents
    .set(routeNames.LOGIN, LoginRoute)
    .set(routeNames.SETTINGS, SettingsRoute)
    .set(routeNames.WORKBENCH, WorkbenchRoute)
    .set(routeNames.DIALOG, DialogRoute)
    .set(routeNames.ABOUT, AboutRoute);

// eslint-disable-next-line import/prefer-default-export
export const getRouteComponent = (routeName) => (routeComponents.get(routeName));