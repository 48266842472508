import React, { PureComponent, Fragment } from 'react';
import { observer } from 'mobx-react';
import * as PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { constants, serviceFactory } from 'cv-react-core';
import { ERROR_TYPES } from 'cv-library-react-web';

import ErrorModal from '../base/ErrorModal';
import IconButton from '../base/IconButton';
import Button from '../base/Button';
import routeNames from '../../routing/routeNames';

const { error: { errorTypes, MODAL_STATE }, ui } = constants;
const { MODAL_DIALOG_ID, MODAL_DIALOG_STORE_INFO } = ui;

const { hard, soft } = errorTypes;
const { lang } = serviceFactory;
const { WORKBENCH } = routeNames;
// Keeping this local for now as it is specific to this control.
const MODAL_IS_EXPANDED = 'MODAL_IS_EXPANDED';

@observer
class ErrorRouteModal extends PureComponent {
    static propTypes = {
        objectId: PropTypes.string,
        uiStore: PropTypes.object,
        history: PropTypes.object,
        match: PropTypes.object,
    };

    constructor(props) {
        super(props);
        this.handleCloseWindow = this.handleCloseWindow.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);
        this.handleModalExpand = this.handleModalExpand.bind(this);
    }
    render() {
        const { objectId, uiStore } = this.props;
        const modalState = uiStore.getValueForUIObject(objectId, MODAL_STATE);
        const isModalExpanded = uiStore.getValueForUIObject(objectId, MODAL_IS_EXPANDED) || false;
        const {
            isModalOpen,
            errors,
            isCloseWindow,
        } = modalState || { isModalOpen: false, errors: [] };
        const errorList = this.getErrors(errors);
        return (
            <ErrorModal
                errorType={ ERROR_TYPES.ERROR }
                isModalOpen={ isModalOpen }
                isModalExpanded={ isModalExpanded }
                title={ isCloseWindow ? 'Close Browser Tab' : 'Errors' }
                actions={
                    <Fragment>
                        <IconButton
                            iconName={ isModalExpanded ? 'expand_less' : 'expand_more' }
                            onClick={ this.handleModalExpand } />
                        <Button
                            text="Close"
                            onClick={ isCloseWindow ? this.handleCloseWindow : this.handleModalClose } />
                    </Fragment>
                }
                errors={ errorList && errorList.hard.length > 0 ? errorList.hard : errorList.soft } />
        );
    }

    isEmpty = (str) => str && !str.trim();

    getErrors = (errors) => {
        const errorList = {
            hard: [],
            soft: [],
        };

        if (!errors) return errorList;

        // Gather errors by type for display
        errors.forEach((error) => { /* eslint-disable no-param-reassign */
            const {
                type,
            } = error;
            if (type === soft) {
                if (this.isEmpty(error.title)) {
                    error.title = lang.errors.noMessageTitle;
                }
                if (error.err && this.isEmpty(error.err.message)) {
                    error.err.message = lang.errors.noMessageText;
                }
                if (error.err && this.isEmpty(error.err.stackTrace)) {
                    error.err.stackTrace = lang.errors.noStacktrace;
                }
                errorList.soft.push(error);
            }
            else if (type === hard) {
                errorList.hard.push(error);
            }
        });

        return errorList;
    };

    handleModalExpand() {
        const { objectId, uiStore } = this.props;
        const modalIsExpanded = uiStore.getValueForUIObject(objectId, MODAL_IS_EXPANDED) || false;
        uiStore.setValueForUIObject(objectId, MODAL_IS_EXPANDED, !modalIsExpanded);
    }

    handleCloseWindow() {
        // Closing the browser tab since we dont have any history entry to goBack to prior dialog,
        // And this is most possibly expected when dialog is opened in new tab
        const { objectId, uiStore } = this.props;
        uiStore.clearErrorsForUIObject(objectId);
        if (window.close) window.close();
    }

    handleModalClose() {
        const {
            objectId,
            uiStore,
            match } = this.props;
        const modalState = uiStore.getValueForUIObject(objectId, MODAL_STATE);
        if (!modalState) return;
        const { errors } = modalState;
        const errorList = this.getErrors(errors);
        uiStore.clearErrorsForUIObject(objectId);
        uiStore.removeValueForUIObject(objectId, MODAL_STATE);
        uiStore.removeValueForUIObject(objectId, MODAL_IS_EXPANDED);

        const { params } = match;
        const { route } = params;
        if (errorList && errorList.hard.length > 0 && route !== WORKBENCH) {
            // hard errors need to 'go back' to page prior to error
            const { history } = this.props;
            uiStore.removeValueForUIObject(MODAL_DIALOG_ID, MODAL_DIALOG_STORE_INFO);
            if (history) history.goBack();
        }
    }
}

export default withRouter(ErrorRouteModal);
