import _defineProperty from"/home/vsts/work/1/s/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";import TimerMixin from'react-timer-mixin';export default class RefreshTimer{/** Create a timer with the dialog store that contains the view with the refresh timer interval.
     * @param {object} dialogStore
    */constructor(dialogStore){_defineProperty(this,"timer",undefined);_defineProperty(this,"refreshTimerInMillis",0);_defineProperty(this,"onRefresh",undefined);const{dialog}=dialogStore;const{view}=dialog;const{refreshTimerInMillis}=view;if(refreshTimerInMillis){this.timerRefreshInterval=refreshTimerInMillis;}}get timerRefreshInterval(){return this.refreshTimerInMillis;}set timerRefreshInterval(timerRefreshRate){if(timerRefreshRate===void 0){timerRefreshRate=0;}this.refreshTimerInMillis=timerRefreshRate;}/**
     * This method allows you to stop and start the refresh timer.
     * @param { boolean } pause - Pause the refresh timer.
     * this method need to be modified further as per requirment, its just a placeholder right now
     */pause(){let pause=arguments.length>0&&arguments[0]!==undefined?arguments[0]:true;// If we need to pause just destroy the timer if we want to
// restart we can just create a new timer.
if(pause){TimerMixin.clearInterval(this.timer);this.timer=undefined;}else{this.start();}}/**
     * Destroy the refresh timer when we blur or when we unmount to make sure timer is gone.
     * @param {*} timerRef - instance of timer interval.
     */destroy(){// If we have a timer clear it
if(this.timer){TimerMixin.clearInterval(this.timer);this.timer=undefined;}if(this.disposer)this.disposer();}/** Start the timer process
    * @param {*} onRefresh - function to be executed on every interval.
    */start(dialogStore){// If the refresh timer is set to zero it means it is disabled.
if(this.timerRefreshInterval>0&&this.onRefresh){this.timer=TimerMixin.setInterval(()=>{if(!dialogStore.isDestroyed){dialogStore.setRefreshTriggerByTimer(true);this.onRefresh();}},this.timerRefreshInterval);}}}