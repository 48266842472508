import { Component } from 'react';
import * as PropTypes from 'prop-types';
import { DialogComponentBuilder } from 'cv-react-core';

export default class RWProperty extends Component {
    static propTypes = {
        isReadMode: PropTypes.bool,
        property: PropTypes.object,
        propertyDef: PropTypes.object,
        propertyRef: PropTypes.object,

        // This is either a cellValue (Details) or a column (List)
        viewDef: PropTypes.object,
        record: PropTypes.object,
        focusPropertyName: PropTypes.string,
        annotationMode: PropTypes.string,
        onFieldAction: PropTypes.func,
        onValueChanged: PropTypes.func,
        asyncDataCallback: PropTypes.func,
        availableValues: PropTypes.func,
        availableValuesLoader: PropTypes.func,
        style: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.arrayOf(PropTypes.object),
        ]),
        toolTip: PropTypes.string,
        xStyle: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.arrayOf(PropTypes.object),
        ]),
        viewType: PropTypes.string,
        firstDayofWeek: PropTypes.number,
    };

    constructor(props) {
        super(props);
        this.dialogComponentBuilder = new DialogComponentBuilder();
    }

    render() {
        return this.buildComponent();
    }

    buildComponent() {
        const {
            isReadMode,
            property,
            propertyDef,
            propertyRef,
            viewDef,
            record,
            focusPropertyName,
            annotationMode,
            onFieldAction,
            onValueChanged,
            availableValues,
            availableValuesLoader,
            asyncDataCallback,
            viewType,
            style,
            toolTip,
            xStyle,
            firstDayofWeek,
        } = this.props;
        const isFocusedState = focusPropertyName === property.name;

        // console.log('RW_PROPERTY: ', property, xStyle); // eslint-disable-line

        this.dialogComponentBuilder.reset();
        this.dialogComponentBuilder
            .setAsyncDataCallback(asyncDataCallback)
            .setViewType(viewType)
            .setReadMode(!!isReadMode)
            .setRecord(record)
            .setProperty(property)
            .setPropDef(propertyDef)
            .setPropertyRef(propertyRef)
            .setViewDef(viewDef)
            .setFocusedState(isFocusedState)
            .setEnabledAnnotations(annotationMode)
            .setActionOnPressHandler(onFieldAction)
            .setStyle(style)
            .setContextStyles(xStyle)
            .setToolTip(toolTip)
            .setFirstDayofWeek(firstDayofWeek);

        if (!isReadMode) {
            this.dialogComponentBuilder.setOnValueChangeHandler(onValueChanged);
            if (viewDef && (viewDef.isDropDownEntryMethod || viewDef.isComboBoxEntryMethod || viewDef.isIconEntryMethod || viewDef.autoFillCapable)) {
                this.dialogComponentBuilder.setAvailableValuesLoader(availableValuesLoader);
                this.dialogComponentBuilder.setAvailableValues(availableValues);
            }
        }

        return this.dialogComponentBuilder.build();
    }
}
