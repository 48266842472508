import _defineProperty from"/home/vsts/work/1/s/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";import{constants}from'../constants';import serviceFactory from'../services/serviceFactory';const{ui:{LIST_ROWS_LOADING}}=constants;const onCatchError=_ref=>{let{listDialogStore,error,onError}=_ref;listDialogStore.setSearchFormOpen(false);const title=serviceFactory.lang.formatString(serviceFactory.lang.dialog.errors.failedToOpenSearchDialog,listDialogStore.dialog.id);onError({title,err:error,type:constants.error.errorTypes.soft});};class SearchController{constructor(){_defineProperty(this,"openQuickSearch",_ref2=>{let{listDialogStore,onError}=_ref2;const catchError=error=>{onCatchError({listDialogStore,error,onError});};if(listDialogStore.getIsLoadingSearchStore())return null;return listDialogStore.getOrOpenSearchDialogFormStore().then(searchFormDialogStore=>{const searchDialogStore=searchFormDialogStore.firstChildStore;return searchDialogStore.readRecord().then(()=>{return searchDialogStore;}).catch(catchError);}).catch(catchError);});_defineProperty(this,"openSearchForm",_ref3=>{let{listDialogStore,onError}=_ref3;const catchError=error=>{onCatchError({listDialogStore,error,onError});};if(listDialogStore.getIsLoadingSearchStore())return Promise.resolve();return listDialogStore.getOrOpenSearchDialogFormStore().then(searchFormDialogStore=>{const searchDialogStore=searchFormDialogStore.firstChildStore;return searchDialogStore.readRecord().then(()=>{listDialogStore.setSearchFormOpen(true);return searchDialogStore;}).catch(catchError);}).catch(catchError);});_defineProperty(this,"getSearchDialogStore",listDialogStore=>{return listDialogStore.searchDialogStore;});_defineProperty(this,"getSearchFormDialogStore",listDialogStore=>{return listDialogStore.searchDialogFormStore;});/**
     * Provide quick lookup for determining if property contains a filter value
     * @param {bool} propertyName
     */_defineProperty(this,"isPropertyNameFiltered",_ref4=>{let{listDialogStore,propertyName}=_ref4;const{searchDialogStore}=listDialogStore;if(!searchDialogStore)return false;const searchValue=searchDialogStore.getSearchValuePropertyForName(propertyName);return searchValue&&searchValue.value;});_defineProperty(this,"clearSearchValues",_ref5=>{let{listDialogStore}=_ref5;const{searchDialogStore}=listDialogStore;searchDialogStore.clearSearchValues();});_defineProperty(this,"clearSortValues",_ref6=>{let{listDialogStore}=_ref6;const{searchDialogStore}=listDialogStore;searchDialogStore.clearSortValues();});_defineProperty(this,"setSearchKeyword",_ref7=>{let{listDialogStore,keyword}=_ref7;const{searchDialogStore}=listDialogStore;searchDialogStore.setKeywordSearchValue(keyword);});_defineProperty(this,"setSortTerms",_ref8=>{let{listDialogStore,sortTerms}=_ref8;const{searchDialogStore}=listDialogStore;searchDialogStore.clearSortValues();sortTerms.forEach((e,i)=>{if(e.isAscending){searchDialogStore.setAscending(e.name,i);}else{searchDialogStore.setDescending(e.name,i);}});});_defineProperty(this,"getSortTerms",_ref9=>{let{listDialogStore}=_ref9;const{searchDialogStore,dialog}=listDialogStore;if(!searchDialogStore)return[];const values=searchDialogStore.sortValues;const builtValues=[];const{view}=dialog;const{columns}=view;if(values){values.forEach(e=>{const column=columns.find(c=>c.propertyName===propertyName);const{heading}=column;builtValues.push({name:e.name,label:heading||e.name,isAscending:e.isAscending,priority:e.priority});});}return builtValues;});_defineProperty(this,"submitSortTerms",_ref10=>{let{listDialogStore,sortTerms,onError,uiStore}=_ref10;// Temp hack until we can move the controls around in the grid to leverage mobx.
// Reference work in work item 19632: https://dev.azure.com/HexagonXalt/Xalt%20Mobility/_workitems/edit/19632
if(uiStore){const{dialog}=listDialogStore;uiStore.setValueForUIObject(dialog.id,LIST_ROWS_LOADING,true);}// ******************
this.setSortTerms({listDialogStore,sortTerms,onError});return this.submitChanges({listDialogStore,onError,uiStore});});_defineProperty(this,"submitChanges",_ref11=>{let{listDialogStore,onError,uiStore}=_ref11;const catchError=error=>{onCatchError({listDialogStore,error,onError});};const{searchDialogStore}=listDialogStore;if(searchDialogStore.submitInProgress)return searchDialogStore;searchDialogStore.setSubmitInProgress(true);// Temp hack until we can move the controls around in the grid to leverage mobx.
// Reference work in work item 19632: https://dev.azure.com/HexagonXalt/Xalt%20Mobility/_workitems/edit/19632
if(uiStore){const{dialog}=listDialogStore;uiStore.setValueForUIObject(dialog.id,LIST_ROWS_LOADING,true);}// ******************
return searchDialogStore.submitSearch().then(()=>{// After we submit the search we need to reopen for editing.
listDialogStore.setSearchFormOpen(false);return searchDialogStore.reopenSearch().then(dialogRedirection=>searchDialogStore.readRecord().then(()=>{listDialogStore.refreshList().then(()=>{searchDialogStore.setSubmitInProgress(false);// Temp hack until we can move the controls around in the grid to leverage mobx.
// Reference work in work item 19632: https://dev.azure.com/HexagonXalt/Xalt%20Mobility/_workitems/edit/19632
if(uiStore){const{dialog}=listDialogStore;uiStore.setValueForUIObject(dialog.id,LIST_ROWS_LOADING,false);}// ******************
}).catch(error=>{// We need to clear the search results to prevent a hard refresh
// from creating the same issue this is addressing. Even though we
// throw a hard error, if you refresh with the dialog up the same issue occurs.
searchDialogStore.clearAllValues();this.submitChanges({listDialogStore,onError});onCatchError({listDialogStore,error,onError});});return searchDialogStore;}).catch(catchError)).catch(catchError);}).catch(catchError);});}}const searchController=new SearchController();export default searchController;