import { BuildGlobals, constants } from 'cv-react-core';

/* eslint-disable class-methods-use-this */

class Device {
    get deviceSize() {
        const { innerWidth, innerHeight } = window;
        const device = {
            screenWidth: innerWidth,
            screenHeight: innerHeight,
        };

        // LANDSCAPE
        if (innerWidth > innerHeight) {
            device.orientation = constants.devices.ORIENTATION_LANDSCAPE;
            if (innerHeight < 560) {
                device.type = constants.devices.PHONE;
            }
            else if (innerHeight < 720) {
                device.type = constants.devices.TABLET_MEDIUM;
            }
            else if (innerHeight < 1080) {
                device.type = constants.devices.TABLET_LARGE;
            }
            else {
                device.type = constants.devices.TABLET_EXTRA_LARGE;
            }
        }
         else {
        // PORTRAIT
            device.orientation = constants.devices.ORIENTATION_PORTRAIT;
            if (innerHeight < 1024) {
                device.type = constants.devices.PHONE;
            }
            else if (innerHeight < 1366) {
                device.type = constants.devices.TABLET_MEDIUM;
            }
            else if (innerHeight < 1920) {
                device.type = constants.devices.TABLET_LARGE;
            }
            else {
                device.type = constants.devices.TABLET_EXTRA_LARGE;
            }
        }
        device.platform = constants.devices.PLATFORM_BROWSER_REACT;
        device.clientVersion = BuildGlobals.getClientReleaseVersion(); // need one of these

        return device;
    }

    getDeviceProps(uiStore, deviceSize) {
        const deviceProps = {};

        // Get device settings
        let deviceSettings = uiStore.getValueForUIObject(constants.ui.APPLICATION_UI_ID, constants.ui.DEVICE_PROPERTIES);

        // On logout we destroy the device information that is created at startup.
        // This will restore that information so the app can continue
        if (!deviceSettings) {
            const deviceProperties = {
                ...deviceSize,
            };
            uiStore.setValueForUIObject(constants.ui.APPLICATION_UI_ID, constants.ui.DEVICE_PROPERTIES, deviceProperties, false);
            deviceSettings = uiStore.getValueForUIObject(constants.ui.APPLICATION_UI_ID, constants.ui.DEVICE_PROPERTIES);
        }

        deviceProps.FormFactor = deviceSettings.type;
        deviceProps.DisplayHeight = deviceSettings.screenHeight;
        deviceProps.DisplayWidth = deviceSettings.screenWidth;
        deviceProps.platform = deviceSettings.platform;
        deviceProps.clientVersion = deviceSettings.clientVersion;

        deviceProps.GPSSupported = 'false';

        return deviceProps;
    }
}

const device = new Device();
export default device;
