/* eslint-disable no-unused-vars */
import { rootStore } from 'cv-react-core';

const TableHeaderLockedZIndex = 500;
const TableHeaderZIndex = 400;
/**
* Helper method to get the styles for Icon component
* @param {Object} contextStyles - Context styles for Icon Component
*/
const getStyles = (contextStyles = {}, isFiltered, isFixedColumn) => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();
    return {
            backgroundColor: isFiltered ? theme.dataTableHeadingBackgroundFilteredColor : theme.dataTableHeadingBackgroundColor,
            padding: '4px',
            borderRightWidth: 1,
            borderRightStyle: 'solid',
            borderRightColor: 'black',
            zIndex: isFixedColumn ? TableHeaderLockedZIndex : TableHeaderZIndex,
            ...contextStyles,
        };
};

export default getStyles;
