import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { constants, loginController, serviceFactory } from 'cv-react-core';
import { Log } from 'cv-dialog-sdk';

import Prompt from '../../base/Prompt';
import Button, { BUTTON_VARIANT } from '../../base/Button';
import TextLabel from '../../base/TextLabel';
import PasswordField from '../../base/PasswordField';
import lang from '../../../nls/i18n';

import getStyles from './ChangePassword.styles';

const {
    session,
} = constants;

const {
    CURRENT_PASSWORD,
    NEW_PASSWORD,
    CONFIRM_PASSWORD,
} = session;

// TODO: this components logic can be lifted to the LoginRoute component and most likely converted to a library functional component for improved performance
@observer
class ChangePassword extends Component {
    static defaultProps = {
        contextStyles: {},
    };

    static propTypes = {
        contextStyles: PropTypes.object,
        passwordExpiryInXDays: PropTypes.bool,
        sessionStore: PropTypes.object,
        settingsStore: PropTypes.object,
        uiStore: PropTypes.object,
    };

    render() {
        const {
            sessionStore,
            contextStyles,
            passwordExpiryInXDays,
        } = this.props;
        const {
            changeCredentials,
            changePasswordPrompt,
            passwordExpired,
        } = sessionStore;
        const styles = getStyles(contextStyles);

        // Since the ChangePassword prompt requires currentPassword, newPassword and newConfirmPassword we have to set an empty string
        const currentPassword = changeCredentials.get(CURRENT_PASSWORD) || '';
        const newPassword = changeCredentials.get(NEW_PASSWORD) || '';
        const newConfirmPassword = changeCredentials.get(CONFIRM_PASSWORD) || '';

        if ( ( passwordExpired || passwordExpiryInXDays ) && changePasswordPrompt ){
            return (
                <Prompt
                    actions={ [
                        <Button
                            key="Change"
                            onClick={ this.handleChangePasswordPress }
                            text="Change"
                            variant={ BUTTON_VARIANT.CONTAINED } />,
                        <Button
                            key="Cancel"
                            onClick={ this.handleCancelPress }
                            text="Cancel"
                            variant={ BUTTON_VARIANT.OUTLINED } />,
                    ] }
                    contextStyles={ {
                        actions: styles.buttonContainer,
                        divider: styles.modalDivider,
                        modal: styles.modalContainer,
                        title: styles.modalTitle,
                    } }
                    title={ lang.login.changePasswordTitle }>
                    <div>
                        <div style={ styles.passwordContainer }>
                            <TextLabel>Current Password</TextLabel>
                            <PasswordField
                                onChange={ (event) => this.handleChangePasswordFieldChange(event, constants.session.CURRENT_PASSWORD) }
                                value={ currentPassword }
                                autoComplete="current-password" />
                        </div>
                        <div style={ styles.passwordContainer }>
                            <TextLabel>New Password</TextLabel>
                            <PasswordField
                                onChange={ (event) => this.handleChangePasswordFieldChange(event, constants.session.NEW_PASSWORD) }
                                value={ newPassword }
                                autoComplete="new-password" />
                        </div>
                        <div style={ styles.passwordContainer }>
                            <TextLabel>Confirm New Password</TextLabel>
                            <PasswordField
                                onChange={ (event) => this.handleChangePasswordFieldChange(event, constants.session.CONFIRM_PASSWORD) }
                                value={ newConfirmPassword }
                                autoComplete="confirm-new-password" />
                        </div>
                    </div>
                </Prompt>
            );
        }

        return null;
    };

    handleChangePasswordPress = () => {
        const {
            sessionStore,
            settingsStore,
            uiStore,
        } = this.props;

        serviceFactory.notify.dismissAllToasts();
        const newPassword = sessionStore.changeCredentials.get(NEW_PASSWORD);
        const newConfirmPassword = sessionStore.changeCredentials.get(CONFIRM_PASSWORD);

        if (newPassword && newConfirmPassword && newPassword.localeCompare(newConfirmPassword) === 0){
            if (sessionStore.passwordExpired){
                sessionStore.setPasswordExpired(false);
                loginController.handleChangePasswordAndCreateSessionPress(sessionStore, settingsStore, uiStore, this.getDeviceProps(), this.handlePostLogin, 'DESKTOP')
                .catch((err) => {
                    Log.error('Failed to log in.');
                    Log.error(err.message);
                    Log.error(err.stack);
                });
            }
            else {
                loginController.handleChangePasswordPress(sessionStore, uiStore, this.handlePostLogin)
                .catch((err) => {
                    Log.error('Failed to log in.');
                    Log.error(err.message);
                    Log.error(err.stack);
                });
            }
        }
        else {
            uiStore.setValueForUIObject(constants.ui.APPLICATION_UI_ID, constants.ui.LOGIN_IN_PROGRESS, false, false);

            serviceFactory.notify.showError(lang.errors.passwordMisMatch);
        }
    }

    handleCancelPress = () => {
        this.getDeviceProps();
        const {
            sessionStore,
        } = this.props;

        sessionStore.changeCredentials.clear();

        if (sessionStore.passwordExpired){
            sessionStore.setPasswordExpired(false);
        }
        else if (sessionStore.passwordExpiryInXDays){
            sessionStore.setPasswordExpiryInXDays(false);
        }

        sessionStore.setChangePasswordPrompt(false);
    }

    handleChangePasswordFieldChange = (event, key) => {
        const { sessionStore, uiStore } = this.props;
        uiStore.clearErrorsForUIObject(constants.ui.APPLICATION_UI_ID);
        const { currentTarget } = event;
        const { value } = currentTarget;
        loginController.handleChangeCredentialTextChanges(sessionStore, value, key);
    }

    getDeviceProps = () => {
        const { uiStore } = this.props;
        return serviceFactory.device.getDeviceProps(uiStore, serviceFactory.device.deviceSize);
    };
}

export default ChangePassword;
