import React, { Component } from 'react';
import * as PropTypes from 'prop-types';

import CvImagePicker from '../components/imagePicker/CvImagePicker';

class RWImagePicker extends Component {
    static propTypes = {
        ...CvImagePicker.propTypes,

        xStyle: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.array,
        ]),
    };
    static defaultProps = {
        ...CvImagePicker.defaultProps,
        xStyle: {},
    };

    render() {
        const {
            xStyle,
            ...rest
        } = this.props;

        // Combine style arrays
        const combine = (style) => {
            let result = {};
            if (Array.isArray(style)) {
                style.forEach((styleObj) => {
                    result = {
                        ...result,
                        ...styleObj,
                    };
                });
            }
            else {
                result = style || {};
            }
            return result;
        };

        const contextStyles = combine(xStyle);
        return (
            <CvImagePicker
                { ...rest }
                contextStyles={ contextStyles }
                onChooseItem={ this.handleChooseItem } />
        );
    }

    /**
     * Helper method to update the current selected record
     * @param {Number} currentIndex
     */
    handleChooseItem = (currentIndex) => {
        const {
            items,
            onChooseItem,
        } = this.props;
        const record = items[currentIndex];

        if (onChooseItem && record) {
            onChooseItem(record.id, currentIndex);
        }
    }
};

export default RWImagePicker;
