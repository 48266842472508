import { engineUtils } from 'cv-react-core';

import SaltPage from '../components/pages/SaltPage';

/*
  Put your test salt documents in a <root>/local/salt-index.json file
*/
const saltIndex = require('./salt-index.json');

/*
    You can add a customer 'Store' type based on the dialog.view.type to the Map for debugging purposes
*/
const storeMap = {};

/* eslint-disable class-methods-use-this */
class DebugProvider {
    provideDialogStoreInstance(dialog, sessionStore, parentDialogStore) {
        const { type } = dialog.view;
        if (typeof storeMap[type] === 'undefined') {
            return undefined;
        }
        return new storeMap[type](sessionStore, parentDialogStore);
    }

    provideDialogPageComponent(dialog) {
        const { view } = dialog;
        if (saltIndex.views) {
            const salt = saltIndex.views[view.persistentId];
            if (salt) {
                view.saltDocument = engineUtils.camelCase(salt);
            }
        }
        return view.saltDocument ? SaltPage : null;
    }
}

const debugProvider = new DebugProvider();
export default debugProvider;
