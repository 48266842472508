import { observer } from 'mobx-react';
import { Component } from 'react';
import * as PropTypes from 'prop-types';
import { engine } from 'cv-react-core';

@observer
class SaltPage extends Component {
    static propTypes = {
        uiStore: PropTypes.object,
        dialogStore: PropTypes.object,
        onTransition: PropTypes.func,
        onError: PropTypes.func,
    };

    render() {
        const {
            dialogStore,
            uiStore,
            onTransition,
            onError,
        } = this.props;

        const {
            saltDocument,
        } = dialogStore.dialog.view;
        const params = {
            dialogStore,
            uiStore,
            onTransition,
            onError,
        };
        return engine.render(saltDocument, params);
    }
}

export default SaltPage;
