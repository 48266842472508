import ScopeManager from'../../ScopeManager';/*
    All plugins should have an exported function with this signature that returns a bindings object.
    Bindings is an object with names/values and/or names/functions to bind
 */ /* eslint-disable no-unused-vars */export default((rootObject,expression)=>{// Provide easier access to locally scoped 'special' references
// ****************** plugin functions ****************** /
const prop=name=>{// the record may be in 'context' due to list iteration
// otherwise assume we'll need to use the observable
const record=rootObject.ref[ScopeManager.SCOPED_RECORD_REF_NAME];return record?record.propAtName(name):rootObject.modelStore.getProperty(name);};const ref=name=>{return rootObject.ref[name];};// becasue jsonata's or operator casts to boolean
// simple or expression with JS semantics (returns the actual value assocaited with the arg)
const or=(value1,value2)=>{return value1||value2;};// becasause jsonata's and operator casts to boolean
// simple and expression with JS semantics (returns the actual value assocaited with the arg)
const and=(value1,value2)=>{return value1&&value2;};const propDef=name=>{return rootObject.modelStore.dialog.propDefAtName(name);};const sessionValue=name=>{return rootObject.modelStore.sessionValueWithName(name);};// ****************** end plugin functions ****************** /
// ****************** plugin object ****************** /
const getDialog=()=>{return rootObject.modelStore.dialog;};const getRecords=()=>{return rootObject.modelStore.records;};const getRecord=()=>{return rootObject.ref[ScopeManager.SCOPED_RECORD_REF_NAME]||rootObject.modelStore.record;};const getProperty=()=>{return rootObject.ref[ScopeManager.SCOPED_PROPERTY_REF_NAME];};const getCurrentIndex=()=>{return rootObject.ref[ScopeManager.SCOPED_INDEX_REF_NAME];};const getSession=()=>{return rootObject.globals.session;};const getDevice=()=>{return rootObject.globals.device;};const isPortrait=()=>{return rootObject.globals.isPortrait;};const isLandscape=()=>{return rootObject.globals.isLandscape;};const isAndroid=()=>{return rootObject.globals.isAndroid;};const isIOS=()=>{return rootObject.globals.isIOS;};const isBrowser=()=>{return rootObject.globals.isBrowser;};// ****************** end plugin objects ****************** /
return{dialog:getDialog(),record:getRecord(),records:getRecords(),property:getProperty(),currentIndex:getCurrentIndex(),session:getSession(),device:getDevice(),isPortrait:isPortrait(),isLandscape:isLandscape(),isAndroid:isAndroid(),isIOS:isIOS(),isBrowser:isBrowser(),prop,ref,or,and,propDef,sessionValue};});