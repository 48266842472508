import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';
// import { Log } from 'cv-dialog-sdk';

import getStyles from './TableTextCell.styles';

class TableTextCell extends PureComponent {
    static propTypes = {
        textString: PropTypes.string,
        tipText: PropTypes.string,
        isNumericType: PropTypes.bool,
    }

    static defaultProps = {
        isNumericType: false,
    }

    render() {
        const {
            textString,
            tipText,
            isNumericType } = this.props;

        const styles = getStyles(isNumericType);

        return (
            <div style={ { ...styles } }>
                <span
                    title={ tipText }>
                    { textString }
                </span>
            </div>
        );
    };
};

export default TableTextCell;
