const getStyle = (rowId, isSelected = false, rowStyle = {}) => {
    const {
        alternatingBackgroundColors,
        selectionBackgroundColor,
        verticalAlign } = rowStyle;

    const colors = alternatingBackgroundColors.split(',').map((m) => m.trim()) || '';
    let backgroundColor = '';
    if (isSelected) {
        backgroundColor = selectionBackgroundColor;
    }
    else if (colors.length > 0) {
        const colorIndex = rowId % colors.length;
        backgroundColor = colors[colorIndex];
    }

    return {
        verticalAlign,
        backgroundColor,
    };
};

export default getStyle;
