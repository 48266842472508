import{EditorDialog}from'./EditorDialog';import{SortDirectionEnum,ViewModeEnum}from'./types';export const FILTER_VALUE_SUFFIX='_FILTER_VALUE';export const FILTER_OPERATOR_SUFFIX='_FILTER_OPER';export const SORT_DIRECTION_SUFFIX='_SORT_DIRECTION';export const SORT_SEQUENCE_SUFFIX='_SORT_SEQUENCE';export const KEYWORD_PROPERTY_NAME='keyword';export class SearchDialog extends EditorDialog{/**
     * Clear the search values currently in the buffer.  Does not submit search.
     */clearSearchValues(){this.record.propNames.filter(propName=>{return propName.endsWith(FILTER_OPERATOR_SUFFIX)||propName.endsWith(FILTER_VALUE_SUFFIX)||propName===KEYWORD_PROPERTY_NAME;}).forEach(propName=>{this.setPropertyValue(propName,null);});}/**
     * Clear the sort values currently in the buffer. Does not submit search.
     */clearSortValues(){this.record.propNames.filter(propName=>{return propName.endsWith(SORT_DIRECTION_SUFFIX)||propName.endsWith(SORT_SEQUENCE_SUFFIX);}).forEach(propName=>{this.setPropertyValue(propName,null);});}/**
     * Get the current search value for a property name, as a Property
     * @param propName
     */getSearchValuePropertyForName(propName){return this.record.propAtName(propName+FILTER_VALUE_SUFFIX);}/**
     * Get the PropertyDef associated with the search value Property
     * @param propName
     */getSearchValuePropertyDefForName(propName){return this.propDefAtName(propName+FILTER_VALUE_SUFFIX);}getSearchablePropertyNames(){return this.recordDef.propertyDefs.filter(propertyDef=>{return propertyDef.propertyName.endsWith(FILTER_VALUE_SUFFIX);}).map(propertyDef=>this.getPropertyNameForSearchPropertyName(propertyDef.propertyName));}getSortablePropertyNames(){return this.recordDef.propertyDefs.filter(propertyDef=>{return propertyDef.propertyName.endsWith(SORT_DIRECTION_SUFFIX);}).map(propertyDef=>this.getPropertyNameForSearchPropertyName(propertyDef.propertyName));}getPropertyNameForSearchPropertyName(searchPropertyName){if(searchPropertyName.endsWith(FILTER_VALUE_SUFFIX)){return searchPropertyName.substring(0,searchPropertyName.indexOf(FILTER_VALUE_SUFFIX));}else if(searchPropertyName.endsWith(FILTER_OPERATOR_SUFFIX)){return searchPropertyName.substring(0,searchPropertyName.indexOf(FILTER_OPERATOR_SUFFIX));}else if(searchPropertyName.endsWith(SORT_DIRECTION_SUFFIX)){return searchPropertyName.substring(0,searchPropertyName.indexOf(SORT_DIRECTION_SUFFIX));}else if(searchPropertyName.endsWith(SORT_SEQUENCE_SUFFIX)){return searchPropertyName.substring(0,searchPropertyName.indexOf(SORT_SEQUENCE_SUFFIX));}return null;}/**
     * Get the current keyword search value.  This is a global substring search
     * across all properties
     */getKeywordSearchValue(){return this.record.propAtName(KEYWORD_PROPERTY_NAME);}/**
     * Get the current search operator for a property name, as a Property
     * @param propName
     */getSearchOpPropertyForName(propName){return this.record.propAtName(propName+FILTER_OPERATOR_SUFFIX);}/**
     * Get the PropertyDef associated with the search operator Property
     * @param propName
     */getSearchOpPropertyDefForName(propName){return this.propDefAtName(propName+FILTER_OPERATOR_SUFFIX);}/**
     * Get the current sort direction for a property name, as a Property
     * @param propName
     */getSortDirectionPropertyForName(propName){return this.record.propAtName(propName+SORT_DIRECTION_SUFFIX);}/**
     * Get the PropertyDef associated with the sort direction
     * @param propName
     */getSortDirectionPropertyDefForName(propName){return this.propDefAtName(propName+SORT_DIRECTION_SUFFIX);}/**
     * Get the current sort priority for a property name, as a Property
     * @param propName
     */getSortPriorityPropertyForName(propName){return this.record.propAtName(propName+SORT_SEQUENCE_SUFFIX);}/**
     * Get the PropertyDef associated with the sort priority
     * @param propName
     */getSortPriorityPropertyDefForName(propName){return this.propDefAtName(propName+SORT_SEQUENCE_SUFFIX);}/**
     * Returns whether or not the sort direction for a property is set to ascending
     * @param propName
     */isAscending(propName){const dirProp=this.getSortDirectionPropertyForName(propName);const dirValue=dirProp?dirProp.value:null;// server may return ASCENDING or ASC and DSC or DESCENDING
return dirValue&&dirValue.indexOf('A')===0;}/**
     * Returns whether or not the sort direction for a property is set to descending
     * @param propName
     */isDescending(propName){const dirProp=this.getSortDirectionPropertyForName(propName);const dirValue=dirProp?dirProp.value:null;// server may return ASCENDING or ASC and DSC or DESCENDING
return dirValue&&dirValue.indexOf('D')===0;}/**
     * Reopen the search dialog for writing
     */reopenSearch(){return this.changeViewMode(ViewModeEnum.WRITE);}/**
     * Set the sort direction for a property to ascending
     * @param propName
     * @param sortFieldPriority
     */setAscending(propName){let sortFieldPriority=arguments.length>1&&arguments[1]!==undefined?arguments[1]:0;this.setSortValue(propName,SortDirectionEnum.ASC,sortFieldPriority);}/**
     * Set the sort direction for a property to descending
     * @param propName
     * @param sortFieldPriority
     */setDescending(propName){let sortFieldPriority=arguments.length>1&&arguments[1]!==undefined?arguments[1]:0;this.setSortValue(propName,SortDirectionEnum.DESC,sortFieldPriority);}/**
     * Set the search value for a property
     * @param propName
     * @param searchValue
     */setSearchValue(propName,searchValue){this.setPropertyValue(propName+FILTER_VALUE_SUFFIX,searchValue);}/**
     * Set the search value for the keyword search.  This is a global substring search
     * across all properties
     * @param keyword
     */setKeywordSearchValue(keyword){this.setPropertyValue(KEYWORD_PROPERTY_NAME,keyword);}/**
     * Set the filter operator type for a property to one of [[FilterOperatorEnum]]
     * @param propName
     * @param operator
     */setSearchValueOperation(propName,operator){this.setPropertyValue(propName+FILTER_OPERATOR_SUFFIX,operator);}/**
     * Set the sort direction for a property to one of [[SortDirectionEnum]]
     * @param propName
     * @param sortDirection
     * @param sortFieldPriority
     */setSortValue(propName){let sortDirection=arguments.length>1&&arguments[1]!==undefined?arguments[1]:SortDirectionEnum.ASC;let sortFieldPriority=arguments.length>2&&arguments[2]!==undefined?arguments[2]:0;this.setPropertyValue(propName+SORT_DIRECTION_SUFFIX,sortDirection);this.setPropertyValue(propName+SORT_SEQUENCE_SUFFIX,sortFieldPriority);}/**
     * Submit the search dialog (write)
     */submitSearch(){return this.write();}}