import _defineProperty from"/home/vsts/work/1/s/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";var _class;/*       */import{CHILDREN,ELEMENTS,MISC_A,QUALIFIERS}from'../gmlConstants';import GmlUtil from'../GmlUtil';import Const from'./Const';import Alist from'./Alist';import Plist from'./Plist';import Div from'./Div';import Detail from'./Detail';import List from'./List';import Style from'./Style';import Line from'./Line';import Conditional from'./Conditional';import FlexDirection from'../layoutAttribute/general/FlexDirection';/**
 * This class is necessary to prevent circular references.  The content ideally would be part of AbstractGml.
 */const childrenElements=[ELEMENTS.alist,ELEMENTS.conditional,ELEMENTS.div,ELEMENTS.plist,ELEMENTS.line,ELEMENTS.row,ELEMENTS.col,ELEMENTS.colProps,ELEMENTS.rowProps,ELEMENTS.colActions,ELEMENTS.rowActions];const operatorElements=[ELEMENTS.and,ELEMENTS.or];export default class Base{}_class=Base;_defineProperty(Base,"constants",gml=>{const raw=_class.elements(gml).filter(e=>{return _class.pvtElementName(e)===ELEMENTS.const;});const filteredRaw=raw.filter(f=>!_class.isIgnore(f));return filteredRaw.map(x=>{return new Const(x);});});_defineProperty(Base,"children",myContext=>{const excludeMe=new Line({});// Needed to cheat flow
const asEither=(inJson,dir)=>{const toUpdate=inJson;if(!toUpdate.$){toUpdate.$={};}delete toUpdate.orientation;// Just not allowed
toUpdate.$.flexDirection=dir;// Modifying the XML directly, but don't want to copy for performance.
return toUpdate;};const asColumn=inJson=>{return asEither(inJson,'column');};const asRow=inJson=>{return asEither(inJson,'row');};const toModel=r=>{const name=_class.pvtElementName(r);const{version}=myContext;const isV1=version.isV1();if(name===ELEMENTS.alist){_class.reportVersionWarning(myContext,'alist','colActions/rowActions');return new Alist(r);}if(!isV1&&name===ELEMENTS.colActions){// alias for alist
return new Alist(asColumn(r));}if(!isV1&&name===ELEMENTS.rowActions){// alias for alist
return new Alist(asRow(r));}if(name===ELEMENTS.conditional){return new Conditional(r);// } if (!isV1 && name === ELEMENTS.and) {
//     return new Conditional(r, 'and');
// } if (!isV1 && name === ELEMENTS.or) {
//     return new Conditional(r, 'or');
}if(name===ELEMENTS.plist){_class.reportVersionWarning(myContext,'plist','colProps/rowProps');return new Plist(r);}if(!isV1&&name===ELEMENTS.colProps){// alias for plist
return new Plist(asColumn(r));}if(!isV1&&name===ELEMENTS.rowProps){// alias for plist
return new Plist(asRow(r));}if(name===ELEMENTS.line){return new Line(r);}if(name===ELEMENTS.div){_class.reportVersionWarning(myContext,'div','col/row');return new Div(r);}if(!isV1&&name===ELEMENTS.row){// alias for div
return new Div(asRow(r));}if(!isV1&&name===ELEMENTS.col){// alias for div
return new Div(asColumn(r));}// This will catch known elements, but not supported with this version.
myContext.warnings.addUnknownElement(name);return excludeMe;};const raw=_class.elements(myContext.gml.json).filter(e=>{const name=_class.pvtElementName(e);const isKnown=!!childrenElements.find(x=>{return name===x;});if(!isKnown){// This will catch completely unknown elements.
const isOperator=!!operatorElements.find(x=>{return name===x;});if(!isOperator&&name!==ELEMENTS.else){myContext.warnings.addUnknownElement(name);}}return isKnown;});const filteredRaw=raw.filter(f=>!_class.isIgnore(f));return filteredRaw.map(toModel).filter(f=>f!==excludeMe);});_defineProperty(Base,"elseChildren",myContext=>{const raw=_class.elements(myContext.gml.json).filter(e=>_class.pvtElementName(e)===ELEMENTS.else);const filteredRaw=raw.filter(f=>!_class.isIgnore(f));return filteredRaw.map(r=>new Div(r));// Return a div just so the children can be processed.
});_defineProperty(Base,"operatorChildren",gml=>{const raw=_class.elements(gml).filter(e=>{return!!operatorElements.find(x=>{return _class.pvtElementName(e)===x;});});const filteredRaw=raw.filter(f=>!_class.isIgnore(f));return filteredRaw.map(r=>{const name=_class.pvtElementName(r);if(name===ELEMENTS.and){return new Conditional(r,'and');}if(name===ELEMENTS.or){return new Conditional(r,'or');}return new Conditional(r);// Should never be here.  This will default to 'and'.
});});_defineProperty(Base,"reportVersionWarning",(context,alias,preferredKey)=>{const{version,warnings}=context;warnings.addV2ElementWarning(version,alias,preferredKey);});_defineProperty(Base,"rootChildren",(json,myContext)=>{const raw=_class.elements(json).filter(e=>{return _class.pvtElementName(e)===ELEMENTS.detail||_class.pvtElementName(e)===ELEMENTS.list;});// Allowed root tag's are const(optional), style(optional) and list/detail only.
const invalidTags=_class.elements(json).filter(f=>!_class.isIgnore(f)&&_class.pvtElementName(f)!==ELEMENTS.const&&_class.pvtElementName(f)!==ELEMENTS.style&&_class.pvtElementName(f)!==ELEMENTS.detail&&_class.pvtElementName(f)!==ELEMENTS.list);// invalidTags.length > 0 will say that we have some other tag's in our gml other than valid root tags so warn user.
if(invalidTags.length){const invalidTagNames=invalidTags.map(e=>{return _class.pvtElementName(e);}).join(', ');myContext.warnings.addUnknownElement(invalidTagNames);}const filteredRaw=raw.filter(f=>!_class.isIgnore(f));return filteredRaw.map(x=>{const name=_class.pvtElementName(x);if(name===ELEMENTS.list){return new List(x);}return new Detail(x);});});_defineProperty(Base,"details",gml=>{const raw=_class.elements(gml).filter(e=>{return _class.pvtElementName(e)===ELEMENTS.detail;});const filteredRaw=raw.filter(f=>!_class.isIgnore(f));return filteredRaw.map(x=>{return new Detail(x);});});_defineProperty(Base,"lists",gml=>{const raw=_class.elements(gml).filter(e=>{return _class.pvtElementName(e)===ELEMENTS.list;});const filteredRaw=raw.filter(f=>!_class.isIgnore(f));return filteredRaw.map(x=>{return new List(x);});});_defineProperty(Base,"styles",gml=>{return _class.elements(gml).filter(e=>{return _class.pvtElementName(e)===ELEMENTS.style;}).map(x=>{return new Style(x);});});_defineProperty(Base,"elements",json=>{return GmlUtil.getValueForExpr(json,[CHILDREN])||[];});_defineProperty(Base,"flattenChildren",children=>{// The children should be an array of GML elements, but one of those elements might be an array of GML elements.
// Flatten such that what is returned is an array of elements (with not elements that are an array).
const result=[];children.forEach(e=>{if(Array.isArray(e)){_class.flattenChildren(e).forEach(e2=>result.push(e2));}else{result.push(e);}});return result;});_defineProperty(Base,"isIgnore",json=>{return _class.pvtContainsQualifier(json,QUALIFIERS.ignore);});// eslint-disable-next-line no-unused-vars
_defineProperty(Base,"isHeader",(json,version)=>{// return version.isV1() && Base.pvtContainsQualifier(gml, QUALIFIERS.header);
return _class.pvtContainsQualifier(json,QUALIFIERS.header);});// eslint-disable-next-line no-unused-vars
_defineProperty(Base,"isFooter",(json,version)=>{// return version.isV1() && Base.pvtContainsQualifier(gml, QUALIFIERS.footer);
return _class.pvtContainsQualifier(json,QUALIFIERS.footer);});_defineProperty(Base,"isNoData",json=>{return _class.pvtContainsQualifier(json,QUALIFIERS.nodata);});_defineProperty(Base,"isCol",json=>{return!_class.isRow(json);});_defineProperty(Base,"isRow",json=>{const elementName=_class.pvtElementName(json);return!!(elementName&&elementName.length>=3&&elementName.substr(0,3)==='row');});_defineProperty(Base,"flexDirectionForJson",json=>{return _class.isCol(json)?FlexDirection.column:FlexDirection.row;});// Private ----------------------------------------------------------------
_defineProperty(Base,"pvtElementName",e=>{return e['#name'];});_defineProperty(Base,"pvtQualifierList",gml=>{const qualifierList=GmlUtil.getValueForExpr(gml,[MISC_A.qualifiers],'');return(qualifierList?qualifierList.split(','):[]).map(m=>m.trim());});_defineProperty(Base,"pvtContainsQualifier",(gml,qualifier)=>{return!!_class.pvtQualifierList(gml).find(i=>i===qualifier);});