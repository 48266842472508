import { componentBuilderTypes as types } from 'cv-react-core';
import BarCodeActionComponentBuilder from './componentBuilders/BarCodeActionComponentBuilder';
import BooleanComponentBuilder from './componentBuilders/BooleanComponentBuilder';
import ClearActionComponentBuilder from './componentBuilders/ClearActionComponentBuilder';
import ColorPickerComponentBuilder from './componentBuilders/ColorPickerComponentBuilder';
import CompositeComponentBuilder from './componentBuilders/CompositeComponentBuilder';
import DateTimeComponentBuilder from './componentBuilders/DateTimeComponentBuilder';
import DropDownComponentBuilder from './componentBuilders/DropDownComponentBuilder';
import EmailActionComponentBuilder from './componentBuilders/EmailActionComponentBuilder';
import ImageComponentBuilder from './componentBuilders/ImageComponentBuilder';
import ImageActionComponentBuilder from './componentBuilders/ImageActionComponentBuilder';
import PaperCheckboxComponentBuilder from './componentBuilders/PaperCheckboxComponentBuilder';
import PhoneActionComponentBuilder from './componentBuilders/PhoneActionComponentBuilder';
import SelectActionComponentBuilder from './componentBuilders/SelectActionComponentBuilder';
import TextFieldComponentBuilder from './componentBuilders/TextFieldComponentBuilder';
import TextLabelComponentBuilder from './componentBuilders/TextLabelComponentBuilder';
import TextWithImageComponentBuilder from './componentBuilders/TextWithImageComponentBuilder';
import WebActionComponentBuilder from './componentBuilders/WebActionComponentBuilder';
import BarChartPropComponentBuilder from './componentBuilders/BarChartPropComponentBuilder';
import GaugeChartComponentBuilder from './componentBuilders/GaugeChartComponentBuilder';
import FilePickerInputComponentBuilder from './componentBuilders/FilePickerInputComponentBuilder';

const builders = {};
builders[types.BarChartProp] = BarChartPropComponentBuilder;
builders[types.BarCodeIcon] = BarCodeActionComponentBuilder;
builders[types.Boolean] = BooleanComponentBuilder;
builders[types.ClearAction] = ClearActionComponentBuilder;
builders[types.ColorPicker] = ColorPickerComponentBuilder;
builders[types.Composite] = CompositeComponentBuilder;
builders[types.DateTime] = DateTimeComponentBuilder;
builders[types.DropDown] = DropDownComponentBuilder;
builders[types.EmailIcon] = EmailActionComponentBuilder;
builders[types.GaugeChartProp] = GaugeChartComponentBuilder;
builders[types.Image] = ImageComponentBuilder;
builders[types.ImageAction] = ImageActionComponentBuilder;
builders[types.PaperCheckbox] = PaperCheckboxComponentBuilder;
builders[types.PhoneIcon] = PhoneActionComponentBuilder;
builders[types.Select] = SelectActionComponentBuilder;
builders[types.TextField] = TextFieldComponentBuilder;
builders[types.TextLabel] = TextLabelComponentBuilder;
builders[types.TextWithImage] = TextWithImageComponentBuilder;
builders[types.WebIcon] = WebActionComponentBuilder;
builders[types.FilePickerInput] = FilePickerInputComponentBuilder;
export default builders;
