import React from 'react';
import { BaseComponentBuilder } from 'cv-react-core';
import Switch from '../../components/base/Switch';


/**
 * A builder for creating boolean style (switch) components
 */
class BooleanComponentBuilder extends BaseComponentBuilder {
    /**
     * COMPONENT BASED PROPERTIES
     */

    /** Begin Context Styles */

    setThumbStyles(thumbStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            thumb: thumbStyles,
        });
    }
    getThumbStyles() {
        const { thumb } = this.getContextStyles();
        return thumb || {};
    }

    setThumbContainerStyles(thumbContainerStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            thumbContainer: thumbContainerStyles,
        });
    }
    getThumbContainerStyles() {
        const { thumbContainer } = this.getContextStyles();
        return thumbContainer || {};
    }

    setThumbContainerSelectedStyles(thumbContainerSelectedStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            thumbContainerSelected: thumbContainerSelectedStyles,
        });
    }
    getThumbContainerSelectedStyles() {
        const { thumbContainerSelected } = this.getContextStyles();
        return thumbContainerSelected || {};
    }

    setThumbSelectedStyles(thumbSelectedStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            thumbSelected: thumbSelectedStyles,
        });
    }
    getThumbSelectedStyles() {
        const { thumbSelected } = this.getContextStyles();
        return thumbSelected || {};
    }

    setTrackStyles(trackStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            track: trackStyles,
        });
    }
    getTrackStyles() {
        const { track } = this.getContextStyles();
        return track || {};
    }

    setTrackContainerStyles(trackContainerStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            trackContainer: trackContainerStyles,
        });
    }
    getTrackContainerStyles() {
        const { trackContainer } = this.getContextStyles();
        return trackContainer || {};
    }

    setTrackSelectedStyles(trackSelectedStyles) {
        this.setContextStyles({
            ...this.getContextStyles(),
            trackSelected: trackSelectedStyles,
        });
    }
    getTrackSelectedStyles() {
        const { trackSelected } = this.getContextStyles();
        return trackSelected || {};
    }

    /** End Context Styles */


    /** Begin Props */
    setChecked(checked = true) {
        this.props.checked = checked;
        return this;
    }
    getChecked() {
        return this.props.checked;
    }

    setDisabled(disabled = true) {
        this.props.disabled = disabled;
        return this;
    }
    getDisabled() {
        return this.props.disabled;
    }

    setOnChange(onChange) {
        if (typeof onChange === 'function') {
            this.props.onChange = onChange;
        }
        return this;
    }
    getOnChange() {
        return this.props.onChange;
    }
    /** End Props */


    /**
     * PROCESS AND UPDATE COMPONENT PROPERTIES
     */

    processDisplay() {
        const stagedValue = this.getStagedValue();
        const property = this.getProperty();
        const propDef = this.getPropDef();
        const booleanValue = this.resolveBooleanValue(stagedValue, property, propDef);
        this.setChecked(booleanValue);
    }

    processChangeHandler() {
        const onChangeHandler = this.getOnValueChangeHandler();
        const isReadMode = this.getReadMode();
        const property = this.getProperty();
        const { name: propertyName = '' } = property;

        if (!isReadMode && !!propertyName && !!onChangeHandler) {
            this.setOnChange((event, props) => {
                const { checked, checkedValue } = props;
                // const propertyValue = event.target.checked;
                const booleanValue = !checked; // Toggle the original prop
                // If there is a checkedValue, then only respond to turning the boolean on, and do so by
                // setting the value to the checkedValue.
                if (checkedValue) {
                    if (booleanValue) {
                        onChangeHandler(propertyName, checkedValue);
                    }
                }
                else {
                    onChangeHandler(propertyName, booleanValue);
                }
            });
        }
    }

    processStyle() {
        const categorizedStyles = BaseComponentBuilder.styleHelper.categorizeWebStyles(this.getStyle());
        const {
            container,
            text,
        } = categorizedStyles;

        this.setContainerStyles({
            ...container,
            ...this.getContainerStyles(),
        });
        this.setThumbStyles({
            color: text.color,
            ...this.getThumbStyles(),
        });
    }

    buildProps() {
        // Update the selected state
        this.processDisplay();

        // Update change handler to support property / value data
        this.processChangeHandler();

        // Update the input style
        this.processStyle();
    }


    /**
     * BUILD AND RETURN COMPONENT AS REACT ELEMENT
     */

    build() {
        // Process and construct final props
        this.buildProps();

        // Build and return component as React element
        return <Switch { ...this.getProps() } />;
    }
}

export default BooleanComponentBuilder;
