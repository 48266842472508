import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import FilterComponent from './FilterComponent';
import getStyles from './styles/Control.styles';

export default class FilterControl extends PureComponent {
    static defaultProps = {
        // styles: {},
    }

    static propTypes = {
        searchDialogStore: PropTypes.object,
        filterTerms: PropTypes.array,
        onSetFilterValue: PropTypes.func,
        onSetFilterOperation: PropTypes.func,
        // styles: PropTypes.object,
    }

    // TODO: Need to refactor and consolidate the two filter drop downs and use that as a pattern for creating drop down controls.
    // There is currently a lot of redunencies in this are due to this.
    render() {
        const {
            filterTerms,
            onSetFilterValue,
            onSetFilterOperation,
            searchDialogStore } = this.props;
        const styles = getStyles();
        return (
            <div style={ styles.scrollContainer }>
                <div style={ styles.controlledRowContainer }>
                    <div style={ { ...styles.controledColumnContainer, flexGrow: 1 } }>
                        {
                            filterTerms.map((filterData) => {
                                const filterComponentProps = {
                                    ...filterData,
                                    searchDialogStore,
                                    onSetFilterValue,
                                    onSetFilterOperation,
                                };
                                return React.createElement(FilterComponent, { ...filterComponentProps });
                            })
                        }
                    </div>
                </div>
            </div>
        );
    }
}
