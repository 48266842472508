import{Dialog}from'./Dialog';import{QueryMarkerOption,QueryScroller}from'./QueryScroller';import{RedirectionUtil}from'./RedirectionUtil';import{ActionIdsEnum,TypeNames}from'./types';import{Log}from'../util/Log';/**
 * Dialog Subtype that represents a 'Query Dialog'.
 * A 'Query' represents and is backed by a list of Records and a single Record definition.
 * See {@link Record} and {@link RecordDef}.
 */export class QueryDialog extends Dialog{get defaultActionId(){return this.view.defaultActionId;}initScroller(pageSize){let firstRecordId=arguments.length>1&&arguments[1]!==undefined?arguments[1]:null;let markerOptions=arguments.length>2&&arguments[2]!==undefined?arguments[2]:[QueryMarkerOption.None];this._scroller=new QueryScroller(this,pageSize,firstRecordId,markerOptions);}isBinary(column){const propDef=this.propDefAtName(column.propertyName);return propDef&&(propDef.isLargePropertyType||propDef.isURLType&&propDef.isInlineMediaStyle);}performMenuActionWithId(actionId,targets){return this.invokeMenuActionWithId(actionId,{targets,type:TypeNames.ActionParametersTypeName}).then(result=>{return result;});}/**
     * Get a redirection to the search dialog for this query dialog
     */openSearch(){return this.performMenuActionWithId(ActionIdsEnum.SEARCH_ACTION_ID,[]);}performMenuActionForCalculateStatistics(actionId){return this.invokeMenuActionForCalculateStatistics(actionId).then(result=>{return result;});}/**
     * Get a Recordset for Calculate Column Statistics
     */openCalculateStatistics(){return this.performMenuActionForCalculateStatistics(ActionIdsEnum.CALCULATE_STATISTICS_ACTION_ID);}/**
     * Perform this action associated with the given Menu on this dialog.
     * The targets array is expected to be an array of object ids.
     * @param {Menu} menu
     * @param {Array<string>} targets
     * @returns {Promise<{actionId: string} | Redirection>}
     */performMenuAction(menu,targets){return this.invokeMenuAction(menu,{targets,type:TypeNames.ActionParametersTypeName}).then(result=>{return result;});}/**
     * Perform a query
     *
     * @param {number} maxRows
     * @param {QueryDirection} direction
     * @param {string} fromRecordId
     * @returns {Promise<RecordSet>}
     */query(maxRows,direction,fromRecordId){const queryParams=fromRecordId?{fetchDirection:direction,fetchMaxRecords:maxRows,fromRecordId,type:TypeNames.QueryParametersTypeName}:{fetchDirection:direction,fetchMaxRecords:maxRows,type:TypeNames.QueryParametersTypeName};return this.catavolt.dialogApi.getRecords(this.catavolt.session.tenantId,this.catavolt.session.id,this.id,queryParams).then(recordSet=>{this.lastRefreshTime=new Date();return recordSet;}).catch(error=>{Log.error('Failed to get records. This is likely due to a networking issue.',error);return Promise.reject(error);});}/**
     * Get the associated QueryScroller
     * @returns {QueryScroller}
     */get scroller(){if(!this._scroller){this._scroller=this.defaultScroller();}return this._scroller;}get records(){return this.scroller.buffer;}get sessionValues(){return this.scroller.sessionValues;}getProperty(params,propertyName){return this.catavolt.dialogApi.getQueryProperty(this.tenantId,this.sessionId,this.id,propertyName,params);}/**
     * Creates a new QueryScroller with default buffer size of 50
     * @returns {QueryScroller}
     */defaultScroller(){return new QueryScroller(this,50,null,[QueryMarkerOption.None]);}/**
     * Set the value of a property in this {@link Record}.
     * Values may be already constructed target types (CodeRef, TimeValue, Date, etc.)
     * or primitives, in which case the values will be parsed and objects constructed as necessary.
     * @param recordId
     * @param name
     * @param value
     * @returns {any}
     */setPropertyValue(recordId,name,value){const record=this.records.find(rec=>rec.id===recordId);const propDef=this.propDefAtName(name);let property=null;if(propDef&&record){const parsedValue=value!==null&&value!==undefined?this.parseValue(value,propDef.propertyName):null;property=record.setValue(propDef.propertyName,parsedValue,propDef);}return property;}/**
      Write updated records back to the server
     */write(){const pendingWrites=this.records.filter(rec=>{const recEffects=rec.afterEffects();return recEffects.properties.length>0;});if(pendingWrites.length){return this.catavolt.dialogApi.putRecords(this.tenantId,this.sessionId,this.id,{pendingWrites,type:TypeNames.QueryActionParametersTypeName}).then(result=>{const now=new Date();this.catavolt.dataLastChangedTime=now;if(RedirectionUtil.isRedirection(result)){this.updateSettingsWithNewDialogProperties(result.referringObject);if(result.refreshNeeded){this.catavolt.dataLastChangedTime=new Date();}return result;}else{const dialog=result;this.parentDialog.updateDialogTreeWithChild(dialog);return dialog;}});}return Promise.resolve();}/**
     * Get available values for a dropdown in query dialog
     * @param {string} propName
     * @param {string} target - Selected Record Id as target
     * @returns {Promise<any[]>}
     */getAvailableValues(propName,target){return this.catavolt.dialogApi.getAvailableValues(this.tenantId,this.sessionId,this.id,propName,{target,type:TypeNames.AvailableValuesParametersTypeName});}}