import { rootStore } from 'cv-react-core';

const getStyles = () => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();
    return {
        logout: {
            title: {
                ...theme.fonts.logoutPrompt,
                fontSize: theme.fonts.fontSizeMidLarge,
            },
            text: theme.fonts.logoutPrompt,
        },
    };
};

export default getStyles;
