import { rootStore } from 'cv-react-core';

/**
* Helper method to get the styles for Icon component
* @param {Object} contextStyles - Context styles for Icon Component
*/
const getStyles = (backgroundColor, fixedColumn, position) => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    return {
        display: 'table-cell',
        userSelect: 'none',
        padding: '0px',
        height: '100%',
        position: fixedColumn ? 'sticky' : 'unset',
        zIndex: fixedColumn ? '300' : 'unset',
        left: fixedColumn ? position : 'unset',
        backgroundColor: backgroundColor || 'unset',
        borderRightWidth: 1,
        borderRightStyle: 'solid',
        borderRightColor: theme.dataTableGridBorderColor,
    };
};

export default getStyles;
