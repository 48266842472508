import { rootStore } from 'cv-react-core';

const getTheme = () => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();
    const {
        color,
        fontSize,
        fontFamily,
    } = theme.fonts.default;

    // Populate the component parts with theme styles
    return {
        container: {
        },

        badge: {
            fontFamily,
        },

        icon: {
            color,
            fontSize,
        },
    };
};

export default getTheme;
