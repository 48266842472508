import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { toJS } from 'mobx';
import uuidv4 from 'uuid/v4';

import { Log } from 'cv-dialog-sdk';
import { constants, uiHelper, pageController, utilities, onTransitionRegistry } from 'cv-react-core';
import { Map, MapMarker, MapRoutes, MapCallout, MapAddressSearch as AddressSearch } from 'cv-library-react-web';
import Button from '../components/base/Button';
import Menu from '../components/base/Menu';
import MenuItem from '../components/base/MenuItem';
import TextLabel from '../components/base/TextLabel';

/**
 * This component was created to handle maps display.
 */
class RWMap extends Component {
    static defaultProps = {
        uiStore: {},
        dialogStore: {},
        onTransition: () => Promise.resolve(),
        onError: () => Promise.resolve(),
        onMenuAction: () => Promise.resolve(),
        onSetGeoLocation: () => Promise.resolve(),
    };

    static propTypes = {
        uiStore: PropTypes.object,
        dialogStore: PropTypes.object,
        onTransition: PropTypes.func,
        onError: PropTypes.func,
        onMenuAction: PropTypes.func,
        onSetGeoLocation: PropTypes.func,
        defaultZoomLevel: PropTypes.number,
    };

    constructor(props) {
        super(props);
        this.selectedMarker = undefined;
        this.markers = {};
        this.fitToPins = true;
        this.routeColors = constants.map.DEFAULT_ROUTE_COLORS;
        this.defaultMaxNumberOfRoutes = constants.map.DEFAULT_NUMBER_OF_ROUTES;
        this.mapRef = null;
        this.isBoundsSet = false;

        this.state = {
            routes: [],
            droppedMarker: null,
            activeMarker: {},
            showInfoWindow: false,
            anchorPosition: null,
        };
    }

    render() {
        const mapProps = {
            fitToPins: this.fitToPins,
            onMapClick: this.addMarkerToMap.bind(this),
        };

        return (
            <Map
                ref={ (ref) => { this.mapRef = ref; } }
                { ...mapProps }>
                { this.renderMarkers() }
                { this.renderRoutes() }
                { this.renderCallout() }
                { this.renderMenu() }
                <AddressSearch
                    onAddressSearch={ this.handleAddressSearch } />
            </Map>
        );
    }

    componentWillUnmount() {
        // Unregister any registry entries if some are left behind
        onTransitionRegistry.unsubscribe();
    }

    /**
     * Sets markers on map
     * @returns {Array<React.Component>}
     */
    renderMarkers = () => {
        const { dialogStore } = this.props;
        const { isRefreshNeeded, refreshInProgress } = dialogStore;

        const markers = toJS(this.getFormattedMarkerData());
        if (!isRefreshNeeded && !refreshInProgress && markers.length === 0) {
            this.setUserRegion();
        }

        const markerProps = {
            onClick: this.handleOnClickMarker.bind(this),
        };

        // Set the map to fit all the markers
        this.updateMapBounds();

        return markers.map((marker) => (
            <MapMarker
                key={ marker.id }
                marker={ marker }
                position={ {
                        lat: marker.latitude,
                        lng: marker.longitude,
                    } }
                markerType={ marker.imageName ? MapMarker.MarkerType.Image : MapMarker.MarkerType.Pin }
                { ...markerProps } />
        ));
    };

    renderRoutes = () => {
        const { routes } = this.state;
        return (
            <MapRoutes
                routes={ routes }
                strokeWidth={ 3 } />
        );
    };

    renderCallout = () => {
        const { activeMarker, showInfoWindow } = this.state;
        const styles = {
            container: {
                flexDirection: 'row',
                alignItems: 'center',
                display: 'flex',
            },
            textContainer: {
                padding: 10,
                maxWidth: 300,
            },
            text: {
                paddingBottom: 10,
            },
        };

        return (
            <MapCallout
                marker={ activeMarker }
                visible={ showInfoWindow }>
                <div style={ styles.container }>
                    <div>
                        <Button
                            text="Menu"
                            onClick={ this.handleOnMarkerMenuPress } />
                    </div>
                    <div style={ styles.textContainer }>
                        <TextLabel
                            numberOfLines={ 3 }
                            contextStyles={ { text: styles.text } }>
                            { activeMarker.title || '' }
                        </TextLabel>
                        { this.renderRouteInformation() }
                    </div>
                </div>
            </MapCallout>
        );
    };

    /**
    * Renders route information on map
    */
   renderRouteInformation = () => {
    const { routes } = this.state;
    if (!routes || routes.length === 0) return null;

    return routes.map((route) => (
        <TextLabel
            key={ uuidv4() }
            contextStyles={ { text: { color: route.color } } }>
            { `${route.leg.distance.text} / ${route.leg.duration.text}` }
        </TextLabel>
    ));
    };

    renderMenu = () => {
        const { dialogStore } = this.props;
        const { dialog } = dialogStore;
        const { menu: topMenu } = dialog.view;
        const menu = topMenu ? uiHelper.asGenericMenu(dialog.id, topMenu.findContextMenu()) : null;
        const { anchorPosition } = this.state;

        if (anchorPosition){
        return (
            <Menu
                closeOnSelection
                anchorPosition={ anchorPosition }
                onClose={ this.handleMarkerMenuClose }
                keepMounted
                open>
                { menu.map(
                    (item) => (
                        <MenuItem
                            key={ item.id }
                            onClick={ () => this.handleOnMenuAction(item) }
                            text={ item.menuText } />
                    ))
                }
            </Menu>
        );
        }

        return null;
    };

    /**
     * Render marker on Map
     * @param {Object} coordinate
     */
    renderMarkerOnMap = (coordinate) => {
        const markerId = '-1';
        this.setState({
            droppedMarker: {
                latitude: coordinate.latitude,
                longitude: coordinate.longitude,
                name: coordinate.address,
                description: coordinate.address,
                id: markerId,
            },
            routes: [],
            showInfoWindow: false,
        }, () => {
            // if any marker is already selected draw routes from selcted marker to the added marker
            if (this.selectedMarker) {
                this.setRoutesFromSelectedMarker(coordinate, markerId);
            }
            else {
                this.selectedMarker = {
                    markerId,
                    ...coordinate,
                };
            }
        });

        // Update the position if the dropped pin is already selected
        if (this.selectedMarker && this.selectedMarker.markerId === markerId) {
            this.selectedMarker.latitude = coordinate.latitude;
            this.selectedMarker.longitude = coordinate.longitude;
        }

        // Updating the UIStore with Marker Coordinates
        const { uiStore } = this.props;
        uiStore.setValueForUIObject(constants.ui.APPLICATION_UI_ID, constants.ui.MARKER_COORDINATES, {
            latitude: coordinate.latitude,
            longitude: coordinate.longitude,
        }, false);
    }

    /**
     * Format marker data to structure Marker needs to render
     * @return { array } markers
     */
    getFormattedMarkerData = () => {
        const { dialogStore } = this.props;
        const { records } = dialogStore;
        const { droppedMarker } = this.state;

        if (!records || records === null) return [];

        const markers = [];

        records.forEach((record) => {
            const longitude = uiHelper.formatForRead(record, dialogStore.dialog.recordDef, 'longitude');
            const latitude = uiHelper.formatForRead(record, dialogStore.dialog.recordDef, 'latitude');
            const name = uiHelper.formatForRead(record, dialogStore.dialog.recordDef, 'name');
            const description = uiHelper.formatForRead(record, dialogStore.dialog.recordDef, 'description');
            const marker = {
                id: record.id,
                imageName: record.imageName,
                latitude: parseFloat(latitude),
                longitude: parseFloat(longitude),
                pinColor: record.foregroundColor,
                name,
                description,
            };
            markers.push(marker);
        });

        if (droppedMarker) {
            markers.push(droppedMarker);
        }
        return markers;
    };

    /**
     * Helper method to get array of all markers position
     */
    getMarkerPointsArray = () => {
        const markers = toJS(this.getFormattedMarkerData());
        const points = [];

        markers.forEach((marker) => {
            if (marker.id !== '-1') {
                points.push({
                    lat: marker.latitude,
                    lng: marker.longitude,
                });
            }
        });
        return points;
    };

    /**
     * Fit to users current location
     */
    setUserRegion() {
        try {
            navigator.geolocation.getCurrentPosition((position) => {
                if (this.mapRef){
                    this.mapRef.setCenter(position.coords);
                }
            },
            (error) => {
                this.addLog(`Failed to get geoLocation:\r\nMessage: ${error}`);
            });
        }
        catch (e) {
            this.addLog(`Failed to get geoLocation:\r\nMessage: ${e}`);
        }
    }

    /**
     * @param {String|LatLng} origin
     * @param {String|LatLng} destination
     * @returns {void}
     */
    setRoutesBetweenLocations = (origin, destination, routeColors) => {
        const callback = (res) => {
            let routes = [];

            if (res.routes.length) {
                routes = res.routes.map((route, idx) => {
                return {
                    leg: route.legs.reduce((carry, curr) => curr),
                    distance:
                        route.legs.reduce((carry, curr) => carry + curr.distance.value, 0) / 1000,
                    duration:
                        route.legs.reduce((carry, curr) => carry + curr.duration.value, 0) / 60,
                    coordinates: utilities.mapHelper.decodePolyLinePoints(route.overview_polyline),
                    color: routeColors[idx],
                };
                });
            }
            this.setState({ routes });
        };

        this.mapRef.getDirections(origin, destination, callback.bind(this));
    };

    /**
     * Updates map to fit all markers
     */
    updateMapBounds = () => {
        if (this.fitToPins && this.mapRef && !this.isBoundsSet) {
            const points = this.getMarkerPointsArray();
            if (points.length > 0) {
                this.mapRef.fitToMarkers(points);

                // Need to track whether bounds are set only once when map initialized
                this.isBoundsSet = true;
            }
        }
    }

    /**
     * Log warning
     */
    addLog = (message) => {
        Log.warn(`Map Error: ${message}`);
    };

    handleOnMenuAction = (item) => {
        const { onMenuAction } = this.props;
        onTransitionRegistry.subscribe('RWMap', this.handleOnTransition);
        onMenuAction(item);
    }

    handleOnTransition = (redirection) => {
        if (!redirection) return redirection;

        const { displayHint } = redirection;
        const { displayHints } = constants;
        if (displayHint === displayHints.HINT_NO_VIEW) {
            const { onSetGeoLocation } = this.props;
            onSetGeoLocation(redirection);
            return null;
        }

        return redirection;
    }

    handleMarkerMenuClose = () => {
        this.setState({ anchorPosition: null });
    };

    handleOnMarkerMenuPress = (event) => {
        const { uiStore, dialogStore } = this.props;
        const objectId = this.selectedMarker.markerId;
        const {
            clientX,
            clientY,
        } = event;
        const position = {
            left: clientX,
            top: clientY,
        };

        // If not in selection mode, then un-select all before selecting the long press record.
        if (!pageController.getSelectionMode(dialogStore, uiStore)) {
            utilities.listHelper.clearSelectedRecords(uiStore, dialogStore);
        }

        // Need not select dropped marker
        if (objectId !== '-1') {
            utilities.listHelper.selectRecord(uiStore, dialogStore, objectId, utilities.listHelper.single);

            // Updating the UIStore with null Coordinates
            const { droppedMarker } = this.state;
            if (droppedMarker === null){
                uiStore.setValueForUIObject(constants.ui.APPLICATION_UI_ID, constants.ui.MARKER_COORDINATES, null, false);
            }
        }

        this.setState({
            anchorPosition: position,
         });
    };

    /**
     * @param {object} marker
     * @returns {void}
     */
    handleOnClickMarker = (props, marker) => {
        if (!marker) return;
        const { id, position } = props;

        if (this.selectedMarker && this.selectedMarker.markerId !== id) {
            this.setRoutesBetweenLocations(
                this.selectedMarker, {
                    latitude: position.lat,
                    longitude: position.lng,
                }, this.routeColors);
        }

        this.selectedMarker = {
            markerId: id,
            latitude: position.lat,
            longitude: position.lng,
        };

        this.setState({
            activeMarker: marker,
            showInfoWindow: true,
        });
    };

    /**
     * Handles address search
     * @param {String} text
     */
    handleAddressSearch = (text) => {
        const { defaultZoomLevel } = this.props;
        utilities.mapHelper
            .getGoogleCoordinatesForAddress(text)
            .then((coordinate) => {
                this.addMarkerToMap(coordinate);
                this.centerToCoordinate(coordinate, defaultZoomLevel);
            })
            .catch((e) => {
                this.addLog(`Error searching address: ${text} - Message: ${e}`);
            });
    };

    /**
     * Adds marker to the map
     * @param {Object} coordinate
     */
    addMarkerToMap = (coordinate) => {
        if (coordinate.address){
            this.renderMarkerOnMap(coordinate);
        }
        else {
            utilities.mapHelper
            .getGoogleAddressForCoordinates(`${coordinate.latitude},${coordinate.longitude}`)
            .then((address) => {
                // eslint-disable-next-line no-param-reassign
                coordinate.address = address;
                this.renderMarkerOnMap(coordinate);
            })
            .catch((e) => {
                this.addLog(`Error fetching address for coordinates: ${coordinate} - Message: ${e}`);
            });
        }
    }

    /**
     * Center map to coordinate
     * @param {Object} coordinate
     */
    centerToCoordinate = (coordinate, zoom) => {
        if (coordinate) {
            this.mapRef.setCenter(coordinate);
        }
        if (zoom){
            this.mapRef.setZoom(zoom);
        }
    };

    /**
     * Sets routes from selected marker to given cooridnate and markerId
     * @param {LatLng} coordinate
     * @param {string} markerId
     * @returns {void}
     */
    setRoutesFromSelectedMarker= (coordinate, markerId) => {
        if (this.selectedMarker && this.selectedMarker.markerId !== markerId) {
            this.setRoutesBetweenLocations(this.selectedMarker, coordinate, this.routeColors);
            this.selectedMarker = {
                markerId,
                ...coordinate,
            };
        }
    }
}

export default RWMap;