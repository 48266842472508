import { rootStore } from 'cv-react-core';

const getTheme = () => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    // Populate the component parts with theme styles
    return {
        container: {
            backgroundColor: theme.colors.panel,
        },
        buttonText: {
            ...theme.fonts.default,
        },

        heading: {
            backgroundColor: theme.panelBackgroundColor,
            ...theme.fonts.panelTitle,
            color: theme.loginSettingsTextColor,
        },

        primaryButton: {
            backgroundColor: theme.buttonPrimaryBackgroundColor,
            borderColor: theme.buttonPrimaryBorderColor,
        },

        primaryButtonHover: {
            backgroundColor: theme.buttonUnderlayColor,
        },

        primaryButtonText: {
            ...theme.fonts.buttonPrimary,
        },

        secondaryButton: {
            backgroundColor: theme.buttonSecondaryBackgroundColor,
            borderColor: theme.buttonSecondaryBorderColor,
        },

        secondaryButtonHover: {
            borderColor: theme.buttonUnderlayColor,
        },

        secondaryButtonText: {
            ...theme.fonts.buttonSecondary,
        },

        switchThumb: {
            color: theme.checkBoxButtonColor,
        },

        switchTrack: {
            backgroundColor: theme.checkBoxUnCheckedBackgroundColor,
        },

        switchTrackSelected: {
            backgroundColor: theme.checkBoxCheckedBackgroundColor,
        },

        textField: {
            ...theme.fonts.default,
            color: theme.loginSettingsTextColor,
        },

        textLabel: {
            ...theme.fonts.default,
            color: theme.loginSettingsTextColor,
        },
    };
};

export default getTheme;
