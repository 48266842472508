import React from 'react';
import * as PropTypes from 'prop-types';

import ActionButton from '../ActionButton/ActionButton';
import ActionMenu from '../ActionMenu/ActionMenu';
import ActionSeparator from '../ActionSeparator/ActionSeparator';

/**
 * An ActionHeader is a collection of actions and a menu.  The actions can be grouped, with a grouping separator
 * displayed between each group.
 *
 * An action is an object with the following properties:
 *   id (required)
 *   icon (optional)
 *   label (optional)
 *
 * The properties for CvActionHeader consist of a menu, which is an array of actions as described above, and groups,
 * which are an array of menus.  A group with with a menu of 2 actions, and another group with a menu of 2 actions
 * will result in 2 actions, a separator, and two more actions.
 *
 * Sample data:
 *   const m1 = { id: '1', icon: 'https://vignette.wikia.nocookie.net/animalcrossing/images/2/2e/Star.png' };
 *   const m2 = { id: '2', label: 'Menu 2', icon: 'https://vignette.wikia.nocookie.net/animalcrossing/images/2/2e/Star.png' };
 *   const m3 = { id: '3', label: 'Menu 3' };
 *   const m4 = { id: '4', label: 'Menu 4' };
 *
 *   Resulting properties:
 *     const groups = [ [ m1, m2 ], [ m3, m4 ] ];
 *     const menu = [ m1, m2, m3 ];
 *     const onMenuAction = (id) => { this.handleOnMenuAction(id); };
 *
 *                     <ActionHeader
 *                         menu={ menu }
 *                         groups={ groups }
 *                         onMenuAction={ onMenuAction } />
 *
 */

const handleGroupAction = (props, menu, nativeEvent) => {
    const { onGroupAction } = props;
    onGroupAction(menu, nativeEvent);
};

const renderAction = (throughProps, action = {}, key) => {
    // EARLY EXIT
    if (typeof action.id === 'undefined') { return null; }

    const onAction = action.onAction ? action.onAction : (event, nativeEvent) => { handleGroupAction(throughProps, event, nativeEvent); };

    const toolProps = {
        ...action,
        onAction,
        key,
    };
    const ComponentClass = action.componentClass || ActionButton;

    return (
        <ComponentClass { ...toolProps } />
    );
};

const renderMenu = (menu, onMenuAction, throughProps) => {
    const { popContextMenu } = throughProps;
    const labels = [];
    const values = [];
    if (menu) {
        menu.forEach((m) => {
            labels.push(m.menuText ? m.menuText : m.label);
            values.push(m.id);
        });
    }
    const showMenu = menu && (labels.length || values.length);
    const menuProps = {
        labels,
        values,
        onMenuAction,
        key: 'menuKey',
        popContextMenu,
    };

    if (showMenu) {
        return (
            <ActionMenu { ...menuProps } />
        );
    }

    return null;
};

const renderTools = (props) => {
    const { groups } = props;
    const groupedTools = [];
    let key = 5;
    if (groups) {
        // Filter the empty groups out, then render each group with a separator in between.
        groups.filter((g) => (g.length)).forEach((g, i) => {
            if (i !== 0) {
                key += 1;
                groupedTools.push(
                    <ActionSeparator key={ `separatorKey${key}` } />
                );
            }
            g.forEach((a) => {
                key += 1;
                groupedTools.push(renderAction(props, a, `toolKey${key}`));
            });
        });
    }

    return groupedTools;
};

const ActionHeader = (props) => {
    // Layout each group with a separator in between, followed by the menu.
    const {
        menu,
        onMenuAction,
    } = props;
    const resultingParts = renderTools(props);
    const menuE = renderMenu(menu, onMenuAction, props);

    if (menuE) {
        resultingParts.push(menuE);
    }

    return resultingParts;
};

ActionHeader.defaultProps = {
    groups: [],
    menu: [],
    onMenuAction: () => Promise.resolve(),
    onGroupAction: () => Promise.resolve(),
};

ActionHeader.propTypes = {
    groups: PropTypes.array,
    menu: PropTypes.array,
    onMenuAction: PropTypes.func,
    onGroupAction: PropTypes.func,
};

export default ActionHeader;
