// Core component styles
const coreStyles = {
    container: {
        backgroundColor: '#eee',
        display: 'flex',
        flexDirection: 'column',
    },
};

/**
* Helper method to get the styles for the component
* @param {Object} contextStyles - Context styles for the Component
*/
const getStyles = (contextStyles = {}) => {
    return {
        container: {
            ...coreStyles.container,
            ...contextStyles.container,
        },
    };
};

export default getStyles;
