/* eslint-disable react/self-closing-comp */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/void-dom-elements-no-children */
/* eslint-disable class-methods-use-this */
/* eslint-disable arrow-body-style */
/* eslint-disable arrow-parens */
/* eslint-disable array-bracket-newline */
/* eslint-disable array-element-newline */
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { PureComponent } from 'react';

const column1 = [ 'alan', 'has to the ', 'be a bunch OF NON TEST' ];
const column2 = [ 'a', 'has to the ', 'be a ' ];
const column3 = [ 'alan', 'has to the ', 'be a bunch OF NON TEST asdfasdfsadfsadf ads fsad fsad saffasdfsdafasdfsadfasdfasdfasdfasfdfsdadafsdfasfds' ];

const FONT = {
    fontWeight: 'normal',
    fontSize: 18,
    fontFamily: 'Open Sans',
};

const OFFSET_ADJUSTMENT = FONT.fontSize / 10;
/**
 * These test pages are designed to be plugged into the App.js as a TestPage.
 *    App.js
 *       import TestPage from './testPages/TestSimplePage';
 *
 *       then activate the commented line:  if (TestPage) return <TestPage />;
 *
 * This is a quick and dirty way to test a component without having to create a react
 * environment to sandbox in.
 *
 */
export default class ColumnWidthTest extends PureComponent {
    render() {
        const dataSize = this.sizeColumns(column1);
        const dataSize1 = this.sizeColumns(column2);
        const dataSize2 = this.sizeColumns(column3);
        const style1 = { width: dataSize, backgroundColor: 'red', ...FONT };
        const style2 = { width: dataSize1, backgroundColor: 'yellow', ...FONT };
        const style3 = { width: dataSize2, backgroundColor: 'green', ...FONT };

        return (
            <div>
                {
                    column1.map((columnInfo, index) => {
                        return <div key={ `column_1-${index}` }><input key={ `column_1-${index}` } style={ style1 } value={ columnInfo } /><br></br></div>;
                    })
                }
                {
                    column2.map((column1Info, index) => {
                        return <div key={ `column_2-${index}` }><input style={ style2 } value={ column1Info } /><br></br></div>;
                    })
                }
                {
                    column3.map((column2Info, index) => {
                        return <div key={ `column_3-${index}` }><input style={ style3 } value={ column2Info } /><br></br></div>;
                    })
                }
            </div>
        );
    }

    getRowDataValue = (value) => {
        if (typeof value === 'object') {
            if (value.description) return value.description;
            return 'UNKNOWN_VALUE_DESC';
        }
        return value;
    }

    sizeColumns(columnData) {
        const maxSize = 350;
        const columnHeading = 'WWW';
        const columnSizes = columnData.map(column => {
            return this.columnSizer(column);
        });
        const minSize = this.columnSizer(columnHeading);
        const boxSize = Math.max(...columnSizes, minSize) * OFFSET_ADJUSTMENT;
        return boxSize > maxSize ? maxSize : boxSize;
    }

    columnSizer(columnData) {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        const metrics = context.measureText(columnData);
        return Math.ceil(metrics.width);
    }
}
