import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { observer } from 'mobx-react';
import DropDown from '../base/DropDown';
import TextLabelWithImage from '../base/TextLabelWithImage';

@observer
class FilterSelector extends Component {
    static defaultProps = {
        styles: {},
        value: '',
        imageHeight: 40,
        imageWidth: 40,
        noSelectionText: '',
        allSelectedText: '',
        multipleSelectionsText: '',
        onChange: () => {},
        itemLoader: () => {},
    }

    static propTypes = {
        styles: PropTypes.object,
        value: PropTypes.oneOfType([
            PropTypes.object,
            PropTypes.string,
            PropTypes.array,
        ]),
        noSelectionText: PropTypes.string,
        allSelectedText: PropTypes.string,
        multipleSelectionsText: PropTypes.string,
        imageHeight: PropTypes.number,
        imageWidth: PropTypes.number,
        isMultiselect: PropTypes.bool,
        isComboBox: PropTypes.bool,
        itemsContainImage: PropTypes.bool,
        itemLoader: PropTypes.func,
        items: PropTypes.array,
        onChange: PropTypes.func,
    }

    constructor(props) {
        super(props);

        this.getRenderValue = this.getRenderValue.bind(this);
        this.getRenderItem = this.getRenderItem.bind(this);
        this.getRenderInput = this.getRenderInput.bind(this);
    }

    render() {
        const {
            isMultiselect,
            value,
            ...restprops } = this.props;
        const renderValue = this.getRenderValue;
        const renderItem = this.getRenderItem;
        const renderInput = this.getRenderInput;
        const selectorValue = isMultiselect && !value ? [] : value;
        const dropDownProps = {
            ...restprops,
            value: selectorValue,
            multiple: isMultiselect,
            renderValue,
            renderItem,
            renderInput,
        };
        return React.createElement(DropDown, { ...dropDownProps });
    }

    getRenderItem(MenuItem, item) {
        const isCodeRef = typeof item === 'object';
        const {
            itemsContainImage,
            imageHeight,
            imageWidth } = this.props;
        const menuItemProps = {
            value: isCodeRef ? item.code : item,
            text: isCodeRef ? item.description : item,
            key: isCodeRef ? (item.code || item.description) : item,
            imageHeight,
            imageWidth,
            isTextWithImage: itemsContainImage,
            item,
        };

        if (itemsContainImage) {
            const [
                imageName,
                imageSrc,
            ] = item.description.split(/:(.*)/);
            menuItemProps.imageName = imageName;
            menuItemProps.imageSrc = imageSrc;
        }
        return <MenuItem { ...menuItemProps } />;
    };

    getRenderInput = (value) => {
        let returnValue = value;
        const specialSpace = '‎\u00A0';
        if (Array.isArray(value)) {
             if (value.length === 1) {
                returnValue = value[0].description;
            }
            else if (value.length === 0) {
                returnValue = [];
            }
            else {
                const selectedValues = value.map((item) => item.description || item.code);
                returnValue = selectedValues.join(',');
            }
        }
        else if (value && typeof value === 'object') {
            returnValue = value.description ? `${value.description}` : specialSpace;
        }
        else {
            returnValue = `${value}`;
        }
        return returnValue;
    }

    getRenderValue(value) { /* eslint-disable no-param-reassign */
        const { items, itemsContainImage } = this.props;
        const specialSpace = '‎\u00A0';

        if (Array.isArray(value)) {
            const {
                noSelectionText,
                allSelectedText,
                multipleSelectionsText } = this.props;
             if (value.length === 1) {
                value = value[0].description;
            }
            else if (value.length === 0) {
                value = noSelectionText;
            }
            else if (items && value.length === items.length) {
                value = allSelectedText;
            }
            else {
                value = multipleSelectionsText;
            }
        }
        let v = '';
        if (value && typeof value === 'object') {
            v = value.description ? `${value.description}` : specialSpace;
        }
        else {
            v = `${value}`;
        }

        if (itemsContainImage) {
            const [
                imageName,
                imageSrc,
            ] = value.split(/:(.*)/);

            const { imageHeight, imageWidth } = this.props;
            return (
                <TextLabelWithImage
                    imageHeight={ imageHeight }
                    imageSrc={ imageSrc }
                    imageWidth={ imageWidth }
                    reverse
                    text={ imageName } />
            );
        }
        return (v && v.length > 0 ? v : specialSpace);
    };
}

export default FilterSelector;