import _defineProperty from"/home/vsts/work/1/s/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";import{action}from'mobx';import{Catavolt,TypeNames}from'cv-dialog-sdk';import rootStore from'../stores/rootStore';import{constants}from'../constants';import{utilities}from'../utilities';import serviceFactory from'../services/serviceFactory';/**
 * PSA - No react-native in here!  This must be x-platform!
 */const COMPLETE=2;const IS_INITIAL_ACTION=1;const IS_NOTIFICATIONS_ACTION=2;const{ui:{WORKBENCH_UI_OBJECT_ID}}=constants;class WorkbenchController{constructor(){var _this=this;_defineProperty(this,"setGloballyBusy",function(dialogId,uiStore){let params=arguments.length>2&&arguments[2]!==undefined?arguments[2]:{};// { loadingText, detailText, menu }
const existingStatusObj=uiStore.getValueForUIObject(dialogId,constants.ui.GLOBAL_SDK_BUSY);const alreadyBusy=!!existingStatusObj;const newStatus=new utilities.SdkBusyStatus(dialogId,params,existingStatusObj?existingStatusObj.status:null,serviceFactory.lang);if(!existingStatusObj){const statusObj={status:newStatus};uiStore.setValueForUIObject(dialogId,constants.ui.GLOBAL_SDK_BUSY,statusObj);}else if(!newStatus.differsFrom(existingStatusObj.status)){action(()=>{existingStatusObj.status=newStatus;})();}return alreadyBusy;});_defineProperty(this,"clearGloballyBusy",(dialogId,uiStore)=>{uiStore.setValueForUIObject(dialogId,constants.ui.GLOBAL_SDK_BUSY,null);});_defineProperty(this,"performWorkbenchOAuthCallBackCall",async function(tenantId,sessionId,uiStore,token,onTransition,oAuthUrl){let clientType=arguments.length>6&&arguments[6]!==undefined?arguments[6]:'MOBILE';_this.setGloballyBusy(constants.ui.WORKBENCH_UI_OBJECT_ID,uiStore);let oAuthToken=token;if(clientType==='MOBILE'){const cryptoProofKey=await serviceFactory.crypto.getSecureRandom(16);const{permissionToken}=await serviceFactory.oAuth.authenticate(cryptoProofKey,oAuthUrl,constants.oauth.OAUTH_CALLBACK_TOKEN);oAuthToken=permissionToken;}const{sessionStore}=rootStore;return Catavolt.getOAuthWorkBenchRedirection(tenantId,sessionId,oAuthToken).then(redirection=>{onTransition(redirection).then(()=>{_this.clearGloballyBusy(constants.ui.WORKBENCH_UI_OBJECT_ID,uiStore);sessionStore.setOAuthToken(null);uiStore.removeValueForUIObject(constants.ui.WORKBENCH_UI_OBJECT_ID,constants.ui.WORKBENCH_ACTIVE_ID);});});});}/**
     * Performs the necessary workbench action based on launcher content. This method
     * will perform the described launcher workbench action based on workbench id and
     * transition to that workbench. If failure occurs we raise the onError page.
     * @param {object} launcher launcher data
     * @param {INT} isInitialLaunchAction state machine, IS_INITIAL_ACTION = 1, IS_NOTIFICATIONS_ACTION = 2. Both need one-time launch code and have their own state machines to update on completion.
     */performWorkbenchAction(uiStore,workbenchStore,onTransition,onError,launcher,isInitialLaunchAction,modifiers){// const StateIsGrid = 'WorkbenchPageIsGrid';
const params={};if(launcher&&launcher.name)params.menu={label:launcher.name};this.setGloballyBusy(constants.ui.WORKBENCH_UI_OBJECT_ID,uiStore,params);const StateActiveLauncherId='WorkbenchPageActiveLauncherId';uiStore.setValueForUIObject(constants.ui.WORKBENCH_UI_OBJECT_ID,StateActiveLauncherId,launcher.id);if(workbenchStore){return workbenchStore.performWorkbenchAction(launcher.workbenchId,launcher.id).then(redirection=>{// throw new Error('Trouble BIG');
if(redirection.type===TypeNames.OAuthLoginRedirectionTypeName){uiStore.setValueForUIObject(constants.ui.WORKBENCH_UI_OBJECT_ID,constants.ui.WORKBENCH_ACTIVE_ID,launcher.workbenchId);}onTransition(redirection,modifiers).then(()=>{workbenchStore.setFilter('');uiStore.setValueForUIObject(constants.ui.WORKBENCH_UI_OBJECT_ID,StateActiveLauncherId,null);if(isInitialLaunchAction===IS_INITIAL_ACTION){uiStore.setValueForUIObject(constants.ui.WORKBENCH_UI_OBJECT_ID,constants.ui.WORKBENCH_PAGE_INITIAL_ACTION,COMPLETE);}else if(isInitialLaunchAction===IS_NOTIFICATIONS_ACTION){uiStore.setValueForUIObject(constants.ui.APPLICATION_UI_ID,constants.ui.NOTIFICATION_ACTION,COMPLETE);}this.clearGloballyBusy(constants.ui.WORKBENCH_UI_OBJECT_ID,uiStore);});}).catch(err=>{uiStore.setValueForUIObject(constants.ui.WORKBENCH_UI_OBJECT_ID,StateActiveLauncherId,null);if(isInitialLaunchAction===IS_INITIAL_ACTION){uiStore.setValueForUIObject(constants.ui.WORKBENCH_UI_OBJECT_ID,constants.ui.WORKBENCH_PAGE_INITIAL_ACTION,COMPLETE);}else if(isInitialLaunchAction===IS_NOTIFICATIONS_ACTION){uiStore.setValueForUIObject(constants.ui.APPLICATION_UI_ID,constants.ui.NOTIFICATION_ACTION,COMPLETE);}const{lang}=serviceFactory;const title=lang.formatString(lang.dialog.errors.errorLaunchingTitle,launcher.name);this.clearGloballyBusy(constants.ui.WORKBENCH_UI_OBJECT_ID,uiStore);onError({id:WORKBENCH_UI_OBJECT_ID,title,err});});}const{sessionStore}=rootStore;const workbenchStore2=sessionStore.selectedWorkbenchStore;// if (!this.setGloballyBusy('99', uiStore, { detailText: serviceFactory.lang.loadingStatus.pleaseWaitOpening })) {
return workbenchStore2.performWorkbenchAction(launcher.workbenchId,launcher.id).then(redirection=>{// throw new Error('Trouble BIG');
onTransition(redirection,modifiers).then(()=>{workbenchStore2.setFilter('');uiStore.setValueForUIObject(constants.ui.WORKBENCH_UI_OBJECT_ID,StateActiveLauncherId,null);if(isInitialLaunchAction===IS_INITIAL_ACTION){uiStore.setValueForUIObject(constants.ui.WORKBENCH_UI_OBJECT_ID,constants.ui.WORKBENCH_PAGE_INITIAL_ACTION,COMPLETE);}else if(isInitialLaunchAction===IS_NOTIFICATIONS_ACTION){uiStore.setValueForUIObject(constants.ui.APPLICATION_UI_ID,constants.ui.NOTIFICATION_ACTION,COMPLETE);}this.clearGloballyBusy(constants.ui.WORKBENCH_UI_OBJECT_ID,uiStore);// this.clearGloballyBusy(dialogId, uiStore);
});}).catch(err=>{// this.clearGloballyBusy(dialogId, uiStore);
uiStore.setValueForUIObject(constants.ui.WORKBENCH_UI_OBJECT_ID,StateActiveLauncherId,null);if(isInitialLaunchAction===IS_INITIAL_ACTION){uiStore.setValueForUIObject(constants.ui.WORKBENCH_UI_OBJECT_ID,constants.ui.WORKBENCH_PAGE_INITIAL_ACTION,COMPLETE);}else if(isInitialLaunchAction===IS_NOTIFICATIONS_ACTION){uiStore.setValueForUIObject(constants.ui.APPLICATION_UI_ID,constants.ui.NOTIFICATION_ACTION,COMPLETE);}const title=serviceFactory.lang.formatString(serviceFactory.lang.dialog.errors.errorLaunchingTitle,launcher.text);this.clearGloballyBusy(constants.ui.WORKBENCH_UI_OBJECT_ID,uiStore);onError({id:WORKBENCH_UI_OBJECT_ID,title,err});});}}const workbenchController=new WorkbenchController();export default workbenchController;