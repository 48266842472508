/* eslint-disable no-plusplus */import{TypeNames,Log}from'cv-dialog-sdk';import{SaltDocument}from'../SaltDocument';const detailsTemplate=require('./details_simple');/*
    @TODO - currently lays out the mobile version of our Cell structure
    Need to add a condition for size and platform (small, med, large, etc.)
    For large/html platforms, we need to honor additional CellValue types (ForcedLineCellValue, TabCellValue, SubstitutionCellValue, etc)

    In our model (legacy meta data) we allow a Detail to be defined with rows (arrays) of Cells, which are in turn composed of arrays of CellValues

    Example:
    (row 1)  [Cell [ CellValue ] ],  Cell [ [ CellValue, CellValue ] ]
    (row 2)  [Cell [ CellValue, CellValue ] ],  Cell [ [CellValue, CellValue] ]

    However, most cases look like this:
    (row 1)  [Cell [ CellValue (label) ] ],  Cell [ [ CellValue (value) ] ]
    (row 2)  [Cell [ CellValue (label) ] ],  Cell [ [ CellValue (value) ] ]
    with an occasional lone item as a 'label':
    (row 3)  [Cell [ CellValue (label) ] ] ]

    This transpiler maps the Cell to 'Box' container, and puts the contents (CellValues) directly adjacent to each other
*/class DetailsTranspiler{generateSalt(dialog){const document=new SaltDocument(detailsTemplate);try{const viewEl=document.getByExpr('**.view');document.singleSelectNode(viewEl);// @TODO alias, viewAlias - we may want to allow salt to also reference user defined aliases
const{view}=dialog;const viewId=view.persistentId||view.id;document.setAttribute('id',viewId);// Get the orientation conditional
const whenEl=document.getByExpr('**.when');document.singleSelectNode(whenEl);this.buildPortrait(document,dialog);this.buildLandscape(document,dialog);}catch(error){Log.error(error.message);}return document.rootNode;}buildPortrait(document,dialog){var _this=this;const portraitBox=document.getByExpr('children[0].box');const{rows}=dialog.view;rows.forEach(function(){let cellArray=arguments.length>0&&arguments[0]!==undefined?arguments[0]:[];cellArray.forEach(_ref=>{let{values=[]}=_ref;values.forEach(cellValue=>{if(cellValue.type===TypeNames.AttributeCellValue){const propertyEl=_this.newPropertyEl(cellValue);portraitBox.children.push(propertyEl);}else if(cellValue.type===TypeNames.LabelCellValue){const labelEl=_this.newLabelEl(cellValue);portraitBox.children.push(labelEl);}});});});}buildLandscape(document,dialog){var _this2=this;const landscapeTable=document.getByExpr('`else-children`[0].table');const{rows}=dialog.view;const tableRows=[];let maxCols=0;rows.forEach(function(){let cellArray=arguments.length>0&&arguments[0]!==undefined?arguments[0]:[];const rowEls=[];maxCols=cellArray.length>maxCols?cellArray.length:maxCols;cellArray.forEach(_ref2=>{let{values=[]}=_ref2;const cellValueEls=[];values.forEach(cellValue=>{if(cellValue.type===TypeNames.AttributeCellValue){cellValueEls.push(_this2.newPropertyEl(cellValue));}else if(cellValue.type===TypeNames.LabelCellValue){cellValueEls.push(_this2.newLabelEl(cellValue));}});// wrap multiple values in the cell in a box
const cellEl={box:{children:cellValueEls}};rowEls.push(cellEl);});tableRows.push(rowEls);});const columnModel=[];for(let i=0;i<maxCols-1;i++){columnModel.push({wrap:false});}columnModel.push({wrap:true});landscapeTable.rows=tableRows;landscapeTable.layout={column:columnModel};}newPropertyEl(cellValue){const{propertyName,toolTip}=cellValue;const additionalStyle=this.getAdditionalStyle(cellValue);return{property:{name:propertyName,style:[{$ref:'property_style'},additionalStyle],toolTip}};}newLabelEl(cellValue){const{value}=cellValue;const additionalStyle=this.getAdditionalStyle(cellValue);return{label:{value,style:[{$ref:'label_style'},additionalStyle]}};}// @TODO add correct styles here
getAdditionalStyle(cellValue){if(cellValue.isHeading1Style){return{$ref:'heading1_style'};}if(cellValue.isHeading2Style){return{$ref:'heading2_style'};}if(cellValue.isHeading3Style){return{$ref:'heading3_style'};}if(cellValue.isHeading4Style){return{$ref:'heading4_style'};}if(cellValue.isSectionHeadingStyle){return{$ref:'section_heading_style'};}return{};}}const detailsTranspiler=new DetailsTranspiler();export default detailsTranspiler;