/* eslint-disable putout/multiple-properties-destructuring */

const getStyles = (isSelected, index, contextStyles) => {
    const { listSelectedUnderlayColor, listUnderlayColor, listAlternatingColors } = contextStyles;

    let backgroundColor = listUnderlayColor;
    if (isSelected && listSelectedUnderlayColor) {
        // If an item is selected used the selected underlay color.
        backgroundColor = listSelectedUnderlayColor;
    }
    else if (listAlternatingColors) {
        // If we have alternating colors, alternate via index number which matches native.
        const alternateColors = listAlternatingColors.split(',').map((m) => m.trim());
        if (alternateColors.length) {
            const colorIndex = index % alternateColors.length;
            backgroundColor = alternateColors[colorIndex];
        }
    }

    return {
        backgroundColor,
    };
};

export default getStyles;
