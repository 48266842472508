import { rootStore } from 'cv-react-core';

const getTheme = (contextStyles) => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    // Populate the component parts with theme styles
    const title = contextStyles && contextStyles.title ? contextStyles.title : {};
    return {
        actions: {
            backgroundColor: theme.colors.panel,
            shadowColor: theme.modalShadowColor,
        },

        content: {
            backgroundColor: theme.colors.panel,
            shadowColor: theme.modalShadowColor,
        },

        divider: {
        },

        header: {
            backgroundColor: theme.colors.panel,
            shadowColor: theme.modalShadowColor,
        },

        modal: {
            backgroundColor: theme.colors.panel,
            shadowColor: theme.modalShadowColor,
        },

        overlay: {
        },

        title: {
            ...theme.fonts.default,
            fontSize: theme.fonts.fontSizeMidLarge,
            ...title,
        },
    };
};

export default getTheme;
