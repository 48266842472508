import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Log, TypeNames } from 'cv-dialog-sdk';
import { onTransitionRegistry, constants } from 'cv-react-core';

import { GVC, GVCModelObject, GVCSAMAuth, GVCOKTAAuth, GVCPropertyObject, GVC_ENUM, GVCMonikerConverter } from '@ppm/gvc-react';
import '@ppm/gvc-react/view/styles/gvc.scss';

const { viz } = constants;

/**
 * PPM View Control Wrapper
 * This component is used to display graphical data generated by Xalt Visualization and PPM View Control.
 * PPM View Control is located at: https://dev.azure.com/hexagonPPMInnerSource/Visualization/_packaging?_a=feed&feed=PPM%40Release
 * Models used to load graphical data
 * @property { object } style
 * @property { object } xStyle
 * @property { bool } isLibraryLoaded
 * @property { object } view
 * @property { number } height
 * @property { number } width
 * @property { object } properties
 * @property { func } onSelectionChanged
 * @property { func } onModelLoaded
 * @property { func } onViewerLoaded
 * @property { func } onWebGVCLibLoaded
 * @property { func } onWindowResize
 */
class PPMViewControl extends PureComponent {
    static propTypes = {
        isViewerLoaded: PropTypes.bool,
        height: PropTypes.number,
        width: PropTypes.number,
        models: PropTypes.object,
        componentProperties: PropTypes.object,
        modelProperties: PropTypes.object,
        graphicProperties: PropTypes.object,
        onSelectionChanged: PropTypes.func,
        onViewerLoaded: PropTypes.func,
        onLoadGraphicProperties: PropTypes.func,
        adsToken: PropTypes.string,
    };

    static defaultProps = {
        models: {},
        isViewerLoaded: false,
        height: 600,
        width: 800,
        onSelectionChanged: () => {},
        onViewerLoaded: () => {},
        onLoadGraphicProperties: () => {},
    };

    constructor(props) {
        super(props);
        this.handleOnViewerLoaded = this.handleOnViewerLoaded.bind(this);
        this.loadFileMonikerConverter = this.loadFileMonikerConverter.bind(this);
        this.handleSelectionChanged = this.handleSelectionChanged.bind(this);
    }

    render() {
        const {
            isViewerLoaded,
            height,
            width,
            models,
            componentProperties,
            graphicProperties,
            modelProperties,
        } = this.props;

        let propertyData;
        if (graphicProperties) {
            const { PropertiesWindowState } = componentProperties;
            const {
                title,
                properties,
                documents } = graphicProperties;
            propertyData = new GVCPropertyObject(title, properties, documents, PropertiesWindowState ? this.getEnumValue(PropertiesWindowState, GVC_ENUM.PropertiesWindowState) : GVC_ENUM.PropertiesWindowState.EXPANDED);
        }

        const defaultSettings = { continuousHighlight: true };

        const viewProps = {
            resources: '/gvc-resources/',
            height,
            width,
            models: isViewerLoaded ? this.createGVCModelObject({ ...modelProperties, ...models }) : [],
            ...componentProperties,
            defaultSettings,
            onLog: this.gvcLogging,
            minLogLevel: GVC_ENUM.LogLevel.Error,
            properties: propertyData,
            selectionChanged: this.handleSelectionChanged,
            viewerLoaded: this.handleOnViewerLoaded,
            workerJSPath: '/worker.js',
        };

        return React.createElement(GVC, { ...viewProps });
    }

    componentDidUpdate(prevProps) {
        const { models: prevModels, adsToken: prevAdsToken } = prevProps;
        const { models, adsToken } = this.props;
        const prevModelsJSON = JSON.stringify(prevModels);
        const currentModelsJSON = JSON.stringify(models);
        this.isModelDataSame = false;
        // Hack because GVC blows up if you recreate the model object. You have to recreate the model object
        // because you have to send it on updates.
        if (currentModelsJSON.localeCompare(prevModelsJSON) === 0) {
            this.isModelDataSame = true;
        }

        if (this.modelObject && (prevAdsToken !== adsToken)) {
            this.modelObject.adsToken = adsToken;
        }
    }

    componentWillUnmount() {
        // Unload the myVr lookup hook. This will be replaced in furture releases of the GVC.
        if (this.monikerLookup && this.monikerLookup.isLoaded) {
            this.monikerLookup.closeBinFile();
            this.monikerLookup = null;
        }

        // Unregister any registry entries if some are left behind
        onTransitionRegistry.unsubscribe();
    }

    componentDidCatch(error, info) {
        // You can also log the error to an error reporting service
        const message = `${error}:\r\n\t${info}`;
        Log.error(message);
    }

    gvcLogging = (log) => {
        Log.error(log);
    }

    getEnumValue = (value, enumObject) => {
        const objectKey = Object.keys(enumObject).find((key) => {
            if (enumObject[key] && value) {
                return enumObject[key].toLowerCase() === value.toLowerCase();
            }
            return undefined;
        });

        return objectKey ? enumObject[objectKey] : value;
    }

    createGVCModelObject(modelData) {
        // Hack to make sure we don't send the same model information twice in a new object.
        // This causes the viewer to blow up.
        if (this.hasLoaded && this.isModelDataSame) return this.currentModelData;

        const models = [];
        const modelObject = this.createModelObject(modelData);
        if (modelObject) models.push(modelObject);

        this.currentModelData = models;
        this.hasLoaded = true;
        return models;
    }

    createModelObject = (modelData) => {
        const {
            adsBaseUri,
            adsResourceUri,
            authorizationType,
            title: displayName,
            graphicResourceUri,
            graphicType,
            monikerTemplate,
            sourceType,
            vdsBaseUri,
            vopClientId,
            vopRedirectUrl,
            vopClientSecret,
            url,
        } = modelData;
        const { adsToken } = this.props;

        let authClient;
        let trimmedGrahicResourceUri = graphicResourceUri;

        if (this.getEnumValue(sourceType, GVC_ENUM.SourceType) !== GVC_ENUM.SourceType.File) {
            if (authorizationType === viz.authorizationType.SAM) {
                const samAuth = new GVCSAMAuth(vopClientId, vopClientSecret, vopRedirectUrl, true);
                authClient = samAuth;
            }
            else {
                const oktaAuth = new GVCOKTAAuth(vopClientId, vopRedirectUrl, true);
                authClient = oktaAuth;
            }
            // HACK::: We get 404 for GraphicsBySavedViewStream api when this uri starts with "/". So, before sending it to GVC, remove starting "/".
            if (graphicResourceUri.startsWith('/')) {
                trimmedGrahicResourceUri = graphicResourceUri.replace('/', '');
            }

            this.modelObject = new GVCModelObject(vdsBaseUri, trimmedGrahicResourceUri, this.getEnumValue(graphicType, GVC_ENUM.GraphicType), this.getEnumValue(sourceType, GVC_ENUM.SourceType), displayName, authClient, {
                adsBaseUri,
                adsResourceUri,
            }, {
                identityMetadata: monikerTemplate,
            });
        }

        // this logic may not work all datasources, it should be validated and modified accordingly
        if (this.getEnumValue(sourceType, GVC_ENUM.SourceType) === GVC_ENUM.SourceType.File) {
            this.modelObject = new GVCModelObject(undefined, url, this.getEnumValue(graphicType, GVC_ENUM.GraphicType), this.getEnumValue(sourceType, GVC_ENUM.SourceType), displayName || 'Test');
        }

        if (adsToken) {
            this.modelObject.adsToken = adsToken;
        }

        return this.modelObject;
    }

    loadFileMonikerConverter(models) {
        const { sourceType, url } = models;
        if (this.getEnumValue(sourceType, GVC_ENUM.SourceType) === GVC_ENUM.SourceType.File) {
            this.monikerLookup = this.monikerLookup || new GVCMonikerConverter();
            if (this.monikerLookup.isLoaded) {
                this.monikerLookup.closeBinFile();
            }
            this.monikerLookup.openBinFile(url);
        }
    }

    handleSelectionChanged(selectionChanged) {
        const { onSelectionChanged } = this.props;
        if (!onSelectionChanged) return;

        const { models } = this.props;
        if (!models) return;

        const {
            graphicType,
            sourceType,
        } = models;

        const {
            graphicId,
            isSelected,
            extraData,
        } = selectionChanged;

        if (isSelected) onTransitionRegistry.subscribe('ppmViewControl', this.handleOnTransition);
        if (!isSelected) {
            onSelectionChanged(undefined);
        }
        else if (this.getEnumValue(sourceType, GVC_ENUM.SourceType) === GVC_ENUM.SourceType.File) {
            if (!this.monikerLookup || graphicId === -1 || !isSelected) {
                Log.info(`No graphic id found: ${JSON.stringify(selectionChanged)}`);
                onTransitionRegistry.unsubscribe();
                onSelectionChanged(undefined);
            }
            else {
                const moniker = this.monikerLookup.findMoniker(graphicId.toString());
                onSelectionChanged(moniker.trim());
            }
        }
        else if (this.getEnumValue(graphicType, GVC_ENUM.GraphicType) === GVC_ENUM.GraphicType.Drawing &&
                    this.getEnumValue(sourceType, GVC_ENUM.SourceType) === GVC_ENUM.SourceType.Workspace) {
            // We have to parse this object because the data is not formatted well. The selection is expecting an object
            // string so we have to parse it to remove the bad formatting, then stringify it to make it clean.
            const name = extraData && extraData.ObjectIdentity ? JSON.parse(extraData.ObjectIdentity.Name) : undefined;
            if (name) {
                // This code looks odd doesn't it. Well based on how we are getting the data and needing to send it
                // back to the server we have to bust up the object and format in a way the OData service will accept it
                // That is the reason this data looks like it does. Refer to work that was done on the following work item
                // and feature for details: https://dev.azure.com/HexagonXalt/Xalt%20Mobility/_workitems/edit/24355
                const newObject = { attributeSets: `${JSON.stringify(name.attributeSets)}` };
                onSelectionChanged(JSON.stringify(newObject));
            }
            else {
                onSelectionChanged(name);
            }
        }
        else {
            onSelectionChanged(graphicId);
        }
    }

    handleOnTransition = (redirection) => {
        const { onLoadGraphicProperties } = this.props;
        const { displayHint, type } = redirection;
        const { displayHints } = constants;
        if (displayHint === displayHints.HINT_NO_VIEW) {
            onLoadGraphicProperties(redirection);
            return null;
        }
        if (displayHint === displayHints.HINT_IN_COMPONENT || type === TypeNames.NullRedirectionTypeName) {
            onLoadGraphicProperties(redirection);
            onTransitionRegistry.unsubscribe();
            return null;
        }

        return redirection;
    }

    handleOnViewerLoaded() {
        const {
            onViewerLoaded,
            models,
        } = this.props;

        this.loadFileMonikerConverter(models);
        onViewerLoaded();
    }
}

export default PPMViewControl;
