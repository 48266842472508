/*       */import{constants}from'cv-react-core';import StyleSet from'../StyleSet';import AbstractControl from'./AbstractControl';/*
An Image is either based on a URL or a property.
 */export default class Image extends AbstractControl{get URL(){return this.valueAt('URL');}get allowPicker(){return this.valueAt('AllowPicker')==='true';}get allowPickOptions(){return this.valueAt('AllowPickOptions')==='true';}get allowAnnotations(){return this.valueAt('AllowAnnotations')==='true';}get resizingMode(){// return this.valueAt('ResizingMode');
return'contain';}calcIconFontSize(bounding){// Could increase this by a factor for large bounding values, but an icon that does
// not fit snug in the box is OK also.
return bounding;}newReadOnlyBoxStyleSet(){const result=super.newReadOnlyBoxStyleSet();result.style.justifyContent='center';return result;}newReadOnlyPropertyStyleSet(){const result=super.newReadOnlyPropertyStyleSet();result.xStyle.container=result.xStyle.container||{};result.xStyle.container.display='flex';result.xStyle.container.flexGrow=1;result.xStyle.container.flexDirection='row';result.xStyle.container.justifyContent='center';result.xStyle.icon=result.xStyle.icon||{};result.xStyle.icon.fontSize=this.calcIconFontSize(Math.min(this.width,this.height));result.xStyle.icon.opacity=0.2;result.xStyle.image={'flex-direction':'column','flex-shrink':1,overflow:'hidden',resizeMode:this.resizingMode,// native
objectFit:this.resizingMode,// web
width:this.width,height:this.height};result.xStyle.actionOverrides={};// This option defaults to false and must be explicitly allowed.
result.xStyle.actionOverrides[constants.imagePickerMenuIds.display]='true';// These options default to true, but can be disabled via paper form.
if(!this.allowAnnotations)result.xStyle.actionOverrides[constants.imagePickerMenuIds.annotate]='false';if(!this.allowPicker)result.xStyle.actionOverrides[constants.imagePickerMenuIds.import]='false';if(!this.allowPickOptions)result.xStyle.actionOverrides[constants.imagePickerMenuIds.takePhoto]='false';return result;}newControlSalt(propertyStyleSet){// TODO: Not sure why bindings can come through with a url. We had an issue, work item: https://dev.azure.com/HexagonXalt/PS-Development%20Liaison/_workitems/edit/19343
// that came up and the only way I knew to resolve it was to add this check where if a url is provided we treat this as
// an object that is not bound to a record. Not sure what the side effect of this will be.
if(this.propertyName&&!this.URL){return super.newControlSalt(propertyStyleSet);}return this.newImageSalt(propertyStyleSet);}newImageSalt(propertyStyleSet){// If there is no URL, display a one pixel transparent gif.
// http://png-pixel.com/
// Without this, the Button, which is a subclass of this, will not fire actions correctly.
const onePixelImage='data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';return{image:{source:this.URL||onePixelImage,...propertyStyleSet.asStyleAttribute(),...propertyStyleSet.asXStyleAttribute()}};}}