import { rootStore } from 'cv-react-core';

// Core component styles
const coreStyles = {
    container: {
        padding: '0rem',
        paddingLeft: '10px',
        paddingRight: '10px',
        paddingBottom: '2px',
        backgroundColor: '#eee',
        display: 'flex',
        justifyContent: 'space-between',
    },

    text: {
    },

    textContainer: {
        paddingLeft: '10px',
        paddingRight: '10px',
    },

    versionContainer: {
        display: 'flex',
    },
};

/**
* Helper method to get the styles for the component
* @param {Object} contextStyles - Context styles for the Component
*/
const getStyles = (contextStyles = {}) => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();
    return {
        container: {
            ...coreStyles.container,
            backgroundColor: theme.colors.alternate1,
            ...contextStyles.container,
        },

        text: {
            ...coreStyles.text,
            ...theme.fonts.copyrightVersion,
            ...contextStyles.text,
        },

        textContainer: {
            ...coreStyles.textContainer,
            ...contextStyles.textContainer,
        },

        versionContainer: {
            ...coreStyles.versionContainer,
            ...contextStyles.versionContainer,
        },
    };
};

export default getStyles;
