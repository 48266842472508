import { rootStore } from 'cv-react-core';

const getTheme = () => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();
    const {
        color,
        fontSize,
    } = theme.fonts.default;

    // Populate the component parts with theme styles
    return {
        adornmentButton: {
        },

        adornmentIcon: {
            color,
            fontSize,
            backgroundColor: theme.detailAttributeBackgroundColor,
        },

        container: {
        },

        hoover: {
            backgroundColor: theme.detailAttributeHooverBackgroundColor,
            paddingTop: '4px',
        },

        input: {
            backgroundColor: theme.detailAttributeBackgroundColor,
            paddingTop: '4px',
            ...theme.fonts.default,
        },
    };
};

export default getTheme;
