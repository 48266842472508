/* eslint-disable no-bitwise */
import React, { PureComponent, Fragment } from 'react';
import * as PropTypes from 'prop-types';
// import { observer } from 'mobx-react';
import { BaseTooltip } from 'cv-library-react-web';
import { serviceFactory } from 'cv-react-core';

import IconButton from '../components/base/IconButton';
import TextField from '../components/base/TextField';

// @observer
class RWQuickSearch extends PureComponent {
    static propTypes = {
        /** Extended styles for this component */
        // contextStyles: PropTypes.object,

        /**
         * Called on search term change
         * @param {any} term
         * @returns {void}
         */
        onSearchTermChange: PropTypes.func,

        disabled: PropTypes.bool,

        searchInProgress: PropTypes.bool,

        /**
         * Called on search term reset
         * @returns {void}
         */
        onClear: PropTypes.func,

        /**
         * Called on search term submit
         * @returns {void}
         */
        onSubmit: PropTypes.func,

        /** placeholder text for quick filter */
        placeholderText: PropTypes.string,

        /** Quick filter search term */
        searchTerm: PropTypes.object,

        /** ID string used for testing */
        testID: PropTypes.string,
    };

    static defaultProps = {
        placeholderText: 'Quick Filter...',
        searchTerm: { value: '' },
        onSubmit: () => {},
        onClear: () => {},
    };

    constructor(props) {
        super(props);
        this.handleSearchTermChange = this.handleSearchTermChange.bind(this);
    }

    render() {
        const {
            searchTerm,
            placeholderText,
            testID,
            onSubmit,
            onClear,
            disabled,
            searchInProgress,
        } = this.props;

        const boxStyles = {
            marginBottom: '5px',
            display: 'flex',
            flexDirection: 'row-reverse',
            minHeight: 'fit-content',
        };
        const buttonStyleProps = {
            button: {
                padding: '5px',
            },
            icon: {
                fontSize: 18,
                padding: '0px',
            },
        };
        const inputStyleProps = {
            container: {
                flexGrow: 0,
                width: '300px',
            },
            input: {
                fontSize: '12px',
            },
        };

        const { lang } = serviceFactory;
        let toolTipText = lang.searchSort.quickSearchHelpText;
        if (disabled) {
            toolTipText = lang.searchSort.quickSearchDisabled;
        }

        const disableSearch = !((searchTerm.value && searchTerm.value.trim().length > 0) | searchInProgress);
        return (
            <div
                style={ boxStyles }>
                <BaseTooltip
                    tooltip={ toolTipText }>
                    <TextField
                        contextStyles={ inputStyleProps }
                        value={ searchTerm.value || '' }
                        disabled={ disabled }
                        endAdornment={
                            <Fragment>
                                <IconButton
                                    contextStyles={ buttonStyleProps }
                                    disabled={ disableSearch }
                                    iconName="search"
                                    onClick={ onSubmit } />
                                <IconButton
                                    contextStyles={ buttonStyleProps }
                                    iconName="cancel"
                                    disabled={ searchInProgress || !searchTerm.value }
                                    onClick={ onClear } />
                            </Fragment>
                        }
                        onChange={ this.handleSearchTermChange }
                        onSubmit={ onSubmit }
                        placeholder={ placeholderText }
                        testID={ `${testID}__seacrchfield-container` } />
                </BaseTooltip>
            </div>
        );
    }

    /**
     * Called on search term change
     * @param {SyntheticEvent} event
     * @returns {void}
     */
    handleSearchTermChange(event) {
        const {
            onSearchTermChange,
        } = this.props;

        if (onSearchTermChange) {
            onSearchTermChange(event.target.value);
        }
    };
}

export default RWQuickSearch;