import _defineProperty from"/home/vsts/work/1/s/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";/*       */import StrictMode from'./layoutAttribute/general/StrictMode';/**
 * A WarningNotifier is a global object that holds warnings to be displayed as a notification.
 */export default class WarningNotifier{constructor(errorCallback){var _this=this;_defineProperty(this,"strictMode",new StrictMode('true'));_defineProperty(this,"strictModeExceptions",void 0);_defineProperty(this,"v2AttributeWarnings",{});_defineProperty(this,"v2ElementWarnings",{});_defineProperty(this,"miscWarnings",{});_defineProperty(this,"errorCallback",void 0);_defineProperty(this,"addInvalidConditional",type=>{const msg="Conditional incomplete.  Requires property, operand and either operandConstant or operandProperty: <".concat(type,">");this.miscWarnings[msg]=null;});_defineProperty(this,"addInvalidConditionalExtras",type=>{const msg="Conditional may not have additional (non-conditional) attributes: <".concat(type,">");this.miscWarnings[msg]=null;});_defineProperty(this,"addInvalidValue",(attribute,value)=>{const msg="Attribute ".concat(attribute," contains an unknown value: ").concat(value);this.miscWarnings[msg]=null;});_defineProperty(this,"addNoPropsInListHeaderFooter",attribute=>{const msg="Properties may not exist in headers or footers on a list: ".concat(attribute);this.miscWarnings[msg]=null;});_defineProperty(this,"addUndefinedConstantRef",constName=>{const msg="Referenced constant is not defined: ".concat(constName);this.miscWarnings[msg]=null;});_defineProperty(this,"addUndefinedStyleNameRef",styleName=>{const msg="Referenced styleName is not defined: ".concat(styleName);this.miscWarnings[msg]=null;});_defineProperty(this,"addUnknownAttribute",attributeName=>{if(!this.strictModeExceptions||this.strictModeExceptions.shouldReportAttribute(attributeName)){// noReports are attributes that don't get formally built and therefore will show as a false hit.
const noReports=['cvId','operandConstant','operandProperty','operator','markupEdit','markupSaveCancel','markupTitle','property','qualifiers','styleNames','version'];if(!noReports.includes(attributeName)){const msg="Attribute ".concat(attributeName," is not recognized");this.miscWarnings[msg]=null;}}});_defineProperty(this,"addUnknownElement",elementName=>{if(!this.strictModeExceptions||this.strictModeExceptions.shouldReportElement(elementName)){const msg="Element ".concat(elementName," is not recognized");this.miscWarnings[msg]=null;}});_defineProperty(this,"addUnpredictableUnboundHeight",()=>{const msg='Unpredictable results: Percent height used in scroll direction';this.miscWarnings[msg]=null;});_defineProperty(this,"addUnpredictableUnboundWidth",()=>{const msg='Unpredictable results: Percent width used in scroll direction';this.miscWarnings[msg]=null;});_defineProperty(this,"addV2AttributeValueWarning",(attribute,value,preferredValue)=>{const msg="Attribute ".concat(attribute," value: ").concat(value," -> ").concat(preferredValue);this.v2AttributeWarnings[msg]=null;});_defineProperty(this,"addV2AttributeWarning",(version,alias,preferredKey)=>{if(!version.isV1()){const msg="".concat(alias," -> ").concat(preferredKey);this.v2AttributeWarnings[msg]=null;}});_defineProperty(this,"addV2ElementWarning",(version,alias,preferredKey)=>{if(!version.isV1()){const msg="".concat(alias," -> ").concat(preferredKey);this.v2ElementWarnings[msg]=null;}// else {
//     // 23322 - silencing GML error's for now
//     this.v2ElementWarnings[preferredKey] = preferredKey;
// }
});_defineProperty(this,"addV1EquallySizedColWarning",()=>{// 23322 - silencing GML error's for now
// const msg = 'V1 does not support equallySized on a column.';
// this.miscWarnings[msg] = null;
});_defineProperty(this,"addV1ScrollWarning",()=>{// 23322 - silencing GML error's for now
// const msg = 'A V1 detail cannot use the scroll attribute';
// this.miscWarnings[msg] = null;
});_defineProperty(this,"addV2ScrollWarning",()=>{const msg='A detail with a header or footer cannot use the scroll attribute';this.miscWarnings[msg]=null;});_defineProperty(this,"addScrollValueWarning",function(){let value=arguments.length>0&&arguments[0]!==undefined?arguments[0]:'undefined';const msg="Value supplied to scroll attribute is not valid: ".concat(value);_this.miscWarnings[msg]=null;});_defineProperty(this,"report",()=>{if(this.strictMode.shouldReport()){const elementKeys=Object.keys(this.v2ElementWarnings);const attributeKeys=Object.keys(this.v2AttributeWarnings);const miscKeys=Object.keys(this.miscWarnings);const needToReport=elementKeys.length||attributeKeys.length||miscKeys.length;let message;if(needToReport){message='GML Issues\n\n';// Report elements
if(elementKeys.length){message+='Convert these elements from V1 syntax\n';message+=this.reportConversions(this.v2ElementWarnings);}// Report attributes
if(attributeKeys.length){message+=elementKeys.length?'\n\n':'';message+='Convert these attributes from V1 syntax\n';message+=this.reportConversions(this.v2AttributeWarnings);}// Misc issues
if(miscKeys.length){message+=elementKeys.length||attributeKeys.length?'\n\n':'';message+='General\n';message+=this.reportConversions(this.miscWarnings);}if(this.errorCallback)this.errorCallback(message);}}});_defineProperty(this,"reportConversions",messagesAndCounts=>{let message='';Object.keys(messagesAndCounts).forEach(e=>{message+="  ".concat(e,"\n");});return message;});_defineProperty(this,"setStrictMode",strictMode=>{this.strictMode=strictMode;});_defineProperty(this,"setStrictModeExceptions",strictModeExceptions=>{this.strictModeExceptions=strictModeExceptions;});this.errorCallback=errorCallback;}}