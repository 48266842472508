/* eslint-disable react/prefer-stateless-function */
/* eslint-disable jsx-a11y/no-onchange */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import serviceFactory from 'cv-react-core/src/services/serviceFactory';
import RichModal from '../modal/RichModal';
import Button, { BUTTON_VARIANT } from '../base/Button';
import TextLabel from '../base/TextLabel';


export default class SaltModal extends Component {
    static propTypes = {
        show: PropTypes.bool,
        onRestore: PropTypes.func,
        onSave: PropTypes.func,
        onTest: PropTypes.func,
        onClose: PropTypes.func,
        onSelectDocument: PropTypes.func,
        onDocumentChange: PropTypes.func,
        onDocumentNameChange: PropTypes.func,
        onDialogIdChange: PropTypes.func,
        title: PropTypes.string,
        salt: PropTypes.string,
        saltDocumentName: PropTypes.string,
        currentDialogId: PropTypes.string,
        documentNames: PropTypes.array,
        dialogNames: PropTypes.array,
        saveText: PropTypes.string,
        testText: PropTypes.string,
        closeText: PropTypes.string,
        restoreText: PropTypes.string,
        errorMessage: PropTypes.string,
    };

    render() {
        const {
            show,
            onRestore,
            onClose,
            onSave,
            onTest,
            onSelectDocument,
            onDocumentChange,
            onDocumentNameChange,
            onDialogIdChange,
            title,
            salt,
            saltDocumentName,
            currentDialogId,
            documentNames,
            dialogNames,
            saveText,
            testText,
            closeText,
            restoreText,
            errorMessage,
        } = this.props;

        if (errorMessage) {
            serviceFactory.notify.showError(errorMessage);
        }

        const actionsList = [];
        if (onClose) {
            actionsList.push(
                <Button
                    onClick={ onClose }
                    key="btnCancel"
                    text={ closeText } />
            );
        }
        if (onRestore) {
            actionsList.push(
                <Button
                    onClick={ onRestore }
                    key="btnRestore"
                    text={ restoreText } />
            );
        }
        if (onTest) {
            actionsList.push(
                <Button
                    onClick={ onTest }
                    variant={ BUTTON_VARIANT.OUTLINED }
                    key="btnTest"
                    text={ testText } />
            );
        }
        if (onSave) {
            actionsList.push(
                <Button
                    onClick={ onSave }
                    key="btnSave"
                    text={ saveText } />
            );
        }
        const props = { show, onClose, title, actionsList };
        return (
            <RichModal { ...props }>
                <div style={ { display: 'flex', flexDirection: 'column', minWidth: 600, minHeight: 500 } }>
                    <div style={ { display: 'flex', flexDirection: 'row', justifyContent: 'center' } }>
                        <TextLabel>Salt For View: </TextLabel>
                        <select
                            defaultValue={ currentDialogId }
                            onChange={ (event) => onDialogIdChange(event.target.value) }
                            style={ { marginLeft: 8, marginTop: 3, marginBottom: 5, display: 'flex', flexShrink: 1 } }>
                            { dialogNames.map((name) => (
                                <option
                                    key={ name.id }
                                    value={ name.id }>
                                    { name.name }
                                </option>
                            )) }
                        </select>
                    </div>
                    <textarea
                        spellCheck={ false }
                        value={ salt }
                        onChange={ (event) => onDocumentChange(event.target.value) }
                        style={ { display: 'flex', alignItems: 'stretch', flexGrow: 1, marginBottom: 10 } } />
                    <div style={ { display: 'flex', flexDirection: 'row', justifyContent: 'center' } }>
                        <TextLabel>Name: </TextLabel>
                        <input
                            placeholder="<No saved document>"
                            type="text"
                            onChange={ (event) => onDocumentNameChange(event.target.value) }
                            value={ saltDocumentName }
                            style={ { marginLeft: 8, marginRight: 10, marginTop: 1, display: 'flex', flexShrink: 1 } } />
                        { (documentNames && documentNames.length > 0) &&
                            <Fragment>
                                <TextLabel>Available Documents: </TextLabel>
                                <select
                                    defaultValue={ saltDocumentName }
                                    onChange={ (event) => onSelectDocument(event.target.value) }
                                    style={ { marginLeft: 8, marginTop: 1, display: 'flex', flexShrink: 1 } }>
                                    { documentNames.map((name) => (
                                        <option
                                            key={ name }
                                            value={ name }>
                                            { name }
                                        </option>
                                    )) }
                                </select>
                            </Fragment>
                             }
                    </div>
                </div>
            </RichModal>
        );
    }
}
