import React from 'react';
import PropTypes from 'prop-types';
import RWSaltComponent from './RWSaltComponent';
import ActivityIndicator from '../components/base/ActivityIndicator';
import TextLabel from '../components/base/TextLabel';

class RWLoading extends RWSaltComponent {
    static propTypes = {
        loadingText: PropTypes.string,
    };
    render() {
        const { loadingText } = this.props;
        return (
            <div style={ { display: 'flex', flexDirection: 'column', flexGrow: 1, justifyContent: 'center', alignItems: 'center', padding: '40px' } }>
                <ActivityIndicator />
                { loadingText &&
                    <div style={ { marginTop: '10px' } }>
                        <TextLabel>
                            { loadingText }
                        </TextLabel>
                    </div>
                }
            </div>
        );
    }
}

export default RWLoading;
