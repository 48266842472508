import { rootStore } from 'cv-react-core';

const TableHeaderLockedZIndex = 500;

/**
* Helper method to get the styles for Icon component
* @param {Object} contextStyles - Context styles for Icon Component
*/
const getStyles = (contextStyles = {}) => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();

    return {
        zIndex: TableHeaderLockedZIndex,
        backgroundColor: theme.dataTableHeadingBackgroundColor,
        borderRightColor: theme.dataTableGridBorderColor,
        ...contextStyles,
    };
};

export default getStyles;
