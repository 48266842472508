import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Graph } from 'cv-dialog-sdk';
import { rootStore } from 'cv-react-core';
import { ActivityIndicator } from 'cv-library-react-web';

import BarChart from '../components/base/BarChart';
import LineChart from '../components/base/LineChart';
import PieChart from '../components/base/PieChart';
import ScatterBubbleChart from '../components/base/ScatterBubbleChart';
import SwimlaneChart from '../components/base/SwimlaneChart';
import ChartFilter from '../components/charts/ChartFilter';
import TextLabel from '../components/base/TextLabel';

/**
 * This component was created to handle graph display.
 */
export default class RWGraph extends PureComponent {
    static propTypes = {
        plotType: PropTypes.string,
        filterable: PropTypes.bool,
        isLoading: PropTypes.bool,
        onFilterPress: PropTypes.func,
        filterArray: PropTypes.array,
        filterLabel: PropTypes.string,
        errorText: PropTypes.string,
    };

    render() {
        const { isLoading, filterable } = this.props;
        if (isLoading) {
            return (
                <div
                    style={ {
                        display: 'flex',
                        flexDirection: 'row',
                        flexGrow: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                    } }>
                    <ActivityIndicator
                        size="2rem" />
                </div>
            );
        }

        const {
            errorText,
            plotType,
        } = this.props;

        if (errorText) {
            const { themeStore } = rootStore;
            const theme = themeStore.getSanitizedTheme();
            const errorTextStyle = {
                text: {
                    color: theme.errorIconColor,
                    fontSize: theme.fonts.fontSizeMedium,
                    marginTop: 10,
                    marginBottom: 10,
                    ...theme.fonts.error,
                },
            };
            return (
                <TextLabel
                    contextStyles={ errorTextStyle }>
                    { errorText }
                </TextLabel>
            );
        }

        let component;
        if (plotType === Graph.PLOT_TYPE_BAR || plotType === Graph.PLOT_TYPE_STACKED) {
            component = React.createElement(BarChart, this.props);
        }
        else if (plotType === Graph.PLOT_TYPE_LINE) {
            component = React.createElement(LineChart, this.props);
        }
        else if (plotType === Graph.PLOT_TYPE_SCATTER || plotType === Graph.PLOT_TYPE_BUBBLE) {
            component = React.createElement(ScatterBubbleChart, this.props);
        }
        else if (plotType === Graph.PLOT_TYPE_SWIMLANE) {
            component = React.createElement(SwimlaneChart, this.props);
        }
        else {
            component = React.createElement(PieChart, this.props);
        }

        // Alan added column wraps to scale horizontally.
        return (
            <Fragment>
                <div
                    style={ { display: 'flex', flexDirection: 'column', flexGrow: 1} }
                    key="container_chart">
                    { component }
                </div>
                <div
                    style={ { display: 'flex', flexDirection: 'column', flexShrink: 1} }
                    key="container_filter">
                    { filterable && this.renderChartFilter() }
                </div>
            </Fragment>
        );
    }

    renderChartFilter = () => {
        const {
            filterArray,
            filterLabel,
            onFilterPress,
        } = this.props;
        const filterProps = {
            filterArray,
            headerText: filterLabel,
        };

        if (onFilterPress) { filterProps.onFilterPress = onFilterPress ; }

        return <ChartFilter { ...filterProps } />;
    }
}
