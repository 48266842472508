import _initializerDefineProperty from"/home/vsts/work/1/s/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty";import _defineProperty from"/home/vsts/work/1/s/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/defineProperty";import _applyDecoratedDescriptor from"/home/vsts/work/1/s/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor";import _initializerWarningHelper from"/home/vsts/work/1/s/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper";var _class,_descriptor,_descriptor2,_descriptor3,_descriptor4,_descriptor5,_descriptor6,_descriptor7;import{observable,action,configure,toJS}from'mobx';import{Log}from'cv-dialog-sdk';import{constants}from'../constants';import{themes}from'../theme';import{utilities}from'../utilities';import serviceFactory from'../services/serviceFactory';// eslint-disable-next-line no-irregular-whitespace
configure({enforceActions:'observed'});let ThemeStore=(_class=class ThemeStore{// tenantIdChanged = false;
constructor(rootStore){_initializerDefineProperty(this,"appLogo",_descriptor,this);_initializerDefineProperty(this,"bannerLogo",_descriptor2,this);_initializerDefineProperty(this,"appName",_descriptor3,this);_initializerDefineProperty(this,"currentTheme",_descriptor4,this);_initializerDefineProperty(this,"themeData",_descriptor5,this);_initializerDefineProperty(this,"setTheme",_descriptor6,this);_initializerDefineProperty(this,"setValue",_descriptor7,this);_defineProperty(this,"getValue",key=>{return this.themeData.get(key);});_defineProperty(this,"getSanitizedTheme",()=>{return toJS(this.currentTheme);});_defineProperty(this,"getSanitizedThemeData",()=>{const result=toJS(this.themeData);return result;});/**
     * Fetch branding login information from the tenant.
     * @returns { object } - Returns style information from the tenant in a flat object format.
     */_defineProperty(this,"getTenantBrandingThemeData",(defaultBrandingLoginJSON,brandingConstants)=>{let themeData={};// extracting and mapping the properties from brandingloginjson to theme format
Object.keys(defaultBrandingLoginJSON).forEach(label=>{if(brandingConstants[label]){const result=brandingConstants[label].split('.').reduceRight((val,key)=>({[key]:val}),defaultBrandingLoginJSON[label]);themeData={...themeData,...result};}});return themeData;});/**
     * Fetch custom GLM theme information from the tenant.
     * @returns { object } - Returns style information from the tenant in a flat object format.
     */_defineProperty(this,"getTenantThemeData",session=>{const themeData={};// Fetch tenant level GML & bail if none exists.
const tenantGMLXML=session.tenantProperties.GMLDefaults||'';if(tenantGMLXML==='')return undefined;// Parse GML into a json object.
const options={explicitChildren:true,preserveChildrenOrder:true,trim:true};const parseXmlString=serviceFactory.xmlParser;parseXmlString(tenantGMLXML,options,(error,gmlJson)=>{if(error){Log.error("Error parsing mobileTheme from the tenant level GML:\n: ".concat(utilities.prettyXmlParseError(tenantGMLXML,error),"\nThe offending line may be before or after this line."),'getTenantThemeData','SessionStore');}if(!error&&gmlJson){// Fetch object data needed to set theme
const mobileTheme=gmlJson.gml.$$.find(f=>f['#name']==='mobileTheme');this.getStyleObject(mobileTheme,themeData);}});return themeData;});/**
     * Extracts data object from GML based on property name.
     * @param { object } tenantGML - tenant level GML
     * @param { object } styleDataIn - object that is populated with found style content.
     */_defineProperty(this,"getStyleObject",(tenantGML,styleDataIn)=>{const styleData=styleDataIn;if(!tenantGML||!tenantGML.$$)return;// Add each attribute to styleData.
tenantGML.$$.forEach(attribute=>{const stringValue=attribute._;if(attribute.$$===undefined){// Process this attribute directly.
const{name}=attribute.$;styleData[name]=Number.isNaN(Number(stringValue))?stringValue:Number(stringValue);}else{// This attribute is really structure for child attributes.
const name=attribute['#name'];const childStyle={};styleData[name]=childStyle;this.getStyleObject(attribute,childStyle);}});});/**
     * Updating the cacheStore with theme infromation
     */_defineProperty(this,"saveThemeStoreCache",()=>{const{cacheStore}=this.rootStore;cacheStore.setCacheType(cacheStore.cacheStores.THEME,this.getSanitizedThemeData());});/**
     * restore the theming infromation from cacheStore and updating the themStore
     */_defineProperty(this,"restoreFromCache",cacheData=>{const{theme}=cacheData;if(theme){this.themeData.clear();Object.keys(theme).forEach(key=>{this.setValue(key,theme[key]);});}// Load theme for the current tenant
this.setTheme(themes.themeFactory(this.rootStore).getTheme(this.rootStore.settingsStore.settings.get('tenantId')));});this.rootStore=rootStore;}/**
     * Processing the tenantCapabilities information to update the default Branding theming
     */processTenantBrandingThemeData(tenantCapabilities,tenantId){// Update the saved tenant values with new values from the Tenant Capabilities info.
const valuesByTenant=this.getValue(constants.storage.THEME)||{};if(tenantCapabilities&&tenantCapabilities.defaultBrandingLoginJSON){const{loginBranding}=themes.brandMappingConstant;valuesByTenant[tenantId]=this.getTenantBrandingThemeData(JSON.parse(tenantCapabilities.defaultBrandingLoginJSON),loginBranding);this.setValue(constants.storage.THEME,valuesByTenant);}// Set the current theme now that tenant branding login json values are available to the factory.
this.setTheme(themes.themeFactory(this.rootStore).getTheme(tenantId));}/**
     * Processing the session infromation to update the login theming ( GML Defaults)
     */processTenantLoginThemeData(session){// Update the saved tenant values with new values from this session.
const valuesByTenant=this.getValue(constants.storage.THEME)||{};if(valuesByTenant[session.tenantId]){const{tenantProperties:{brandingLauncherJSON}}=session;const{loggedInBranding}=themes.brandMappingConstant;// Added minimal valid json string after || operator, so that JSON.parse does not throw error when brandingLauncherJSON not provided, however will still throw error if provided brandingLauncherJSON is not a valid Json
const brandingLauncherStyles=this.getTenantBrandingThemeData(JSON.parse(brandingLauncherJSON||'{}'),loggedInBranding);valuesByTenant[session.tenantId]={...brandingLauncherStyles,...this.getTenantThemeData(session)};}else{valuesByTenant[session.tenantId]=this.getTenantThemeData(session);}this.setValue(constants.storage.THEME,valuesByTenant);// Set the current theme now that tenant values are available to the factory.
this.setTheme(themes.themeFactory(this.rootStore).getTheme(session.tenantId));}},(_descriptor=_applyDecoratedDescriptor(_class.prototype,"appLogo",[observable],{configurable:true,enumerable:true,writable:true,initializer:function(){return'';}}),_descriptor2=_applyDecoratedDescriptor(_class.prototype,"bannerLogo",[observable],{configurable:true,enumerable:true,writable:true,initializer:function(){return'';}}),_descriptor3=_applyDecoratedDescriptor(_class.prototype,"appName",[observable],{configurable:true,enumerable:true,writable:true,initializer:function(){return'';}}),_descriptor4=_applyDecoratedDescriptor(_class.prototype,"currentTheme",[observable],{configurable:true,enumerable:true,writable:true,initializer:function(){return{};}}),_descriptor5=_applyDecoratedDescriptor(_class.prototype,"themeData",[observable],{configurable:true,enumerable:true,writable:true,initializer:function(){return new Map();}}),_descriptor6=_applyDecoratedDescriptor(_class.prototype,"setTheme",[action],{configurable:true,enumerable:true,writable:true,initializer:function(){return theme=>{// Set currentTheme style properties for application
this.currentTheme=theme;this.appLogo=this.currentTheme.appLogo;this.appName=this.currentTheme.appName;this.bannerLogo=this.currentTheme.bannerLogo;this.appTopLogo=this.currentTheme.appTopLogo;};}}),_descriptor7=_applyDecoratedDescriptor(_class.prototype,"setValue",[action],{configurable:true,enumerable:true,writable:true,initializer:function(){return(key,value)=>{// Check and see if we are getting a new tenant value. If so we need to restore cached instance.
// if (key === constants.session.TENANT_ID && value !== this.settings.get(constants.session.TENANT_ID)) {
//     this.tenantIdChanged = true;
// }
// Set the new value
this.themeData.set(key,value);this.saveThemeStoreCache();};}})),_class);export{ThemeStore as default};