/**
* Helper method to get the styles for Icon component
* @param {bool} isNumericType - Used to place text
*/
const getStyles = (isNumeric) => {
    return {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: isNumeric ? 'flex-end' : 'unset',
    };
};

export default getStyles;
