import RWBox from './RWBox';
import RWCalendar from './RWCalendar';
import RWText from './RWText';
import RWImage from './RWImage';
import RWAction from './RWAction';
import RWTable from './RWTable';
import RWMenu from './RWMenu';
import RWProperty from './RWProperty';
import RWDefaultList from './RWDefaultList';
import RWCompactList from './RWCompactList';
import RWGridList from './RWGridList';
import RWPanel from './RWPanel';
import RWSearchSort from './RWSearchSort';
import RWPowerBI from './RWPowerBI';
import RWMap from './RWMap';
import RWGraph from './RWGraph';
import RWQuickSearch from './RWQuickSearch';
import RWImagePicker from './RWImagePicker';
import RWCalculateStatistics from './RWCalculateStatistics';
import RWViz from './RWViz';
import RWGridTable from './RWGridTable';
import RWMaintainableList from './RWMaintainableList';
import RWLoading from './RWLoading';

import TabForm from '../components/layouts/TabForm/TabForm';
import SingleForm from '../components/layouts/SingleForm/SingleForm';
import VerticalForm from '../components/layouts/VerticalForm/VerticalForm';
import ThreeOneOverForm from '../components/layouts/ThreeOneOverForm/ThreeOneOverForm';
import ThreeOneLeftForm from '../components/layouts/ThreeOneLeftForm/ThreeOneLeftForm';
import ThreeOneUnderForm from '../components/layouts/ThreeOneUnderForm/ThreeOneUnderForm';
import ThreeOneRightForm from '../components/layouts/ThreeOneRightForm/ThreeOneRightForm';
import FourBoxSquareForm from '../components/layouts/FourBoxSquareForm/FourBoxSquareForm';
import HorizontalForm from '../components/layouts/HorizontalForm/HorizontalForm';
import MultipleForm from '../components/layouts/MultipleForm/MultipleForm';
import GridForm from '../components/layouts/GridForm/GridForm';
import RWSaltEditor from './RWSaltEditor';

export default {
    // Salt component implementations
    box: RWBox,
    calendar: RWCalendar,
    text: RWText,
    image: RWImage,
    action: RWAction,
    table: RWTable,
    menu: RWMenu,
    property: RWProperty,
    panel: RWPanel,
    defaultList: RWDefaultList,
    compactList: RWCompactList,
    gridList: RWGridList,
    gridTable: RWGridTable,
    maintainableList: RWMaintainableList,
    searchSort: RWSearchSort,
    calculateStatistics: RWCalculateStatistics,
    powerbi: RWPowerBI,
    map: RWMap,
    graph: RWGraph,
    quickSearch: RWQuickSearch,
    imagePicker: RWImagePicker,
    viz: RWViz,
    saltEditor: RWSaltEditor,
    loading: RWLoading,

    // Form Layouts
    // note: the server uses these key names directly
    DEFAULT_LAYOUT: VerticalForm,
    TOP_DOWN: VerticalForm,
    SINGLE: SingleForm,
    TABS: TabForm,
    FOUR_BOX_SQUARE: FourBoxSquareForm,
    THREE_ONE_LEFT: ThreeOneLeftForm,
    THREE_ONE_OVER: ThreeOneOverForm,
    THREE_ONE_RIGHT: ThreeOneRightForm,
    THREE_ONE_UNDER: ThreeOneUnderForm,
    'H(V(1,2),1)': ThreeOneLeftForm,
    'H(2,V(*))': ThreeOneUnderForm,
    'H(2,2)': FourBoxSquareForm,
    'H(*)': HorizontalForm,
    FLOWING: MultipleForm,
    FLOWING_TOP_DOWN: MultipleForm,
    GRID_LAYOUT: GridForm,

    // platform specific default flex direction
    PLATFORM_DEFAULT_FLEX_DIRECTION: 'row',

    // how to handle additional form layout elements
    // PLATFORM_FORM_OVERFLOW_POLICY: 'TABS',

    PLATFORM_FORM_OVERFLOW_POLICY: 'TOP_DOWN',

    // Show a confirmation for unsaved changes when firing an action?
    PLATFORM_SAVE_CONFIRMATION_POLICY: true,
};
