import React, { PureComponent } from 'react';
import * as PropTypes from 'prop-types';

import Icon from '../Icon';
import TextLabel from '../TextLabel';
import getStyles from './GridHeaderCell.styles';

class GridHeaderCellGroup extends PureComponent {
    static propTypes = {
        /** Name of column header */
        displayName: PropTypes.string.isRequired,

        /** Extended styles for this component */
        contextStyles: PropTypes.shape({
            /** Styles for the header cell container */
            headerCell: PropTypes.object,

            /** Styles for header cell text */
            headerCellText: PropTypes.object,
        }),

        /** Indicates if menu needs to be enabled */
        enableMenu: PropTypes.bool,

        /** Callback to trigger in order to get the menu */
        showColumnMenu: PropTypes.func,
    };

    static defaultProps = {
        contextStyles: {},
    };

    render() {
        const {
            contextStyles,
            displayName,
            enableMenu,
        } = this.props;

        const {
            headerCell,
            headerCellMenuIcon,
            headerCellText,
        } = getStyles(contextStyles);
        const textLabelProps = {
            contextStyles: {
                text: headerCellText,
            },
        };

        let menu = null;
        if (enableMenu) {
            menu = (
                <div
                    ref={ (menuButton) => {
                        this.menuButton = menuButton;
                    } }
                    onClick={ this.handleMenuClick }>
                    <Icon
                        contextStyles={ {
                            icon: headerCellMenuIcon,
                        } }
                        iconName="menu" />
                </div>
            );
        }

        return (
            <div
                style={ headerCell }
                className="group-header-cell">
                <TextLabel { ...textLabelProps }>
                    { displayName }
                </TextLabel>
                { menu }
            </div>
        );
    }

    handleMenuClick = () => {
        const { showColumnMenu } = this.props;
        showColumnMenu(this.menuButton);
    }
}

export default GridHeaderCellGroup;
