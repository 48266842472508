import { Slider } from 'cv-library-react-web';
import { themes } from 'cv-react-core';

// Get theme method
import getTheme from './Slider.theme';

// Get theme component method
const { themeComponent } = themes;

// Generate and export a themed component with themed parts
export default themeComponent(Slider, getTheme);
