import { rootStore } from 'cv-react-core';

const getTheme = () => {
    const { themeStore } = rootStore;
    const theme = themeStore.getSanitizedTheme();
    const {
        color,
        fontSize,
    } = theme.fonts.default;

    // Populate the component parts with theme styles
    return {
        container: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },

        checkboxContainer: {
        },

        checkbox: {
            color: theme.checkBoxUnCheckedBackgroundColor,
        },

        checkboxSelected: {
            color: theme.checkBoxCheckedBackgroundColor,
        },

        dropDown: {
            borderWidth: theme.htmlDropDownBorderWidth,
            borderStyle: theme.htmlDropDownBorderStyle,
            borderColor: theme.htmlDropDownBorderColor,
            borderRadius: theme.htmlDropDownBorderRadius,
            backgroundColor: theme.detailAttributeBackgroundColor,
            paddingLeft: '8px',
            paddingTop: '2px',
            paddingBottom: '3px',
            ...theme.fonts.default,
        },

        dropDownFocused: {
            borderColor: theme.textInputUnderlineColor,
        },

        icon: {
            display: 'flex',
            color,
            fontSize,
        },

        input: {
            ...theme.fonts.default,
        },

        menu: {
        },

        menuImageLoadingIndicator: {
            color: theme.statusLoadingIndicatorColor,
        },

        menuItem: {
            ...theme.fonts.default,
            backgroundColor: theme.htmlDropDownMenuBackgroundColor,
            color: theme.htmlDropDownMenuTextColor,
        },

        selectedItem: {
            ...theme.fonts.default,
        },
    };
};

export default getTheme;
