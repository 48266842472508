import ActionIconComponentBuilder from './ActionIconComponentBuilder';
import WebActionIcon from '../../components/base/WebActionIcon';

class WebActionComponentBuilder extends ActionIconComponentBuilder {
    constructor() {
        super();
        this.setActionIconComponent(WebActionIcon);
    }

    processAction() {
        // Populate custom action data
        this.processActionData('OPEN_URL');

        // Finish action processing after custom action data populated
        super.processAction();
    }
}

export default WebActionComponentBuilder;
