const getTheme = () => {
    // Populate the component parts with theme styles
    return {
        cell: {
            padding: '2px',
        },
        cellSelected: {
        },
        container: {
        },
        filterCell: {
            height: '110px',
        },
        filterCellApplyButton: {
        },
        filterCellApplyButtonIcon: {
        },
        filterCellButton: {
            padding: '6px',
            marginLeft: '8px',
        },
        filterCellButtonIcon: {
            fontSize: '14px',
        },
        filterCellCancelButton: {
        },
        filterCellCancelButtonIcon: {
        },
        filterCellCriteriaButton: {
            marginRight: '8px',
        },
        filterCellCriteriaButtonIcon: {
        },
        filterCellCriteriaMenu: {
        },
        filterCellCriteriaMenuItem: {
        },
        filterCellCriteriaMenuItemIcon: {
        },
        filterCellCriteriaMenuItemText: {
        },
        filterCellDateTimeActionButtons: {
        },
        filterCellDateTimeActionLabels: {
        },
        filterCellDateTimeActionsContainer: {
        },
        filterCellDateTimeCalendarButton: {
        },
        filterCellDateTimeCalendarButtonIcon: {
        },
        filterCellDateTimeCalendarContainer: {
        },
        filterCellDateTimeCalendarDayLabel: {
        },
        filterCellDateTimeCalendarDaysHeader: {
        },
        filterCellDateTimeCalendarHeaderLabel: {
        },
        filterCellDateTimeCalendarSwitchHeader: {
        },
        filterCellDateTimeCalendarWeek: {
        },
        filterCellDateTimeClock: {
        },
        filterCellDateTimeClockContainer: {
        },
        filterCellDateTimeClockNumber: {
        },
        filterCellDateTimeClockNumberSelected: {
        },
        filterCellDateTimeClockPin: {
        },
        filterCellDateTimeClockPointer: {
        },
        filterCellDateTimeClockPointerNoPoint: {
        },
        filterCellDateTimeClockPointerThumb: {
        },
        filterCellDateTimeDay: {
        },
        filterCellDateTimeDayCurrent: {
        },
        filterCellDateTimeDayDisabled: {
        },
        filterCellDateTimeDayHidden: {
        },
        filterCellDateTimeDaySelected: {
        },
        filterCellDateTimeDialog: {
        },
        filterCellDateTimeDialogContent: {
        },
        filterCellDateTimeInput: {
        },
        filterCellDateTimeInputIcon: {
        },
        filterCellDateTimeInputIconContainer: {
        },
        filterCellDateTimeInputUnderline: {
        },
        filterCellDateTimeInputUnderlineFocus: {
        },
        filterCellDateTimeInputUnderlineHover: {
        },
        filterCellDateTimeTabButtons: {
        },
        filterCellDateTimeTabIndicator: {
        },
        filterCellDateTimeTabs: {
        },
        filterCellDateTimeText: {
        },
        filterCellDateTimeToolbar: {
        },
        filterCellDateTimeToolbarButton: {
        },
        filterCellDateTimeToolbarButtonLabels: {
        },
        filterCellDateTimeToolbarSeparator: {
        },
        filterCellDateTimeView: {
        },
        filterCellDateTimeYear: {
        },
        filterCellDateTimeYearContainer: {
        },
        filterCellDateTimeYearDisabled: {
        },
        filterCellDateTimeYearSelected: {
        },
        filterCellInput: {
            fontSize: '12px',
        },
        filterCellInputAdornment: {
            fontSize: '16px',
        },
        filterCellInputUnderline: {
        },
        filterCellInputUnderlineFocus: {
        },
        filterCellInputUnderlineHover: {
        },
        filterCellSortAscendingButton: {
        },
        filterCellSortAscendingButtonBadge: {
            height: '12px',
            minWidth: '8px',
            fontSize: '8px',
        },
        filterCellSortAscendingButtonIcon: {
        },
        filterCellSortClearButton: {
        },
        filterCellSortClearButtonIcon: {
        },
        filterCellSortDescendingButton: {
        },
        filterCellSortDescendingButtonBadge: {
            height: '12px',
            minWidth: '8px',
            fontSize: '8px',
        },
        filterCellSortDescendingButtonIcon: {
        },
        filterCellToggleThumb: {
            height: '16px',
            width: '16px',
        },
        filterCellToggleThumbContainer: {
            padding: '6px',
        },
        filterCellToggleThumbContainerSelected: {
            transform: 'translateX(100%)',
        },
        filterCellToggleThumbSelected: {
        },
        filterCellToggleTrack: {
        },
        filterCellToggleTrackContainer: {
            height: '28px',
            padding: '12px',
        },
        filterCellToggleTrackSelected: {
        },
        filterCellResetButton: {
        },
        filterCellResetButtonIcon: {
        },
        filterRow: {
        },
        headerCell: {
            padding: '4px',
        },
        headerCellButton: {
            padding: '6px',
            marginLeft: '8px',
        },
        headerCellButtonBadge: {
            height: '12px',
            minWidth: '8px',
            fontSize: '8px',
        },
        headerCellButtonIcon: {
            fontSize: '14px',
        },
        headerCellFilterButton: {
        },
        headerCellFilterButtonIcon: {
        },
        headerCellLockButton: {
        },
        headerCellLockButtonIcon: {
        },
        headerCellText: {
            fontSize: '12px',
        },
        headerRow: {
        },

        // only visible when cells have transparent background color
        row: {
        },

        // only visible when cells have transparent background color
        rowSelected: {
        },
        rowSelectionCell: {
            textAlign: 'center',
        },
        rowSelectionCellCheck: {
            width: '16px',
            height: '16px',
        },
        rowSelectionCellChecked: {
        },
        rowSelectionCellSelected: {
        },
        rowSelectionTray: {
        },
        rowSelectionTrayCloseButton: {
        },
        rowSelectionTrayCloseButtonIcon: {
        },
        rowSelectionTrayText: {
        },
    };
};

export default getTheme;
