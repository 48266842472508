import { Component } from 'react';
import * as PropTypes from 'prop-types';

// eslint-disable-next-line react/prefer-stateless-function
export default class RWSaltComponent extends Component {
    static propTypes = {
        // eslint-disable-next-line react/no-unused-prop-types
        style: PropTypes.oneOfType([
            PropTypes.object,
            // eslint-disable-next-line react/no-unused-prop-types
            PropTypes.arrayOf(PropTypes.object),
        ]),
        params: PropTypes.shape({
            viewId: PropTypes.string,
        }),
    };
}
