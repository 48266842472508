import { DataTable, DataTableCell, DataTableRow, SORT_DIRECTIONS } from 'cv-library-react-web';
import { themes } from 'cv-react-core';

// Get theme method
import getTheme from './DataTable.theme';
import getCompactTheme from './DataTable.theme.compact';

// Get theme component method
const { themeComponent } = themes;

// Generate and export a themed component
const CompactDataTable = themeComponent(DataTable, getCompactTheme);
export default themeComponent(CompactDataTable, getTheme);
export {
    DataTableCell,
    DataTableRow,
    SORT_DIRECTIONS,
};
