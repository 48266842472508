import React from 'react';
import PropTypes from 'prop-types';
import Prompt from '../base/Prompt';


const RichModal = (({ show, onCancel, onConfirm, title, children, actionsList, contextStyles }) => (
    <Prompt
        contextStyles={ contextStyles }
        isModalOpen={ show }
        isTransparentOverlay
        onClose={ onCancel }
        onSubmit={ onConfirm }
        title={ title }
        actions={ actionsList }>
        { children }
    </Prompt>
    ));

RichModal.propTypes = {
    show: PropTypes.bool,
    onCancel: PropTypes.func,
    onConfirm: PropTypes.func,
    title: PropTypes.string,
    children: PropTypes.element,
    contextStyles: PropTypes.object,
    actionsList: PropTypes.array,
};

export default RichModal;
